import api from '../../utils/api.js'
import { setAlert } from './alert'

import {
  CREATE_BIKE,
  CUSTOMER_BIKES_LOADED,
  UPDATE_BIKE,
  CLEAR_CUSTOMER_BIKES,
  DELETE_BIKE
} from './types'

// Create Bike
export const createBike = (formData, cyclistId, alert) => async (dispatch) => {
  try {
    const res = await api.post(`/bikes/${cyclistId}`, formData)

    dispatch({
      type: CREATE_BIKE,
      payload: res.data
    })
    if (alert) dispatch(setAlert('Bike Created', 'success'))
    dispatch(loadCustomerBikes(cyclistId))
    return res.data._id
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

// Get All Bikes for Customer based on Customer Id
export const loadCustomerBikes = (customerId) => async (dispatch) => {
  try {
    const res = await api.get(`/bikes/by-customer/${customerId}`)

    dispatch({
      type: CUSTOMER_BIKES_LOADED,
      payload: res.data
    })
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    dispatch({
      type: CLEAR_CUSTOMER_BIKES
    })

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}

//Update Bike

export const updateBike =
  (bikeId, formData, alertMessage) => async (dispatch) => {
    if (!formData.updated) formData.updated = new Date()
    try {
      const res = await api.put(`/bikes/${bikeId}`, formData)

      dispatch({ type: UPDATE_BIKE, payload: res.data })

      if (alertMessage) dispatch(setAlert(alertMessage, 'success'))
      dispatch(loadCustomerBikes(res.data.ownedBy))
    } catch (err) {
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
      }
    }
  }

//Delete Bike---Fix this

export const deleteBike = (bikeId) => async (dispatch) => {
  try {
    await api.delete(`/bikes/${bikeId}`)

    dispatch({ type: DELETE_BIKE, payload: bikeId })

    dispatch(setAlert('Bike Deleted', 'success'))
  } catch (err) {
    console.error(err)
  }
}

// Get All Bikes for Logged in User
export const loadUserBikeList = () => async (dispatch) => {
  try {
    const res = await api.get(`/bikes/by-user`)

    return res.data
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}
