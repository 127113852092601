import React from 'react'
import { Card, Tab, Row, Col, Nav } from 'react-bootstrap'
import QuickSize from './quicksize/QuickSize'
import BarToFrame from './bartoframe/BarToFrame'
import FrameToBar from './frametobar/FrameToBar'
import StemDelta from './stemDelta/StemDelta'
import QuickSize2 from './quicksize2/QuickSize2'
import QuickStackReach from './quickstackreach/QuickStackReach'
import { ADMIN_ICON_FKS } from '../../utils/fontAwesomeDefaults'

const ToolsTabs = ({ user, fieldOverrides }) => {
  return (
    <Tab.Container id='left-tabs-example' defaultActiveKey='quick-size'>
      <Row>
        <Col sm={3}>
          <Card>
            <Nav variant='pills' className='flex-column'>
              <Nav.Item>
                <Nav.Link eventKey='quick-size'>Quick Size</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='bar-to-frame'>Bar XY to Frame XY</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='frame-to-bar'>Frame XY to Bar XY</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='stem-delta'>Stem Delta Calculator</Nav.Link>
              </Nav.Item>
              {user.role === 'admin' && (
                <Nav.Item>
                  <Nav.Link eventKey='quick-size-2'>
                    <i className={ADMIN_ICON_FKS} /> Quick Size 2.0
                  </Nav.Link>
                </Nav.Item>
              )}
              {user.role === 'admin' && (
                <Nav.Item>
                  <Nav.Link eventKey='quick-stack-reach'>
                    <i className={ADMIN_ICON_FKS} /> Quick Stack/Reach
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Card>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey='quick-size'>
              <QuickSize user={user} fieldOverrides={fieldOverrides} />
            </Tab.Pane>
            <Tab.Pane eventKey='bar-to-frame'>
              <BarToFrame user={user} fieldOverrides={fieldOverrides} />
            </Tab.Pane>
            <Tab.Pane eventKey='frame-to-bar'>
              <FrameToBar user={user} fieldOverrides={fieldOverrides} />
            </Tab.Pane>
            <Tab.Pane eventKey='stem-delta'>
              <StemDelta user={user} fieldOverrides={fieldOverrides} />
            </Tab.Pane>
            {user.role === 'admin' && (
              <Tab.Pane eventKey='quick-size-2'>
                <QuickSize2 user={user} fieldOverrides={fieldOverrides} />
              </Tab.Pane>
            )}
            {user.role === 'admin' && (
              <Tab.Pane eventKey='quick-stack-reach'>
                <QuickStackReach user={user} fieldOverrides={fieldOverrides} />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
}

export default ToolsTabs
