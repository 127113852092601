import React from 'react'
import { Link } from 'react-router-dom'
import { Form, Col, Row, Button, Container } from 'react-bootstrap'

const LandingLoginForm = ({ form, disabled, onSubmit }) => {
  return (
    <Container className='mt-3 mb-2'>
      <Form onSubmit={onSubmit}>
        <Row>
          {Object.values(form).map((input, index) => {
            return (
              <Col key={index}>
                <Form.Control {...input} size='sm' placeholder={input.label} />
                {input.type === 'password' && (
                  <div className='mt-3 text-center'>
                    <Link to='/password-reset-request'>
                      {' '}
                      Lost your password?
                    </Link>
                  </div>
                )}
              </Col>
            )
          })}

          <Col>
            <Button
              size='sm'
              style={{ minWidth: '100%' }}
              className='btn btn-dark'
              type='submit'
              disabled={disabled}
            >
              Login
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default LandingLoginForm
