import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'

const DefaultFormGroup = ({
  label,
  value,
  type,
  name,
  isValid,
  isInvalid,
  onChange,
  placeholder,
  as,
  rows,
  spellCheck,
  min,
  max,
  step,
  helpmessage,
  errormessage,
  error,
  inline,
  mode
}) => {
  if (type === 'date' && value) value = value.substring(0, 10)
  return (
    <>
      <Form.Group className='mb-3 mt-3'>
        <Form.Label>
          {label && label}{' '}
          {type === 'password' && error && (
            <span className='ms-3 text-danger' style={{ fontSize: '.8rem' }}>
              <strong>{errormessage}</strong>
            </span>
          )}
        </Form.Label>

        <InputGroup size='sm'>
          <Form.Control
            size={'sm'}
            as={as}
            rows={rows}
            spellCheck={spellCheck}
            value={value}
            type={type}
            isValid={isValid}
            isInvalid={isInvalid}
            onChange={onChange}
            name={name}
            placeholder={placeholder}
            min={min || 0}
            max={max}
            step={step}
            inline={inline}
          />
        </InputGroup>
      </Form.Group>
      {type === 'password' &&
        label === 'Password' &&
        (mode === 'Register' ||
          mode === 'Reset Password' ||
          mode === 'Edit Password') && (
          <p>
            <i>{helpmessage}</i>
          </p>
        )}
    </>
  )
}

export default DefaultFormGroup
