import React from 'react'

import { DOWNLOAD_ICON } from '../../utils/fontAwesomeDefaults'

import Tip from '../layout/overlays/Tip'

const DownloadDataButton = ({ csv, tip, filename }) => {
  const downloadData = () => {
    let hiddenElement = document.createElement('a')
    hiddenElement.href =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
    hiddenElement.target = '_blank'
    hiddenElement.download =
      filename + '-' + new Date().toISOString().substring(0, 10) + '.csv'
    hiddenElement.click()
  }

  return (
    <Tip message={tip} placement='top'>
      <i
        style={{ cursor: 'pointer' }}
        className={DOWNLOAD_ICON}
        onClick={downloadData}
      />
    </Tip>
  )
}

export default DownloadDataButton
