import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form } from 'react-bootstrap'
import { useStripe } from '@stripe/react-stripe-js'
import { createStripeSubscription } from '../../../redux/actions/stripe'

import {
  getSubscriptionPrice,
  getFrequencyValue
} from '../../../utils/stripeUtils'

import Spinner from '../../layout/Spinner'
import PaymentFrequencyForm from './PaymentFrequencyForm'
import PropTypes from 'prop-types'

const ActivateNewTrialButton = ({
  users: { user },
  subscription,
  createStripeSubscription,
  confirmationPrompt,
  confirmationText,
  confirmationTextInput,
  clickCancel
}) => {
  const stripe = useStripe()

  const [processing, setProcessing] = useState(false)

  const { plan } = subscription
  const [frequency, setFrequency] = useState(getFrequencyValue(plan))

  const [showModal, setShowModal] = useState(false)

  const handleCancel = () => {
    setShowModal(false)
    setFrequency(getFrequencyValue(plan))
    clickCancel()
  }

  const clickActivate = async (e) => {
    if (!stripe) {
      return
    }
    setProcessing(true)

    await createStripeSubscription({
      name: user.name,
      email: user.email,
      customerId: user.stripe_customer_id,
      trial: true,
      frequency
    })

    setShowModal(false)
    setProcessing(false)
  }

  return (
    <>
      <div className='d-grid gap-2'>
        <Button variant='dark' onClick={() => setShowModal(true)}>
          Activate Free Trial
        </Button>
      </div>
      <Modal
        show={showModal}
        onHide={clickCancel}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Activate Subscription to Quick Fit
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Subscription: Quick Fit
          <br />
          <span className='me-3'>Payment Frequency:</span>{' '}
          <PaymentFrequencyForm
            inline
            frequency={frequency}
            setFrequency={setFrequency}
          />
          <Form.Label>
            Amount: U.S. ${getSubscriptionPrice(frequency)}
          </Form.Label>
          <p className='mt-4'>
            {' '}
            You will not be charged until your trial period ends. After your
            trial period, you will be charged ${getSubscriptionPrice(
              frequency
            )}{' '}
            for your {frequency === 'six months' ? 'six month' : frequency}{' '}
            subscription. You may cancel or change plans at any time.
          </p>
          <Form.Group controlId='formText'>
            <Form.Label>
              {confirmationPrompt} type{' '}
              <i>
                <b>{confirmationText}</b>
              </i>{' '}
              below.
            </Form.Label>
            <Form.Control
              {...confirmationTextInput}
              autoFocus
              placeholder={confirmationText}
            />
          </Form.Group>
        </Modal.Body>

        {processing && (
          <Modal.Footer className='d-flex justify-content-center'>
            <span>
              <Spinner message='Processing . . .' />
            </span>
          </Modal.Footer>
        )}
        {!processing && (
          <Modal.Footer>
            <Button
              disabled={!confirmationTextInput.isValid}
              onClick={clickActivate}
              variant='danger'
            >
              <i className='fa-regular fa-dollar-sign' /> Activate Subscription
            </Button>{' '}
            <Button variant='secondary' onClick={handleCancel}>
              Cancel
            </Button>
            <Button disabled variant='outline-light'>
              <img
                src='/stripe-black.svg'
                alt='Powered by Stripe'
                width='100'
              />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  )
}

ActivateNewTrialButton.propTypes = {
  createStripeCustomer: PropTypes.func.isRequired,
  createStripeSubscription: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  users: state.users,
  mode: PropTypes.func.isRequired,
  createStripeCustomer: PropTypes.func.isRequired,
  createStripeSubscription: PropTypes.func.isRequired
})

export default connect(mapStateToProps, {
  createStripeSubscription
})(ActivateNewTrialButton)
