import { useEffect } from 'react'
import MainHeading from '../../layout/MainHeading'
import { Link } from 'react-router-dom'
import { TOOLBOX_ICON } from '../../../utils/fontAwesomeDefaults'
import PricingInfo from '../../account/dashboard/PricingInfo'
import QuickFitFeatures from '../../account/dashboard/QuickFitFeatures'
import SEO from '../../seo/SEO'

const QuickFitDescription = () => {
  useEffect(() => {
    document.body.classList.add('bodyPad')

    return () => {
      document.body.classList.remove('bodyPad')
    }
  }, [])
  return (
    <>
      <SEO
        title='Fit Kit Studio | Free Tools'
        description={`Fit Kit Studio: software for bike fitting professionals. Fit Kit Studio's Quick Fit subscription service provides a full suite of features for bike fitters, including cloud storage of pre-fit customer interview forms, customer profiles, bike equipment, fit position and fit history data, customizable summary PDF reports for printing or emailing to customers, automated customer intake processes, and customized bike shop or fitting studio branding.`}
        type='website'
        name='Fit Kit Systems'
      />
      <MainHeading
        heading='Quick Fit'
        headingIcon={TOOLBOX_ICON}
        subheading={
          'All registered users are eligible for a free 60-day trial.'
        }
      />
      <QuickFitFeatures />
      <PricingInfo />
      <p>
        To subscribe to Quick Fit, first <Link to='/register'>sign up</Link> for
        a free Fit Kit Studio account or <Link to='/login'>login</Link> to your
        existing account if you already have one. You will then be able to
        purchase a subscription by visiting your My Account/Settings page and
        selecting "Subscription."
      </p>
    </>
  )
}

export default QuickFitDescription
