import { useNavigate } from 'react-router-dom'

import { connect } from 'react-redux'

import EditFormButton from '../../hook-components/EditFormButton'
import FormModal from '../../layout/modals/FormModal'
import GenerateForm from '../../forms/form-components/GenerateForm'
import useCustomerProfileForm from '../../hooks/form-hooks/customers/useCustomerProfileForm'
import useFormModal from '../../hooks/modal-hooks/useFormModal'

import { createCyclist } from '../../../redux/actions/cyclists'

import { CYCLIST_ICON } from '../../../utils/fontAwesomeDefaults'
import DuplicateCustomerWarning from './DuplicateCustomerWarning'

const AddNewCustomer = ({
  cyclists: { cyclists },
  fields: { fieldOverrides },
  createCyclist,
  link,
  navLink,
  tip,
  interview
}) => {
  let cyclistProfile = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    zipCode: '',
    birthDate: '',
    gender: 'Male'
  }

  let bodyMeasurements = {
    inseam: 0,
    footLength: 0,
    torso: 0,
    arm: 0,
    height: 0,
    weight: 0,
    shoulders: 0,
    sitBones: 0
  }

  if (interview) {
    const { firstName, lastName, email, phone, zipCode, birthDate, gender } =
      interview
    cyclistProfile = {
      firstName,
      lastName,
      email,
      phone,
      zipCode,
      birthDate: birthDate.substring(0, 10),
      gender
    }
    bodyMeasurements.height = interview.height
    bodyMeasurements.weight = interview.weight
  }

  const customerProfileForm = useCustomerProfileForm(
    cyclistProfile,
    fieldOverrides
  )

  const { form, validateForm } = customerProfileForm

  let matchingCyclist = null

  if (interview) {
    const { lastName, birthDate } = interview
    matchingCyclist = cyclists.find(
      (cyclist) =>
        lastName === cyclist.cyclistProfile.lastName &&
        birthDate === cyclist.cyclistProfile.birthDate
    )
  }

  const navigate = useNavigate()

  const saveFunction = async () => {
    const bikeData = {}
    if (interview) {
      bikeData.make = interview.bikeMake || ''
      bikeData.model = interview.bikeModel || ''
      bikeData.type = interview.bikeType || ''
      bikeData.frameSize = interview.bikeFrameSize || ''
    }

    const newCyclist = {
      cyclistProfile: customerProfileForm.values,
      softScores: {
        flexibility: 'Good',
        ridingStyle: 'Relaxed',
        preconditions: 'None'
      },
      bodyMeasurements
    }
    const newCyclistId = await createCyclist(newCyclist, bikeData, interview)
    customerProfileForm.reset()

    navigate(`/quickfit/customer/${newCyclistId}`)
  }

  const handleIgnore = () => {
    formModal.setShowModal()
  }

  const formModal = useFormModal({
    icon: CYCLIST_ICON,
    title: `New Customer`,
    confirmationButtonIcon: CYCLIST_ICON,
    confirmationButtonText: 'Save Customer',

    cancelAction: () => {
      customerProfileForm.reset()
    },

    confirmationAction: () => {
      saveFunction()
    }
  })

  if (matchingCyclist)
    return (
      <>
        <DuplicateCustomerWarning
          cyclist={matchingCyclist}
          link={link}
          tip={tip}
          handleIgnore={handleIgnore}
        />
        <FormModal
          {...formModal}
          disabled={
            !validateForm({
              firstName: form.firstName,
              lastName: form.lastName,
              email: form.email,
              phone: form.phone,
              birthDate: form.birthDate
            })
          }
        >
          <GenerateForm {...customerProfileForm} />
        </FormModal>
      </>
    )

  return (
    <>
      <EditFormButton
        editButtonText='New Customer'
        modalTitle={`New Customer`}
        modalForm={customerProfileForm}
        modalTitleIcon={CYCLIST_ICON}
        confirmationButtonIcon={CYCLIST_ICON}
        confirmationButtonText='Save Customer'
        saveFunction={saveFunction}
        //user={user}
        disabled={
          !validateForm({
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email,
            phone: form.phone,
            birthDate: form.birthDate
          })
        }
        link={link}
        tip={tip}
        navLink={navLink}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  cyclists: state.cyclists,
  fields: state.fields
})
export default connect(mapStateToProps, { createCyclist })(AddNewCustomer)
