export const firstName = {
  type: 'text',
  label: 'First Name',
  validationType: 'inputLength',
  length: 1
}

export const lastName = {
  type: 'text',
  label: 'Last Name',
  validationType: 'inputLength',
  length: 1
}

export const email = {
  type: 'email',
  label: 'Email',
  validationType: 'email'
}

export const phone = {
  type: 'tel',
  label: 'Phone',
  validationType: 'phone'
}
export const zipCode = {
  type: 'zipcode',
  label: 'Zip Code',
  validationType: 'zipcode'
}

export const birthDate = {
  type: 'date',
  label: 'Birth Date',
  validationType: 'birthDate'
}

export const gender = {
  type: 'radio',
  label: 'Gender',
  options: ['Male', 'Female', 'Non-Binary'],
  inline: true
}

export const height = {
  type: 'number',
  label: 'Height',
  validationType: 'measurement',
  //min: 48,
  //max: 84.6,
  step: 0.1,
  fksmode: 'inline-number',
  inline: true
}
export const weight = {
  type: 'number',
  label: 'Weight',
  validationType: 'measurement',
  //min: 79,
  //max: 265,
  step: 0.1,
  fksmode: 'inline-number',
  inline: true
}

export const footLength = {
  type: 'number',
  label: 'Foot Length',
  validationType: 'measurement',
  min: 21.5,
  max: 31.8,
  step: 0.1,
  munit: 'cm.',

  inline: true
}
export const inseam = {
  type: 'number',
  label: 'Inseam',
  validationType: 'measurement',
  min: 70,
  max: 99.9,
  step: 0.1,
  munit: 'cm.',
  inline: true
}
export const torso = {
  type: 'number',
  label: 'Torso',
  validationType: 'measurement',
  min: 48,
  max: 72,
  step: 0.1,
  munit: 'cm.',
  inline: true
}
export const arm = {
  type: 'number',
  label: 'Arm',
  validationType: 'measurement',
  min: 48,
  max: 72,
  step: 0.1,
  munit: 'cm.',
  inline: true
}
export const shoulders = {
  type: 'number',
  label: 'Shoulders',
  validationType: 'measurement',
  min: 34,
  max: 50,
  step: 0.5,
  munit: 'cm.',
  inline: true
}
export const sitBones = {
  type: 'number',
  label: 'Sit Bones',
  validationType: 'measurement',
  min: 80,
  max: 150,
  step: 1,
  munit: 'mm.',
  inline: true
}

export const age = {
  type: 'radio',
  label: 'Age',
  options: ['Under 40', 'Over 40'],
  inline: true
}

export const flexibility = {
  type: 'radio',
  label: 'Flexibility',
  options: ['Poor', 'Good', 'Excellent'],
  inline: true
}
export const ridingStyle = {
  type: 'radio',
  label: 'Riding Style',
  options: ['Relaxed', 'Competitive'],
  inline: true
}
export const preconditions = {
  type: 'select',
  label: 'Conditions',
  options: [
    'None',
    'Yes (soft tissue)',
    'Spinal (lumbar fusion)',
    'Spinal (cervical fusion)'
  ]
}

export const notes = {
  type: 'text',
  as: 'textarea',
  label: 'Sizing Notes',
  rows: '4',
  tip: 'These notes appear on PDF Sizing Report, if selected.'
}

export const confidentialNotes = {
  type: 'text',
  as: 'textarea',
  label: 'Confidential Notes',
  rows: '4',
  tip: 'These notes do not appear on PDF reports.'
}
