import React from 'react'

import GenerateForm from '../../forms/form-components/GenerateForm'

const BikeSelectionRadio = ({ inputForm }) => {
  return (
    <>
      <GenerateForm {...inputForm} cols={['1', '3', '5', '1']} noMargin />
    </>
  )
}

export default BikeSelectionRadio
