import React from 'react'
import { connect } from 'react-redux'

import QuickFitTabContent from '../QuickFitTabContent'

import useConfidentialNotesForm from '../../hooks/form-hooks/customers/useConfidentialNotesForm'

import { updateCyclist } from '../../../redux/actions/cyclists'

import { CYCLIST_ICON } from '../../../utils/fontAwesomeDefaults'

const ConfidentialNotes = ({
  customer,
  updateCyclist,
  user,
  fields: { fieldOverrides }
}) => {
  const confidentialNotesForm = useConfidentialNotesForm(
    {
      confidentialNotes: customer.confidentialNotes
    },
    fieldOverrides
  )

  const { validateForm, form, changesMade, values } = confidentialNotesForm

  const saveFunction = () => {
    const customerObject = {
      cyclistProfile: customer.cyclistProfile,
      confidentialNotes: values.confidentialNotes
    }

    updateCyclist(customer._id, customerObject, 'Confidential Notes Updated')
  }

  return (
    <QuickFitTabContent
      title='Confidential Notes'
      dataForm={confidentialNotesForm}
      dataObject={{
        confidentialNotes: customer.confidentialNotes
      }}
      editButtonText='Edit Confidential Notes'
      modalTitle='Edit Confidential Notes'
      modalForm={confidentialNotesForm}
      modalTitleIcon={CYCLIST_ICON}
      confirmationButtonIcon={CYCLIST_ICON}
      saveFunction={saveFunction}
      user={user}
      mode='notesMode'
      disabled={
        !validateForm(form) ||
        !changesMade(customer.confidentialNotes, values.confidentialNotes)
      }
    />
  )
}

const mapStateToProps = (state) => ({
  fields: state.fields
})

export default connect(mapStateToProps, { updateCyclist })(ConfidentialNotes)
