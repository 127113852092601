export const type = {
  type: 'select',
  label: 'Bike Type',
  options: [
    'Road Bike',
    'Mountain Bike',
    'TT/Tri Bike',
    'Gravel',
    'Cyclocross',
    'Fat Bike',
    'Touring or Commuting',
    'Tandem'
  ]
}

export const make = {
  type: 'text',
  label: 'Make',
  validationType: 'inputLength',
  length: 1
}

export const model = {
  type: 'text',
  label: 'Model and Year',
  validationType: 'inputLength',
  length: 1
}

export const frameSize = {
  type: 'text',
  label: 'Frame Size (manufacturer)',
  tip: 'Frame Size as stated by manufacturer.'
}

export const frameReach = {
  type: 'number',
  label: 'Frame Reach',
  min: 111,
  max: 999,
  validationType: 'measurement',
  tip: 'Horizontal distance from the middle of bottom bracket to middle of the top of the head tube.'
}

export const frameStack = {
  type: 'number',
  label: 'Frame Stack',
  min: 111,
  max: 999,
  validationType: 'measurement',
  tip: 'Vertical distance from the middle of bottom bracket to the top of the head tube.'
}

export const effectiveTopTube = {
  type: 'number',
  label: 'Effective Top Tube',
  validationType: 'measurement',
  tip: 'Horizontal distance from the center of the intersection of the top tube and head tube, to the centerline of the seat tube or seat post.'
}

export const stemType = {
  type: 'select',
  label: 'Stem Type',
  options: ['Standard', 'Integrated']
}

export const stemLength = {
  type: 'number',
  label: 'Stem Length',
  min: 35,
  max: 140,
  validationType: 'measurement',
  tip: 'Length of stem measured from center of handlebar clamp to center of steerer tube.'
}
export const stemAngle = {
  type: 'number',
  label: 'Stem Angle',
  min: -45,
  max: 35,
  step: 0.1,
  munit: 'deg.',
  validationType: 'measurementneg',
  tip: ' Angle of stem relative to steerer tube. Angle can be postive (rise) or negative (drop).'
}
export const spacersBelow = {
  type: 'number',
  label: 'Spacers Below Stem',
  min: 0,
  max: 75,
  validationType: 'measurement',
  tip: 'Spacers below stem measured in millimeters.'
}
export const spacersAbove = {
  type: 'number',
  label: 'Spacers Above Stem',
  validationType: 'measurement',
  tip: ' Spacers above stem measured in millimeters.'
}
export const handlebarWidth = {
  type: 'number',
  label: 'Handlebar Width',
  validationType: 'measurement',
  tip: 'Handlebar size measured from the center of the hoods (drop bar) or end to end (flat bar).'
}
export const handlebarReach = {
  type: 'number',
  label: 'Handlebar Reach',
  validationType: 'measurement',
  tip: 'Horizontal distance from center of handlebar to center of drops at longest point.'
}
export const seatPostOffset = {
  type: 'number',
  label: 'Seat Post Offset',
  poslabel: '(back)',
  neglabel: '(forward)',
  min: -200,
  validationType: 'measurementneg',
  tip: 'Horizontal distance from the center of the seat post to the center of the rail clamp.'
}
export const saddleMake = { type: 'text', label: 'Saddle Make' }
export const saddleModel = { type: 'text', label: 'Saddle Model' }
export const saddleWidth = {
  type: 'number',
  label: 'Saddle Width',
  validationType: 'measurement',
  tip: `Saddle width at the saddle's widest point.`
}
export const crankLength = {
  type: 'number',
  label: 'Crank Length',
  validationType: 'measurement',
  tip: 'Length of cranks measured from center of bottom bracket axle to the center of the pedal spindle.'
}
export const pedalType = {
  type: 'select',
  label: 'Pedal Type',
  options: ['Road', 'MTB', 'Flat']
}
export const pedalMakeModel = { type: 'text', label: 'Pedal Make/Model' }
export const shifterType = {
  type: 'select',
  label: 'Shifters',
  options: ['Mechanical', 'Electronic']
}
export const brakeType = {
  type: 'select',
  label: 'Brakes',
  options: ['Rim', 'Disc']
}

//Mountain Bike Specific Fields

export const mtbWheelSize = {
  type: 'select',
  label: 'Wheel Size',
  options: ['29', '27.5', '26']
}
export const mtbSeatPostType = {
  type: 'select',
  label: 'Seat Post Type',
  options: ['Rigid', 'Suspension', 'Dropper']
}

export const mtbHandlebarRise = {
  type: 'number',
  label: 'Handlebar Rise',
  validationType: 'measurement',
  tip: 'Amount of handlebar rise.',
  min: 0,
  max: 45
}

// TT/Tri bike Specific Fields

export const ttBasebarWidth = {
  type: 'number',
  label: 'Basebar Width',
  validationType: 'measurement',
  tip: 'Distance between center point of the aerobar basebars.'
}

export const ttAerobarType = {
  type: 'select',
  label: 'Aerobar Type',
  options: ['Clip-on', 'Integrated']
}

export const ttAerobarMakeModel = {
  type: 'text',
  label: 'Aerobar Make/Model'
}

export const ttExtensionsShape = {
  type: 'text',
  label: 'Extensions--Shape'
}
export const ttRisers = {
  type: 'text',
  label: 'Risers'
}
export const ttPadsMakeModel = {
  type: 'text',
  label: 'Pads Make/Model'
}

//Notes Fields

export const notes = {
  type: 'text',
  as: 'textarea',
  label: 'Fitting Notes',
  rows: '4',
  placeholder: 'Enter any additional comments here',
  tip: 'These notes appear on PDF Fitting Report, if selected'
}
export const confidentialNotes = {
  type: 'text',
  as: 'textarea',
  label: 'Confidential Notes',
  rows: '4',
  tip: 'These notes do not appear on PDF reports.'
}

//Tools fields

export const headTubeAngle = {
  type: 'number',
  label: 'Head Tube Angle',
  validationType: 'measurement',
  min: 60,
  max: 75,
  step: 0.1,
  munit: 'deg.',
  inline: true,
  tip: 'Angle of fork steerer tube compared to horizontal level.'
}
export const headSetHeight = {
  type: 'number',
  label: 'Headset Height',
  validationType: 'measurement',
  min: 0,
  max: 35,
  munit: 'mm.',
  inline: true,
  tip: 'Height of headset in millimeters, excluding any spacers.'
}

export const stemClampHeight = {
  type: 'number',
  label: 'Stem Clamp Height',
  validationType: 'measurement',
  min: 20,
  max: 50,
  munit: 'mm.',
  inline: true,
  tip: 'Height of stem clamp, excluding any spacers above or below.'
}
