import React from 'react'

const QuickFitTableRow = ({ label, value, munit }) => {
  return (
    <tr>
      <td>{label}</td>
      <td style={{ textAlign: 'right' }}>
        {value} {munit}
      </td>
    </tr>
  )
}

export default QuickFitTableRow
