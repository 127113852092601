import api from '../../utils/api.js'

// Get all users
export const loadAdminUserList = () => async (dispatch) => {
  try {
    const res = await api.get('/admin-data/users')

    return res.data
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}
// Get all customers
export const loadAdminCustomerList = () => async (dispatch) => {
  try {
    const res = await api.get('/admin-data/customers')

    return res.data
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}

// Count all customers
export const countCustomers = () => async (dispatch) => {
  try {
    const res = await api.get('/cyclists/count-all')

    return res.data
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}

// Get all bikes
export const loadAdminBikeList = () => async (dispatch) => {
  try {
    const res = await api.get('/admin-data/bikes')

    return res.data
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}
// Count all bikes
export const countBikes = () => async (dispatch) => {
  try {
    const res = await api.get('/bikes/count-all')

    return res.data
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}

// Get all shops
export const loadAdminShopList = () => async (dispatch) => {
  try {
    const res = await api.get('/admin-data/shops')

    return res.data
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}
