import React from 'react'
import { Table } from 'react-bootstrap'

import DashboardCard from '../../layout/cards-containers/DashboardCard'
import ShopRow from './ShopRow'
import NextPreviousFooter from '../../hook-components/NextPreviousFooter'
import QuickFitDashboardItemCounter from '../../quickfit/QuickFitDashboardItemCounter'

import useNextPrevious from '../../hooks/action-hooks/useNextPrevious'

const ShopList = ({ adminStats: { shopList, userList } }) => {
  const nextPrevious = useNextPrevious(100, shopList.length)

  const { start, stop, length } = nextPrevious

  if (shopList.length !== length) nextPrevious.setcurrentLength(shopList.length)
  if (stop > shopList.length) nextPrevious.setStop(shopList.length)

  return (
    <>
      <DashboardCard
        title={
          <>
            Shop List
            <QuickFitDashboardItemCounter
              start={start}
              stop={stop}
              length={length}
            />
          </>
        }
        footer={
          <>
            <NextPreviousFooter {...nextPrevious} />
          </>
        }
      >
        <Table size='sm' style={{ fontSize: '.8rem' }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Phone</th>
              <th>Website</th>
              <th>User</th>
              <th>Logo</th>
            </tr>
          </thead>
          <tbody>
            {shopList.slice(start - 1, stop).map((shop) => {
              return <ShopRow key={shop._id} shop={shop} userList={userList} />
            })}
          </tbody>
        </Table>
      </DashboardCard>
    </>
  )
}

export default ShopList
