import {
  CREATE_SHOP,
  SHOP_LOADED,
  UPDATE_SHOP,
  DELETE_SHOP,
  CLEAR_SHOP
} from '../actions/types'

const initialState = {
  shop: { name: '', address: '', address2: '', phone: '', website: '' },
  logoUrl: ''
}

export default function shops(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case SHOP_LOADED:
      return {
        ...state,
        shop: payload
      }
    case CREATE_SHOP:
    case UPDATE_SHOP:
      return {
        ...state,
        shop: payload
      }

    case DELETE_SHOP:
    case CLEAR_SHOP:
      return {
        ...initialState
      }

    default:
      return state
  }
}
