import React, { useState } from 'react'

import QuickFitTabContent from '../../quickfit/QuickFitTabContent'
import OutputsPlaceholder from '../OutputsPlaceholder'
import StemDeltaOutputs from './StemDeltaOutputs'
import ModalOutputs from '../ModalOutputs'

import useStemDeltaForm from '../../hooks/form-hooks/tools/useStemDeltaForm'

import { stemDeltaForm as dataForm } from '../../forms/form-objects/toolsFormObjects'

import {
  calculateStemReachDelta,
  calculateStemHeightDelta
} from '../../../lib/tools-js-functions'

import { CYCLIST_ICON } from '../../../utils/fontAwesomeDefaults'
//import StemCanvas from './StemCanvas'
//import StemSVG from './StemSVG'

const StemDelta = ({ user, fieldOverrides }) => {
  const initialState = {
    headTubeAngle: 73,
    stemLengthA: 120,
    stemAngleA: -17,
    spacersBelowA: 30,
    stemLengthB: 100,
    stemAngleB: 6,
    stemLength: 35,
    spacersBelowB: 30
  }

  const [inputs, setInputs] = useState(initialState)

  const stemDeltaForm = useStemDeltaForm(initialState, fieldOverrides)

  const { validateForm, form, changesMade, values } = stemDeltaForm

  const newValues = {
    headTubeAngle: parseInt(values.headTubeAngle),
    stemLengthA: parseInt(values.stemLengthA),
    stemAngleA: parseFloat(values.stemAngleA),
    spacersBelowA: parseInt(values.spacersBelowA),
    stemLengthB: parseInt(values.stemLengthB),
    stemAngleB: parseFloat(values.stemAngleB),
    spacersBelowB: parseInt(values.spacersBelowB)
  }
  const saveFunction = () => {
    setInputs(newValues)
  }

  const {
    headTubeAngle,
    stemLengthA,
    stemAngleA,
    spacersBelowA,
    stemLengthB,
    stemAngleB,
    spacersBelowB
  } = form

  const stemHeightDelta = calculateStemHeightDelta(newValues)

  const stemReachDelta = calculateStemReachDelta(newValues)

  let heightLabel =
    stemHeightDelta <= 0
      ? 'Stem Two is lower than Stem One by:'
      : 'Stem Two is higher than Stem One by:'

  const reachLabel =
    stemReachDelta <= 0
      ? 'Reach of Stem Two is shorter than Stem One by:'
      : 'Reach of Stem Two is longer than Stem One by:'

  const modalOutputForm = {
    stemReachDelta: {
      label: reachLabel,
      type: 'number'
    },
    stemHeightDelta: { label: heightLabel, type: 'number' }
  }

  return (
    <>
      <QuickFitTabContent
        title='Stem Delta Calculator'
        dataForm={dataForm}
        dataObject={values}
        editButtonText='Edit Inputs'
        modalTitle='Stem Delta Calculator: Edit Inputs'
        modalForm={stemDeltaForm}
        modalTitleIcon={CYCLIST_ICON}
        confirmationButtonIcon={CYCLIST_ICON}
        saveFunction={saveFunction}
        user={user}
        formGroup='quickfit'
        cols={['1', '5', '4', '2']}
        disabled={
          !validateForm({
            headTubeAngle,
            stemLengthA,
            stemAngleA,
            spacersBelowA,
            stemLengthB,
            stemAngleB,
            spacersBelowB
          }) || !changesMade(inputs, values)
        }
      >
        <ModalOutputs
          inputs={inputs}
          user={user}
          dataForm={modalOutputForm}
          dataObject={{
            stemReachDelta: stemReachDelta,
            stemHeightDelta: stemHeightDelta
          }}
        />
      </QuickFitTabContent>
      <div className='mt-3'></div>
      {!changesMade(initialState, inputs) ? (
        <OutputsPlaceholder
          title='Stem Reach and Height Deltas'
          message='Please enter the inputs above to calculate frame reach and height changes.'
        />
      ) : (
        <>
          <StemDeltaOutputs inputs={inputs} user={user} />

          {/* <StemSVG /> */}
        </>
      )}
    </>
  )
}

export default StemDelta
