import React from 'react'
import { Image } from 'react-bootstrap'

const TTBikeImage = () => {
  return (
    <Image
      className='mt-3'
      src='/ttsizingannotated.png'
      alt='TT/Tri Bike Reference Image'
      style={{ maxWidth: '100%' }}
    />
  )
}

export default TTBikeImage
