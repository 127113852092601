import { Card } from 'react-bootstrap'
import UserSearchList from './UserSearchList'
import CustomerSearchList from './CustomerSearchList'
import BikeSearchList from './BikeSearchList'

const SearchReport = ({ searchData, collection }) => {
  if (!searchData || searchData.length === 0) return null
  return (
    <Card className='mt-5'>
      <Card.Header className='bg-white'>
        Search Results ({searchData.length} records found)
      </Card.Header>
      <Card.Body>
        <div>
          {collection === 'users' ? (
            <UserSearchList searchData={searchData} />
          ) : collection === 'customers' ? (
            <CustomerSearchList searchData={searchData} />
          ) : collection === 'bikes' ? (
            <BikeSearchList searchData={searchData} />
          ) : null}
        </div>
      </Card.Body>
    </Card>
  )
}

export default SearchReport
