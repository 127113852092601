export const convertedHeight = (height, height_units) => {
  return height_units === 'Metric'
    ? height
    : Math.round(10 * (height / 2.54)) / 10
}

export const heightMunit = (height_units) => {
  return height_units === 'Imperial' ? 'in.' : 'cm.'
}

export const getMetricHeight = (imperialHeight) => {
  return Math.round(100 * (imperialHeight * 2.54)) / 100
}

export const convertedWeight = (weight, weight_units) => {
  return weight_units === 'Metric'
    ? weight
    : Math.round(10 * (weight / 0.45359237)) / 10
}

export const weightMunit = (weight_units) => {
  return weight_units === 'Imperial' ? 'lbs.' : 'kgs.'
}

export const getMetricWeight = (imperialWeight) => {
  return Math.round(100 * (imperialWeight * 0.45359237)) / 100
}
