import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { updateStripeCustomer } from '../../../redux/actions/stripe'
import Spinner from '../../layout/Spinner'
import PropTypes from 'prop-types'

import {
  CREDIT_CARD_ICON,
  CANCEL_ICON
} from '../../../utils/fontAwesomeDefaults'

const UpdatePaymentModal = ({
  stripe: { customer },
  updateStripeCustomer,
  showModal,
  setShowModal
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [disableSubmit, setDisableSubmit] = useState(true)

  const [processing, setProcessing] = useState(false)

  const clickCancel = () => {
    setShowModal(false)
  }

  const clickUpdate = async (e) => {
    if (!stripe || !elements) {
      return
    }

    setProcessing(true)
    const cardElement = elements.getElement(CardElement)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })

    if (error) {
      console.log('[error]', error)
    } else {
      //Update Card Info Here
      await updateStripeCustomer({
        id: customer.id,
        payment_method: paymentMethod.id
      })
    }

    setShowModal(false)
    setProcessing(false)
  }

  const validateForm = (ev) => {
    if (stripe && ev.complete === true) setDisableSubmit(false)
    if (ev.error || !ev.complete) setDisableSubmit(true)
    if (ev.error) console.log(ev.error)
  }

  return (
    <Modal
      show={showModal}
      onHide={clickCancel}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Update Payment Method
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please enter a new payment method.</p>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4'
                }
              },
              invalid: {
                color: '#9e2146'
              }
            }
          }}
          onChange={validateForm}
        />
      </Modal.Body>
      {processing && (
        <Modal.Footer className='d-flex justify-content-center'>
          <span>
            <Spinner message='Processing . . .' />
          </span>
        </Modal.Footer>
      )}
      {!processing && (
        <Modal.Footer>
          <Button
            disabled={disableSubmit}
            onClick={clickUpdate}
            variant='danger'
          >
            <i className={CREDIT_CARD_ICON} /> Update Payment Method
          </Button>{' '}
          <Button variant='secondary' onClick={clickCancel}>
            <i className={CANCEL_ICON} /> Cancel
          </Button>
          <Button disabled variant='outline-light'>
            <img src='/stripe-black.svg' alt='Powered by Stripe' width='100' />
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}

UpdatePaymentModal.propTypes = {
  stripe: PropTypes.object.isRequired,
  updateStripeCustomer: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  stripe: state.stripe
})

export default connect(mapStateToProps, { updateStripeCustomer })(
  UpdatePaymentModal
)
