import { useState } from 'react'

const useFormModal = (modalObject) => {
  const {
    icon,
    title,
    //    confirmationPrompt,
    confirmationText,
    confirmationButtonIcon,
    confirmationButtonText,
    confirmationAction,
    cancelAction
  } = modalObject
  const [showModal, setShowModal] = useState(false)

  const handleToggle = () => {
    setShowModal(!showModal)
  }

  const handleCancel = () => {
    setShowModal(false)

    if (cancelAction) cancelAction()
  }

  const handleConfirmation = (e) => {
    e.preventDefault()
    if (confirmationAction) confirmationAction()
    setShowModal(false)
  }

  return {
    icon,
    title,

    confirmationText,
    showModal: showModal,
    confirmationButtonIcon,
    confirmationButtonText,

    setShowModal: handleToggle,
    clickConfirm: handleConfirmation,
    clickCancel: handleCancel
  }
}

export default useFormModal
