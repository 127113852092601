const NewColorize = ({ value, min, max, children }) => {
  let className
  switch (true) {
    case value >= min && value <= max:
      className = 'text-success'
      break
    case value < min:
      className = 'text-danger'
      break
    case value > max:
      className = 'text-danger'
      break
    default:
      className = ''
  }
  return <span className={className}>{children}</span>
}
export default NewColorize
