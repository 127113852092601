import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { updateUser } from '../../../redux/actions/users'

import useEditNameAndEmailForm from '../../hooks/form-hooks/users-auth/useEditNameAndEmailForm'

import AccountSettingsTabContent from '../AccountSettingsTabContent'

import { editNameAndEmailForm as dataForm } from '../../forms/form-objects/usersAuthFormObjects'

import { USER_ICON } from '../../../utils/fontAwesomeDefaults'
import { updateStripeProfile } from '../../../redux/actions/stripe'

const EditProfile = ({ users: { user }, updateUser, updateStripeProfile }) => {
  const editNameAndEmailForm = useEditNameAndEmailForm(user)

  const saveFunction = () => {
    const { name, email } = editNameAndEmailForm.values

    updateUser({ name, email })
    //check to see if stripe info needs updating
    const { stripe_customer_id } = user
    if (stripe_customer_id) {
      updateStripeProfile({ id: stripe_customer_id, name, email })
    }
  }

  const { form, validateForm, changesMade } = editNameAndEmailForm

  return (
    <>
      <AccountSettingsTabContent
        title='Profile Name and Email'
        dataForm={dataForm}
        dataObject={user}
        editButtonText='Edit Profile'
        modalTitle='Edit Profile'
        modalForm={editNameAndEmailForm}
        modalTitleIcon={USER_ICON}
        confirmationButtonIcon={USER_ICON}
        saveFunction={saveFunction}
        user={user}
        disabled={
          !validateForm(form) ||
          !changesMade(
            { name: user.name, email: user.email },
            editNameAndEmailForm.values
          )
        }
      />
    </>
  )
}

EditProfile.propTypes = {
  users: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  users: state.users
})

export default connect(mapStateToProps, { updateUser, updateStripeProfile })(
  EditProfile
)
