export const TRASH_ICON = 'fa-regular fa-trash'
export const BIKE_ICON = 'fa-regular fa-bicycle'
export const WARNING_ICON = 'fa-regular fa-triangle-exclamation'
export const CYCLIST_ICON = 'fa-regular fa-person-biking'
export const PHONE_ICON = 'fa-regular fa-phone'
export const EMAIL_ICON = 'fa-regular fa-envelope'
export const CALENDAR_ICON = 'fa-regular fa-calendar-day'
export const CALENDAR_EDIT_ICON = 'fa-regular fa-calendar-lines-pen'
export const PLUS_ICON = 'fa-regular fa-plus'
export const USER_ICON = 'fa-regular fa-user'
export const USER_ICON_FKS = 'fa-regular fa-user text-fitkit'
export const ADMIN_ICON = 'fa-regular fa-user-lock'
export const ADMIN_ICON_FKS = 'fa-regular fa-user-lock text-fitkit'
export const SHOP_ICON = 'fa-regular fa-shop'
export const SUBSCRIPTION_ICON = 'fa-regular fa-file-invoice-dollar'
export const CREDIT_CARD_ICON = 'fa-regular fa-credit-card'
export const CANCEL_ICON = 'fa-regular fa-circle-xmark'
export const CHECK_ICON = 'fa-regular fa-circle-check'
export const EDIT_ICON = 'fa-regular fa-edit'
export const SETTINGS_ICON = 'fa-regular fa-gear'
export const DASHBOARD_ICON = 'fa-regular fa-gauge-high'
export const MY_ACCOUNT_ICON = 'fa-regular fa-user-gear'
export const SIGN_IN_ICON = 'fa-regular fa-right-to-bracket'
export const SIGN_OUT_ICON = 'fa-regular fa-right-from-bracket'
export const POLICY_ICON = 'fa-regular fa-file-contract'
export const INFO_ICON = 'fa-regular fa-square-info'
export const TOOLBOX_ICON = 'fa-regular fa-toolbox'
export const TOOLS_ICON = 'fa-regular fa-screwdriver-wrench'
export const NEXT_ICON = 'fa-regular fa-caret-right fa-lg'
export const PREVIOUS_ICON = 'fa-regular fa-caret-left fa-lg'
export const DOWNLOAD_ICON = 'fa-regular fa-download text-fitkit float-end mt-1'
export const PRINT_PDF_ICON = 'fa-regular fa-print text-fitkit float-end mt-1'
export const COMMENT_ICON = 'fa-regular fa-comment'
export const RESOURCES_ICON = 'fa-regular fa-books'
export const REFRESH_ICON = 'fa-regular fa-rotate-right'
export const GEAR_ICON = 'fa-regular fa-gear'
export const HYPHEN_ICON = 'fa-thin fa-hyphen'
export const SAVE_ICON = 'fa-regular fa-floppy-disk'
export const STRAVA_ICON = 'fa-brands fa-strava'
export const SAND_BOX_ICON = 'fa-regular fa-box-open'
export const LOCK_ICON = 'fa-regular fa-lock'
export const IMAGE_ICON = 'fa-regular fa-image'
export const SORT_UNSORTED_ICON = 'fa-regular fa-sort'
export const SORT_ASC_ICON = 'fa-regular fa-sort-up'
export const SORT_DESC_ICON = 'fa-regular fa-sort-down'
