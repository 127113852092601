import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import AccountRoutes from '../routing/AccountRoutes'

const Account = ({
  prefitinterviews: { newInterviews },
  cyclists: { cyclists },
  users: { user },
  shops: { shop },
  stripe: { subscription, last4, activeStatus }
}) => {
  return (
    <>
      <AccountRoutes
        cyclists={cyclists}
        newInterviews={newInterviews}
        user={user}
        shop={shop}
        subscription={subscription}
        last4={last4}
        activeStatus={activeStatus}
      />
    </>
  )
}

Account.propTypes = {
  prefitinterviews: PropTypes.object.isRequired,
  cyclists: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  prefitinterviews: state.prefitinterviews,
  cyclists: state.cyclists,
  users: state.users,
  shops: state.shops,
  stripe: state.stripe
})

export default connect(mapStateToProps, {})(Account)
