import { useEffect } from 'react'
import MainHeading from '../layout/MainHeading'
import { Link } from 'react-router-dom'
import { TOOLBOX_ICON } from '../../utils/fontAwesomeDefaults'
import PricingInfo from '../account/dashboard/PricingInfo'
import QuickFitFeatures from '../account/dashboard/QuickFitFeatures'

const Upgrade = () => {
  useEffect(() => {
    document.body.classList.add('bodyPad')

    return () => {
      document.body.classList.remove('bodyPad')
    }
  }, [])
  return (
    <>
      <MainHeading
        heading='Quick Fit'
        headingIcon={TOOLBOX_ICON}
        subheading={
          'Available to subscribers only. All registered users are eligible for a free 60-day trial.'
        }
      />
      <p>
        As a registered user, you currently have access to the free{' '}
        <Link to='/tools-resources/tools'>Toolbox</Link>
      </p>

      <QuickFitFeatures />
      <PricingInfo />
      <p>
        <Link to='/account/settings?tab=subscription'>
          Upgrade Subscription
        </Link>{' '}
      </p>
    </>
  )
}

export default Upgrade
