export const height = {
  name: 'height',
  type: 'number',
  label: 'Height',
  validatationtype: 'measurement',
  min: 0,
  //max: 84.6,
  step: 0.1,
  fksmode: 'inline-number',
  inline: 'true'
}

export const footLength = {
  name: 'footLength',
  type: 'number',
  label: 'Foot Length',
  validatationtype: 'measurement',
  //min: 21.5,
  max: 31.8,
  min: 0,
  step: 0.1,
  munit: 'cm.',

  inline: 'true'
}
export const inseam = {
  name: 'inseam',
  type: 'number',
  label: 'Inseam',
  validatationtype: 'measurement',
  //min: 70,
  min: 0,
  max: 99.9,
  step: 0.1,
  munit: 'cm.',
  inline: 'true'
}
export const torso = {
  name: 'torso',
  type: 'number',
  label: 'Torso',
  validatationtype: 'measurement',
  // min: 48,
  min: 0,
  max: 72,
  step: 0.1,
  munit: 'cm.',
  inline: 'true'
}
export const arm = {
  name: 'arm',
  type: 'number',
  label: 'Arm',
  validatationtype: 'measurement',
  //min: 48,
  min: 0,
  max: 72,
  step: 0.1,
  munit: 'cm.',
  inline: 'true'
}
export const name = {
  name: 'name',
  type: 'text',
  label: 'Fitter Name',
  validationtype: 'inputLength',
  length: 1,
  inline: 'true'
}

export const email = {
  name: 'email',
  type: 'email',
  label: 'Fitter Email',
  //placeholder: 'Email',
  //  validationtype: 'email',
  inline: 'true'
}

export const gender = {
  name: 'gender',
  type: 'radio',
  label: 'Gender',
  options: ['Male', 'Female', 'Non-Binary'],
  inline: 'true'
}

export const type = {
  name: 'bikeType',
  type: 'select',
  label: 'Bike Type',
  options: [
    'Road Bike',
    'Mountain Bike',
    'TT/Tri Bike',
    'Gravel',
    'Cyclocross',
    'Fat Bike',
    'Touring or Commuting',
    'Tandem'
  ],
  inline: 'true'
}

/* export const shoulders = {
  type: 'number',
  label: 'Shoulders',
  validatationtype: 'measurement',
  min: 34,
  max: 50,
  step: 0.5,
  munit: 'cm.',
  inline: 'true'
}
export const sitBones = {
  type: 'number',
  label: 'Sit Bones',
  validatationtype: 'measurement',
  min: 80,
  max: 150,
  step: 1,
  munit: 'mm.',
  inline: 'true'
} */

export const stackReachFields = [
  email,
  height,
  //footLength,
  inseam,
  torso,
  arm,
  type,
  gender
]
