export const calculateOppositeFromAdjacent = (angle, adjacent) => {
  const opposite = Math.tan((Math.PI * angle) / 180.0) * adjacent
  return opposite
}

export const calculateHypotenuseFromAdjacent = (angle, adjacent) => {
  const opposite = adjacent / Math.cos((Math.PI * angle) / 180.0)
  return opposite
}

export const calculateAdjacentFromHypotenuse = (angle, hypotenuse) => {
  const adjacent = Math.cos((Math.PI * angle) / 180.0) * hypotenuse
  return adjacent
}

export const calculateOppositeFromHypotenuse = (angle, hypotenuse) => {
  const opposite = Math.sin((Math.PI * angle) / 180.0) * hypotenuse
  return opposite
}

export const calculateAngleFromAdjacentAndHypotenuse = (
  adjacent,
  hypotenuse
) => {
  const angle = Math.acos(adjacent / hypotenuse) * (180 / Math.PI)
  return angle
}

export const calculateAngleFromOppositeAndHypotenuse = (
  opposite,
  hypotenuse
) => {
  const angle = Math.asin(opposite / hypotenuse) * (180 / Math.PI)
  return angle
}
