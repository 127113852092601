import React from 'react'
import { Table } from 'react-bootstrap'
import QuickFitDashboardCard from '../../quickfit/QuickFitDashboardCard'

import useQS2SizingRecommendations from '../../hooks/fit-kit-hooks/useQS2SizingRecommendations'
import SizingRecommendationRow from './Q2SizingRecommendationRow'

import PrintPDFButton from '../../../pdf/PrintPDFButton'
import BikeSelectionRadio from './BikeSelectionRadio'
import RoadBikeImage from './RoadBikeImage'
import MTBImage from './MTBImage'
import TTBikeImage from './TTBikeImage'

import useSingleInputForm from '../../hooks/form-hooks/tools/singleInputForm'

import {
  roadBikeSizingForm,
  mountainBikeSizingForm,
  ttBikeSizingForm
} from '../../forms/form-objects/sizingFormObjects'

import { sizingPDF } from '../../../pdf/SizingPdf'
import TableData from '../../layout/cards-containers/TableData'

const QS2SizingRecommendations = ({
  bodyMeasurements,
  softScores,
  birthDate,
  noPrint,
  bikeImage,
  bikeSelection,
  user,
  shop,
  customer
}) => {
  const sizingRecommendations = useQS2SizingRecommendations(
    bodyMeasurements,
    softScores,
    birthDate
  )

  const {
    frameSize,
    minSaddleHeight,
    maxSaddleHeight,
    maxStandoverHeight,
    handlebarWidth,
    minSaddleWidth,
    maxSaddleWidth,
    topTubeAndStemDropBar,
    topTubeAndStemFlatBar,
    topTubeAndStemAeroBar,
    AdjustedTopTubeAndStemDropBar,
    AdjustedTopTubeAndStemFlatBar,
    AdjustedTopTubeAndStemAeroBar,
    seatTubeAngle,
    reachRange,
    stackRange
  } = sizingRecommendations

  const radioForm = {
    bikeType: {
      type: 'radio',
      options: ['Road', 'MTB', 'TT/Tri'],
      inline: true
    }
  }

  const inputForm = useSingleInputForm(radioForm, { bikeType: 'Road' })

  let BikeImage, dataForm
  const { bikeType } = inputForm.values || 'Road'

  switch (bikeType) {
    case 'Road':
      BikeImage = <RoadBikeImage />
      dataForm = roadBikeSizingForm
      break
    case 'MTB':
      BikeImage = <MTBImage />
      dataForm = mountainBikeSizingForm
      break
    case 'TT/Tri':
      BikeImage = <TTBikeImage />
      dataForm = ttBikeSizingForm
      break
    default:
      BikeImage = <RoadBikeImage />
      dataForm = roadBikeSizingForm
  }

  return (
    <>
      <QuickFitDashboardCard
        title={<>Sizing Recommendations</>}
        headerIcon={
          !noPrint ? (
            <PrintPDFButton
              pdfFunction={sizingPDF}
              sizingRecommendations={sizingRecommendations}
              tip='Print PDF Report'
              user={user}
              shop={shop}
              customer={customer}
              fileName='Sizing_Recommendations'
              bikeType={bikeType}
            />
          ) : null
        }
        style={{ minHeight: '305px' }}
        footer={
          bikeSelection ? <BikeSelectionRadio inputForm={inputForm} /> : null
        }
      >
        {noPrint && (
          <Table striped size='sm' style={{ fontSize: '.8rem' }}>
            <tbody>
              <SizingRecommendationRow
                label='Frame Size (virtual seat tube)'
                value={frameSize}
              />
              <SizingRecommendationRow
                label='Saddle Height (to pedal spindle)'
                value={minSaddleHeight + '-' + maxSaddleHeight}
              />
              <SizingRecommendationRow
                label='Maximum Standover Height'
                value={maxStandoverHeight}
              />
              <SizingRecommendationRow
                label='Handlebar Width (drop bar)'
                value={handlebarWidth}
              />
              <SizingRecommendationRow
                label='Saddle Width'
                value={minSaddleWidth + '-' + maxSaddleWidth}
                unit='mm.'
              />
              <SizingRecommendationRow
                label='Top Tube + Stem (drop bar)'
                value={topTubeAndStemDropBar}
                unit='mm.'
              />
              <SizingRecommendationRow
                label='Top Tube + Stem (flat bar)'
                value={topTubeAndStemFlatBar}
              />
              <SizingRecommendationRow
                label='Top Tube + Stem (aero bar)'
                value={topTubeAndStemAeroBar}
              />
              <SizingRecommendationRow
                label='Adjusted Top Tube + Stem (drop bar)'
                value={AdjustedTopTubeAndStemDropBar}
                unit='mm.'
              />
              <SizingRecommendationRow
                label='Adjusted Top Tube + Stem (flat bar)'
                value={AdjustedTopTubeAndStemFlatBar}
              />
              <SizingRecommendationRow
                label='Adjusted Top Tube + Stem (aero bar)'
                value={AdjustedTopTubeAndStemAeroBar}
              />
              {/* <SizingRecommendationRow
                label='Seat Tube Angle (drop bar)'
                value={seatTubeAngle}
                unit='deg.'
              /> */}
              {/* <SizingRecommendationRow
                label='Min Stem Length (drop bar)'
                value={90}
                unit='mm.'
              />
              <SizingRecommendationRow
                label='Max Stem Length (drop bar)'
                value={105}
                unit='mm.'
              /> */}
              {/* <SizingRecommendationRow
                label='Target Effective Top Tube (drop bar)'
                value={reachRange.targetETT}
                unit='mm.'
              />
              <SizingRecommendationRow
                label='Min Effective Top Tube (drop bar)'
                value={reachRange.minETT}
                unit='mm.'
              />
              <SizingRecommendationRow
                label='Max Effective Top Tube (drop bar)'
                value={reachRange.maxETT}
                unit='mm.'
              /> */}
              <SizingRecommendationRow
                label='Target Reach (drop bar)'
                value={reachRange.targetReach.toFixed(0)}
                unit='mm.'
              />
              <SizingRecommendationRow
                label='Min Frame Reach Top Tube (drop bar)'
                value={reachRange.minReach.toFixed(0)}
                unit='mm.'
              />
              <SizingRecommendationRow
                label='Max Frame Reach (drop bar)'
                value={reachRange.maxReach.toFixed(0)}
                unit='mm.'
              />
              <SizingRecommendationRow
                label='Target Stack (drop bar)'
                value={stackRange.targetStack.toFixed(0)}
                unit='mm.'
              />
              <SizingRecommendationRow
                label='Min Frame Stack Top Tube (drop bar)'
                value={stackRange.minStack.toFixed(0)}
                unit='mm.'
              />
              <SizingRecommendationRow
                label='Max Frame Stack (drop bar)'
                value={stackRange.maxStack.toFixed(0)}
                unit='mm.'
              />
            </tbody>
          </Table>
        )}
        {/* <TableData
          dataForm={dataForm}
          dataObject={{
            ...sizingRecommendations,
            saddleHeight: minSaddleHeight + '-' + maxSaddleHeight,
            saddleWidth: minSaddleWidth + '-' + maxSaddleWidth
          }}
          user={user}
        /> */}

        {bikeImage && BikeImage}
      </QuickFitDashboardCard>
    </>
  )
}

export default QS2SizingRecommendations
