import { combineReducers } from 'redux'
import alert from './alert'
import users from './users'
import shops from './shops'
import stripe from './stripe'
import prefitinterviews from './prefitinterviews'
import cyclists from './cyclists'
import bikes from './bikes'
import fields from './fields'
import adminmessages from './adminmessages'
import feedback from './feedback'

export default combineReducers({
  alert,
  users,
  shops,
  stripe,
  prefitinterviews,
  cyclists,
  bikes,
  fields,
  adminmessages,
  feedback
})
