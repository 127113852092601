export const frameSize = {
  label: 'Frame Size (virtual seat tube)',
  type: 'number',
  munit: 'cm.',
  tip: 'Virtual seat tube length from center to center. (Based solely on inseam and may not reflect optimal size.)'
}
export const saddleHeight = {
  label: 'Saddle Height (saddle to pedal spindle)',
  type: 'number',
  munit: 'cm.',
  tip: 'Distance from pedal spindle center to saddle top with crank in line with seat tube.'
}
export const maxStandoverHeight = {
  label: 'Maximum Standover Height',
  type: 'number',
  munit: 'cm.',
  tip: 'The maximum distance from the ground to the top of the top tube that will be safe and comfortable for a cyclist based on their inseam.'
}
export const handlebarWidth = {
  label: 'Handlebar Width (drop bar)',
  type: 'number',
  munit: 'cm.',
  tip: 'Recommended handlebar size measured from the center of the hoods.'
}
export const saddleWidth = {
  label: 'Saddle Width',
  type: 'number',
  tip: `Recommended saddle width at the saddle's widest point based on sit bone measurement.`
}

export const topTubeAndStemDropBar = {
  label: 'Top Tube + Stem (drop bar)',
  type: 'number',
  munit: 'cm.',
  tip: 'Effective (horizontal) top tube length plus stem length. To arrive at the starting stem to be used, subtract the bicylce top tube measurement from this value.'
}
export const topTubeAndStemFlatBar = {
  label: 'Top Tube + Stem (flat bar)',
  type: 'number',
  munit: 'cm.',
  tip: 'Effective (horizontal) top tube length plus stem length. To arrive at the starting stem to be used, subtract the bicylce top tube measurement from this value.'
}
export const topTubeAndStemAeroBar = {
  label: 'Top Tube + Stem (aero bar)',
  type: 'number',
  munit: 'cm.',
  tip: 'Effective (horizontal) top tube length plus stem length. To arrive at the starting stem to be used, subtract the bicylce top tube measurement from this value.'
}
export const AdjustedTopTubeAndStemDropBar = {
  label: 'Adjusted Top Tube + Stem (drop bar)',
  type: 'number',
  munit: 'cm.',
  tip: 'Effective (horizontal) top tube length plus stem length (as adusted for physical considerations). To arrive at the starting stem to be used, subtract the bicycle top tube measurement from this value.'
}
export const AdjustedTopTubeAndStemFlatBar = {
  label: 'Adjusted Top Tube + Stem (flat bar)',
  type: 'number',
  munit: 'cm.',
  tip: 'Effective (horizontal) top tube length plus stem length (as adusted for physical considerations). To arrive at the starting stem to be used, subtract the bicycle top tube measurement from this value.'
}
export const AdjustedTopTubeAndStemAeroBar = {
  label: 'Adjusted Top Tube + Stem (areo bar)',
  type: 'number',
  munit: 'cm.',
  tip: 'Effective (horizontal) top tube length plus stem length (as adusted for physical considerations). To arrive at the starting stem to be used, subtract the bicycle top tube measurement from this value.'
}
