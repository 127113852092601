import React from 'react'
import UserFormContainer from './UserFormContainer'
import { EMAIL_ICON } from '../../utils/fontAwesomeDefaults'

const ContactConfirmation = () => {
  return (
    <UserFormContainer heading='Contact Us' icon={EMAIL_ICON}>
      <p>Your message has been sent.</p>
      <p> Thank you for contacting us. </p>
    </UserFormContainer>
  )
}

export default ContactConfirmation
