import api from '../../utils/api.js'
import { setAlert } from './alert'

import {
  FEEDBACK_MESSAGES_LOADED,
  CLEAR_FEEDBACK_MESSAGES,
  UPDATE_FEEDBACK,
  DELETE_FEEDBACK
} from './types'

// Get all messsages
export const loadAdminMessages = () => async (dispatch) => {
  try {
    const res = await api.get('/admin-messages')

    dispatch({
      type: FEEDBACK_MESSAGES_LOADED,
      payload: res.data
    })
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}

// Get all feedback
export const loadAdminFeedbackList = () => async (dispatch) => {
  try {
    const res = await api.get('/feedback')

    dispatch({
      type: FEEDBACK_MESSAGES_LOADED,
      payload: res.data
    })

    //return res.data
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}

// Create Feedback Message
export const createFeedback = (formData, alert) => async (dispatch) => {
  try {
    await api.post('/feedback', formData)

    if (alert) dispatch(setAlert(alert, 'success'))
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

//Update Message

export const updateFeedback =
  (feedbackId, formData, alertMessage) => async (dispatch) => {
    try {
      const res = await api.put(`/feedback/${feedbackId}`, formData)

      dispatch({ type: UPDATE_FEEDBACK, payload: res.data })

      if (alertMessage) dispatch(setAlert(alertMessage, 'success'))
    } catch (err) {
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
      }
    }
  }

//Delete Feedback
export const deleteFeedback = (id) => async (dispatch) => {
  try {
    await api.delete(`/feedback/${id}`)
    dispatch({ type: DELETE_FEEDBACK, payload: id })
    dispatch(setAlert('Feedback Deleted', 'success'))
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

//Clear Feedback

export const clearFeedback = () => (dispatch) => {
  dispatch({ type: CLEAR_FEEDBACK_MESSAGES })
}
