import React from 'react'
import Tip from '../../layout/overlays/Tip'
import useConfirmCancelModal from '../../hooks/modal-hooks/useConfirmCancelModal'
import ConfirmCancelModal from '../../layout/modals/ConfirmCancelModal'

const DuplicateCustomerWarning = ({
  cyclist: { cyclistProfile },
  link,
  tip,
  handleIgnore
}) => {
  const duplicateCustomerModal = useConfirmCancelModal({
    icon: 'fa fa-exclamation-triangle',
    title: 'Duplicate Customer?',
    confirmationPrompt: 'To continue,',
    confirmationText: 'ignore duplicate',
    confirmationButtonIcon: 'far fa-check-circle',
    confirmationButtonText: 'Continue',
    confirmationAction: () => {
      handleIgnore()
    }
  })

  const { firstName, lastName } = cyclistProfile

  return (
    <>
      <Tip message={tip} placement='top'>
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'none',
            color: '#0d6efd',
            fontSize: '.8rem'
          }}
          onClick={duplicateCustomerModal.setShowModal}
        >
          {link}
        </span>
      </Tip>
      <ConfirmCancelModal {...duplicateCustomerModal}>
        <p>
          Existing Customer{' '}
          <strong>
            {firstName} {lastName}
          </strong>{' '}
          has the same last name and birthdate.
        </p>
        <p>
          Enter the confirmation text below and click "Continue" if you want to
          ignore the potential duplicate and create a new customer from this
          interview.
        </p>
        <p>
          Or, click "Cancel" to return to the dashboard. You may add this
          interview to an existing customer from the dashboard.
        </p>
      </ConfirmCancelModal>
    </>
  )
}

export default DuplicateCustomerWarning
