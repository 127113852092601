import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import QuickFitTabContent from '../QuickFitTabContent'

//import { confidentialBikeNotesForm as dataForm } from '../../forms/form-objects/bikeFormObjects'

import useConfidentialBikeNotesForm from '../../hooks/form-hooks/bikes/useConfidentialBikeNotesForm'

import { BIKE_ICON } from '../../../utils/fontAwesomeDefaults'

import { updateBike } from '../../../redux/actions/bikes'

const ConfidentialBikeNotes = ({ bike, updateBike, user, fieldOverrides }) => {
  const confidentialBikeNotesForm = useConfidentialBikeNotesForm(
    {
      confidentialNotes: bike.confidentialNotes
    },
    fieldOverrides
  )

  const [currentBike, setCurrentBike] = useState(bike)

  const saveFunction = () => {
    const bikeObject = {
      make: bike.make,
      model: bike.model,
      confidentialNotes: confidentialBikeNotesForm.values.confidentialNotes
    }

    updateBike(bike._id, bikeObject, 'Confidential Bike Notes Updated')
  }

  const { changesMade } = confidentialBikeNotesForm

  useEffect(() => {
    if (bike !== currentBike) {
      confidentialBikeNotesForm.reset()
      setCurrentBike(bike)
    }
  }, [bike, currentBike, confidentialBikeNotesForm])

  return (
    <>
      <QuickFitTabContent
        title='Confidential Bike Notes'
        dataForm={confidentialBikeNotesForm}
        dataObject={{ confidentialNotes: bike.confidentialNotes }}
        editButtonText='Edit Confidential Bike Notes'
        modalTitle='Edit Confidential Bike Notes'
        modalForm={confidentialBikeNotesForm}
        modalTitleIcon={BIKE_ICON}
        confirmationButtonIcon={BIKE_ICON}
        saveFunction={saveFunction}
        user={user}
        mode='notesMode'
        disabled={
          !changesMade(
            bike.confidentialNotes,
            confidentialBikeNotesForm.values.confidentialNotes
          )
        }
      />
    </>
  )
}

export default connect(null, { updateBike })(ConfidentialBikeNotes)
