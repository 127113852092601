import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { usShort } from '../../utils/dateFormats'
import { EMAIL_ICON } from '../../utils/fontAwesomeDefaults'

const UserHeader = ({ name, email, created, updated }) => {
  const textArray = [
    name,

    <>
      <i className={EMAIL_ICON} /> {email}
    </>,

    'Joined: ' + usShort(created),
    'Last Updated: ' + usShort(updated)
  ]
  return (
    <Card className='mb-3' body>
      <Row>
        {textArray.map((text, index) => {
          return (
            <Col xs='auto' key={index}>
              {index === 0 ? <strong>{text}</strong> : <>{text}</>}
            </Col>
          )
        })}
      </Row>
    </Card>
  )
}

export default UserHeader
