import React from 'react'
import { connect } from 'react-redux'
import { createResourceCategory } from '../../../redux/actions/resources'

import EditFormButton from '../../hook-components/EditFormButton'

import useEditResourceCategoryForm from '../../hooks/form-hooks/admin/useEditResourceCategoryForm'

import { ADMIN_ICON } from '../../../utils/fontAwesomeDefaults'

const AddNewCategory = ({
  resources,
  setResourcesLoaded,
  createResourceCategory
}) => {
  const nextTab = resources.length + 1
  const editResourceCategoryForm = useEditResourceCategoryForm({
    category: '',
    tab: nextTab
  })

  const { form, values, validateForm, changesMade } = editResourceCategoryForm

  const saveFunction = () => {
    createResourceCategory(
      { ...editResourceCategoryForm.values, resourceList: [] },
      'Resource Category Created.'
    )
    editResourceCategoryForm.reset()
    setResourcesLoaded(false)
    console.log({ ...editResourceCategoryForm.values, resourceList: [] })
  }

  return (
    <>
      <EditFormButton
        editButtonText='Add Resource Category'
        modalTitle={`Add Resource Category?`}
        modalForm={editResourceCategoryForm}
        modalTitleIcon={ADMIN_ICON}
        confirmationButtonIcon={ADMIN_ICON}
        confirmationButtonText='Add Resource'
        saveFunction={saveFunction}
        disabled={
          !validateForm({ category: form.category, tab: form.tab }) ||
          !changesMade({ category: '', tab: nextTab }, values)
        }
        fksmode='stacked'
      />
    </>
  )
}

export default connect(null, { createResourceCategory })(AddNewCategory)
