import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import QuickFitTabContent from '../QuickFitTabContent'

//import { sharedBikeNotesForm as dataForm } from '../../forms/form-objects/bikeFormObjects'

import useSharedBikeNotesForm from '../../hooks/form-hooks/bikes/useSharedBikeNotesForm'

import { BIKE_ICON } from '../../../utils/fontAwesomeDefaults'

import { updateBike } from '../../../redux/actions/bikes'

const SharedBikeNotes = ({ bike, updateBike, user, fieldOverrides }) => {
  const sharedBikeNotesForm = useSharedBikeNotesForm(
    {
      notes: bike.notes
    },
    fieldOverrides
  )

  const [currentBike, setCurrentBike] = useState(bike)

  const saveFunction = () => {
    const bikeObject = {
      make: bike.make,
      model: bike.model,
      notes: sharedBikeNotesForm.values.notes
    }

    updateBike(bike._id, bikeObject, 'Shared Bike Notes Updated')
  }

  const { changesMade } = sharedBikeNotesForm

  useEffect(() => {
    if (bike !== currentBike) {
      sharedBikeNotesForm.reset()
      setCurrentBike(bike)
    }
  }, [bike, currentBike, sharedBikeNotesForm])

  return (
    <>
      <QuickFitTabContent
        title='Shared Bike Notes'
        dataForm={sharedBikeNotesForm}
        dataObject={{ notes: bike.notes }}
        editButtonText='Edit Shared Bike Notes'
        modalTitle='Edit Shared Bike Notes'
        modalForm={sharedBikeNotesForm}
        modalTitleIcon={BIKE_ICON}
        confirmationButtonIcon={BIKE_ICON}
        saveFunction={saveFunction}
        user={user}
        mode='notesMode'
        disabled={!changesMade(bike.notes, sharedBikeNotesForm.values.notes)}
      />
    </>
  )
}

export default connect(null, { updateBike })(SharedBikeNotes)
