import Tip from '../../layout/overlays/Tip'
const SizingRecommendationRow = ({ label, value, tip }) => {
  return (
    <tr>
      <td>
        {tip ? (
          <Tip message={tip} placement='top'>
            <span style={{ cursor: 'help' }}>{label}</span>
          </Tip>
        ) : (
          label
        )}
      </td>

      <td style={{ textAlign: 'right' }}>
        {value}
        {label === 'Saddle Width' ? ' mm.' : ' cm.'}
      </td>
    </tr>
  )
}

export default SizingRecommendationRow
