import React from 'react'
import { Card } from 'react-bootstrap'
import { CARD_HEADER_BG } from '../../../utils/styleAliases'

import TableData from '../../layout/cards-containers/TableData'

import {
  calculateFrameReach,
  calculateFrameStack
} from '../../../lib/tools-js-functions'

import { frameReachAndStackForm as dataForm } from '../../forms/form-objects/toolsFormObjects'

const BarToFrameOutputs = ({ inputs, user }) => {
  return (
    <Card>
      <Card.Header className={CARD_HEADER_BG}>
        <Card.Title>Frame Reach and Stack</Card.Title>
      </Card.Header>
      <Card.Body>
        <TableData
          dataForm={dataForm}
          dataObject={{
            frameReach: calculateFrameReach(inputs),
            frameStack: calculateFrameStack(inputs)
          }}
          user={user}
          //fontStyleObject={{ fontSize: '1.0rem' }}
        />
      </Card.Body>
    </Card>
  )
}

export default BarToFrameOutputs
