import { Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'

import NewInterviewTable from './interviews/NewInterviewTable'
import RecentCustomerTable from './customers/RecentCustomerTable'
import Stats from './data/Stats'
import InterviewLinks from './interviews/InverviewLinks'
import NewsAndUpdates from '../admin/message-header/NewsAndUpdates'

const QuickFitDashboard = ({ newInterviews, cyclists, user, shop }) => {
  return (
    <>
      <NewsAndUpdates />

      <Row className='mt-3'>
        <Col lg={8}>
          <NewInterviewTable newInterviews={newInterviews} />
          <InterviewLinks user={user} shop={shop} />
        </Col>
        <Col>
          <RecentCustomerTable />
          <Stats cyclists={cyclists} />
        </Col>
      </Row>
    </>
  )
}

QuickFitDashboard.propTypes = {
  newInterviews: PropTypes.array.isRequired,
  cyclists: PropTypes.array.isRequired
}

export default QuickFitDashboard
