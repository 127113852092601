export const searchParamField = {
  name: 'searchParam',
  type: 'text',
  label: 'Look for',
  placeholder: 'search text'
}

export const collection = {
  name: 'collection',
  type: 'select',
  label: 'Collection',
  options: ['users', 'customers', 'bikes']
}

export const userField = {
  name: 'userField',
  type: 'select',
  label: 'Field',
  options: ['name', 'email']
}
export const customerField = {
  name: 'customerField',
  type: 'select',
  label: 'Field',
  options: ['firstName', 'lastName', 'email']
}
export const bikeField = {
  name: 'bikeField',
  type: 'select',
  label: 'Field',
  options: ['make', 'model', 'frameSize']
}

export const collectionField = [collection]

export const userParam = [userField]

export const customerParam = [customerField]

export const bikeParam = [bikeField]

export const searchParam = [searchParamField]
