import jsPDF from 'jspdf'

import { getFormat, getHeader, getTableBlock, getFooter } from './pdfFunctions'

import { LEFT_MARGIN, TOP_MARGIN } from './pdfFunctions'

import { utcToutc } from '../utils/dateFormats'

export const interviewPdf = (props) => {
  const {
    fileName,
    user,
    shop,
    customer,
    interview,
    objectives,
    ridingProfile,
    physicalProfile
  } = props
  const {
    created,
    firstName,
    lastName,
    email,
    phone,
    zipCode,
    referralSource,
    referralSourceDetails,
    priorBikeFit,
    objectiveMeasureAndAdvise,
    objectiveComments,
    skillsAndConfidence,
    cyclingGoals,
    physicalComments,
    discomfortAreas,
    otherPhysicalActivities,
    bikeType,
    bikeReasons,
    bikeChannels,
    bikeMake,
    bikeModel,
    bikeComments,
    bikeNewStyle,
    bikeBudget,
    bikeFrameSize,
    bikeBrandsModels,
    bikeDecisionFactors,
    mediaConsent
  } = interview
  let pdf = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: getFormat(user)
  })

  pdf.setFontSize(10)
  pdf.setTextColor(user.preferences.report_color)
  // Header
  getHeader(pdf, user, shop, 'Pre-Fit Interview Summary', customer, interview)

  //Table Blocks

  const mediaPermission = { label: 'Media Permission' }

  const baseDataForm = {
    dateCompleted: { label: 'Date Completed', type: 'text' },
    contactDetails: { label: 'Contact Details' },
    referralSource: { label: 'Referral Source' },
    referralSourceDetails: { label: 'Referral Source Details' },
    priorBikeFit: { label: 'Prior Bike Fit' },
    objectives: { label: 'Bike Fit Objectives' },
    objectiveComments: { label: 'Additional Comments' },
    ridingProfile: { label: 'Riding Profile' },
    skillsAndConfidence: { label: 'Skills And Confidence' },
    cyclingGoals: { label: 'Cycling Goals' },
    physicalProfile: { label: 'Physical Profile' },
    physicalComments: { label: 'Physical Considerations' },
    discomfortAreas: { label: 'Areas of Discomfort' },
    otherPhysicalActivities: { label: 'Other Physical Activities' }
  }
  let dataForm = objectiveMeasureAndAdvise
    ? {
        ...baseDataForm,
        bikeDetails: { label: 'New Bike Details' },
        bikeReasons: { label: 'Reasons for Purchase' },
        bikeChannels: { label: 'Type of Purchase' },
        bikeBrandsModels: { label: 'Brands or Models Under Consideration' },
        bikeDecisionFactors: { label: 'Primary Decision Factors' },
        mediaPermission
      }
    : {
        ...baseDataForm,
        bikeDetails: { label: 'Current Bike Details' },
        bikeComments: { label: 'Additional Comments' },
        mediaPermission
      }

  const baseDataObject = {
    dateCompleted: utcToutc(created),
    contactDetails:
      firstName +
      ' ' +
      lastName +
      ' | ' +
      email +
      ' | ' +
      phone +
      ' | ' +
      zipCode,
    referralSource,
    referralSourceDetails,
    priorBikeFit,
    objectives,
    objectiveComments,
    ridingProfile,
    skillsAndConfidence,
    cyclingGoals,
    physicalProfile,
    physicalComments,
    discomfortAreas,
    otherPhysicalActivities
  }

  let dataObject = objectiveMeasureAndAdvise
    ? {
        ...baseDataObject,
        bikeDetails:
          bikeType +
          bikeType +
          ' | New Style: ' +
          (bikeNewStyle ? 'Yes' : 'No') +
          ' | Budget: ' +
          bikeBudget,
        bikeReasons,
        bikeChannels,
        bikeBrandsModels,
        bikeDecisionFactors,
        mediaPermission: mediaConsent
      }
    : {
        ...baseDataObject,
        bikeDetails:
          bikeType +
          ' | Make: ' +
          bikeMake +
          ' | Model: ' +
          bikeModel +
          ' | Frame Size: ' +
          bikeFrameSize,
        bikeComments,
        mediaPermission: mediaConsent
      }

  //Footer
  getFooter({ pdf })

  getTableBlock({
    pdf,
    dataForm: dataForm,
    dataObject: dataObject,
    user,
    shop,
    customer,
    interview,
    cols: [65],
    heading: 'Pre-Fit Interview Summary',
    x: LEFT_MARGIN,
    y: TOP_MARGIN + 30,
    headerMargin: 10,
    lineHeightFactor: 1.8
  })

  pdf.save(fileName + '.pdf')
}
