import { Card } from 'react-bootstrap'

const SearchError = ({ errors }) => {
  return (
    <Card className='mt-5'>
      <Card.Header className='bg-white'>Search Results (Error)</Card.Header>
      <Card.Body>
        <div>
          {errors.map((error, index) => (
            <p key={index}>{error.msg}</p>
          ))}
        </div>
      </Card.Body>
    </Card>
  )
}

export default SearchError
