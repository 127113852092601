import api from '../../utils/api.js'
import { setAlert } from './alert'
import { createBike } from './bikes'
import { loadNewPrefitInterviews } from './prefitInterviews'

import {
  CREATE_CYCLIST,
  CYCLISTS_LOADED,
  UPDATE_CYCLIST,
  DELETE_CYCLIST,
  CLEAR_CYCLISTS
} from './types'

// Create Cyclist
export const createCyclist =
  (cyclist, bikeData, interview) => async (dispatch) => {
    try {
      const res = await api.post('/cyclists', cyclist)

      dispatch({
        type: CREATE_CYCLIST,
        payload: res.data
      })

      if (interview) {
        interview.createdForCyclist = res.data._id
        await api.put(`/prefitinterviews/${interview._id}`, interview)
        dispatch(loadNewPrefitInterviews())
      }

      if (bikeData.make && bikeData.model) {
        dispatch(createBike(bikeData, res.data._id))
        dispatch(setAlert('Customer and Bike Created', 'success'))
      } else dispatch(setAlert('Customer Created', 'success'))

      dispatch(loadCyclists())
      return res.data._id
    } catch (err) {
      console.log(err)
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
      }
    }
  }

// Get all cyclists for Logged in User
export const loadCyclists = () => async (dispatch) => {
  try {
    const res = await api.get('/cyclists')

    dispatch({
      type: CYCLISTS_LOADED,
      payload: res.data
    })
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}

// Update Cyclist

export const updateCyclist =
  (cyclistId, formData, alertMessage) => async (dispatch) => {
    try {
      const res = await api.put(`/cyclists/${cyclistId}`, formData)

      dispatch({ type: UPDATE_CYCLIST, payload: res.data })

      if (alertMessage) dispatch(setAlert(alertMessage, 'success'))
      dispatch(loadCyclists())
    } catch (err) {
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
      }
    }
  }

//Delete Cyclist

export const deleteCyclist = (cyclistId) => async (dispatch) => {
  try {
    await api.delete(`/cyclists/${cyclistId}`)

    dispatch({ type: DELETE_CYCLIST, payload: cyclistId })

    dispatch(setAlert('Customer Deleted', 'success'))
    //dispatch(loadCyclists())
  } catch (err) {
    console.error(err)
  }
}

//Clear Cyclists

export const clearCyclists = () => (dispatch) => {
  dispatch({ type: CLEAR_CYCLISTS })
}
