import React from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, InputGroup } from 'react-bootstrap'
import { BIKE_ICON } from '../../../utils/fontAwesomeDefaults'

const BikeSelector = ({ bike, customerId, bikes: { customerBikes } }) => {
  const { _id } = bike

  const navigate = useNavigate()

  const onChange = (e) => {
    const bikeId = e.target.value
    navigate(`/quickfit/bike/${customerId}/${bikeId}`)
  }

  return (
    <div className='bikeSelect'>
      <InputGroup>
        <InputGroup.Text className='bg-white'>
          <i className={BIKE_ICON} />
        </InputGroup.Text>
        <Form.Select
          size='sm'
          style={{ fontWeight: 'bold', boxShadow: 'none' }}
          value={_id}
          onChange={onChange}
        >
          {customerBikes.map((bike) => {
            return (
              <option key={bike._id} value={bike._id}>
                {bike.make} {bike.model}
              </option>
            )
          })}
        </Form.Select>
      </InputGroup>
    </div>
  )
}

const mapStateToProps = (state) => ({
  bikes: state.bikes
})

export default connect(mapStateToProps, {})(BikeSelector)
