import api from '../../utils/api.js'
import { setAlert } from './alert'
import { loadShop, clearShop } from './shops'
import { loadCyclists, clearCyclists } from './cyclists'
import {
  loadNewPrefitInterviews,
  clearPrefitInterviews
} from './prefitInterviews'
import {
  loadStripeCustomerData,
  loadStripeSubscriptionData,
  clearStripeData
} from './stripe'

import { v4 as uuidv4 } from 'uuid'

import { loadAdminMessages, clearAdminMessages } from './adminmessages'
import { loadFieldOverrides, clearFieldOverrides } from './fields'
import { clearFeedback } from './feedback'

import {
  SET_LOADING,
  SET_REFRESH,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  USER_ID_CONFIRMED,
  USER_ID_NOT_CONFIRMED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  PASSWORD_RESET_TOKEN_INVALID,
  PASSWORD_RESET_TOKEN_VALID,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_FAIL,
  REQUEST_PASSWORD_RESET_SUCCESS,
  REQUEST_PASSWORD_RESET_FAIL,
  AUTO_LOGOUT
} from './types'

// Set Loading

export const setLoading = (loading) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: loading })
}

// Set Refresh

export const setRefresh = (refresh) => (dispatch) => {
  dispatch({ type: SET_REFRESH, payload: refresh })
}

// Check Valid User Id

export const confirmUserId = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/users/${userId}`)

    dispatch({
      type: USER_ID_CONFIRMED,
      payload: res.data
    })
  } catch (err) {
    console.log(err)

    dispatch({
      type: USER_ID_NOT_CONFIRMED
    })
  }
}

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get('/auth')

    if (res.data.stripe_customer_id)
      await dispatch(loadStripeCustomerData(res.data.stripe_customer_id))
    if (res.data.stripe_subscription_id) {
      await dispatch(
        loadStripeSubscriptionData(res.data.stripe_subscription_id)
      )

      if (res.data.shop_owner) await dispatch(loadShop())

      await dispatch(loadNewPrefitInterviews())
      await dispatch(loadCyclists())
    }
    await dispatch(loadAdminMessages())
    await dispatch(loadFieldOverrides())

    if (!res.data.avatar) {
      //update user to include avatar
      await api.put('/users', {
        name: res.data.name,
        email: res.data.email,
        avatar: 'update'
      })
    }

    dispatch({
      type: USER_LOADED,
      payload: res.data
    })
  } catch (err) {
    console.log(err)
    dispatch({
      type: AUTH_ERROR
    })
  }
}

// Register User
export const register = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/users', formData)

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data
    })

    dispatch(loadUser())
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }

    dispatch({
      type: REGISTER_FAIL
    })
  }
}

// Login User
export const login = (email, password) => async (dispatch) => {
  const body = { email, password }

  try {
    const res = await api.post('/auth', body)

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data
    })

    dispatch(loadUser())
  } catch (err) {
    console.error(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }

    dispatch({
      type: LOGIN_FAIL
    })
  }
}

// Logout
export const logout = () => (dispatch) => {
  dispatch(clearShop())
  dispatch(clearStripeData())
  dispatch(clearCyclists())
  dispatch(clearPrefitInterviews())
  dispatch(clearAdminMessages())
  dispatch(clearFeedback())
  dispatch(clearFieldOverrides())
  dispatch({ type: LOGOUT })
}
// Update User

export const updateUser = (formData) => async (dispatch) => {
  try {
    const res = await api.put('/users', formData)

    dispatch({ type: UPDATE_USER_SUCCESS, payload: res.data })
    formData.password
      ? dispatch(setAlert('Password Updated', 'success'))
      : formData.preferences
      ? dispatch(setAlert('Preferences Updated', 'success'))
      : formData.shop_owner
      ? dispatch(setAlert('Shop Created', 'success'))
      : formData.shop_owner === false
      ? dispatch(setAlert('Shop Deleted', 'success'))
      : dispatch(setAlert('Profile Updated', 'success'))
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }

    dispatch({ type: UPDATE_USER_FAIL })
  }
}

//Delete User

export const deleteAccount = () => async (dispatch) => {
  try {
    //Delete User

    await api.delete('/users')

    //Logout
    dispatch(logout())
    dispatch(setAlert('Account Deleted', 'success'))
  } catch (err) {
    console.error(err)
  }
}

//Admin Delete User

export const adminDeleteAccount = (userId) => async (dispatch) => {
  try {
    // Admin Delete User

    await api.delete(`/users/admin/${userId}`)

    dispatch(setAlert('Account Deleted', 'success'))
  } catch (err) {
    console.error(err)
  }
}

//Admin Approve User

export const adminApproveUser = (user) => async (dispatch) => {
  try {
    // Admin Approve User

    await api.put(`/users/admin/${user._id}`, user)

    dispatch(setAlert('User Approved', 'success'))
  } catch (err) {
    console.error(err)
  }
}

//Request Password Reset Email

export const requestPasswordReset = (email) => async (dispatch) => {
  const body = { email }

  try {
    const res = await api.put('/users/password-reset-request', body)

    dispatch(setAlert(res.data.message, 'success'))

    dispatch({ type: REQUEST_PASSWORD_RESET_SUCCESS })
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }

    dispatch({ type: REQUEST_PASSWORD_RESET_FAIL })
  }
}

// Check Reset Token

export const checkResetToken = (token) => async (dispatch) => {
  try {
    const body = { token }

    const res = await api.post('users/validate-reset-token', body)

    dispatch({
      type: PASSWORD_RESET_TOKEN_VALID,
      payload: res.data
    })
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }

    dispatch({
      type: PASSWORD_RESET_TOKEN_INVALID
    })
  }
}

// Check Auth Token

export const checkAuthToken = (token) => async (dispatch) => {
  try {
    const body = { token }

    const res = await api.post('auth/check-token', body)

    const tokenExpired = res.data

    if (tokenExpired)
      dispatch({
        type: AUTO_LOGOUT
      })
  } catch (err) {
    console.error(err)
  }
}

// Reset User Password

export const resetUserPassword = (token, password) => async (dispatch) => {
  try {
    const body = { token, password }

    const res = await api.put('/users/reset-password', body)

    dispatch(setAlert(res.data, 'success'))

    dispatch({
      type: RESET_USER_PASSWORD
    })
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }

    dispatch({
      type: RESET_USER_PASSWORD_FAIL
    })
  }
}

//Send Contact Message

export const sendContactMessage = (message) => async (dispatch) => {
  try {
    await api.post('users/send-contact-message', message)

    dispatch(setAlert('Message sent', 'success'))
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

//Refresh User API Key

export const refreshAPIKey = (user, subscription) => async (dispatch) => {
  const newKey = 'fks_qs_' + uuidv4() + uuidv4()
  try {
    const res = await api.put('/users', {
      name: user.name,
      email: user.email,
      api_key: newKey,
      api_exp: subscription.current_period_end
    })

    dispatch({ type: UPDATE_USER_SUCCESS, payload: res.data })
    dispatch(setAlert('API Key Updated', 'success'))
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }

    dispatch({ type: UPDATE_USER_FAIL })
  }
}
