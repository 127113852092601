import React from 'react'

const FieldOverrides = ({ fieldName, fieldFormName, fieldOverrides }) => {
  let overridesObject = fieldOverrides.find(
    (field) => field.fieldName === fieldName && field.formName === fieldFormName
  )

  if (!overridesObject)
    overridesObject = fieldOverrides.find(
      (field) =>
        field.fieldName === fieldName.substring(5) &&
        field.formName === fieldFormName
    )

  if (!overridesObject) return <>None</>

  const {
    _id,
    __v,
    formName,
    fieldName: dbfieldName,
    ...overrides
  } = overridesObject

  return (
    <table style={{ width: '100%' }}>
      <tbody>
        {Object.entries(overrides).map(([prop, val], index) => {
          return (
            <tr key={index}>
              <td style={{ verticalAlign: 'top' }}>{prop}</td>
              <td> </td>
              {typeof val !== 'object' ? (
                <td style={{ textAlign: 'right' }}>{val}</td>
              ) : (
                <table>
                  {val.map((item) => {
                    return (
                      <tr>
                        <td>{item}</td>
                      </tr>
                    )
                  })}
                </table>
              )}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default FieldOverrides
