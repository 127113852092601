import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { updateUser } from '../../../redux/actions/users'

import useResetPasswordForm from '../../hooks/form-hooks/users-auth/useResetPasswordForm'

import AccountSettingsTabContent from '../AccountSettingsTabContent'

import { USER_ICON } from '../../../utils/fontAwesomeDefaults'

import { validateForm, checkPasswordMatch } from '../../../utils/formValidation'

const EditPassword = ({ users: { user }, updateUser }) => {
  const resetPasswordForm = useResetPasswordForm({
    password: '',
    confirmPassword: ''
  })

  const saveFunction = () => {
    const { password } = resetPasswordForm.values
    updateUser({
      name: user.name,
      email: user.email,
      password
    })
    resetPasswordForm.reset()
  }

  return (
    <>
      <AccountSettingsTabContent
        title='Password'
        editButtonText='Edit Password'
        modalTitle='Edit Password'
        modalForm={resetPasswordForm}
        modalTitleIcon={USER_ICON}
        confirmationButtonIcon={USER_ICON}
        saveFunction={saveFunction}
        disabled={
          !validateForm(resetPasswordForm.form) ||
          !checkPasswordMatch(
            resetPasswordForm.values.password,
            resetPasswordForm.values.confirmPassword
          )
        }
        error={
          validateForm(resetPasswordForm.form) &&
          !checkPasswordMatch(
            resetPasswordForm.values.password,
            resetPasswordForm.values.confirmPassword
          )
        }
        user={user}
        mode='password'
      />
    </>
  )
}

EditPassword.propTypes = {
  users: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  users: state.users
})

export default connect(mapStateToProps, { updateUser })(EditPassword)
