import React from 'react'

import { Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap'

import { reportFields } from '../../forms/form-objects/userPreferencesFormObjects'

const PreferenceInfo = ({ user: { preferences }, activeStatus }) => {
  const {
    height_units,
    weight_units,
    report_fields,
    report_format,
    report_color
  } = preferences

  const reportKeys = Object.keys(report_fields)
  const reportValues = Object.values(report_fields)
  const reportFieldValues = Object.values(reportFields)

  return (
    <>
      <Row>
        <Col>
          <ListGroup>
            {activeStatus ? (
              <>
                <ListGroupItem>Height Units: {height_units}</ListGroupItem>
                <ListGroupItem>Weight Units: {weight_units}</ListGroupItem>

                <ListGroupItem>
                  PDF Report Fields: <br />
                  <div style={{ fontSize: '.8rem', marginLeft: '10px' }}>
                    {reportKeys.map((field, index) => {
                      return reportValues[index] ? (
                        <span key={index}>
                          {reportFieldValues[index].label}
                          <br />
                        </span>
                      ) : null
                    })}
                  </div>
                </ListGroupItem>
                <ListGroupItem>
                  PDF Report Format: {report_format}
                </ListGroupItem>
                <ListGroupItem>
                  PDF Report Heading Color:
                  <span style={{ color: report_color }}>
                    &nbsp;&nbsp;&nbsp;
                    <i className='fas fa-brush' />
                  </span>
                </ListGroupItem>
              </>
            ) : (
              <>
                <p>
                  <strong>Available to subscribers only.</strong>
                </p>
                Quick Fit subscribers can specify preferences for:
                <br />
                <li>height units</li>
                <li> weight units</li>
                <li> report fields</li>
                <li>paper format</li>
                <li>heading color</li>
              </>
            )}
          </ListGroup>
        </Col>
      </Row>
    </>
  )
}

export default PreferenceInfo
