//import customer fields

import {
  footLength,
  inseam,
  torso,
  arm,
  shoulders,
  sitBones,
  age,
  flexibility,
  ridingStyle,
  preconditions
} from '../field-objects/customerFields'

import {
  handlebarReachHX,
  handlebarStackHY
} from '../field-objects/fitPositionFields'

import {
  frameReach,
  frameStack,
  headTubeAngle,
  headSetHeight,
  spacersBelow,
  stemClampHeight,
  stemLength,
  stemAngle
} from '../field-objects/bikeFields'

export const quickSizeMeasurementsForm = {
  footLength,
  inseam,
  torso,
  arm,
  shoulders,
  sitBones,
  age,
  flexibility,
  ridingStyle,
  preconditions
}

export const barToFrameXYForm = {
  handlebarReachHX,
  handlebarStackHY,
  headTubeAngle,
  headSetHeight,
  spacersBelow,
  stemClampHeight,
  stemLength,
  stemAngle
}

export const frameReachAndStackForm = {
  frameReach,
  frameStack
}

export const frameToBarXYForm = {
  frameReach,
  frameStack,
  headTubeAngle,
  headSetHeight,
  spacersBelow,
  stemClampHeight,
  stemLength,
  stemAngle
}

export const handlebarReachAndStackForm = {
  handlebarReachHX,
  handlebarStackHY
}

/* export const oldstemDeltaForm = {
  headTubeAngle,
  stemLengthA: { ...stemLength, label: 'Stem One Length' },
  stemAngleA: { ...stemAngle, label: 'Stem One Angle', step: 1 },
  stemClampHeightA: { ...stemClampHeight, label: 'Stem One Spacers Below' },
  stemLengthB: { ...stemLength, label: 'Stem Two Length' },
  stemAngleB: { ...stemAngle, label: 'Stem Two Angle', step: 1 },
  stemClampHeightB: { ...stemClampHeight, label: 'Stem Two Spacers Below' }
} */

export const stemDeltaForm = {
  headTubeAngle,
  stemLengthA: { ...stemLength, label: 'Stem One Length' },
  stemAngleA: { ...stemAngle, label: 'Stem One Angle', step: 1 },
  spacersBelowA: { ...spacersBelow, label: 'Stem One Spacers Below' },
  stemLengthB: { ...stemLength, label: 'Stem Two Length' },
  stemAngleB: { ...stemAngle, label: 'Stem Two Angle', step: 1 },
  spacersBelowB: { ...spacersBelow, label: 'Stem Two Spacers Below' }
}
