import api from '../../utils/api.js'
import { setAlert } from './alert'

import {
  FIELDS_LOADED,
  UPDATE_FIELDS,
  DELETE_OVERRIDES,
  CLEAR_FIELD_OVERRIDES
} from './types'

// Get all fields
export const loadFieldOverrides = () => async (dispatch) => {
  try {
    const res = await api.get('/fields')

    dispatch({
      type: FIELDS_LOADED,
      payload: res.data
    })
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}

// Create or Update Field Overrides

export const updateOverrides = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/fields', formData)

    dispatch({ type: UPDATE_FIELDS, payload: res.data })

    dispatch(setAlert('Field Overrides Updated', 'success'))
    //dispatch(loadFieldOverrides())
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

//Delete Field Overrides
export const deleteOverrides = (id) => async (dispatch) => {
  try {
    await api.delete(`/fields/${id}`)
    dispatch({ type: DELETE_OVERRIDES, payload: id })
    dispatch(setAlert('Overrides Deleted', 'success'))
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

//Clear Overrides

export const clearFieldOverrides = () => (dispatch) => {
  dispatch({ type: CLEAR_FIELD_OVERRIDES })
}
