import React from 'react'
import { Card } from 'react-bootstrap'

const DashboardCard = ({ children, style, title, footer }) => {
  return (
    <Card style={style} className='mb-3'>
      <Card.Header className='bg-white'>
        <h5 style={{ display: 'inline' }}>{title}</h5>
      </Card.Header>

      <Card.Body>{children}</Card.Body>

      {footer && <Card.Footer className='bg-white'>{footer}</Card.Footer>}
    </Card>
  )
}

export default DashboardCard
