export const feedbackMessage = {
  type: 'text-area',
  label: 'Message Content',
  rows: 4,
  validationType: 'inputLength',
  length: 1
}

export const read = {
  type: 'switch',
  label: 'Mark as Read'
}

export const resolved = {
  type: 'switch',
  label: 'Mark as Resolved'
}
