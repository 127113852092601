import { Card } from 'react-bootstrap'

import {
  getPaymentAmount,
  getPaymentFrequency
} from '../../../utils/stripeUtils'

import UpdatePaymentFrequencyButton from './UpdatePaymentFrequencyButton'
import CancelSubscriptionButton from './CancelSubscriptionButton'
import UpdatePaymentButton from './UpdatePaymentButton'

const Trialing = ({ currentStop, subscription, last4 }) => {
  const trialPeriod = process.env.REACT_APP_TRIAL_PERIOD
  const { plan } = subscription

  return (
    <>
      <Card>
        <Card.Header className='bg-white'>
          <Card.Title>Subscription</Card.Title>
        </Card.Header>{' '}
        <Card.Body>
          <p>
            You are currently subscribed to <b>Quick Fit</b> and your{' '}
            {trialPeriod}
            -day trial is active.
          </p>
          <p>
            Your {trialPeriod}
            -day trial will expire on: <b>{currentStop}</b>.
          </p>
          <p>
            Unless you cancel your subscription before <b>{currentStop}</b>,
            your subscription will automatically renew on such date, and you
            will be charged <b>{getPaymentAmount(plan)}</b> based on your
            selected payment frequency.
          </p>
          <p>
            Your payment frequency is: <b>{getPaymentFrequency(plan)}</b>
            <UpdatePaymentFrequencyButton
              subscription={subscription}
              currentStop={currentStop}
            />
          </p>
          Your current payment method is: Credit Card ending in: <b>{last4}</b>{' '}
          <UpdatePaymentButton />
        </Card.Body>
        <Card.Footer className='bg-white'>
          <CancelSubscriptionButton
            subscription={subscription}
            currentStop={currentStop}
          />
        </Card.Footer>
      </Card>
    </>
  )
}

export default Trialing
