import React from 'react'
import { Card } from 'react-bootstrap'
import { CARD_HEADER_BG } from '../../utils/styleAliases'

const OutputsPlaceholder = ({ title, message }) => {
  return (
    <Card>
      <Card.Header className={CARD_HEADER_BG}>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Body>{message}</Card.Body>
    </Card>
  )
}

export default OutputsPlaceholder
