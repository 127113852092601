import api from '../../utils/api.js'
import { setAlert } from './alert'

// Get all resources
export const loadResources = () => async (dispatch) => {
  try {
    const res = await api.get('/resources')

    return res.data
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}

// Create New Resource Category
export const createResourceCategory = (formData, alert) => async (dispatch) => {
  try {
    await api.post('/resources', formData)

    if (alert) dispatch(setAlert(alert, 'success'))
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

//Update Resource Category

export const updateResourceCategory =
  (resourceId, formData, alertMessage) => async (dispatch) => {
    try {
      await api.put(`/resources/${resourceId}`, formData)

      //  dispatch({ type: UPDATE_ADMIN_MESSAGE, payload: res.data })

      if (alertMessage) dispatch(setAlert(alertMessage, 'success'))
    } catch (err) {
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
      }
    }
  }

//Delete Resource Category
export const deleteResourceCategory = (id) => async (dispatch) => {
  try {
    await api.delete(`/resources/${id}`)

    dispatch(setAlert('Resource Category Deleted', 'success'))
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}
