import {
  CLEAR_FIELD_OVERRIDES,
  DELETE_OVERRIDES,
  FIELDS_LOADED,
  UPDATE_FIELDS
} from '../actions/types'

const initialState = {
  fieldOverrides: []
}

export default function fields(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case FIELDS_LOADED:
      return {
        ...state,
        fieldOverrides: payload
      }
    case UPDATE_FIELDS:
      let cleanArray = state.fieldOverrides.filter(
        (field) => field.fieldName !== payload.fieldName
      )
      cleanArray.push(payload)
      return {
        ...state,
        fieldOverrides: cleanArray
      }
    case DELETE_OVERRIDES:
      return {
        ...state,
        fieldOverrides: state.fieldOverrides.filter(
          (field) => field._id !== payload
        )
      }

    case CLEAR_FIELD_OVERRIDES:
      return {
        ...initialState
      }

    default:
      return state
  }
}
