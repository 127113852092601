import React from 'react'
import { Modal, Form, Button } from 'react-bootstrap'

import { CANCEL_ICON } from '../../../utils/fontAwesomeDefaults'

const FormModal = ({
  showModal,
  title,
  icon,
  children,
  clickConfirm,
  clickCancel,
  confirmationButtonIcon,
  confirmationButtonText,
  disabled
}) => {
  return (
    <Modal className='bikeFormModal' centered show={showModal}>
      <Modal.Header>
        <Modal.Title>
          {icon && <i className={icon} />} {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {children}
        <Form className='mt-3'></Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='danger' onClick={clickConfirm} disabled={disabled}>
          <i className={confirmationButtonIcon} /> {confirmationButtonText}
        </Button>{' '}
        <Button variant='dark' onClick={clickCancel}>
          <i className={CANCEL_ICON} /> Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default FormModal
