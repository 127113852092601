import React from 'react'
import { connect } from 'react-redux'

import useEditFieldsForm from '../../hooks/form-hooks/admin/useEditFieldsForm'

import { updateOverrides } from '../../../redux/actions/fields'

import EditFormButton from '../../hook-components/EditFormButton'
import { ADMIN_ICON, EDIT_ICON } from '../../../utils/fontAwesomeDefaults'

const EditFieldButton = ({
  type,
  fieldName,
  fieldFormName,
  fieldOverride,
  updateOverrides
}) => {
  if (!fieldOverride) fieldOverride = {}
  const {
    _id,
    __v,
    formName,
    fieldName: dbfieldName,
    ...overrides
  } = fieldOverride

  let editFieldsForm = useEditFieldsForm(overrides)

  let editForm

  const { label, tip } = editFieldsForm.form

  type === 'number'
    ? (editForm = { ...editFieldsForm })
    : (editForm = { ...editFieldsForm, form: { label, tip } })

  const saveFunction = () => {
    //  let fieldFieldName = fieldName
    if (fieldName.includes('cust-') || fieldName.includes('bike-'))
      fieldName = fieldName.substring(5)

    // console.log({ ...editForm.values, fieldName, formName: fieldFormName })
    updateOverrides({ ...editForm.values, fieldName, formName: fieldFormName })
    editFieldsForm.reset()
  }

  let oldValues = {}
  oldValues.label = overrides.label
  oldValues.max = overrides.max
  oldValues.min = overrides.min
  oldValues.step = overrides.step
  oldValues.tip = overrides.tip

  return (
    <EditFormButton
      modalTitle={`Edit "${fieldName}" Field`}
      modalForm={editForm}
      modalTitleIcon={ADMIN_ICON}
      confirmationButtonIcon={ADMIN_ICON}
      confirmationButtonText='Save Changes'
      saveFunction={saveFunction}
      resetFunction={editFieldsForm.reset}
      disabled={
        !editForm.validateForm(editForm.form) ||
        !editForm.changesMade(oldValues, editForm.values)
      }
      link={
        <>
          <i className={EDIT_ICON} />
        </>
      }
      tip={`Edit "${fieldName}" field.`}
    />
  )
}

export default connect(null, { updateOverrides })(EditFieldButton)
