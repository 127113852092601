import { useState, useEffect } from 'react'
import store from '../../../redux/store'
import { loadAdminCustomerList } from '../../../redux/actions/admindata'

const useAdminCustomerList = () => {
  const [customerListLoaded, setCustomerListLoaded] = useState(false)
  const [customerList, setCustomerList] = useState()

  useEffect(() => {
    const fetchUserList = async () => {
      const customerList = await store.dispatch(loadAdminCustomerList())
      setCustomerList(customerList)
      setCustomerListLoaded(true)
    }
    fetchUserList()
  }, [])

  return { customerListLoaded, customerList }
}

export default useAdminCustomerList
