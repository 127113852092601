import React from 'react'
import { Table, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import {
  loadCustomerPrefitInterviews,
  deleteInterview
} from '../../../redux/actions/prefitInterviews'

import QuickFitDashboardCard from '../QuickFitDashboardCard'
import QuickFitDashboardTitle from '../QuickFitDashboardTitle'
import QuickFitDashboardItemCounter from '../QuickFitDashboardItemCounter'
import NextPreviousFooter from '../../hook-components/NextPreviousFooter'
import QuickFitTableRow from '../QuickFitTableRow'
import DeleteRecordButton from '../../hook-components/DeleteRecordButton'
import PrintPDFButton from '../../../pdf/PrintPDFButton'
import { interviewPdf } from '../../../pdf/InterviewPdf'

import useNextPrevious from '../../hooks/action-hooks/useNextPrevious'
import useCustomerInterviews from '../../hooks/data-fetch-hooks/useCustomerInterviews'

import { usNormal, calculateAge } from '../../../utils/dateFormats'

import {
  convertedHeight,
  convertedWeight,
  heightMunit,
  weightMunit
} from '../../../utils/heightWeight'

const CustomerInterviews = ({
  customerId,
  bikeTableMode,
  loadCustomerPrefitInterviews,
  deleteInterview,
  prefitinterviews: { customerInterviews },
  users: { user },
  shops: { shop }
}) => {
  useCustomerInterviews(customerId)

  const nextPrevious = useNextPrevious(1, customerInterviews.length)

  const { start, stop, length } = nextPrevious

  const deleteFunction = (interviewId) => {
    nextPrevious.handleDelete()
    deleteInterview(interviewId)
  }

  if (customerInterviews.length !== length)
    nextPrevious.setcurrentLength(customerInterviews.length)

  if (customerInterviews.length === 0)
    return (
      <QuickFitDashboardCard
        style={{ minHeight: '415px' }}
        title='Pre-fit Interviews'
      >
        No Interviews Found
      </QuickFitDashboardCard>
    )

  const {
    firstName,
    lastName,
    email,
    phone,
    zipCode,
    referralSource,
    referralSourceDetails,
    priorBikeFit,
    objectiveMeasureAndAdvise,
    objectiveSetUp,
    objectiveGeneral,
    objectiveRelieve,
    objectiveImprove,
    objectiveReplicate,
    objectiveShoe,
    objectiveSaddle,
    objectiveAero,
    objectiveComments,
    ridingStyle,
    yearsCycling,
    hoursPerWeek,
    skillsAndConfidence,
    cyclingGoals,
    birthDate,
    gender,
    height,
    weight,
    physicalComments,
    discomfortAreas,
    otherPhysicalActivities,
    bikeType,
    bikeReasons,
    bikeChannels,
    bikeMake,
    bikeModel,
    bikeComments,
    bikeNewStyle,
    bikeBudget,
    bikeFrameSize,
    _id: interviewId,
    bikeBrandsModels,
    bikeDecisionFactors,
    mediaConsent
  } = customerInterviews[start - 1]

  let objectives = ''

  if (objectiveMeasureAndAdvise)
    objectives = objectives + 'Measure and Advise for New Bike Purchase | '
  if (objectiveSetUp) objectives = objectives + 'Set Up New Bike | '
  if (objectiveGeneral)
    objectives = objectives + 'General Check Up and Refinement | '
  if (objectiveRelieve)
    objectives = objectives + 'Relieve Pain or Discomfort | '
  if (objectiveImprove) objectives = objectives + 'Improve Performance | '
  if (objectiveReplicate)
    objectives = objectives + 'Replicate Fit from Another Bike | '
  if (objectiveShoe) objectives = objectives + 'Shoe or Cleat Fit | '
  if (objectiveSaddle) objectives = objectives + 'Saddle Fit | '
  if (objectiveAero) objectives = objectives + 'Aero Fit | '
  if (objectives.length > 3)
    objectives = objectives.substring(0, objectives.length - 3)

  const ridingProfile =
    ridingStyle +
    ' | Years of Experience: ' +
    yearsCycling +
    ' | Average Hours Per Week: ' +
    hoursPerWeek

  const { height_units, weight_units } = user.preferences

  const physicalProfile =
    'Age: ' +
    calculateAge(birthDate) +
    ' | Gender: ' +
    gender +
    ' | Height: ' +
    convertedHeight(height, height_units) +
    ' ' +
    heightMunit(height_units) +
    ' | Weight: ' +
    convertedWeight(weight, weight_units) +
    ' ' +
    weightMunit(weight_units)

  return (
    <QuickFitDashboardCard
      style={{ minHeight: '691px' }}
      title={
        <Col>
          <QuickFitDashboardTitle title='Pre-fit Interviews' />
          <QuickFitDashboardItemCounter
            start={start}
            stop={stop}
            length={length}
          />
        </Col>
      }
      headerIcon={
        bikeTableMode ? (
          <>
            <span className='float-end'>
              <DeleteRecordButton
                title='Permanently Delete Interview?'
                confirmationPrompt='To confirm deletion'
                confirmationText='delete interview'
                confirmationButtonText='Delete Interview'
                tip='Delete this Interview.'
                deleteFunction={deleteFunction}
                recordId={interviewId}
              >
                Delete interview by{' '}
                <strong>
                  {' '}
                  {firstName} {lastName}
                </strong>{' '}
                created on{' '}
                <strong>
                  {' '}
                  {usNormal(customerInterviews[start - 1].created)}?
                </strong>
                This action cannot be undone.
              </DeleteRecordButton>
            </span>
          </>
        ) : null
      }
      headerIcon2={
        bikeTableMode ? (
          <>
            <PrintPDFButton
              pdfFunction={interviewPdf}
              interview={customerInterviews[start - 1]}
              objectives={objectives}
              ridingProfile={ridingProfile}
              physicalProfile={physicalProfile}
              tip='Print PDF Interview Summary'
              user={user}
              shop={shop}
              //bike={bike}
              customer={{ firstName, lastName }}
              fileName='FKS_Interview_Summary'
            />
          </>
        ) : null
      }
      footer={
        <>
          <NextPreviousFooter {...nextPrevious} />
        </>
      }
    >
      <Row>
        <Col>
          <Table size='sm' style={{ fontSize: '.8rem' }}>
            <tbody>
              <QuickFitTableRow
                label='Date Completed'
                value={usNormal(customerInterviews[start - 1].created)}
              />
              <QuickFitTableRow
                label='Contact Details'
                value={
                  firstName +
                  ' ' +
                  lastName +
                  ' | ' +
                  email +
                  ' | ' +
                  phone +
                  ' | ' +
                  zipCode
                }
              />
              <QuickFitTableRow
                label='Referral Source'
                value={referralSource}
              />
              <QuickFitTableRow
                label='Referral Source Details'
                value={referralSourceDetails}
              />
              <QuickFitTableRow
                label='Prior Bike Fit'
                value={priorBikeFit ? 'Yes' : 'No'}
              />
              <QuickFitTableRow
                label='Bike Fit Objectives'
                value={objectives}
              />
              <QuickFitTableRow
                label='Additional Comments'
                value={objectiveComments}
              />
              <QuickFitTableRow label='Riding Profile' value={ridingProfile} />
              <QuickFitTableRow
                label='Skills And Confidence'
                value={skillsAndConfidence}
              />
              <QuickFitTableRow label='Cycling Goals' value={cyclingGoals} />
              <QuickFitTableRow
                label='Physical Profile'
                value={physicalProfile}
              />
              <QuickFitTableRow
                label='Physical Considerations'
                value={physicalComments}
              />
              <QuickFitTableRow
                label='Areas of Discomfort'
                value={discomfortAreas}
              />
              <QuickFitTableRow
                label='Other Physical Activities'
                value={otherPhysicalActivities}
              />
              {objectiveMeasureAndAdvise ? (
                <>
                  <QuickFitTableRow
                    label='New Bike Details'
                    value={
                      bikeType +
                      ' | New Style: ' +
                      (bikeNewStyle ? 'Yes' : 'No') +
                      ' | Budget: ' +
                      bikeBudget
                    }
                  />
                  <QuickFitTableRow
                    label='Reasons for Purchase'
                    value={bikeReasons}
                  />
                  <QuickFitTableRow
                    label='Type of Purchase'
                    value={bikeChannels}
                  />
                  <QuickFitTableRow
                    label='Brands or Models Under Consideration'
                    value={bikeBrandsModels}
                  />
                  <QuickFitTableRow
                    label='Primary Decision Factors'
                    value={bikeDecisionFactors}
                  />
                  <QuickFitTableRow
                    label='Media Permission'
                    value={mediaConsent}
                  />
                </>
              ) : (
                <>
                  <QuickFitTableRow
                    label='Current Bike Details'
                    value={
                      bikeType +
                      ' | Make: ' +
                      bikeMake +
                      ' | Model: ' +
                      bikeModel +
                      ' | Frame Size: ' +
                      bikeFrameSize
                    }
                  />
                  <QuickFitTableRow
                    label='Additional Comments'
                    value={bikeComments}
                  />
                  <QuickFitTableRow
                    label='Media Permission'
                    value={mediaConsent}
                  />
                </>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {/*  {bikeTableMode ? (
        <span className='float-end' style={{ cursor: 'pointer' }}>
          <DeleteRecordButton
            title='Permanently Delete Interview?'
            confirmationPrompt='To confirm deletion'
            confirmationText='delete interview'
            confirmationButtonText='Delete Interview'
            tip='Delete this Interview.'
            deleteFunction={deleteFunction}
            recordId={interviewId}
          >
            Delete interview by{' '}
            <strong>
              {' '}
              {firstName} {lastName}
            </strong>{' '}
            created on{' '}
            <strong> {usNormal(customerInterviews[start - 1].created)}?</strong>
            This action cannot be undone.
          </DeleteRecordButton>
        </span>
      ) : null} */}
    </QuickFitDashboardCard>
  )
}

const mapStateToProps = (state) => ({
  prefitinterviews: state.prefitinterviews,
  users: state.users,
  shops: state.shops
})

export default connect(mapStateToProps, {
  loadCustomerPrefitInterviews,
  deleteInterview
})(CustomerInterviews)
