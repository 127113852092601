import React from 'react'
import { Link } from 'react-router-dom'

import { Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap'

import { usNormal } from '../../../utils/dateFormats'

import DashboardCard from '../../layout/cards-containers/DashboardCard'

import { WARNING_ICON } from '../../../utils/fontAwesomeDefaults'
import { getIcon } from '../../../utils/generalFunctions'
import { getPaymentFrequency } from '../../../utils/stripeUtils'

const SubscriptionInfo = ({ subscription, activeStatus }) => {
  const { plan, status, cancel_at_period_end } = subscription

  const { current_period_end } = subscription

  const currentPeriodEndDate = new Date(current_period_end * 1000)

  const currentStop = usNormal(currentPeriodEndDate)

  let subscriptionType = plan.name
  if (plan.name === 'Fit Kit Studio Quick Fit Subscription')
    subscriptionType = 'Quick Fit'

  return (
    <>
      <DashboardCard
        style={{ minHeight: '100px', fontSize: '.8rem' }}
        title='Subscription'
      >
        <Row>
          <Col>
            <ListGroup>
              <ListGroupItem>Type: {subscriptionType}</ListGroupItem>
              <ListGroupItem>
                Status:{' '}
                {activeStatus ? (
                  <Link
                    className={
                      status === 'trialing' ? 'text-warning' : 'text-success'
                    }
                    to='/account/settings?tab=subscription'
                  >
                    {status[0].toUpperCase() + status.slice(1)}
                  </Link>
                ) : (
                  <Link
                    className='text-danger'
                    to='/account/settings?tab=subscription'
                  >
                    {getIcon(WARNING_ICON)}{' '}
                    {status[0].toUpperCase() + status.slice(1)}
                  </Link>
                )}{' '}
                {cancel_at_period_end && (
                  <Link
                    className='text-danger'
                    to='/account/settings?tab=subscription'
                  >
                    {getIcon(WARNING_ICON)} (Cancelation Pending)
                  </Link>
                )}
              </ListGroupItem>
              <ListGroupItem>
                Payment frequency:{' '}
                {activeStatus ? getPaymentFrequency(plan) : 'N/A'}
              </ListGroupItem>
              <ListGroupItem>
                Renews: {activeStatus ? currentStop : 'N/A'}
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </DashboardCard>
    </>
  )
}

export default SubscriptionInfo
