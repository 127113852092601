import { Routes, Route } from 'react-router-dom'

import NotFound from '../layout/NotFound'
import Login from '../users/Login'
import Register from '../users/Register'
import PasswordResetRequest from '../users/PasswordResetRequest'
import ResetPassword from '../users/ResetPassword'
import RequireAuth from './RequireAuth'
import RequireSubscription from './RequireSubscription'
import PrefitInterview from '../prefitinterview/PrefitInterview'
import PreFitConfirmation from '../prefitinterview/PreFitConfirmation'
import ContactForm from '../users/ContactForm'
import ContactConfirmation from '../users/ContactConfirmation'
import PrivacyPolicy from '../admin/PrivacyPolicy'
import TermsOfUse from '../admin/TermsOfUse'
import QuickFit from '../quickfit/QuickFit'
import Account from '../account/Account'
import ToolsAndResources from '../tools/ToolsAndResources'
import FreeToolsDescription from '../admin/product-descriptions/FreeToolsDescription'
import QuickFitDescription from '../admin/product-descriptions/QuickFitDescription'
import Upgrade from '../quickfit/Upgrade'

const ClientRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          exact
          path='/pre-fit-interview/:userId'
          element={<PrefitInterview />}
        />
        <Route
          exact
          path='/pre-fit-interview/confirmation'
          element={<PreFitConfirmation />}
        />
      </Routes>
      <div className='mobile-padding'>
        <Routes>
          <Route exact path='/pre-fit-interview/:userId' element={null} />
          <Route exact path='/pre-fit-interview/confirmation' element={null} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/contact' element={<ContactForm />} />
          <Route
            exact
            path='/contact-confirmation'
            element={<ContactConfirmation />}
          />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/terms-of-use' element={<TermsOfUse />} />
          <Route
            exact
            path='/product/free-tools'
            element={<FreeToolsDescription />}
          />
          <Route
            exact
            path='/product/quick-fit'
            element={<QuickFitDescription />}
          />
          <Route exact path='/register' element={<Register />} />

          <Route
            exact
            path='/account/*'
            element={
              <RequireAuth redirectTo='/login'>
                <Account />
              </RequireAuth>
            }
          />

          <Route
            path='/quickfit/*'
            element={
              <RequireAuth redirectTo='/login'>
                <RequireSubscription redirectTo='/account/dashboard'>
                  <QuickFit />
                </RequireSubscription>
              </RequireAuth>
            }
          />
          <Route
            path='/tools-resources/*'
            element={
              <RequireAuth redirectTo='/login'>
                <ToolsAndResources />
              </RequireAuth>
            }
          />
          <Route
            path='/upgrade'
            element={
              <RequireAuth redirectTo='/login'>
                <Upgrade />
              </RequireAuth>
            }
          />
          {/* <Route
            path='/admin/*'
            element={
              <RequireAuth redirectTo='/login'>
                <RequireAdmin redirectTo='/account/dashboard'>
                  <Admin />
                </RequireAdmin>
              </RequireAuth>
            }
          /> */}

          <Route
            exact
            path='/password-reset-request'
            element={<PasswordResetRequest />}
          />
          <Route
            exact
            path='/reset-password/:token'
            element={<ResetPassword />}
          />
          <Route path='*' status={404} element={<NotFound />} />
        </Routes>
      </div>
    </>
  )
}

export default ClientRoutes
