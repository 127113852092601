//USERS AND AUTH
export const SET_LOADING = 'SET_LOADING'
export const SET_REFRESH = 'SET_REFRESH'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const USER_ID_CONFIRMED = 'USER_ID_CONFIRMED'
export const USER_ID_NOT_CONFIRMED = 'USER_ID_NOT_CONFIRMED'
export const UPDATE_USER_SUCCESS = 'USER-UPDATED'
export const UPDATE_USER_FAIL = 'UPDATE_ERROR'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const AUTO_LOGOUT = 'AUTO_LOGOUT'
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED'
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET'
export const REQUEST_PASSWORD_RESET_FAIL = 'REQUEST_PASSWORD_RESET_FAIL'
export const PASSWORD_RESET_TOKEN_INVALID = 'PASSWORD_RESET_TOKEN_INVALID'
export const PASSWORD_RESET_TOKEN_VALID = 'PASSWORD_RESET_TOKEN_VALID'
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD'
export const RESET_USER_PASSWORD_FAIL = 'RESET_USER_PASSWORD_FAIL'
export const CHECK_AUTH_TOKEN = 'CHECK_AUTH_TOKEN'

//SHOPS
export const CREATE_SHOP = 'CREATE_SHOP'
export const SHOP_LOADED = 'SHOP_LOADED'
export const UPDATE_SHOP = 'UPDATE_SHOP'
export const DELETE_SHOP = 'DELETE SHOP'
export const CLEAR_SHOP = 'CLEAR_SHOP'

//ALERTS
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'

//STRIPE
export const CREATE_STRIPE_CUSTOMER = 'CREATE_STRIPE_CUSTOMER'
export const CREATE_STRIPE_SUBSCRIPTION = 'CREATE_STRIPE_SUBSCRIPTION'
export const STRIPE_CUSTOMER_LOADED = 'STRIPE_CUSTOMER_LOADED'
export const STRIPE_SUBSCRIPTION_LOADED = 'STRIPE_SUBSCRIPTION_LOADED'
export const STRIPE_CARD_LOADED = 'STRIPE_CARD_LOADED'
export const CLEAR_STRIPE_DATA = 'CLEAR_STRIPE_DATA'
export const UPDATE_STRIPE_CUSTOMER = 'UPDATE_STRIPE_CUSTOMER'
export const CANCEL_STRIPE_SUBSCRIPTION = 'CANCEL_STRIPE_SUBSCRIPTION'
export const REACTIVATE_STRIPE_SUBSCRIPTION = 'REACTIVATE_STRIPE_SUBSCRIPTION'
export const CHANGE_PAYMENT_FREQUENCY = 'CHANGE_PAYMENT_FREQUENCY'

//PRE-FIT INTERVIEWS
export const CREATE_INTERVIEW = 'CREATE_INTERVIEW'
export const NEW_INTERVIEWS_LOADED = 'NEW_INTERVIEWS_LOADED'
export const CUSTOMER_INTERVIEWS_LOADED = 'CUSTOMER_INTERVIEWS_LOADED'
export const UPDATE_INTERVIEW = 'UPDATE_INTERVIEW'
export const DELETE_INTERVIEW = 'DELETE_INTERVIEW'
export const CLEAR_CUSTOMER_INTERVIEWS = 'CLEAR_INTERVIEWS'
export const CLEAR_INTERVIEWS = 'CLEAR_NEW_INTERVIEWS'

//CYCLISTS
export const CREATE_CYCLIST = 'CREATE_CYCLIST'
export const CYCLIST_LOADED = 'CYCLIST_LOADED'
export const CYCLISTS_LOADED = 'CYCLISTS_LOADED'
export const UPDATE_CYCLIST = 'UPDATE_CYCLIST'
export const DELETE_CYCLIST = 'DELETE CYCLIST'
export const CLEAR_CYCLIST = 'CLEAR_CYCLIST'
export const CLEAR_CYCLISTS = 'CLEAR_CYCLISTS'

//BIKES
export const CREATE_BIKE = 'CREATE_BIKE'
export const BIKE_LOADED = 'BIKE_LOADED'
export const CUSTOMER_BIKES_LOADED = 'CUSTOMER_BIKES_LOADED'
export const CLEAR_CUSTOMER_BIKES = 'CLEAR_CUSTOMER_BIKES'
export const UPDATE_BIKE = 'UPDATE_BIKE'
export const DELETE_BIKE = 'DELETE BIKE'
export const CLEAR_BIKE = 'CLEAR_BIKE'

//FIELDS

export const FIELDS_LOADED = 'FIELDS_LOADED'
export const UPDATE_FIELDS = 'UPDATE_FIELDS'
export const DELETE_OVERRIDES = 'DELETE_OVERRIDES'
export const CLEAR_FIELD_OVERRIDES = 'CLEAR_FIELD_OVERRIDES'

//ADMIN MESSAGES
export const ADMIN_MESSAGES_LOADED = 'ADMIN_MESSAGES_LOADED'
export const CREATE_ADMIN_MESSAGE = 'CREATE_ADMIN_MESSAGE'
export const UPDATE_ADMIN_MESSAGE = 'UPDATE_ADMIN_MESSAGE'
export const DELETE_ADMIN_MESSAGE = 'DELETE_ADMIN_MESSAGE'
export const CLEAR_ADMIN_MESSAGES = 'CLEAR_ADMIN_MESSAGES'

//ADMIN DATA
export const ADMIN_USER_LIST_LOADED = 'ADMIN_USER_LIST_LOADED'

//FEEDBACK
export const FEEDBACK_MESSAGES_LOADED = 'FEEDBACK_MESSAGES_LOADED'
export const CREATE_FEEDBACK = 'CREATE_FEEDBACK'
export const UPDATE_FEEDBACK = 'UPDATE_FEEDBACK'
export const DELETE_FEEDBACK = 'DELETE_FEEDBACK'
export const CLEAR_FEEDBACK_MESSAGES = 'CLEAR_FEEDBACK'
