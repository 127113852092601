import { Table } from 'react-bootstrap'
import CustomerSearchListRow from './CustomerSearchListRow'

const CustomerSearchList = ({ searchData }) => {
  return (
    <Table size='sm' style={{ fontSize: '.8rem' }}>
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>View</th>
        </tr>
      </thead>
      <tbody>
        {searchData.map((customer) => {
          return (
            <CustomerSearchListRow key={customer._id} customer={customer} />
          )
        })}
      </tbody>
    </Table>
  )
}

export default CustomerSearchList
