export const category = {
  type: 'text',
  label: 'Resource Category'
}

export const tab = {
  type: 'number',
  label: 'Tab Order'
}

export const resourceName = {
  type: 'text',
  label: 'Resource Name'
}

export const resourceLink = {
  type: 'text',
  label: 'Resource Link'
}

export const resourceDescription = {
  type: 'text',
  label: 'Description'
}

export const resourceType = {
  type: 'select',
  options: ['link', 'card-link'],
  label: 'Type'
}

export const resourceImageUrl = {
  type: 'text',
  label: 'Image Url (for card-links only)'
}
