import { useEffect } from 'react'

import MainHeading from '../layout/MainHeading'

import { POLICY_ICON } from '../../utils/fontAwesomeDefaults'

import SEO from '../seo/SEO'

const PrivacyPolicy = () => {
  useEffect(() => {
    document.body.classList.add('bodyPad')

    return () => {
      document.body.classList.remove('bodyPad')
    }
  }, [])
  return (
    <>
      <SEO
        title='Fit Kit Studio | Privacy Policy'
        description='Fit Kit Studio Privacy Policy'
        type='website'
        name='Fit Kit Systems'
      />
      <MainHeading
        subHeadingIcon={POLICY_ICON}
        heading='Privacy Policy'
        subheading='(Last Updated June 30, 2022)'
      />

      <p>
        This Privacy Policy governs the manner in which GoBike LLC collects,
        uses, maintains and discloses information collected from users (each, a
        "User") of the{' '}
        <a href='https://fitkitstudio.com'>https://fitkitstudio.com</a> website
        ("Site"). This privacy policy applies to the Site and all products and
        services offered by GoBike LLC.
      </p>
      <p>
        <strong>Personal identification information</strong>
      </p>
      <p>
        We may collect personal identification information from Users in a
        variety of ways, including, but not limited to, when Users visit our
        site, register on the site, place an order, fill out a form, respond to
        a survey, and in connection with other activities, services, features or
        resources we make available on our Site. Users may be asked for, as
        appropriate, name, company, email address, mailing address, phone
        number. Users may, however, visit our Site anonymously. We will collect
        personal identification information from Users only if they voluntarily
        submit such information to us. Users can always refuse to supply
        personal identification information, except that it may prevent them
        from engaging in certain Site related activities.
      </p>
      <p>
        <strong>Non-personal identification information</strong>
      </p>
      <p>
        We may collect non-personal identification information about Users
        whenever they interact with our Site. Non-personal identification
        information may include the browser name, the type of computer and
        technical information about Users means of connection to our Site, such
        as the operating system and the Internet service providers utilized and
        other similar information.
      </p>
      <p>
        <strong>Web browser cookies</strong>
      </p>
      <p>
        Our Site may use "cookies" to enhance User experience. A user's web
        browser places cookies on their hard drive for record-keeping purposes
        and sometimes to track information about them. User may choose to set
        their web browser to refuse cookies, or to alert you when cookies are
        being sent. If they do so, note that some parts of the Site may not
        function properly.
      </p>
      <p>
        <strong>How we use collected information</strong>
      </p>
      <p>
        GoBike LLC may collect and use personal information provided by Users
        for the following purposes:
      </p>
      <ul>
        <li>
          To run and operate our Site ( we may need your information to display
          content on the Site correctly).
        </li>
        <li>
          To improve customer service (user information helps us respond to
          customer service requests and support needs more efficiently).
        </li>
        <li>
          To process payments (we may use information Users provide when
          subscribing to our services to process a subscription. We do not share
          this information with outside parties except to the extent necessary
          to provide the services subscribed).
        </li>
        <li>
          To run a promotion, contest, survey or other Site feature (to send
          Users information they agreed to receive about topics we think will be
          of interest to them).
        </li>
        <li>
          To send periodic emails (we may use a User's email address to send
          information and updates relating to the services provided by the Site.
          It may also be used to respond to inquiries, questions, and/or other
          requests).
        </li>
      </ul>
      <p>
        <strong>How we protect your information</strong>
      </p>
      <p>
        We adopt appropriate data collection, storage and processing practices
        and security measures to protect against unauthorized access,
        alteration, disclosure or destruction of your personal information,
        username, password, transaction information and data stored on our Site.
      </p>
      <p>
        <strong>Sharing your personal information</strong>
      </p>
      <p>
        We do not sell, trade, or rent Users personal identification information
        to others. We may share generic aggregated demographic information not
        linked to any personal identification information regarding visitors and
        users with our business partners, trusted affiliates and advertisers for
        the purposes outlined above. We may use third party service providers to
        help us operate our business and the Site or administer activities on
        our behalf, such as sending out newsletters or surveys, and collecting
        payments. We may share your information with these third parties for
        those limited purposes provided that you have given us your permission.
      </p>
      <p>
        <strong>Electronic newsletters</strong>
      </p>
      <p>
        If User decides to opt-in to our mailing list, they will receive emails
        that may include company news, updates, related product or service
        information, etc. We may use third party service providers to help us
        operate our business and the Site or administer activities on our
        behalf, such as sending out newsletters or surveys. We may share your
        information with these third parties for those limited purposes provided
        that you have given us your permission.
      </p>
      <p>
        <strong>Third party websites</strong>
      </p>
      <p>
        Users may find advertising or other content on our Site that link to the
        sites and services of our partners, suppliers, advertisers, sponsors,
        licensors and other third parties. We do not control the content or
        links that appear on these sites and are not responsible for the
        practices employed by websites linked to or from our Site. In addition,
        these sites or services, including their content and links, may be
        constantly changing. These sites and services may have their own privacy
        policies and customer service policies. Browsing and interaction on any
        other website, including websites which have a link to our Site, is
        subject to that website's own terms and policies.
      </p>
      <p>
        <strong>Changes to this privacy policy</strong>
      </p>
      <p>
        GoBike LLC has the discretion to update this privacy policy at any time.
        When we do, we will post a notification on the main page of our Site. We
        encourage Users to frequently check this page for any changes to stay
        informed about how we are helping to protect the personal information we
        collect. You acknowledge and agree that it is your responsibility to
        review this privacy policy periodically and become aware of
        modifications.
      </p>
      <p>
        <strong>Your acceptance of these terms</strong>
      </p>
      <p>
        By using this Site, you signify your acceptance of this policy. If you
        do not agree to this policy, please do not use our Site. Your continued
        use of the Site following the posting of changes to this policy will be
        deemed your acceptance of those changes.
      </p>
      <p>
        <strong>Contacting us</strong>
      </p>
      <p>
        If you have any questions about this Privacy Policy, the practices of
        this site, or your dealings with this site, please contact us.
      </p>
    </>
  )
}

export default PrivacyPolicy
