import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'

const InlineInputAndRadio = ({ input, radio, min, max, step }) => {
  return (
    <div>
      <Row className='mb-3 align-items-center'>
        <Form.Label column md='auto'>
          {input.label}
        </Form.Label>
        <Col md='2'>
          <Form.Control
            type={input.type}
            value={input.value}
            label={input.label}
            placeholder={input.placeholder}
            isValid={input.isValid}
            isInvalid={input.isInvalid}
            onChange={input.onChange}
            reset=''
            min={min}
            max={max}
            step={step}
            size='sm'
          />
        </Col>
        <Col>
          {radio.options.map((option) => {
            return (
              <Form.Check
                key={option}
                inline
                className='custom-control-input black'
                {...radio}
                label={option}
                value={option}
                checked={radio.value === option}
              />
            )
          })}
        </Col>
      </Row>
    </div>
  )
}

export default InlineInputAndRadio
