import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'

import { INVOICE_ICON, WARNING_ICON } from '../../../utils/fontAwesomeDefaults'

import { cancelStripeSubscription } from '../../../redux/actions/stripe'

import ConfirmCancelModal from '../../layout/modals/ConfirmCancelModal'
import useConfirmCancelModal from '../../hooks/modal-hooks/useConfirmCancelModal'

const CancelSubscriptionButton = ({
  currentStop,
  subscription,
  cancelStripeSubscription
}) => {
  const changeSubscriptionModal = useConfirmCancelModal({
    icon: WARNING_ICON,
    title: 'Cancel Subscription?',
    confirmationPrompt: 'To confirm deletion',
    confirmationText: 'downgrade',
    confirmationButtonIcon: INVOICE_ICON,
    confirmationButtonText: 'Downgrade Subscription',

    confirmationAction: () => {
      cancelStripeSubscription(subscription.id)
    }
  })

  const { status } = subscription
  return (
    <>
      <div className='d-grid gap-2'>
        <Button variant='dark' onClick={changeSubscriptionModal.setShowModal}>
          <i className={INVOICE_ICON} /> Cancel Subscription
        </Button>
      </div>
      <ConfirmCancelModal {...changeSubscriptionModal}>
        <p>Do you want to downgrade to the free plan?</p>{' '}
        <p>
          This will cancel your Quick Fit subscription at the end of the{' '}
          {status === 'trialing' ? 'free trial' : 'current billing'} period.
        </p>{' '}
        <p>Changes will take effect on {currentStop}</p>{' '}
      </ConfirmCancelModal>
    </>
  )
}

export default connect(null, {
  cancelStripeSubscription
})(CancelSubscriptionButton)
