import React, { useState } from 'react'

import QuickFitTabContent from '../../quickfit/QuickFitTabContent'
import OutputsPlaceholder from '../OutputsPlaceholder'
import BarToFrameOutputs from './BarToFrameOutputs'
import ModalOutputs from '../ModalOutputs'

import useBarToFrameXYForm from '../../hooks/form-hooks/tools/useBarToFrameXYForm'

import {
  barToFrameXYForm as dataForm,
  frameReachAndStackForm as modalOutputForm
} from '../../forms/form-objects/toolsFormObjects'

import {
  calculateFrameReach,
  calculateFrameStack
} from '../../../lib/tools-js-functions'

import { CYCLIST_ICON } from '../../../utils/fontAwesomeDefaults'

const BarToFrame = ({ user, fieldOverrides }) => {
  const initialState = {
    handlebarReachHX: 460,
    handlebarStackHY: 630,
    headTubeAngle: 72,
    headSetHeight: 10,
    spacersBelow: 20,
    stemClampHeight: 40,
    stemLength: 90,
    stemAngle: -7
  }

  const [inputs, setInputs] = useState(initialState)

  const barToFrameXYForm = useBarToFrameXYForm(initialState, fieldOverrides)

  const { validateForm, form, changesMade, values } = barToFrameXYForm

  const newValues = {
    handlebarReachHX: parseInt(values.handlebarReachHX),
    handlebarStackHY: parseInt(values.handlebarStackHY),
    headTubeAngle: parseFloat(values.headTubeAngle),
    headSetHeight: parseInt(values.headSetHeight),
    spacersBelow: parseInt(values.spacersBelow),
    stemClampHeight: parseInt(values.stemClampHeight),
    stemLength: parseInt(values.stemLength),
    stemAngle: parseFloat(values.stemAngle)
  }
  const saveFunction = () => {
    setInputs(newValues)
  }

  const {
    handlebarReachHX,
    handlebarStackHY,
    headTubeAngle,
    headSetHeight,
    spacersBelow,
    stemClampHeight,
    stemLength,
    stemAngle
  } = form

  return (
    <>
      <QuickFitTabContent
        title='Handle Bar XY to Frame XY (reach and stack)'
        dataForm={dataForm}
        dataObject={values}
        editButtonText='Edit Inputs'
        modalTitle='Bar XY to Frame XY: Edit Inputs'
        modalForm={barToFrameXYForm}
        modalTitleIcon={CYCLIST_ICON}
        confirmationButtonIcon={CYCLIST_ICON}
        saveFunction={saveFunction}
        user={user}
        formGroup='quickfit'
        cols={['2', '4', '4', '2']}
        disabled={
          !validateForm({
            handlebarReachHX,
            handlebarStackHY,
            headTubeAngle,
            headSetHeight,
            spacersBelow,
            stemClampHeight,
            stemLength,
            stemAngle
          }) || !changesMade(inputs, values)
        }
      >
        <ModalOutputs
          inputs={inputs}
          user={user}
          dataForm={modalOutputForm}
          dataObject={{
            frameReach: calculateFrameReach(newValues),
            frameStack: calculateFrameStack(newValues)
          }}
        />
      </QuickFitTabContent>
      <div className='mt-3'></div>
      {!changesMade(initialState, inputs) ? (
        <OutputsPlaceholder
          title='Frame Reach And Stack'
          message='Please enter the inputs above to calculate frame reach and stack.'
        />
      ) : (
        <BarToFrameOutputs inputs={inputs} user={user} />
      )}
    </>
  )
}

export default BarToFrame
