import React from 'react'
import { Modal, Form, Button } from 'react-bootstrap'

import { CANCEL_ICON } from '../../../utils/fontAwesomeDefaults'

const ConfirmCancelModal = ({
  showModal,
  title,
  icon,
  confirmationText,
  confirmationPrompt,
  children,
  clickConfirm,
  clickCancel,
  confirmationButtonIcon,
  confirmationButtonText,
  confirmationTextInput,
  disabled
}) => {
  return (
    <Modal centered show={showModal}>
      <Modal.Header>
        <Modal.Title>
          {icon && <i className={icon} />} {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {' '}
        {children}
        <Form onSubmit={clickConfirm} className='mt-3'>
          <Form.Group controlId='formText'>
            <Form.Label>
              {confirmationPrompt} type{' '}
              <i>
                <b>{confirmationText}</b>
              </i>{' '}
              below.
            </Form.Label>
            <Form.Control
              {...confirmationTextInput}
              autoFocus
              placeholder={confirmationText}
            />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant='danger'
          disabled={
            disabled === true
              ? true
              : disabled === false
              ? false
              : !confirmationTextInput.isValid
          }
          onClick={clickConfirm}
        >
          <i className={confirmationButtonIcon} /> {confirmationButtonText}
        </Button>{' '}
        <Button variant='dark' onClick={clickCancel}>
          <i className={CANCEL_ICON} /> Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmCancelModal
