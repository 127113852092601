import {
  frameSize,
  saddleHeight,
  maxStandoverHeight,
  handlebarWidth,
  saddleWidth,
  topTubeAndStemDropBar,
  topTubeAndStemFlatBar,
  topTubeAndStemAeroBar,
  AdjustedTopTubeAndStemDropBar,
  AdjustedTopTubeAndStemFlatBar,
  AdjustedTopTubeAndStemAeroBar
} from '../field-objects/sizingFields'

export const roadBikeSizingForm = {
  frameSize,
  saddleHeight,
  maxStandoverHeight,
  handlebarWidth,
  saddleWidth,
  topTubeAndStemDropBar,
  topTubeAndStemFlatBar,
  topTubeAndStemAeroBar,
  AdjustedTopTubeAndStemDropBar,
  AdjustedTopTubeAndStemFlatBar,
  AdjustedTopTubeAndStemAeroBar
}

export const mountainBikeSizingForm = {
  frameSize,
  saddleHeight,
  maxStandoverHeight,
  saddleWidth,
  topTubeAndStemFlatBar,
  AdjustedTopTubeAndStemFlatBar
}

export const ttBikeSizingForm = {
  frameSize,
  saddleHeight,
  maxStandoverHeight,
  saddleWidth,
  topTubeAndStemAeroBar,
  AdjustedTopTubeAndStemAeroBar
}
