import React from 'react'

import { Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'

import AccountInfo from './AccountInfo'
import SubscriptionInfo from './SubscriptionInfo'
import ShopStudioInfo from './ShopStudioInfo'
import PreferenceInfo from './PreferenceInfo'
import InterviewLinks from '../../quickfit/interviews/InverviewLinks'
import PreFitUpgradeNotice from '../prefit/PreFitUpgradeNotice'
import ShopUpgradeNotice from '../shop/ShopUpgradeNotice'
import DashboardCard from '../../layout/cards-containers/DashboardCard'
import NewsAndUpdates from '../../admin/message-header/NewsAndUpdates'
import UpgradeNotice from './UpgradeNotice'

const AccountDashboard = ({ user, shop, subscription, activeStatus }) => {
  return (
    <>
      <NewsAndUpdates />
      <Row className='mt-3'>
        <Col lg={8}>
          <AccountInfo user={user} />
          {activeStatus ? (
            <ShopStudioInfo user={user} shop={shop} />
          ) : (
            <>
              <UpgradeNotice />
              <ShopUpgradeNotice />
            </>
          )}
          {activeStatus ? (
            <InterviewLinks shop={shop} user={user} />
          ) : (
            <PreFitUpgradeNotice />
          )}
        </Col>
        <Col>
          <SubscriptionInfo
            user={user}
            subscription={subscription}
            activeStatus={activeStatus}
          />
          <DashboardCard
            style={{ minHeight: '100px', fontSize: '.8rem' }}
            title='Preferences'
          >
            <PreferenceInfo user={user} activeStatus={activeStatus} />
          </DashboardCard>
        </Col>
      </Row>
    </>
  )
}

AccountDashboard.propTypes = {
  user: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  activeStatus: PropTypes.bool.isRequired
}

export default AccountDashboard
