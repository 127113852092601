import React from 'react'
import { Table } from 'react-bootstrap'
import QuickFitDashboardCard from '../QuickFitDashboardCard'

const Stats = ({ cyclists }) => {
  const month = new Date().getMonth() + 1
  const year = new Date().getFullYear()

  const newCyclistCount = cyclists.filter(
    (cyclist) => new Date(cyclist.created).getMonth() + 1 === month
  ).length

  const modifiedCyclistCount = cyclists.filter(
    (cyclist) => new Date(cyclist.updated).getMonth() + 1 === month
  ).length
  const ytdNewCyclistCount = cyclists.filter(
    (cyclist) => new Date(cyclist.created).getFullYear() === year
  ).length

  const ytdModifiedCyclistCount = cyclists.filter(
    (cyclist) => new Date(cyclist.updated).getFullYear() === year
  ).length

  return (
    <QuickFitDashboardCard title='Stats'>
      <h6>This Month</h6>
      <Table size='sm' style={{ fontSize: '.8rem' }}>
        <tbody>
          <tr>
            <td>New Customers</td>
            <td>{newCyclistCount}</td>
          </tr>
          <tr>
            <td>Customers Modified</td>
            <td>{modifiedCyclistCount}</td>
          </tr>
        </tbody>
      </Table>
      <h6>Year to Date</h6>
      <Table size='sm' style={{ fontSize: '.8rem' }}>
        <tbody>
          <tr>
            <td>New Customers</td>
            <td>{ytdNewCyclistCount}</td>
          </tr>
          <tr>
            <td>Customers Modified</td>
            <td>{ytdModifiedCyclistCount}</td>
          </tr>
        </tbody>
      </Table>
    </QuickFitDashboardCard>
  )
}

export default Stats
