import { Image } from 'react-bootstrap'

const MainFooter = () => {
  return (
    <footer className='mt-3 p-2 bg-dark text-light footerPin'>
      <div className='text-center'>
        <Image style={{ height: '25px', width: '25px' }} src='/favicon.ico' />
        <span className='ms-3'>&copy; {new Date().getFullYear()}</span>{' '}
        <a
          target='_blank'
          rel='noreferrer'
          className='footer-links'
          href='https://fitkitsystems.com'
        >
          {' '}
          Fit Kit Systems
        </a>{' '}
        · All Rights Reserved ·{' '}
        <a
          target='_blank'
          rel='noreferrer'
          className='footer-links'
          href='https://fitkitsystems.com/bike-sizing-and-fitting/software/studio-release-notes/'
        >
          {' '}
          Release Notes
        </a>{' '}
        · {/* <Link className='footer-links' to='/privacy-policy'> */}{' '}
        <a
          className='footer-links'
          href='https://fitkitstudio.com/privacy-policy'
        >
          {' '}
          Privacy Policy
        </a>
        {/* </Link> */} ·{' '}
        {/* <Link className='footer-links' to='/terms-of-use'> */}
        <a
          className='footer-links'
          href='https://fitkitstudio.com/terms-of-use'
        >
          Terms of Use
        </a>
        {/*  </Link> */} · {/* <Link className='footer-links' to='/contact'> */}
        <a className='footer-links' href='https://fitkitstudio.com/contact'>
          Contact Us
        </a>
        {/* </Link> */}
      </div>
    </footer>
  )
}

export default MainFooter
