import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Navigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import { resetUserPassword, checkResetToken } from '../../redux/actions/users'

import useResetPasswordForm from '../hooks/form-hooks/users-auth/useResetPasswordForm'

import UserFormContainer from './UserFormContainer'
import GenerateForm from '../forms/form-components/GenerateForm'

import { validateForm, checkPasswordMatch } from '../../utils/formValidation'

const ResetPassword = ({
  users: { isAuthenticated, validResetToken },
  resetUserPassword,
  checkResetToken
}) => {
  const { token } = useParams()
  useEffect(() => {
    checkResetToken(token)
  }, [token, checkResetToken, validResetToken])

  const resetPasswordForm = useResetPasswordForm({
    password: '',
    confirmPassword: ''
  })

  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()

    resetUserPassword(token, resetPasswordForm.values.password)
    setRedirectToLogin(true)
  }

  const {
    form,
    values: { password, confirmPassword }
  } = resetPasswordForm

  if (isAuthenticated) {
    return <Navigate to='/dashboard' />
  }

  if (redirectToLogin) {
    return <Navigate to='/login' />
  }

  if (!validResetToken)
    return (
      <UserFormContainer heading='Reset Password'>
        <p className='text-center'>The reset token is not valid.</p>
        <p className='text-center'>
          <Link to='/password-reset-request'>
            Request a new password reset email?
          </Link>
        </p>
      </UserFormContainer>
    )

  return (
    <>
      <UserFormContainer heading='Reset Password'>
        <GenerateForm
          {...resetPasswordForm}
          buttonText='Reset Password'
          onSubmit={onSubmit}
          disabled={
            !validateForm(form) ||
            !checkPasswordMatch(password, confirmPassword)
          }
          error={
            validateForm(form) && !checkPasswordMatch(password, confirmPassword)
          }
        />
      </UserFormContainer>
    </>
  )
}

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  resetUserPassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  checkResetToken: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  users: state.users
})

export default connect(mapStateToProps, {
  resetUserPassword,
  checkResetToken
})(ResetPassword)
