import React from 'react'

import { APPROVE_ICON, WARNING_ICON } from '../../utils/fontAwesomeDefaults'

import Tip from '../layout/overlays/Tip'
import ConfirmCancelModal from '../layout/modals/ConfirmCancelModal'

import useConfirmCancelModal from '../hooks/modal-hooks/useConfirmCancelModal'

const ApproveUserButton = ({
  icon,
  recordId,
  tip,
  title,
  confirmationPrompt,
  confirmationText,
  confirmationButtonText,
  approveFunction,
  disabled,
  children
}) => {
  const approveUserModal = useConfirmCancelModal({
    icon: icon || WARNING_ICON,
    title: title,
    confirmationPrompt: confirmationPrompt || 'To confirm approval',
    confirmationText: confirmationText,
    confirmationButtonIcon: APPROVE_ICON,
    confirmationButtonText: confirmationButtonText,

    confirmationAction: () => {
      approveFunction(recordId)
    }
  })

  return (
    <>
      <Tip message={tip} placement='top'>
        <i
          style={{ cursor: 'pointer' }}
          className={APPROVE_ICON}
          onClick={approveUserModal.setShowModal}
          id={recordId}
        />
      </Tip>
      <ConfirmCancelModal
        {...approveUserModal}
        children={children}
        disabled={disabled}
      />
    </>
  )
}

export default ApproveUserButton
