import useInputWithReset from '../../input-hooks/useInputWithReset'

import { addBikeForm as form } from '../../../forms/form-objects/bikeFormObjects'

import {
  getInput,
  getValidation,
  getForm
} from '../../input-hooks/inputFunctions'

const useAddBikeForm = (record) => {
  const keyArray = Object.keys(form)
  const i = []

  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )

  return getForm(keyArray, i)
}

export default useAddBikeForm
