import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Navbar, Nav, Image, Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { logout } from '../../redux/actions/users'
import SEO from '../seo/SEO'

import {
  SIGN_OUT_ICON,
  MY_ACCOUNT_ICON,
  CYCLIST_ICON,
  TOOLBOX_ICON,
  ADMIN_ICON_FKS,
  COMMENT_ICON
} from '../../utils/fontAwesomeDefaults'

import FeedbackForm from '../users/FeedbackForm'

const MainNavbar = ({
  title,
  users: { isAuthenticated, user },
  stripe: { activeStatus },
  logout
}) => {
  const onLogout = () => {
    logout()
  }

  const [showFeedbackModal, setShowFeedbackModal] = useState(false)

  const authLinks = (
    <Fragment>
      <SEO
        title='Fit Kit Studio'
        description='Fit Kit Studio Authenticated Users'
        type='website'
        name='Fit Kit Systems'
      />
      <Navbar.Text>{user && 'Hello, ' + user.name} </Navbar.Text>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />
      <div>
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav>
            {activeStatus && (
              <Nav.Item>
                <Nav.Link as={Link} to='/quickfit/dashboard'>
                  <i className={CYCLIST_ICON} /> Quick Fit
                </Nav.Link>
              </Nav.Item>
            )}

            {!activeStatus && (
              <Nav.Item>
                <Nav.Link as={Link} to='/upgrade'>
                  <i className={CYCLIST_ICON} /> Quick Fit
                </Nav.Link>
              </Nav.Item>
            )}

            <Nav.Item>
              <Nav.Link as={Link} to='/tools-resources/tools'>
                <i className={TOOLBOX_ICON} /> Toolbox
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link as={Link} to='/account/dashboard'>
                <i className={MY_ACCOUNT_ICON} /> My Account
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={() => setShowFeedbackModal(true)}>
                <i className={COMMENT_ICON} /> Feedback
              </Nav.Link>
            </Nav.Item>

            {user && user.role === 'admin' && (
              <Nav.Item>
                {' '}
                <Nav.Link as={Link} to='/admin/dashboard'>
                  <span className='text-fitkit'>
                    <i className={ADMIN_ICON_FKS} /> Admin
                  </span>
                </Nav.Link>
              </Nav.Item>
            )}

            <Nav.Item>
              <Nav.Link as={Link} to='/login' onClick={onLogout}>
                <i className={SIGN_OUT_ICON} /> Logout
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>{' '}
      </div>
    </Fragment>
  )

  let guestLinks = (
    <Fragment>
      <Nav>
        <Nav.Item>
          <Nav.Link as={Link} to='/register'>
            Register
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link as={Link} to='/login'>
            Login
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </Fragment>
  )

  return (
    <div>
      <Navbar collapseOnSelect expand='lg' variant='dark' bg='dark'>
        <Container>
          <Navbar.Brand as={Link} to='/'>
            <Image className='brandImage' src='/favicon.ico' /> {title}
          </Navbar.Brand>

          {isAuthenticated ? authLinks : guestLinks}
        </Container>
      </Navbar>
      {showFeedbackModal && (
        <FeedbackForm
          showFeedbackModal={showFeedbackModal}
          setShowFeedbackModal={setShowFeedbackModal}
          user={user}
        />
      )}
    </div>
  )
}

MainNavbar.propTypes = {
  title: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired
}

MainNavbar.defaultProps = {
  title: 'Fit Kit Studio',
  isAuthenticated: false
}

const mapStateToProps = (state) => ({
  users: state.users,
  stripe: state.stripe
})

export default connect(mapStateToProps, { logout })(MainNavbar)
