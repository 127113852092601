import React from 'react'

import DashboardCard from '../../layout/cards-containers/DashboardCard'

import { utcToutc } from '../../../utils/dateFormats'

import { getMessageIcon } from '../../../utils/generalFunctions'

const ActiveMessages = (props) => {
  const { messages } = props
  return (
    <>
      <DashboardCard
        style={{ minHeight: '100px', fontSize: '.8rem' }}
        title='Active Messages'
      >
        {messages.map((message, index) => {
          const start = message.start.substring(0, 10)
          let stop = null
          if (message.stop) stop = message.stop.substring(0, 10)
          let margin

          index === messages.length - 1 ? (margin = '') : (margin = 'mb-3')

          return (
            <div className={margin} key={message._id}>
              <table>
                <tbody>
                  <tr>
                    <td>{getMessageIcon(message.icon)}</td>
                    <td>
                      {message.title && (
                        <span className='ms-3'>
                          <strong>{message.title}</strong>
                        </span>
                      )}
                      <span className='ms-3'>{message.content}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: '10%' }}></td>
                    <td> Started: {utcToutc(start)}</td>
                    <td style={{ width: '15%' }}></td>
                    <td>
                      Stops: {stop === null ? 'Never' : utcToutc(message.stop)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        })}
      </DashboardCard>
    </>
  )
}

export default ActiveMessages
