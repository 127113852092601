import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { changePaymentFrequency } from '../../../redux/actions/stripe'
import ConfirmCancelModal from '../../layout/modals/ConfirmCancelModal'
import useConfirmCancelModal from '../../hooks/modal-hooks/useConfirmCancelModal'
import PaymentFrequencyForm from './PaymentFrequencyForm'
import { CALENDAR_EDIT_ICON } from '../../../utils/fontAwesomeDefaults'
import { getFrequencyValue } from '../../../utils/stripeUtils'

const UpdatePaymentFrequencyButton = ({
  currentStop,
  subscription,
  changePaymentFrequency
}) => {
  const { plan } = subscription
  const [frequency, setFrequency] = useState(getFrequencyValue(plan))

  const changeFrequencyModal = useConfirmCancelModal({
    icon: CALENDAR_EDIT_ICON,
    title: 'Change Payment Frequency?',
    confirmationPrompt: 'To confirm change',
    confirmationText: 'change',
    confirmationButtonIcon: CALENDAR_EDIT_ICON,
    confirmationButtonText: 'Change Payment Frequency',

    confirmationAction: () => {
      changePaymentFrequency(subscription.id, frequency)
    },
    cancelAction: () => {
      setFrequency(getFrequencyValue(plan))
    }
  })
  return (
    <>
      <Button
        className='shadow-none'
        variant='link-dark'
        onClick={changeFrequencyModal.setShowModal}
      >
        <i className={CALENDAR_EDIT_ICON}> </i> Change
      </Button>
      <ConfirmCancelModal
        {...changeFrequencyModal}
        disabled={frequency === getFrequencyValue(plan)}
      >
        <p>Do you want to change your payment frequency?</p> <p></p>
        <PaymentFrequencyForm
          frequency={frequency}
          setFrequency={setFrequency}
        />
        <p> </p>
        {currentStop && <p>Changes will take effect on {currentStop}</p>}{' '}
      </ConfirmCancelModal>
    </>
  )
}

export default connect(null, {
  changePaymentFrequency
})(UpdatePaymentFrequencyButton)
