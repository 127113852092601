import React from 'react'

import AdminSettingsTabContent from '../AdminSettingsTabContent'

import { ADMIN_ICON } from '../../../utils/fontAwesomeDefaults'

import { bikeEquipmentForm } from '../../forms/form-objects/bikeFormObjects'
import { fitPositionForm } from '../../forms/form-objects/fitPositionFormObjects'
import {
  customerProfileForm,
  bodyMeasurementsForm,
  physicalProfileForm,
  customerNotesForm
} from '../../forms/form-objects/customerFormObjects'
import { shoesCleatsForm } from '../../forms/form-objects/shoesCleatsFormObjects'
import { bikeNotesForm } from '../../forms/form-objects/bikeFormObjects'

const convertFieldNames = (form, prefix) => {
  const newForm = {}
  Object.entries(form).map(([key, value]) => {
    newForm[prefix + key] = value
    return null
  })
  return newForm
}

const convertedCustomerNotesForm = convertFieldNames(customerNotesForm, 'cust-')
const convertedBikeNotesForm = convertFieldNames(bikeNotesForm, 'bike-')

const addFormName = (form, formName) => {
  Object.values(form).map((field) => {
    field.formName = formName
    return null
  })
}
addFormName(bikeEquipmentForm, 'Bike Equipment')
addFormName(fitPositionForm, 'Fit Position')
addFormName(customerProfileForm, 'Customer Profile')
addFormName(bodyMeasurementsForm, 'Body Measurements')
addFormName(physicalProfileForm, 'Physical Profile')
addFormName(convertedCustomerNotesForm, 'Customer Notes')
addFormName(shoesCleatsForm, 'Shoes/Cleats')
addFormName(convertedBikeNotesForm, 'Bike Notes')

const dataForm = {
  ...bikeEquipmentForm,
  ...fitPositionForm,
  ...customerProfileForm,
  ...bodyMeasurementsForm,
  ...physicalProfileForm,
  ...convertedCustomerNotesForm,
  ...shoesCleatsForm,
  ...convertedBikeNotesForm
}

const EditFields = (props) => {
  return (
    <AdminSettingsTabContent
      {...props}
      title='Manage Form Fields'
      dataForm={dataForm}
      dataObject={{ ...dataForm }}
      editButtonText='Edit Field Overrides'
      modalTitle='Edit Field Overrides'
      modalTitleIcon={ADMIN_ICON}
      confirmationButtonIcon={ADMIN_ICON}
      noFooter
      mode='fields'
    />
  )
}

export default EditFields
