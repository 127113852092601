import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'

import QuickFitTabContent from '../QuickFitTabContent'
import FormModal from '../../layout/modals/FormModal'
import QuickFitFormGroup from '../../forms/form-components/QuickFitFormGroup'
import NextPreviousFooter from '../../hook-components/NextPreviousFooter'
import QuickFitDashboardItemCounter from '../QuickFitDashboardItemCounter'
import PrintPDFButton from '../../../pdf/PrintPDFButton'

import { updateBike } from '../../../redux/actions/bikes'

import { BIKE_ICON, CYCLIST_ICON } from '../../../utils/fontAwesomeDefaults'

import { changesMade } from '../../../utils/formValidation'

import { historyPdf } from '../../../pdf/HistoryPdf'

import {
  roadBikeFitHistoryForm,
  mountainBikeFitHistoryForm,
  ttBikeFitHistoryForm
} from '../../forms/form-objects/fitHistoryFormObjects'

import useFormModal from '../../hooks/modal-hooks/useFormModal'
import useNextPrevious from '../../hooks/action-hooks/useNextPrevious'

import { applyOverrides } from '../../hooks/input-hooks/inputFunctions'

import { yearMonthDayEval } from '../../../utils/dateFormats'

const FitHistory = ({
  bike,
  updateBike,
  user,
  fieldOverrides,
  shop,
  customer
}) => {
  const [loadingBike, setLoadingBike] = useState(true)

  const { type } = bike
  let dataForm

  const formModal = useFormModal({
    icon: CYCLIST_ICON,
    title: `Edit Fit History`,
    confirmationButtonIcon: CYCLIST_ICON,
    confirmationButtonText: 'Save Fit History',

    cancelAction: () => {
      setFormData(originalState)
    },

    confirmationAction: () => {
      saveFunction()
    }
  })

  const displayLimit = 3

  const nextPrevious = useNextPrevious(displayLimit, bike.fitHistory.length)

  const { start, stop, length, limit } = nextPrevious

  if (stop > bike.fitHistory.length)
    nextPrevious.setStop(bike.fitHistory.length)

  switch (type) {
    case 'Mountain Bike':
    case 'Fat Bike':
      dataForm = mountainBikeFitHistoryForm

      break
    case 'TT/Tri Bike':
      dataForm = ttBikeFitHistoryForm

      break
    default:
      dataForm = roadBikeFitHistoryForm
  }

  const saveFunction = () => {
    const newHistory = bike.fitHistory.slice(0)
    newHistory.map((fit, index) => {
      return (fit.date = new Date(formData[index]).toISOString())
    })

    const bikeObject = {
      make: bike.make,
      model: bike.model,
      fitHistory: newHistory
    }

    if (formData.updated !== bike.updated) bikeObject.updated = formData.updated

    updateBike(bike._id, bikeObject, 'Fit History Updated')
  }

  const deleteFunction = (id) => {
    const newHistory = bike.fitHistory.splice(id, 1)
    const bikeObject = { fitHistory: newHistory }
    updateBike(bike._id, bikeObject, 'Fit History Deleted')
  }

  const [formData, setFormData] = useState()
  const [originalState, setOrigionalState] = useState()

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const fitHistoryData = bike.fitHistory
    .slice()
    .reverse()
    .slice(start - 1, stop)

  const dataObject = { ...bike, fitHistory: fitHistoryData }

  applyOverrides('Bike Equipment', dataForm, fieldOverrides)
  applyOverrides('Fit Position', dataForm, fieldOverrides)

  useEffect(() => {
    let initialState = { updated: yearMonthDayEval(bike.updated) }
    bike.fitHistory.map((fit, index) => {
      return (initialState[index] = yearMonthDayEval(fit.date))
    })
    setFormData(initialState)
    setOrigionalState(initialState)
    nextPrevious.setStart(1)
    nextPrevious.setStop(displayLimit)
    nextPrevious.setcurrentLength(bike.fitHistory.length)

    if (stop > bike.fitHistory.length)
      nextPrevious.setStop(bike.fitHistory.length)

    setLoadingBike(false)
    //eslint-disable-next-line
  }, [bike])

  if (!formData) return null

  if (loadingBike || length !== bike.fitHistory.length) return null

  return (
    <>
      <QuickFitTabContent
        title={
          <>
            Fit History
            <QuickFitDashboardItemCounter
              start={start}
              stop={stop}
              length={length}
            />
          </>
        }
        headerIcon={
          <PrintPDFButton
            pdfFunction={historyPdf}
            dataObject={bike}
            tip='Print History PDF Report'
            user={user}
            shop={shop}
            bike={bike}
            customer={customer}
            fileName='FKS_Fit_History_Report'
          />
        }
        dataForm={dataForm}
        dataObject={dataObject}
        //editButtonText='Edit Fit History'
        //modalTitle='Edit Fit History'
        //modalForm={{ form: formModal, reset: resetFunction }}
        modalTitleIcon={BIKE_ICON}
        confirmationButtonIcon={BIKE_ICON}
        //saveFunction={saveFunction}
        user={user}
        formGroup='quickfit'
        cols={['0', '8', '4', '0']}
        mode='historyMode'
        bike={bike}
        deleteFunction={deleteFunction}
        /* footer={
          <div className='d-grid gap-2'>
            <Button variant='dark' onClick={formModal.setShowModal}>
              Edit Fit History
            </Button>
          </div>
        } */
        footer={
          <>
            {limit <= length && (
              <>
                <NextPreviousFooter {...nextPrevious} />
                <br />
                <br />
              </>
            )}
            <div className='d-grid gap-2'>
              <Button variant='dark' onClick={formModal.setShowModal}>
                Edit Fit History
              </Button>
            </div>
          </>
        }
      />

      <FormModal
        {...formModal}
        disabled={!changesMade(originalState, formData)}
        reset
      >
        <Form>
          <QuickFitFormGroup
            label='History Dates'
            type='static-text'
            value='Revised Dates'
          />
          {bike.fitHistory.map((fit, index) => {
            return (
              <QuickFitFormGroup
                label={yearMonthDayEval(fit.date)}
                value={formData[index]}
                name={index}
                type='date'
                onChange={onChange}
                key={index}
              />
            )
          })}
          <hr />
          <QuickFitFormGroup
            label='Date Last Updated'
            type='static-text'
            value='This date is automatically set each time bike data is saved, but it can be set manually here after all changes are made.'
          />
          <QuickFitFormGroup
            label={yearMonthDayEval(bike.updated)}
            value={formData.updated}
            name='updated'
            type='date'
            onChange={onChange}
          />
        </Form>
      </FormModal>
    </>
  )
}

export default connect(null, { updateBike })(FitHistory)
