import React from 'react'
import { Link } from 'react-router-dom'
import DashboardCard from '../../layout/cards-containers/DashboardCard'
import PricingInfo from './PricingInfo'
import QuickFitFeatures from './QuickFitFeatures'

const UpgradeNotice = () => {
  return (
    <DashboardCard title='Features'>
      <QuickFitFeatures />
      <PricingInfo />
      <p>
        <Link to='/account/settings?tab=subscription'>
          Upgrade Subscription
        </Link>{' '}
      </p>
    </DashboardCard>
  )
}

export default UpgradeNotice
