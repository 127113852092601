import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { deleteCyclist } from '../../../redux/actions/cyclists'

import { utcToutc, usNormal } from '../../../utils/dateFormats'

import Tip from '../../layout/overlays/Tip'

import DeleteRecordButton from '../../hook-components/DeleteRecordButton'

import {
  CALENDAR_ICON,
  CYCLIST_ICON,
  EMAIL_ICON
} from '../../../utils/fontAwesomeDefaults'

const CustomerRow = ({ cyclist, deleteCyclist, updateTable }) => {
  const { firstName, lastName, birthDate, gender, email } =
    cyclist.cyclistProfile
  const { updated } = cyclist

  const deleteFunction = () => {
    deleteCyclist(cyclist._id)
    updateTable()
  }

  return (
    <>
      <tr>
        <td>
          <Tip
            message={
              <>
                {firstName + ' ' + lastName}
                <br />
                Birthdate: {utcToutc(birthDate)} <br />
                Gender: {gender}
                <br />
                {email}
              </>
            }
          >
            <Link to={`/quickfit/customer/${cyclist._id}`}>
              <span>
                <i className={CYCLIST_ICON} /> {firstName + ' ' + lastName}
              </span>
            </Link>
          </Tip>
        </td>
        <td>
          <i className={EMAIL_ICON} /> {email}
        </td>
        <td>
          <Tip message={<>Last updated {usNormal(updated)} </>}>
            <span>
              <i className={CALENDAR_ICON} /> {usNormal(updated)}
            </span>
          </Tip>
        </td>
        <td>
          <DeleteRecordButton
            title='Delete Customer?'
            confirmationPrompt='To confirm deletion'
            confirmationText='delete customer'
            confirmationButtonText='Delete Customer'
            tip='Delete this Customer.'
            deleteFunction={deleteFunction}
            recordId={cyclist._id}
          >
            <p>
              Do you want to delete the customer{' '}
              <strong>{firstName + ' ' + lastName}</strong>?
            </p>
            <p> This action cannot be undone.</p>
          </DeleteRecordButton>
        </td>
      </tr>
    </>
  )
}

export default connect(null, {
  deleteCyclist
})(CustomerRow)
