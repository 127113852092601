//import road bike fields

import {
  type,
  make,
  model,
  frameSize,
  frameReach,
  frameStack,
  effectiveTopTube,
  stemType,
  stemLength,
  stemAngle,
  spacersBelow,
  spacersAbove,
  handlebarWidth,
  handlebarReach,
  seatPostOffset,
  saddleMake,
  saddleModel,
  saddleWidth,
  crankLength,
  pedalType,
  pedalMakeModel,
  shifterType,
  brakeType,
  notes,
  confidentialNotes
} from '../field-objects/bikeFields'

//import MTB specific fields

import {
  mtbWheelSize,
  mtbSeatPostType,
  mtbHandlebarRise
} from '../field-objects/bikeFields'

//import TT specific fields

import {
  ttBasebarWidth,
  ttAerobarType,
  ttAerobarMakeModel,
  ttExtensionsShape,
  ttRisers,
  ttPadsMakeModel
} from '../field-objects/bikeFields'

//export roadBikeForm

export const roadBikeForm = {
  type,
  make,
  model,
  frameSize,
  frameReach,
  frameStack,
  effectiveTopTube,
  stemType,
  stemLength,
  stemAngle,
  spacersBelow,
  spacersAbove,
  handlebarWidth,
  handlebarReach,
  seatPostOffset,
  saddleMake,
  saddleModel,
  saddleWidth,
  crankLength,
  pedalType,
  pedalMakeModel,
  shifterType,
  brakeType
}

//export mountainBikeForm

export const mountainBikeForm = {
  type,
  make,
  model,
  mtbWheelSize,
  frameSize,
  frameReach,
  frameStack,
  effectiveTopTube,
  stemType,
  stemLength,
  stemAngle,
  spacersBelow,
  spacersAbove,
  handlebarWidth,
  mtbHandlebarRise,
  mtbSeatPostType,
  seatPostOffset,
  saddleMake,
  saddleModel,
  saddleWidth,
  crankLength,
  pedalType,
  pedalMakeModel,
  shifterType,
  brakeType
}

//export ttBikeForm

export const ttBikeForm = {
  type,
  make,
  model,
  frameSize,
  frameReach,
  frameStack,
  effectiveTopTube,
  ttBasebarWidth,
  ttAerobarType,
  ttAerobarMakeModel,
  ttExtensionsShape,
  ttRisers,
  ttPadsMakeModel,
  stemType,
  stemLength,
  stemAngle,
  spacersBelow,
  spacersAbove,
  saddleMake,
  saddleModel,
  saddleWidth,
  crankLength,
  pedalType,
  pedalMakeModel,
  shifterType,
  brakeType
}

//export Form containing all bike Fields

export const bikeEquipmentForm = {
  type,
  make,
  model,
  mtbWheelSize,
  frameSize,
  frameReach,
  frameStack,
  effectiveTopTube,
  ttBasebarWidth,
  ttAerobarType,
  ttAerobarMakeModel,
  ttExtensionsShape,
  ttRisers,
  ttPadsMakeModel,
  stemType,
  stemLength,
  stemAngle,
  spacersBelow,
  spacersAbove,
  handlebarWidth,
  mtbHandlebarRise,
  handlebarReach,
  mtbSeatPostType,
  seatPostOffset,
  saddleMake,
  saddleModel,
  saddleWidth,
  crankLength,
  pedalType,
  pedalMakeModel,
  shifterType,
  brakeType
}

export const bikeNotesForm = {
  notes,
  confidentialNotes
}
export const sharedBikeNotesForm = {
  notes
}
export const confidentialBikeNotesForm = {
  confidentialNotes
}

export const addBikeForm = {
  type,
  make,
  model,
  frameSize,
  notes
}
