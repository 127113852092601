import React from 'react'
import { Button } from 'react-bootstrap'
import { NEXT_ICON, PREVIOUS_ICON } from '../../utils/fontAwesomeDefaults'

const NextPreviousFooter = ({
  start,
  stop,
  limit,
  length,
  handlePrevious,
  handleNext
}) => {
  //  if (limit > length) return null
  return (
    <span className='float-end'>
      {length > limit && (
        <>
          {start > 1 && (
            <Button
              className='noButtonOutline'
              variant='link'
              onClick={handlePrevious}
            >
              <span>
                <i className={PREVIOUS_ICON} /> Previous
              </span>
            </Button>
          )}

          {stop < length && (
            <Button
              variant='link'
              className='noButtonOutline'
              onClick={handleNext}
            >
              Next <i className={NEXT_ICON} />
            </Button>
          )}
        </>
      )}
    </span>
  )
}

export default NextPreviousFooter
