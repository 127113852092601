import ViewRecordButton from '../../hook-components/ViewRecordButton'

const UserSearchListRow = ({ user }) => {
  return (
    <tr>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>
        <ViewRecordButton
          record={user}
          modalTitle={'View Record (' + user.name + ')'}
        />
      </td>
    </tr>
  )
}

export default UserSearchListRow
