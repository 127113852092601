import { connect } from 'react-redux'
import AdminRoutes from '../routing/AdminRoutes'
import Spinner from '../layout/Spinner'

import useAdminStats from '../hooks/data-fetch-hooks/useAdminStats'

import useAdminFeedbackList from '../hooks/data-fetch-hooks/useAdminFeedbackList'

const Admin = ({
  users: { user },
  fields: { fieldOverrides },
  adminmessages: { adminMessages },
  feedback: { feedbackList }
}) => {
  const adminStats = useAdminStats()
  const adminFeedbackList = useAdminFeedbackList()

  const { statsLoaded } = adminStats

  const { feedbackListLoaded } = adminFeedbackList

  if (
    !user?._id ||
    !fieldOverrides ||
    !adminMessages ||
    !statsLoaded ||
    !feedbackListLoaded
  ) {
    return <Spinner message='Loading admin data . . .' />
  }

  return (
    <AdminRoutes
      user={user}
      fieldOverrides={fieldOverrides}
      adminMessages={adminMessages}
      adminStats={adminStats}
      feedbackList={feedbackList}
    />
  )
}

const mapStateToProps = (state) => ({
  users: state.users,
  fields: state.fields,
  adminmessages: state.adminmessages,
  feedback: state.feedback
})

export default connect(mapStateToProps, {})(Admin)
