import React from 'react'
import { Card, Tab, Row, Col, Nav } from 'react-bootstrap'
import CustomerProfile from './CustomerProfile'
import CustomerSummary from './CustomerSummary'
import BodyMeasurements from './BodyMeasurements'
import PhysicalProfile from './PhysicalProfile'
import ConfidentialNotes from './ConfidentialNotes'
import SizingNotes from './SizingNotes'
import Bikes from '../bikes/Bikes'
import CustomerInterviews from '../interviews/CustomerInterviews'
import SizingRecommendations from './SizingRecommendations'
//import BikeCanvas from '../../tools/quicksize/BikeCanvas'

const CustomerTabs = ({ customer, user, shop }) => {
  const {
    _id: customerId,
    cyclistProfile,
    bodyMeasurements,
    softScores
  } = customer
  const { firstName, lastName, birthDate } = cyclistProfile
  return (
    <Tab.Container defaultActiveKey='summary'>
      <Row>
        <Col sm={3}>
          <Card>
            <Nav variant='pills' className='flex-column'>
              <Nav.Item>
                <Nav.Link eventKey='summary'>Summary</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='customer'>Customer Profile</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='confidential-notes'>
                  Confidential Notes
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='interviews'>Prefit Interviews</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='bodyMeasurements'>
                  Body Measurements
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='physicalProfile'>
                  Physical Considerations
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='sizing'>Sizing Recommendations</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='sizing-notes'>Sizing Notes</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='bikes'>Bikes</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card>
        </Col>
        <Col lg={9}>
          <Tab.Content>
            <Tab.Pane eventKey='summary'>
              <CustomerSummary customer={customer} user={user} />
            </Tab.Pane>
            <Tab.Pane eventKey='customer'>
              <CustomerProfile customer={customer} user={user} />
            </Tab.Pane>
            <Tab.Pane eventKey='confidential-notes'>
              <ConfidentialNotes customer={customer} user={user} />
            </Tab.Pane>
            <Tab.Pane eventKey='interviews'>
              <CustomerInterviews customerId={customerId} bikeTableMode />
            </Tab.Pane>
            <Tab.Pane eventKey='bodyMeasurements'>
              <BodyMeasurements customer={customer} user={user} />
            </Tab.Pane>
            <Tab.Pane eventKey='physicalProfile'>
              <PhysicalProfile customer={customer} user={user} />
            </Tab.Pane>

            <Tab.Pane eventKey='sizing'>
              <SizingRecommendations
                bodyMeasurements={bodyMeasurements}
                softScores={softScores}
                birthDate={birthDate}
                bikeImage
                bikeSelection
                user={user}
                shop={shop}
                customer={customer}
              ></SizingRecommendations>
              {/* <BikeCanvas imageSource='/roadsizingannotated.png' /> */}
            </Tab.Pane>
            <Tab.Pane eventKey='sizing-notes'>
              <SizingNotes customer={customer} user={user} />
            </Tab.Pane>

            <Tab.Pane eventKey='bikes'>
              <Bikes
                customerId={customerId}
                firstName={firstName}
                lastName={lastName}
                user={user}
                bikeTableMode
              />
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
}

export default CustomerTabs
