import React from 'react'
import { Form } from 'react-bootstrap'

const TextArea = ({
  label,
  value,
  type,
  name,
  isValid,
  isInvalid,
  onChange,
  placeholder
}) => {
  return (
    <>
      <Form.Group className='mb-3 mt-3'>
        {label && <Form.Label>{label}</Form.Label>}

        <Form.Control
          size={'sm'}
          as='textarea'
          rows='4'
          spellCheck
          value={value}
          type={type}
          isValid={isValid}
          isInvalid={isInvalid}
          onChange={onChange}
          name={name}
          placeholder={placeholder}
        />
      </Form.Group>
    </>
  )
}

export default TextArea
