import { Routes, Route } from 'react-router-dom'
import Alert from '../layout/Alert'

const AlertRoutes = () => {
  return (
    <Routes>
      <Route exact path='/pre-fit-interview/:userId' element={null} />
      <Route exact path='/terms-of-use' element={null} />
      <Route exact path='/privacy-policy' element={null} />
      <Route path='*' element={<Alert />} />
    </Routes>
  )
}

export default AlertRoutes
