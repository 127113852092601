import { useState } from 'react'

import useInputWithReset from '../input-hooks/useInputWithReset'

const useConfirmCancelModal = (modalObject) => {
  const {
    icon,
    title,
    confirmationPrompt,
    confirmationText,
    confirmationButtonIcon,
    confirmationButtonText,
    confirmationAction,
    cancelAction
  } = modalObject
  const [showModal, setShowModal] = useState(false)

  const [confirmationTextInput, confirmationTextInputReset] = useInputWithReset(
    { type: 'text', label: '', initialValue: '' },
    {
      validationType: 'confirmationText',
      confirmationValue: confirmationText
    }
  )

  const handleToggle = () => {
    setShowModal(!showModal)
  }

  const handleCancel = () => {
    setShowModal(false)

    confirmationTextInputReset()
    if (cancelAction) cancelAction()
  }

  const handleConfirmation = (e) => {
    e.preventDefault()
    if (confirmationAction) confirmationAction()
    setShowModal(false)
    confirmationTextInputReset()
  }

  return {
    icon,
    title,
    confirmationPrompt,
    confirmationText,
    showModal: showModal,
    confirmationButtonIcon,
    confirmationButtonText,
    confirmationTextInput,
    setShowModal: handleToggle,
    clickConfirm: handleConfirmation,
    clickCancel: handleCancel
  }
}

export default useConfirmCancelModal
