import ViewRecordButton from '../../hook-components/ViewRecordButton'

const CustomerSearchListRow = ({ customer }) => {
  const { firstName, lastName, email } = customer.cyclistProfile
  return (
    <tr>
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{email}</td>
      <td>
        <ViewRecordButton
          record={customer}
          modalTitle={'View Record (' + firstName + ' ' + lastName + ')'}
        />
      </td>
    </tr>
  )
}

export default CustomerSearchListRow
