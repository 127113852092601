import useInputWithReset from '../../input-hooks/useInputWithReset'

//import { barToFrameXYForm as form } from '../../../forms/form-objects/toolsFormObjects'

import {
  getInput,
  getValidation,
  getForm
} from '../../input-hooks/inputFunctions'

const useSingleInputForm = (form, record) => {
  const keyArray = Object.keys(form)
  const i = []

  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )

  return getForm(keyArray, i)
}

export default useSingleInputForm
