import React from 'react'
import KeyStats from './KeyStats'

import Spinner from '../../layout/Spinner'

import useUserStats from '../../hooks/data-fetch-hooks/useUserStats'

const MyData = ({ user }) => {
  const userStats = useUserStats()

  const { statsLoaded } = userStats

  if (!statsLoaded) return <Spinner message='Loading user data. . .' />

  return <KeyStats userStats={userStats} user={user} />
}

export default MyData
