import { useState } from 'react'
import { Card } from 'react-bootstrap'
import api from '../../../utils/api.js'

import useForm from '../../../forms-new/form-hooks/useForm'
import GenerateForm from '../../../forms-new/form-components/GenerateForm'
import Spinner from '../../layout/Spinner.js'
import StackReachReport from './StackReachReport.jsx'
import SearchError from '../search/SearchError.js'

import { stackReachFields } from '../../../forms-new/fields-and-forms/stackReachFields'

const StackReachData = ({
  errors,
  stackReachData,
  setErrors,
  setStackReachData
}) => {
  const initialState = {
    email: '',
    height: '',
    footLength: '',
    inseam: '',
    torso: '',
    arm: '',
    gender: null,
    bikeType: 'Road Bike'
  }

  const stackReachForm = useForm(stackReachFields, initialState)

  const { values } = stackReachForm

  const newValues = {
    email: values.email,
    gender: values.gender,
    height: parseFloat(values.height),
    footLength: parseFloat(values.footLength),
    inseam: parseFloat(values.inseam),
    torso: parseFloat(values.torso),
    arm: parseFloat(values.arm),
    bikeType: values.bikeType
  }

  const { email, gender, height, footLength, inseam, torso, arm, bikeType } =
    newValues

  const [loading, setLoading] = useState(false)

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const res = await api.get(
        `/admin-data/stackreachdata?email=${email}&gender=${gender}&height=${height}&footLength=${footLength}&inseam=${inseam}&torso=${torso}&arm=${arm}&biketype=${bikeType}`
      )
      setStackReachData(res.data)
      setLoading(false)
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        console.log(errors)
        setErrors(errors)
      }
    }
  }

  return (
    <>
      <Card>
        <Card.Header className='bg-white'>
          <h5 style={{ display: 'inline' }}>Stack Reach Data</h5>
        </Card.Header>
        <Card.Body>
          {' '}
          <GenerateForm
            {...stackReachForm}
            onSubmit={onSubmit}
            buttonText='Search'
            buttonVariant='dark'
          />
        </Card.Body>
      </Card>
      {loading && <Spinner className='mt-5' message='Loading Data' />}
      <StackReachReport stackReachData={stackReachData} bikeType={bikeType} />
      {errors && <SearchError errors={errors} />}
    </>
  )
}

export default StackReachData
