import { useState, useEffect } from 'react'
import store from '../../../redux/store'
import { loadAdminFeedbackList } from '../../../redux/actions/feedback'

const useAdminFeedbackList = () => {
  const [feedbackListLoaded, setFeedbackListLoaded] = useState(false)
  const [feedbackList, setFeedbackList] = useState()

  useEffect(() => {
    const feedbackList = async () => {
      const feedbackList = await store.dispatch(loadAdminFeedbackList())
      setFeedbackList(feedbackList)
      setFeedbackListLoaded(true)
    }
    feedbackList()
  }, [])

  return { feedbackListLoaded, feedbackList }
}

export default useAdminFeedbackList
