import React from 'react'
import MainContentContainer from '../layout/cards-containers/MainContentContainer'
import ClientRoutes from './ClientRoutes'

const MainContentRoutes = () => {
  return (
    <>
      <MainContentContainer>
        <ClientRoutes />
      </MainContentContainer>
    </>
  )
}

export default MainContentRoutes
