import React from 'react'
import { Card } from 'react-bootstrap'

import TableData from '../layout/cards-containers/TableData'
import NotesData from '../layout/cards-containers/NotesData'
import EditFormButton from '../hook-components/EditFormButton'
import HistoryData from './bikes/HIstoryData'

import {
  cleatModifications,
  cleatAdjustments
} from '../forms/field-objects/shoesCleatsFields'

const QuickFitTabContent = (props) => {
  const { title, dataObject, mode, footer, headerIcon } = props

  // if (title === 'Shoes/Cleats') console.log(title, dataObject.make)

  return (
    <>
      <Card>
        <Card.Header className='bg-white'>
          <h5 style={{ display: 'inline' }}>{title}</h5>
          {headerIcon && headerIcon}
        </Card.Header>
        <Card.Body>
          {mode === 'notesMode' ? (
            <NotesData {...props} />
          ) : mode === 'historyMode' ? (
            <HistoryData {...props} />
          ) : (
            <TableData {...props} />
          )}
          {mode === 'shoesCleatsMode' && (
            <>
              {' '}
              <div style={{ fontSize: '.8rem' }}>
                <p>{cleatAdjustments.label}</p>
                <Card body>{dataObject.cleatAdjustments}</Card>
                <p></p>
                <p>{cleatModifications.label}</p>
                <Card body>{dataObject.cleatModifications}</Card>
              </div>
            </>
          )}
        </Card.Body>
        {mode !== 'historyMode' ? (
          <Card.Footer className='bg-white'>
            <EditFormButton {...props} />
          </Card.Footer>
        ) : (
          <Card.Footer className='bg-white'>{footer}</Card.Footer>
        )}
      </Card>
    </>
  )
}

export default QuickFitTabContent
