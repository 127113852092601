import {
  name,
  email,
  password,
  confirmPassword,
  subject,
  message
} from '../field-objects/usersAuthFields'

export const signupForm = {
  name,
  email,
  password,
  confirmPassword
}

export const loginForm = {
  email,
  password
}

export const contactForm = {
  name,
  email,
  subject,
  message
}

export const passwordResetRequestForm = {
  email
}

export const resetPasswordForm = {
  password,
  confirmPassword
}

export const editNameAndEmailForm = {
  name,
  email
}
