import {
  height_units,
  weight_units,
  cyclist_name,
  report_format,
  report_color,
  cyclist_profile,
  fk_body_measurements,
  fk_soft_score,
  sizing_recommendations,
  equipment,
  fit_position,
  feet_cleat,
  bike_diagram,
  notes_recommendations
} from '../field-objects/userPreferencesFields'

export const userPreferencesForm = {
  height_units,
  weight_units,
  report_format,
  cyclist_name,
  cyclist_profile,
  fk_body_measurements,
  fk_soft_score,
  sizing_recommendations,
  equipment,
  fit_position,
  feet_cleat,
  bike_diagram,
  notes_recommendations,
  report_color
}

export const reportFields = {
  cyclist_name,
  cyclist_profile,
  fk_body_measurements,
  fk_soft_score,
  sizing_recommendations,
  equipment,
  fit_position,
  feet_cleat,
  bike_diagram,
  notes_recommendations
}
