import React from 'react'

import { Row, Col } from 'react-bootstrap'

import CustomerBikes from '../bikes/CustomerBikes'

import CustomerInfo from './CustomerInfo'

const CustomerSummary = ({ customer, user }) => {
  const { _id: customerId, cyclistProfile } = customer
  const { firstName, lastName } = cyclistProfile
  return (
    <>
      <Row>
        <Col lg={6}>
          <CustomerBikes
            customerId={customerId}
            firstName={firstName}
            lastName={lastName}
          />
        </Col>
        <Col>
          <CustomerInfo customer={customer} user={user} />
        </Col>
      </Row>
    </>
  )
}

export default CustomerSummary
