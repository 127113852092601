import React from 'react'
import { useNavigate } from 'react-router-dom'

import store from '../../redux/store'

import { sendContactMessage } from '../../redux/actions/users'

import UserFormContainer from './UserFormContainer'

import GenerateForm from '../forms/form-components/GenerateForm'

import useContactForm from '../hooks/form-hooks/users-auth/useContactForm'

import { EMAIL_ICON } from '../../utils/fontAwesomeDefaults'

import SEO from '../seo/SEO'

const ContactForm = () => {
  const navigate = useNavigate()

  const contactForm = useContactForm({
    name: '',
    email: '',
    subject: '',
    message: ''
  })

  const { validateForm, form } = contactForm

  const onSubmit = (e) => {
    e.preventDefault()
    const { name, email, subject, message } = contactForm.values
    const messageObject = {
      name,
      email,
      subject,
      message
    }
    store.dispatch(sendContactMessage(messageObject))

    navigate('/contact-confirmation')
  }

  return (
    <>
      <SEO
        title='Fit Kit Studio | Contact'
        description='Fit Kit Studio Bike Fitting Software Contact Page'
        type='website'
        name='Fit Kit Systems'
      />
      <UserFormContainer heading='Contact Us' icon={EMAIL_ICON}>
        <p>Get in touch by phone call or text to: </p>
        <p className='text-center'>(USA) 1-800-434-8548</p>
        <p>Or use the form below to send an email:</p>
        <GenerateForm
          {...contactForm}
          onSubmit={onSubmit}
          disabled={!validateForm(form)}
          buttonText='Send Message'
        />
      </UserFormContainer>
    </>
  )
}

export default ContactForm
