import React from 'react'
import { Image } from 'react-bootstrap'

const MTBImage = () => {
  return (
    <Image
      className='mt-3'
      src='/mtbsizingannotated.png'
      alt='Mountain Bike Reference Image'
      style={{ maxWidth: '100%' }}
    />
  )
}

export default MTBImage
