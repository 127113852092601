export const label = {
  type: 'text',
  label: 'Label'
}

export const tip = {
  type: 'text-area',
  label: 'Help Tip',
  rows: 4
}

export const min = {
  type: 'number',
  label: 'Minimum Value'
}

export const max = {
  type: 'number',
  label: 'Maximum Value'
}

export const step = {
  type: 'number',
  label: 'Step Increment'
}
