import React from 'react'

const NotesData = ({
  dataObject: { notes, confidentialNotes },
  dataForm: { form }
}) => {
  return (
    <div style={{ fontSize: '.8rem' }}>
      {notes && (
        <>
          {/* <p>
            {form && form.notes.tip ? (
              <Tip message={form.notes.tip} placement='top'>
                <span style={{ cursor: 'help' }}>{form.notes.label}</span>
              </Tip>
            ) : form && form.notes.label ? (
              <>{form.notes.label}</>
            ) : (
              'Notes'
            )}
          </p> */}

          <div style={{ whiteSpace: 'pre-line' }}>{notes}</div>

          <p></p>
        </>
      )}
      {/* <p>
        {form && form.confidentailNotes && form.confidentialNotes.tip ? (
          <Tip message={form.confidentialNotes.tip} placement='top'>
            <span style={{ cursor: 'help' }}>
              {form.confidentialNotes.label}
            </span>
          </Tip>
        ) : form && form.confidentialNotes.label ? (
          <>{form.confidentialNotes.label}</>
        ) : (
          'Confidential Notes'
        )}
      </p> */}
      {confidentialNotes && (
        <div style={{ whiteSpace: 'pre-line' }}>{confidentialNotes}</div>
      )}
    </div>
  )
}

export default NotesData
