import React from 'react'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'
import { deleteBike } from '../../../redux/actions/bikes'

import QuickFitDashboardCard from '../QuickFitDashboardCard'
import QuickFitDashboardTitle from '../QuickFitDashboardTitle'
import QuickFitDashboardItemCounter from '../QuickFitDashboardItemCounter'
import BikeRow from './BikeRow'

import useNextPrevious from '../../hooks/action-hooks/useNextPrevious'

import useCustomerBikes from '../../hooks/data-fetch-hooks/useCustomerBikes'

import { usShort } from '../../../utils/dateFormats'
import BikesFooter from './BikesFooter'

const Bikes = ({
  customerId,
  firstName,
  lastName,
  bikes: { customerBikes },
  deleteBike
}) => {
  useCustomerBikes(customerId)

  const displayCount = 10

  const nextPrevious = useNextPrevious(displayCount, customerBikes.length)

  const { start, stop, length } = nextPrevious

  if (customerBikes.length !== length) {
    nextPrevious.setcurrentLength(customerBikes.length)
    if (stop > customerBikes.length) nextPrevious.setStop(customerBikes.length)
  }

  return (
    <>
      <QuickFitDashboardCard
        style={{ minHeight: '392px' }}
        title={
          <>
            <QuickFitDashboardTitle title='Bikes' />
            <QuickFitDashboardItemCounter
              start={start}
              stop={stop}
              length={length}
            />
          </>
        }
        footer={
          <>
            <BikesFooter
              nextPrevious={nextPrevious}
              customerId={customerId}
              firstName={firstName}
              lastName={lastName}
            />
          </>
        }
      >
        {customerBikes.length === 0 ? (
          <p>No Bikes Found</p>
        ) : (
          <Table size='sm' style={{ fontSize: '.8rem' }}>
            <tbody>
              {customerBikes.slice(start - 1, stop).map((bike) => {
                return (
                  <BikeRow
                    key={bike._id}
                    {...bike}
                    updated={usShort(bike.updated)}
                    bikeTableMode={true}
                    deleteBike={deleteBike}
                  />
                )
              })}
            </tbody>
          </Table>
        )}
      </QuickFitDashboardCard>
    </>
  )
}

const mapStateToProps = (state) => ({
  prefitinterviews: state.prefitinterviews,
  bikes: state.bikes
})

export default connect(mapStateToProps, {
  deleteBike
})(Bikes)
