import {
  CREATE_INTERVIEW,
  NEW_INTERVIEWS_LOADED,
  CUSTOMER_INTERVIEWS_LOADED,
  DELETE_INTERVIEW,
  CLEAR_INTERVIEWS,
  CLEAR_CUSTOMER_INTERVIEWS
} from '../actions/types'

const initialState = {
  redirectToConfirmationPage: false,
  interview: null,
  newInterviews: [],
  customerInterviews: []
}

export default function prefitinterviews(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case CREATE_INTERVIEW:
      return {
        ...state,
        interview: payload,
        redirectToConfirmationPage: true
      }
    case NEW_INTERVIEWS_LOADED:
      return {
        ...state,
        newInterviews: payload
      }
    case CUSTOMER_INTERVIEWS_LOADED:
      return {
        ...state,
        customerInterviews: payload
      }
    case DELETE_INTERVIEW:
      return {
        ...state,
        newInterviews: state.newInterviews.filter(
          (interview) => interview._id !== payload
        ),
        customerInterviews: state.customerInterviews.filter(
          (interview) => interview._id !== payload
        )
      }
    case CLEAR_INTERVIEWS:
      return {
        ...initialState
      }

    case CLEAR_CUSTOMER_INTERVIEWS:
      return {
        ...state,
        customerInterviews: []
      }

    default:
      return state
  }
}
