import { Routes, Route } from 'react-router-dom'

import MainNavBar from '../layout/MainNavBar'

const NavRoutes = () => {
  return (
    <Routes>
      <Route exact path='/pre-fit-interview/:userId' element={null} />
      <Route path='*' element={<MainNavBar />} />
    </Routes>
  )
}

export default NavRoutes
