import {
  CREATE_STRIPE_CUSTOMER,
  CREATE_STRIPE_SUBSCRIPTION,
  STRIPE_CUSTOMER_LOADED,
  STRIPE_SUBSCRIPTION_LOADED,
  STRIPE_CARD_LOADED,
  CLEAR_STRIPE_DATA,
  UPDATE_STRIPE_CUSTOMER,
  CANCEL_STRIPE_SUBSCRIPTION,
  REACTIVATE_STRIPE_SUBSCRIPTION,
  CHANGE_PAYMENT_FREQUENCY
} from '../actions/types'

const initialState = {
  customer: null,
  subscription: {
    status: 'N/A',
    plan: { name: 'Not Subscribed', interval: 'N/A' },
    current_period_end: new Date()
  },
  card: {},
  last4: '',
  activeStatus: false
}

export default function stripe(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case CREATE_STRIPE_CUSTOMER:
    case STRIPE_CUSTOMER_LOADED:
    case UPDATE_STRIPE_CUSTOMER:
      return {
        ...state,
        customer: payload
      }

    case CREATE_STRIPE_SUBSCRIPTION:
    case STRIPE_SUBSCRIPTION_LOADED:
    case CANCEL_STRIPE_SUBSCRIPTION:
    case REACTIVATE_STRIPE_SUBSCRIPTION:
    case CHANGE_PAYMENT_FREQUENCY:
      return {
        ...state,
        subscription: payload,
        activeStatus:
          !payload.canceled_at &&
          (payload.status === 'active' || payload.status === 'trialing')
      }
    case STRIPE_CARD_LOADED:
      let digits = ''
      if (payload.last4) digits = payload.last4
      else digits = payload.card.last4
      return {
        ...state,
        card: payload,
        last4: digits
      }
    case CLEAR_STRIPE_DATA:
      return {
        ...initialState
      }

    default:
      return state
  }
}
