import React from 'react'
import { connect } from 'react-redux'
import { usNormal } from '../../../utils/dateFormats'
import DeleteRecordButton from '../../hook-components/DeleteRecordButton'
import ApproveUserButton from '../../hook-components/ApproveUserButton'
import Tip from '../../layout/overlays/Tip'

import {
  adminDeleteAccount,
  adminApproveUser
} from '../../../redux/actions/users'

const UserRow = ({
  user,
  adminStats: { removeUserFromList, updateUserInList },
  adminDeleteAccount,
  adminApproveUser
}) => {
  const {
    name,
    email,
    created,
    updated,
    last_login,
    status,
    customerCount,
    bikeCount,
    oldLogInfo,
    total_logins,
    honey_pot,
    fill_time
  } = user

  let statusColor

  switch (status) {
    case 'active':
      statusColor = 'text-success'
      break
    case 'trialing':
    case 'inactive free user':
      statusColor = 'text-warning'
      break
    case 'canceled inactive':
      statusColor = 'text-muted'
      break
    case 'never logged in':
      statusColor = 'text-danger'
      break
    case 'canceled active':
    case 'active free user':
      statusColor = 'text-info'
      break

    case 'possible spam':
      statusColor = 'text-danger'
      break
    case 'logged in once':
      statusColor = 'text-primary'
      break
    default:
      statusColor = 'text-secondary'
  }

  const deleteFunction = () => {
    adminDeleteAccount(user._id)
    removeUserFromList(user._id)
  }
  const approveFunction = () => {
    adminApproveUser(user)
    updateUserInList(user._id)
  }

  const trim = (text, length) => {
    if (text.length > length) return text.substring(0, length - 3) + '...'
    else return text
  }

  return (
    <tr>
      <td>
        <Tip
          message={
            <>
              {name}
              <br />
              {email}
              <br />
              Joined: {usNormal(created)}
              <br />
              Updated: {usNormal(updated)}
              <br />
              Last Login:{' '}
              {!last_login && status === 'never logged in'
                ? 'Has never logged in since registering'
                : last_login
                ? usNormal(last_login)
                : 'None'}
              <br />
              <span>
                Total Logins:{' '}
                {last_login && !total_logins
                  ? 1
                  : !last_login || !total_logins
                  ? 0
                  : total_logins}
              </span>
              {honey_pot ? (
                <>
                  <br /> <span>Honey Pot: {honey_pot}</span>
                </>
              ) : null}
              {fill_time ? (
                <>
                  <br /> <span>Fill Time: {fill_time}</span>
                </>
              ) : null}
              {/* <span>
                {' '}
                {oldLogInfo
                  ? 'Studio 2 Log Info:'
                  : 'No Studio 2 Log Info exists for this user'}
              </span>
              <br />
              {oldLogInfo && 'Studio 2 logins: ' + oldLogInfo.totalLogins}
              <br />
              <span>
                {oldLogInfo &&
                  'Last Studio 2 login: ' +
                    usNormal(oldLogInfo.lastLoggedLogin)}
              </span> */}
            </>
          }
        >
          <span>{trim(name, 20)}</span>
        </Tip>{' '}
      </td>
      <td>{trim(email, 25)} </td>
      <td>{usNormal(created)} </td>
      <td className={statusColor}>{status} </td>
      <td>{customerCount} </td>
      <td> {bikeCount}</td>
      <td>
        {status === 'never logged in' || status === 'possible spam' ? (
          <>
            <ApproveUserButton
              title='Approve User?'
              confirmationPrompt='To confirm approval'
              confirmationText={'approve'}
              confirmationButtonText='Approve User'
              tip='Approve this User'
              approveFunction={approveFunction}
            >
              <p>Do you want to approve the following user?</p>
              <p>
                {' '}
                <strong>Name: {user.name}</strong>
                <br></br>
                <strong>Email: {user.email}</strong>
                <br></br>
                <strong>Joined: {usNormal(user.created)}</strong>
                <br></br>
                <strong>Updated: {usNormal(user.updated)}</strong> If this date
                is today and user did not join today, it is likely the user has
                never been updated.
                <br></br>
                <strong>Customers: {customerCount}</strong>
                <br></br>
                <strong>Bikes: {bikeCount}</strong>
                <br></br>
                <strong>User Id: {user._id}</strong>
                <br />
                {last_login && (
                  <>
                    <strong>Last Login: {usNormal(last_login)}</strong>
                    <br />
                  </>
                )}
                {total_logins && (
                  <>
                    <strong>Total Logins: {total_logins}</strong>
                    <br />
                  </>
                )}{' '}
                {!last_login && !oldLogInfo && (
                  <>
                    <strong>User has never logged in since registering.</strong>
                    <br />
                  </>
                )}
                <br />
              </p>
              <p>
                {' '}
                Approved users appear in the user list as either active free
                users or inactive free users and are no longer flagged for
                possible deletion.
              </p>{' '}
            </ApproveUserButton>{' '}
            <DeleteRecordButton
              title='Delete User?'
              confirmationPrompt='To confirm deletion'
              confirmationText={'permanently delete ' + user.name}
              /* disabled={false} */
              confirmationButtonText='Delete User'
              tip='Delete this User.'
              deleteFunction={deleteFunction}
              recordId={user._id}
            >
              <p>Do you want to permanently delete the following user?</p>
              <p>
                {' '}
                <strong>Name: {user.name}</strong>
                <br></br>
                <strong>Email: {user.email}</strong>
                <br></br>
                <strong>Joined: {usNormal(user.created)}</strong>
                <br></br>
                <strong>Updated: {usNormal(user.updated)}</strong> If this date
                is today and user did not join today, it is likely the user has
                never been updated.
                <br></br>
                <strong>Customers: {customerCount}</strong>
                <br></br>
                <strong>Bikes: {bikeCount}</strong>
                <br></br>
                <strong>User Id: {user._id}</strong>
                <br />
                {last_login && (
                  <>
                    <strong>Last Login: {usNormal(last_login)}</strong>
                    <br />
                  </>
                )}
                {total_logins && (
                  <>
                    <strong>Total Logins: {total_logins}</strong>
                    <br />
                  </>
                )}
                {/* {oldLogInfo && (
                <>
                  <strong>
                    Last Studio 2 Login: {usNormal(oldLogInfo.lastLoggedLogin)}
                  </strong>
                  <br />
                  <strong>
                    Total Studio 2 Logins: {oldLogInfo.totalLogins}
                  </strong>
                  <br />
                </>
              )} */}
                {!last_login && !oldLogInfo && (
                  <>
                    <strong>User has never logged in since registering.</strong>
                    <br />
                  </>
                )}
                <br></br>
              </p>

              <p>
                {' '}
                This action cannot be undone. Do not delete active users or
                users with canceled subscriptions.
              </p>
            </DeleteRecordButton>{' '}
          </>
        ) : null}
      </td>
    </tr>
  )
}

export default connect(null, { adminDeleteAccount, adminApproveUser })(UserRow)
