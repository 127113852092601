import React from 'react'
import { Card } from 'react-bootstrap'
import { CARD_HEADER_BG } from '../../../utils/styleAliases'

import TableData from '../../layout/cards-containers/TableData'

import {
  calculateStemHeightDelta,
  calculateStemReachDelta
} from '../../../lib/tools-js-functions'

const StemDeltaOutputs = ({ inputs, user }) => {
  const stemHeightDelta = calculateStemHeightDelta(inputs)

  const stemReachDelta = calculateStemReachDelta(inputs)

  let heightLabel =
    stemHeightDelta <= 0
      ? 'Stem Two is lower than Stem One by:'
      : 'Stem Two is higher than Stem One by:'

  const reachLabel =
    stemReachDelta <= 0
      ? 'Reach of Stem Two is shorter than Stem One by:'
      : 'Reach of Stem Two is longer than Stem One by:'

  const dataForm = {
    stemReachDelta: {
      label: reachLabel,
      type: 'number'
    },
    stemHeightDelta: { label: heightLabel, type: 'number' }
  }
  return (
    <Card>
      <Card.Header className={CARD_HEADER_BG}>
        <Card.Title>Stem Reach and Height Deltas</Card.Title>
      </Card.Header>
      <Card.Body>
        <TableData
          dataForm={dataForm}
          dataObject={{
            stemReachDelta: stemReachDelta,
            stemHeightDelta: stemHeightDelta
          }}
          user={user}
          //fontStyleObject={{ fontSize: '1.0rem' }}
        />
      </Card.Body>
    </Card>
  )
}

export default StemDeltaOutputs
