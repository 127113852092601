import React from 'react'

import { usNormal } from '../../../utils/dateFormats'

const FeedbackRow = ({ feedback, feedbackList }) => {
  const { userName, userEmail, date } = feedback

  return (
    <>
      <tr>
        <td>{userName}</td>
        <td>{userEmail} </td>
        <td>{usNormal(date)}</td>
      </tr>
    </>
  )
}

export default FeedbackRow
