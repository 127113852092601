import React from 'react'
import { Table } from 'react-bootstrap'

import { connect } from 'react-redux'

import RecentCustomerRow from './RecentCustomerRow'
import QuickFitDashboardCard from '../QuickFitDashboardCard'
import NextPreviousFooter from '../../hook-components/NextPreviousFooter'
import AddNewCustomer from './AddNewCustomer'

import useNextPrevious from '../../hooks/action-hooks/useNextPrevious'

const RecentCustomerTable = ({ cyclists: { cyclists } }) => {
  const nextPrevious = useNextPrevious(3, cyclists.length)

  const { start, stop } = nextPrevious

  return (
    <QuickFitDashboardCard
      title='Recent Customers'
      style={{ minHeight: '305px' }}
      footer={
        <>
          <NextPreviousFooter {...nextPrevious} />
          <br />
          <br />
          <AddNewCustomer />
        </>
      }
    >
      <Table size='sm' style={{ fontSize: '.8rem' }}>
        <tbody>
          {cyclists.length === 0 ? (
            <tr>
              <td>
                <h6>No customers found.</h6>
              </td>
            </tr>
          ) : (
            cyclists.slice(start - 1, stop).map((cyclist) => {
              return <RecentCustomerRow key={cyclist._id} cyclist={cyclist} />
            })
          )}
        </tbody>
      </Table>
    </QuickFitDashboardCard>
  )
}

const mapStateToProps = (state) => ({
  cyclists: state.cyclists
})
export default connect(mapStateToProps, {})(RecentCustomerTable)
