import React from 'react'
import { Row, Col, Table } from 'react-bootstrap'

import QuickFitDashboardCard from '../QuickFitDashboardCard'
import QuickFitTableRow from '../QuickFitTableRow'

import { heightMunit, weightMunit } from '../../../utils/heightWeight'

const CustomerInfo = ({ customer, user }) => {
  const { bodyMeasurements, softScores } = customer

  const {
    arm,
    footLength,
    height,
    inseam,
    shoulders,
    sitBones,
    torso,
    weight
  } = bodyMeasurements

  const { flexibility, ridingStyle, preconditions } = softScores

  const { height_units, weight_units } = user.preferences

  return (
    <>
      <QuickFitDashboardCard
        style={{ minHeight: '105px' }}
        title='Customer Summary'
      >
        <Row>
          <Col>
            <Table size='sm' style={{ fontSize: '.8rem' }}>
              <tbody>
                <QuickFitTableRow
                  label='Height'
                  value={height}
                  munit={heightMunit(height_units)}
                />
                <QuickFitTableRow
                  label='Weight'
                  value={weight}
                  munit={weightMunit(weight_units)}
                />
                <QuickFitTableRow
                  label='Foot Length'
                  value={footLength}
                  munit={'cm.'}
                />
                <QuickFitTableRow label='Inseam' value={inseam} munit={'cm.'} />
                <QuickFitTableRow label='Torso' value={torso} munit={'cm.'} />
                <QuickFitTableRow label='Arm' value={arm} munit={'cm.'} />
                <QuickFitTableRow
                  label='Shoulders'
                  value={shoulders}
                  munit={'cm.'}
                />
                <QuickFitTableRow
                  label='Sit Bones'
                  value={sitBones}
                  munit={'mm.'}
                />
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table size='sm' style={{ fontSize: '.8rem' }}>
              <tbody>
                <QuickFitTableRow label='Flexibility' value={flexibility} />
                <QuickFitTableRow label='Riding Style' value={ridingStyle} />
                <QuickFitTableRow label='Conditions' value={preconditions} />
              </tbody>
            </Table>
          </Col>
        </Row>
      </QuickFitDashboardCard>
    </>
  )
}

export default CustomerInfo
