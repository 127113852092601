import React from 'react'
import Spinner from '../layout/Spinner'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { checkAuthToken } from '../../redux/actions/users'

const RequireAuth = ({
  children,
  redirectTo,
  users: { isAuthenticated, userLoading },
  checkAuthToken
}) => {
  if (userLoading) return <Spinner message='Loading User Data' />

  if (localStorage.token) {
    checkAuthToken(localStorage.token)
  }

  return isAuthenticated ? children : <Navigate to={redirectTo} />
}

const mapStateToProps = (state) => ({
  users: state.users
})

export default connect(mapStateToProps, { checkAuthToken })(RequireAuth)
