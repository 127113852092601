export const icon = {
  type: 'select',
  label: 'Icon',
  options: ['INFO', 'WARNING', 'CYCLIST', 'BIKE', 'CALENDAR', 'POLICY'],
  fksmode: 'stacked'
}

export const title = {
  type: 'text',
  label: 'Title (optional)'
}

export const content = {
  type: 'text-area',
  label: 'Message Content',
  rows: 4,
  validationType: 'inputLength',
  length: 1
}

export const start = {
  type: 'date',
  label: 'Start Date'
}
export const stop = {
  type: 'date',
  label: 'Stop Date'
}
