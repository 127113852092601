import React from 'react'
import { connect } from 'react-redux'
import { getIcon } from '../../../utils/generalFunctions'

import { ADMIN_ICON, EDIT_ICON } from '../../../utils/fontAwesomeDefaults'

import useEditResourceForm from '../../hooks/form-hooks/admin/useEditResourceForm'

import EditFormButton from '../../hook-components/EditFormButton'
import DeleteRecordButton from '../../hook-components/DeleteRecordButton'

import { updateResourceCategory } from '../../../redux/actions/resources'

const ResourceRow = ({
  resource,
  item,
  itemIndex,
  setResourcesLoaded,
  updateResourceCategory
}) => {
  const editResourceForm = useEditResourceForm(item)

  const saveFunction = () => {
    const newResourceList = resource.resourceList.map((item, index) => {
      if (index === itemIndex) return editResourceForm.values
      return item
    })

    updateResourceCategory(
      resource._id,
      {
        category: resource.category,
        tab: resource.tab,
        resourceList: newResourceList
      },
      'Resource updated.'
    )
    setResourcesLoaded(false)
    editResourceForm.reset()
  }
  const deleteFunction = () => {
    const newResourceList = resource.resourceList.filter(
      (item, index) => index !== itemIndex
    )

    updateResourceCategory(
      resource._id,
      {
        category: resource.category,
        tab: resource.tab,
        resourceList: newResourceList
      },
      'Resource deleted.'
    )
    setResourcesLoaded(false)
    editResourceForm.reset()
  }

  return (
    <tr>
      <td>{item.resourceName}</td>
      <td>{item.resourceType}</td>
      <td>{item.resourceLink}</td>
      <td>{item.resourceDescription}</td>
      <td style={{ textAlign: 'center' }}>
        <EditFormButton
          modalTitle={`Edit Resource`}
          modalForm={editResourceForm}
          modalTitleIcon={ADMIN_ICON}
          confirmationButtonIcon={ADMIN_ICON}
          confirmationButtonText='Save Changes'
          saveFunction={saveFunction}
          resetFunction={editResourceForm.reset}
          disabled={
            !editResourceForm.validateForm(editResourceForm) ||
            !editResourceForm.changesMade(item, editResourceForm.values)
          }
          link={getIcon(EDIT_ICON)}
          tip={`Edit resource.`}
          fksmode='stacked'
        />
      </td>
      <td style={{ textAlign: 'center' }}>
        <DeleteRecordButton
          title='Delete Resource?'
          confirmationPrompt='To confirm deletion'
          confirmationText='delete resource'
          confirmationButtonText='Delete Resource'
          tip='Delete this resource.'
          deleteFunction={deleteFunction}
          recordId={itemIndex}
        >
          Delete resource?
        </DeleteRecordButton>
      </td>
    </tr>
  )
}

export default connect(null, { updateResourceCategory })(ResourceRow)
