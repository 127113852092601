import React from 'react'
import { Table } from 'react-bootstrap'
import { usNormal } from '../../../utils/dateFormats'
import {
  convertedHeight,
  convertedWeight,
  heightMunit,
  weightMunit
} from '../../../utils/heightWeight'

import { yearMonthDay, yearMonthDayEval } from '../../../utils/dateFormats'

import { highlightChanges } from '../../../utils/generalFunctions'

import DeleteRecordButton from '../../hook-components/DeleteRecordButton'

const HistoryData = ({ dataForm, dataObject, user, bike, deleteFunction }) => {
  if (!user || !dataForm || !dataObject) return null
  const { height_units, weight_units } = user.preferences

  const fitHistory = dataObject.fitHistory.slice().reverse()

  let showCurrentFit = true

  if (bike.fitHistory.length > 0)
    showCurrentFit =
      bike.fitHistory[bike.fitHistory.length - 1].date ===
      dataObject.fitHistory[0].date

  return (
    <Table striped borderless size='sm' style={{ fontSize: '.8rem' }}>
      <thead>
        <tr>
          <th></th>
          {fitHistory.length > 0 &&
            fitHistory.map((fit, index) => {
              return (
                <th key={index} style={{ textAlign: 'right' }}>
                  {yearMonthDayEval(fit.date)}
                </th>
              )
            })}
          {showCurrentFit && (
            <th style={{ textAlign: 'right' }}>
              {yearMonthDayEval(bike.updated)}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {Object.entries(dataForm).map(([key, value]) => {
          let text = dataObject[key]
          let mUnit = 'mm.'
          if (key === 'birthDate') text = usNormal(text)
          if (key === 'height') {
            mUnit = heightMunit(height_units)
            if (height_units === 'Metric')
              text = convertedHeight(text, height_units)
          }
          if (key === 'weight') {
            mUnit = weightMunit(weight_units)
            if (weight_units === 'Metric')
              text = convertedWeight(text, weight_units)
          }
          if (text === true) text = 'Yes'
          if (text === false) text = 'No'
          return (
            <tr key={key}>
              <td>{value.label}</td>

              {fitHistory.length > 0 &&
                fitHistory.map((fit, index) => {
                  return (
                    <td
                      className={highlightChanges(fitHistory, bike, key, index)}
                      style={{ textAlign: 'right' }}
                      key={index}
                    >
                      {fit[key]}{' '}
                      {value.type === 'number' &&
                        value.munit !== 'none' &&
                        (value.munit || mUnit)}
                    </td>
                  )
                })}
              {showCurrentFit && (
                <td
                  className={highlightChanges(
                    fitHistory,
                    bike,
                    key,
                    fitHistory.length
                  )}
                  style={{ textAlign: 'right' }}
                >
                  {text}{' '}
                  {value.type === 'number' &&
                    value.munit !== 'none' &&
                    (value.munit || mUnit)}
                </td>
              )}
            </tr>
          )
        })}
        <tr style={{ backgroundColor: 'white' }}>
          <td> </td>
          {fitHistory.length > 0 &&
            fitHistory.map((fit, index) => {
              return (
                <td key={index} style={{ textAlign: 'right' }}>
                  <DeleteRecordButton
                    title='Delete Fit History?'
                    confirmationPrompt='To confirm deletion'
                    confirmationText='delete history'
                    confirmationButtonText='Delete Fit History'
                    tip={
                      <>
                        Delete Fit History for
                        <br /> {yearMonthDay(fit.date)}
                      </>
                    }
                    deleteFunction={deleteFunction}
                    recordId={index}
                  >
                    <p>
                      Delete Fit History for{' '}
                      <strong>{yearMonthDay(fit.date)}</strong>?{' '}
                    </p>
                    <p>This action cannot be undone.</p>
                  </DeleteRecordButton>
                </td>
              )
            })}
          <td> &nbsp;</td>
        </tr>
      </tbody>
    </Table>
  )
}

export default HistoryData
