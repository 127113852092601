import React from 'react'

import { Row, Col } from 'react-bootstrap'
import MainContentContainer from '../../layout/cards-containers/MainContentContainer'

import ActiveMessages from './ActiveMessages'
import KeyStats from './stats/KeyStats'

const AdminDashboard = ({ adminMessages, user, adminStats }) => {
  return (
    <MainContentContainer>
      <Row className='mt-3'>
        <Col>
          <ActiveMessages messages={adminMessages} />
          <KeyStats adminStats={adminStats} user={user} />
        </Col>
      </Row>
    </MainContentContainer>
  )
}

export default AdminDashboard
