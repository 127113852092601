import React from 'react'
import { Link } from 'react-router-dom'
import { Nav, Navbar, Container } from 'react-bootstrap'
import {
  TOOLBOX_ICON,
  TOOLS_ICON,
  RESOURCES_ICON
} from '../../utils/fontAwesomeDefaults'

const ToolsNav = () => {
  return (
    <Navbar className='mb-3' fill='true' variant='dark' bg='dark'>
      <Container>
        <Navbar.Brand>
          <i className={TOOLBOX_ICON + ' text-fitkit'} /> Tools and Resources
        </Navbar.Brand>

        <Nav>
          <Nav.Item>
            <Nav.Link as={Link} to='/tools-resources/tools'>
              <i className={TOOLS_ICON} /> Tools
            </Nav.Link>
          </Nav.Item>

          <Nav.Item>
            <Nav.Link as={Link} to='/tools-resources/resources'>
              <i className={RESOURCES_ICON} /> Resources
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default ToolsNav
