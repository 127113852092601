import api from '../../utils/api.js'
import {
  CREATE_STRIPE_CUSTOMER,
  CREATE_STRIPE_SUBSCRIPTION,
  STRIPE_CUSTOMER_LOADED,
  STRIPE_SUBSCRIPTION_LOADED,
  STRIPE_CARD_LOADED,
  CLEAR_STRIPE_DATA,
  UPDATE_STRIPE_CUSTOMER,
  CANCEL_STRIPE_SUBSCRIPTION,
  REACTIVATE_STRIPE_SUBSCRIPTION,
  CHANGE_PAYMENT_FREQUENCY
} from './types'
import { setAlert } from './alert'
import { loadUser } from './users'

export const clearStripeData = () => (dispatch) => {
  try {
    dispatch({ type: CLEAR_STRIPE_DATA })
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

export const createStripeCustomer = (customer) => async (dispatch) => {
  try {
    const res = await api.post('/stripe', customer)
    await api.put('/users', {
      name: customer.name,
      email: customer.email,
      stripeCustomerId: res.data.id
    })
    dispatch({
      type: CREATE_STRIPE_CUSTOMER,
      payload: res.data
    })
    return res.data
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

export const updateStripeCustomer = (customer) => async (dispatch) => {
  try {
    const res = await api.put('/stripe', customer)

    dispatch({
      type: UPDATE_STRIPE_CUSTOMER,
      payload: res.data
    })

    dispatch(setAlert('Payment Method Updated', 'success'))
    dispatch(loadUser())
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

export const updateStripeProfile = (customer) => async (dispatch) => {
  try {
    const res = await api.put('/stripe/update-profile', customer)

    dispatch({
      type: UPDATE_STRIPE_CUSTOMER,
      payload: res.data
    })

    //dispatch(setAlert('Payment Method Updated', 'success'))
    dispatch(loadUser())
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

export const createStripeSubscription = (customer) => async (dispatch) => {
  try {
    const res = await api.post('/stripe/create-new-subscription', customer)
    await api.put('/users', {
      name: customer.name,
      email: customer.email,
      stripeSubscriptionId: res.data.id
    })
    dispatch({
      type: CREATE_STRIPE_SUBSCRIPTION,
      payload: res.data
    })
    dispatch(setAlert('Plan Activated', 'success'))
    dispatch(loadUser())
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

export const cancelStripeSubscription =
  (subscriptionId) => async (dispatch) => {
    try {
      const res = await api.put('/stripe/cancel-subscription', {
        subscriptionId: subscriptionId
      })
      dispatch({
        type: CANCEL_STRIPE_SUBSCRIPTION,
        payload: res.data
      })
      dispatch(setAlert('Subscription Canceled', 'success'))
      dispatch(loadUser())
    } catch (err) {
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
      }
    }
  }

export const changePaymentFrequency =
  (subscriptionId, frequency) => async (dispatch) => {
    try {
      const res = await api.put('/stripe/change-payment-frequency', {
        subscriptionId,
        frequency
      })
      dispatch({
        type: CHANGE_PAYMENT_FREQUENCY,
        payload: res.data
      })
      dispatch(setAlert('Payment Frequency Updated', 'success'))
      //dispatch(loadUser())
    } catch (err) {
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
      }
    }
  }

export const reactivateStripeSubscription =
  (subscriptionId) => async (dispatch) => {
    try {
      const res = await api.put('/stripe/reactivate-subscription', {
        subscriptionId: subscriptionId
      })
      dispatch({
        type: REACTIVATE_STRIPE_SUBSCRIPTION,
        payload: res.data
      })
      dispatch(setAlert('Subscription Reactivated', 'success'))
      dispatch(loadUser())
    } catch (err) {
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
      }
    }
  }

export const createStripePaymentIntent = (amount) => async (dispatch) => {
  try {
    const res = await api.post('/stripe/create-payment-intent', amount)
    console.log(res.data)
  } catch (err) {}
}

export const loadStripeCustomerData = (customerId) => async (dispatch) => {
  try {
    const res = await api.get(`/stripe/customer/${customerId}`)
    dispatch({ type: STRIPE_CUSTOMER_LOADED, payload: res.data })

    dispatch(
      loadStripeCardData(
        res.data.invoice_settings.default_payment_method,
        customerId,
        res.data.default_source
      )
    )
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

export const loadStripeCardData =
  (paymentMethodId, customerId, defaultSource) => async (dispatch) => {
    if (paymentMethodId) {
      try {
        const res = await api.get(`/stripe/card/${paymentMethodId}`)
        dispatch({ type: STRIPE_CARD_LOADED, payload: res.data })
      } catch (err) {
        const errors = err.response.data.errors

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
        }
      }
    } else {
      try {
        const res = await api.get(
          `/stripe/card/by-customer/${customerId}/${defaultSource}`
        )
        dispatch({ type: STRIPE_CARD_LOADED, payload: res.data })
      } catch (err) {
        const errors = err.response.data.errors

        if (errors) {
          errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
        }
      }
    }
  }

export const loadStripeSubscriptionData =
  (subscriptionId) => async (dispatch) => {
    try {
      const res = await api.get(`/stripe/subscription/${subscriptionId}`)
      dispatch({ type: STRIPE_SUBSCRIPTION_LOADED, payload: res.data })
    } catch (err) {
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
      }
    }
  }

export const loadStripeSubscriptionList = () => async (dispatch) => {
  try {
    const res = await api.get(`/stripe/subscriptions/list`)
    //dispatch({ type: STRIPE_SUBSCRIPTION_LOADED, payload: res.data })
    return res.data
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}
