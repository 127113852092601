import { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import LinkButton from '../../layout/LinkButton'

import DashboardCard from '../../layout/cards-containers/DashboardCard'
import UserRow from './UserRow'
import NextPreviousFooter from '../../hook-components/NextPreviousFooter'
import QuickFitDashboardItemCounter from '../../quickfit/QuickFitDashboardItemCounter'

import useNextPrevious from '../../hooks/action-hooks/useNextPrevious'
import { BIKE_ICON, CYCLIST_ICON } from '../../../utils/fontAwesomeDefaults'

import { getIcon } from '../../../utils/generalFunctions'

const UserList = (props) => {
  const { adminStats } = props

  const { userList, customerList, bikeList } = adminStats

  const getModifiedUserList = () => {
    const list = userList.map((user) => {
      let statusSortValue
      const { status } = user.subscriptionData

      switch (status) {
        case 'active':
          statusSortValue = 1
          break
        case 'trialing':
          statusSortValue = 2
          break
        case 'never logged in':
        case 'admin approved':
          statusSortValue = 3
          break
        case 'possible spam':
          statusSortValue = 4
          break
        case 'profile modified':
          statusSortValue = 5
          break
        case 'canceled active':
          statusSortValue = 6
          break
        case 'canceled inactive':
          statusSortValue = 7
          break

        case 'active free user':
          statusSortValue = 8
          break
        case 'inactive free user':
          statusSortValue = 9
          break

        default:
          statusSortValue = 10
      }

      return {
        ...user,
        status,
        statusSortValue,
        customerCount: customerList.filter(
          (customer) => customer.createdBy === user._id
        ).length,
        bikeCount: bikeList.filter((bike) => bike.createdBy === user._id).length
      }
    })
    return list
  }

  const [modifiedUserList, setModifiedUserList] = useState(
    getModifiedUserList()
  )

  const [ascending, setAscending] = useState(true)

  const [sortedList, setSortedList] = useState(
    [...modifiedUserList].sort((a, b) => {
      return ascending
        ? a.statusSortValue - b.statusSortValue
        : b.statusSortValue - a.statusSortValue
    })
  )

  useEffect(() => {
    const updateLists = async () => {
      const newList = await getModifiedUserList([])

      setModifiedUserList(newList)
      setSortedList(
        [...newList].sort((a, b) => {
          return ascending
            ? a.statusSortValue - b.statusSortValue
            : b.statusSortValue - a.statusSortValue
        })
      )
    }
    if (userList) updateLists()
    //eslint-disable-next-line
  }, [userList])

  const nextPrevious = useNextPrevious(100, userList.length)

  const { start, stop, length } = nextPrevious

  if (userList.length !== length) nextPrevious.setcurrentLength(userList.length)
  if (stop > userList.length) nextPrevious.setStop(userList.length)

  const handleSort = (e) => {
    const id = e.currentTarget.id

    switch (id) {
      case 'customerCount':
      case 'bikeCount':
      case 'statusSortCount':
        setSortedList(
          [...modifiedUserList].sort((a, b) => {
            return ascending ? a[id] - b[id] : b[id] - a[id]
          })
        )
        break
      default:
        setSortedList(
          [...modifiedUserList].sort((a, b) => {
            if (a[id] < b[id]) return ascending ? -1 : 1
            if (a[id] > b[id]) return ascending ? 1 : -1
            return 0
          })
        )
    }

    setAscending(!ascending)
  }

  return (
    <>
      <DashboardCard
        title={
          <>
            User List
            <QuickFitDashboardItemCounter
              start={start}
              stop={stop}
              length={length}
            />
          </>
        }
        footer={
          <>
            <NextPreviousFooter {...nextPrevious} />
          </>
        }
      >
        <Table size='sm' style={{ fontSize: '.8rem' }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>
                <LinkButton id='created' onClick={handleSort}>
                  Joined
                </LinkButton>
              </th>
              <th>
                <LinkButton id='statusSortValue' onClick={handleSort}>
                  Status
                </LinkButton>
              </th>
              <th>
                <LinkButton id='customerCount' onClick={handleSort}>
                  {getIcon(CYCLIST_ICON)}
                </LinkButton>
              </th>
              <th>
                <LinkButton id='bikeCount' onClick={handleSort}>
                  {getIcon(BIKE_ICON)}
                </LinkButton>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedList.slice(start - 1, stop).map((user) => {
              return (
                <UserRow key={user._id} user={user} adminStats={adminStats} />
              )
            })}
          </tbody>
        </Table>
      </DashboardCard>
    </>
  )
}

export default UserList
