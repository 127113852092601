import useInputWithReset from '../../input-hooks/useInputWithReset'

import { frameToBarXYForm as form } from '../../../forms/form-objects/toolsFormObjects'

import {
  getInput,
  getValidation,
  getForm,
  applyOverrides
} from '../../input-hooks/inputFunctions'

const useFrameToBarXYForm = (record, fieldOverrides) => {
  applyOverrides('Bike Equipment', form, fieldOverrides)
  const keyArray = Object.keys(form)
  const i = []

  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )

  return getForm(keyArray, i)
}

export default useFrameToBarXYForm
