import React from 'react'
import { Row, Col, Image } from 'react-bootstrap'

const PreFitHeader = ({ preFitUser, shop }) => {
  let logo = '/favicon.ico'
  if (preFitUser.shop_owner)
    logo = `/api/shops/photo/${shop._id}?${new Date().getTime()}`
  return (
    <>
      <Row>
        <Col md='auto'>
          <Image style={{ maxHeight: '150px' }} src={logo} alt='Shop Logo' />
        </Col>
        <Col className='d-flex align-items-center'>
          {shop._id ? (
            <span>
              {shop.address2
                ? shop.name +
                  ' | ' +
                  shop.address +
                  ', ' +
                  shop.address2 +
                  ' | ' +
                  shop.phone
                : shop.name + ' | ' + shop.address + ' | ' + shop.phone}
            </span>
          ) : (
            <h3>Fit Kit Studio (Pre Bike Fit Interview Form)</h3>
          )}
        </Col>
      </Row>
      <hr />
    </>
  )
}

export default PreFitHeader
