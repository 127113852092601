import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import Tip from '../../layout/overlays/Tip'
import { usShort, calculateAge } from '../../../utils/dateFormats'
import {
  PHONE_ICON,
  EMAIL_ICON,
  CALENDAR_ICON,
  CALENDAR_EDIT_ICON
} from '../../../utils/fontAwesomeDefaults'

const CustomerHeader = ({
  firstName,
  lastName,
  birthDate,
  gender,
  phone,
  email,
  created,
  updated
}) => {
  const textArray = [
    firstName + ' ' + lastName,
    gender,
    'Age: ' + calculateAge(birthDate),
    <>
      <i className={PHONE_ICON} /> {phone}
    </>,
    <>
      <i className={EMAIL_ICON} /> {email}
    </>,
    <>
      <Tip message='Created'>
        <i className={CALENDAR_ICON} />
      </Tip>{' '}
      {usShort(created)}
    </>,
    <>
      <Tip message='Updated'>
        <i className={CALENDAR_EDIT_ICON} />
      </Tip>{' '}
      {usShort(updated)}
    </>

    /* 'Created: ' + usShort(created),
    'Updated: ' + usShort(updated) */
  ]
  return (
    <Card className='mb-3' body>
      <Row>
        {textArray.map((text, index) => {
          return (
            <Col key={index} xs='auto'>
              {index === 0 ? <strong>{text}</strong> : <>{text}</>}
            </Col>
          )
        })}
      </Row>
    </Card>
  )
}

export default CustomerHeader
