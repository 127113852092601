import { stackAndReachFromSaddleHeight } from '../lib/fitkit-js-functions-v3.mjs'

export const customerDataCSV = (cyclists, user, title) => {
  let csv =
    title +
    ' ' +
    user.name +
    ' as of ' +
    new Date().toISOString().substring(0, 10) +
    '\n'

  csv +=
    'Created By User ID,Customer ID,First Name,Last Name,Email,Phone,Zip Code,Birth Date,Gender,Inseam,Foot Length,Torso,Arm,Height,Weight,Shoulders,Sit Bones,Flexibility,Riding Style,Preconditions,Created,Last Updated,Notes,Confidential Notes\n'

  cyclists.forEach((cyclist) => {
    const profile = cyclist.cyclistProfile
    const bm = cyclist.bodyMeasurements
    const ss = cyclist.softScores
    csv +=
      cyclist.createdBy +
      ',' +
      cyclist._id +
      ',' +
      profile.firstName +
      ',' +
      profile.lastName +
      ',' +
      profile.email +
      ',' +
      profile.phone +
      ',' +
      profile.zipCode +
      ',' +
      new Date(profile.birthDate).toISOString().substring(0, 10) +
      ',' +
      profile.gender +
      ',' +
      bm.inseam +
      ',' +
      bm.footLength +
      ',' +
      bm.torso +
      ',' +
      bm.arm +
      ',' +
      bm.height +
      ',' +
      bm.weight +
      ',' +
      bm.shoulders +
      ',' +
      bm.sitBones +
      ',' +
      ss.flexibility +
      ',' +
      ss.ridingStyle +
      ',' +
      ss.preconditions +
      ',' +
      new Date(cyclist.created).toISOString().substring(0, 10) +
      ',' +
      new Date(cyclist.updated).toISOString().substring(0, 10) +
      ',"' +
      cyclist.notes +
      '","' +
      cyclist.confidentialNotes +
      '",\n'
  })
  return csv
}

export const bikeDataCSV = (bikes, user, title) => {
  let csv =
    title +
    ' ' +
    user.name +
    ' as of ' +
    new Date().toISOString().substring(0, 10) +
    '\n'
  csv +=
    'Created By User ID,Owned By Customer ID,Bike ID,Make,Model,Type,Frame Size,FrameReach,FrameStack,Effective Top Tube,Seat Post Offset,Saddle Make,Saddle Model,Saddle With,Crank Length,Pedal Type,Pedal Make/Model,Stem Length,Stem Type,Stem Angle,Spacers Below,Spacers Above,Handlebar Width,Handlebar Reach,Shifter Type,Brake Type,Saddle Height, Saddle Height (Bottom Bracket),Saddle Setback,Saddle Angle,Saddle Nose To Bar Center,Saddle Nose To Hood Trough,Saddle To Bar Drop,Handlebar Reach (HX),Handlebar Stack (HY),Handlebar Angle,Hood Angle,Cleat Model,Shoe Brand,Shoe Model,Shoe Size,Insoles,Cleat Adjustments,Foot/Pedal Notes,Bike Length,MTB Wheel Size,MTB Seat Post Type,MTB Saddle Nose To Grip End,MTB Saddle To Grip Center Drop/Rise,TT Basebar Width,TT Aerobar Type,TT Aerobar Make/Model,TT Extensions--Shape,TT Risers,TT Pads Make/Model,TT Saddle To Pad (center) Drop,TT Saddle Nose To Pad Rear,TT Saddle Nose To End of Extensions,TT Extensions Width At Clamps,TT Extensions Width At End,TT Extensions Angle,TT Pad Width,TT Pad X Reach (rear of pad),TT Pad X Reach (center of pad),TT Pad Y Stack (rear of pad),TT Basebar Reach (X),TT Basebar Stack (Y),TT Basebar Angle,Created,Last Updated,Notes,Confidential Notes\n'

  bikes.forEach((bike) => {
    csv +=
      bike.createdBy +
      ',' +
      bike.ownedBy +
      ',' +
      bike._id +
      ',' +
      bike.make +
      ',' +
      bike.model +
      ',' +
      bike.type +
      ',' +
      bike.frameSize +
      ',' +
      bike.frameReach +
      ',' +
      bike.frameStack +
      ',' +
      bike.effectiveTopTube +
      ',' +
      bike.seatPostOffset +
      ',' +
      bike.saddleMake +
      ',' +
      bike.saddleModel +
      ',' +
      bike.saddleWidth +
      ',' +
      bike.crankLength +
      ',' +
      bike.pedalType +
      ',' +
      bike.pedalMakeModel +
      ',' +
      bike.stemLength +
      ',' +
      bike.stemType +
      ',' +
      bike.stemAngle +
      ',' +
      bike.spacersBelow +
      ',' +
      bike.spacersAbove +
      ',' +
      bike.handlebarWidth +
      ',' +
      bike.handlebarReach +
      ',' +
      bike.shifterType +
      ',' +
      bike.brakeType +
      ',' +
      bike.saddleHeight +
      ',' +
      bike.saddleHeightBB +
      ',' +
      bike.saddleSetBack +
      ',' +
      bike.saddleAngle +
      ',' +
      bike.saddleNoseToBar +
      ',' +
      bike.saddleNoseToHood +
      ',' +
      bike.saddleToBarDrop +
      ',' +
      bike.handlebarReachHX +
      ',' +
      bike.handlebarStackHY +
      ',' +
      bike.handlebarAngle +
      ',' +
      bike.hoodAngle +
      ',' +
      bike.cleatModel +
      ',' +
      bike.shoeBrand +
      ',' +
      bike.shoeModel +
      ',' +
      bike.shoeSize +
      ',' +
      bike.insoles +
      ',"' +
      bike.cleatAdjustments +
      '","' +
      bike.cleatModifications +
      '",' +
      bike.bikeLength +
      ',' +
      bike.mtbWheelSize +
      ',' +
      bike.mtbSeatPostType +
      ',' +
      bike.mtbSaddleNoseToGripEnd +
      ',' +
      bike.mtbSaddleToGripCenterDropRise +
      ',' +
      bike.ttBasebarWidth +
      ',' +
      bike.ttAerobarType +
      ',' +
      bike.ttAerobarMakeModel +
      ',' +
      bike.ttExtensionsShape +
      ',' +
      bike.ttRisers +
      ',' +
      bike.ttPadsMakeModel +
      ',' +
      bike.ttSaddleToPadCenterDrop +
      ',' +
      bike.ttSaddleNoseToPadRear +
      ',' +
      bike.ttSaddleNoseToEndOfExtensions +
      ',' +
      bike.ttExtensionWidthAtClamps +
      ',' +
      bike.ttExtensionWidthAtEnd +
      ',' +
      bike.ttExtensionAngle +
      ',' +
      bike.ttPadWidth +
      ',' +
      bike.ttPadXReachRearOfPad +
      ',' +
      bike.ttPadXReachCenterOfPad +
      ',' +
      bike.ttPadYStackRearOfPad +
      ',' +
      bike.ttBasebarReachX +
      ',' +
      bike.ttBasebarStackY +
      ',' +
      bike.ttBasebarAngle +
      ',' +
      new Date(bike.created).toISOString().substring(0, 10) +
      ',' +
      new Date(bike.updated).toISOString().substring(0, 10) +
      ',"' +
      bike.notes +
      '","' +
      bike.confidentialNotes +
      '",\n'
  })
  return csv
}
export const userDataCSV = (users, user, title, subscriptionList) => {
  let csv =
    title +
    ' ' +
    user.name +
    ' as of ' +
    new Date().toISOString().substring(0, 10) +
    '\n'
  csv +=
    'User ID,Name,Email,Created,Last Updated, Shop Owner, Stripe Customer ID, Stripe Subscritpion ID, Subscription Status\n'

  users.forEach((user) => {
    const subscriptionData = subscriptionList.find(
      (subscription) => subscription.id === user.stripe_subscription_id
    )
    let status = 'not subscribed'

    if (subscriptionData) status = subscriptionData.status

    csv +=
      user._id +
      ',' +
      user.name +
      ',' +
      user.email +
      ',' +
      new Date(user.created).toISOString().substring(0, 10) +
      ',' +
      new Date(user.updated).toISOString().substring(0, 10) +
      ',' +
      user.shop_owner +
      ',' +
      user.stripe_customer_id +
      ',' +
      user.stripe_customer_id +
      ',' +
      status +
      '\n'
  })
  return csv
}

export const stackReachDataCSV = (stackReachData, title) => {
  let csv =
    title +
    ' ' +
    'Admin' +
    ' as of ' +
    new Date().toISOString().substring(0, 10) +
    '\n'

  csv +=
    'First Name, Last Name, Make, Model, Height, Inseam,Torso,Arm,ETT,FS,QS FS,Saddle Height,Stem,Actual Stack, Actual Reach, Race Stack, Min Stack, Max Stack, Race Reach,Min Reach, Max Reach, End Stack,End Min Stack, End Max Stack,End Reach,End Min Reach, End Max Reach\n'

  const getStackReachResults = (data) => {
    const results = stackAndReachFromSaddleHeight(
      data.customer.bodyMeasurements.inseam,
      data.customer.bodyMeasurements.torso,
      data.customer.bodyMeasurements.arm,
      data.customerBikes[0]?.saddleHeight / 10
    )
    return results
  }

  stackReachData.forEach((data) => {
    const profile = data.customer.cyclistProfile
    const bm = data.customer.bodyMeasurements
    const bike = data.customerBikes[0]
    const sz = data.sizing
    const results = getStackReachResults(data)

    csv +=
      profile.firstName +
      ',' +
      profile.lastName +
      ',' +
      bike.make +
      ',' +
      bike.model +
      ',' +
      bm.height +
      ',' +
      bm.inseam +
      ',' +
      bm.torso +
      ',' +
      bm.arm +
      ',' +
      bike.effectiveTopTube +
      ',' +
      bike.frameSize +
      ',' +
      sz.frameSize +
      ',' +
      bike.saddleHeight +
      ',' +
      bike.stemLength +
      ',' +
      bike.frameStack +
      ',' +
      bike.frameReach +
      ',' +
      results.targetStack +
      ',' +
      results.minStack +
      ',' +
      results.maxStack +
      ',' +
      results.targetReach +
      ',' +
      results.minReach +
      ',' +
      results.maxReach +
      ',' +
      results.enduranceTargetStack +
      ',' +
      results.enduranceMinStack +
      ',' +
      results.enduranceMaxStack +
      ',' +
      results.enduranceTargetReach +
      ',' +
      results.enduranceMinReach +
      ',' +
      results.enduranceMaxReach +
      ',' +
      '\n'
  })
  return csv
}
