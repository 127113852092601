//import customer fields

import {
  firstName,
  lastName,
  email,
  phone,
  zipCode,
  birthDate,
  gender,
  height,
  weight,
  footLength,
  inseam,
  torso,
  arm,
  shoulders,
  sitBones,
  age,
  flexibility,
  ridingStyle,
  preconditions,
  notes,
  confidentialNotes
} from '../field-objects/customerFields'

import * as customerFields from '../field-objects/customerFields'
export const customerForm = customerFields

//export customer form

export const customerProfileForm = {
  firstName,
  lastName,
  email,
  phone,
  zipCode,
  birthDate,
  gender
}

export const bodyMeasurementsForm = {
  height,
  weight,
  footLength,
  inseam,
  torso,
  arm,
  shoulders,
  sitBones
}

export const quickSizeMeasurementsForm = {
  footLength,
  inseam,
  torso,
  arm,
  shoulders,
  sitBones,
  age,
  flexibility,
  ridingStyle,
  preconditions
}

export const physicalProfileForm = {
  flexibility,
  ridingStyle,
  preconditions
}

export const customerNotesForm = {
  notes,
  confidentialNotes
}

export const confidentialNotesForm = {
  confidentialNotes
}

export const sizingNotesForm = {
  notes
}
