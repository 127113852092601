import React from 'react'
import Tip from '../overlays/Tip'
import { Table } from 'react-bootstrap'
import { utcToutc } from '../../../utils/dateFormats'
import {
  convertedHeight,
  convertedWeight,
  heightMunit,
  weightMunit
} from '../../../utils/heightWeight'

const TableData = ({ dataForm, dataObject, user, fontStyle }) => {
  if (!user || !dataForm || !dataObject) return null
  const { height_units, weight_units } = user.preferences

  return (
    <Table
      striped
      borderless
      size='sm'
      style={fontStyle || { fontSize: '.8rem' }}
    >
      {/* <colgroup>
              <col style={{ width: '30%' }} />
        <col style={{ width: '50%' }} />
        <col style={{ width: '20%' }} />
      </colgroup> */}
      <tbody>
        {Object.entries(dataForm).map(([key, value]) => {
          let text = dataObject[key]
          let mUnit = 'mm.'
          if (key === 'birthDate') text = utcToutc(text)
          if (key === 'height') {
            mUnit = heightMunit(height_units)
            if (height_units === 'Metric')
              text = convertedHeight(text, height_units)
          }
          if (key === 'weight') {
            mUnit = weightMunit(weight_units)
            if (weight_units === 'Metric')
              text = convertedWeight(text, weight_units)
          }
          if (text === true) text = 'Yes'
          if (text === false) text = 'No'

          return (
            <tr key={key}>
              <td>
                {value.tip ? (
                  <Tip message={value.tip} placement='top'>
                    <span style={{ cursor: 'help' }}>{value.label}</span>
                  </Tip>
                ) : (
                  <>{value.label}</>
                )}
              </td>

              <td style={{ textAlign: 'right' }}>
                {/* {dataObject[key]}{' '} */}
                {text}{' '}
                {value.type === 'number' &&
                  value.munit !== 'none' &&
                  (value.munit || mUnit)}
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default TableData
