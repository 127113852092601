import { useState, useEffect } from 'react'
import store from '../../../redux/store'
import { countBikes, countCustomers } from '../../../redux/actions/admindata'
import useAdminUserList from './useAdminUserList'
import useAdminCustomerList from './useAdminCustomerList'
import useAdminBikeList from './useAdminBikeList'
import useAdminShopList from './useAdminShopList'
import {
  todayStamp,
  daysAgoStamp,
  beginYearStamp,
  beginLastYearStamp,
  fksInceptionStamp,
  dateToStamp,
  yesterday,
  getMonthDifference
} from '../../../utils/dateFormats'

const useAdminStats = () => {
  const [statsLoaded, setStatsLoaded] = useState(false)
  const [countsLoaded, setCountsLoaded] = useState(false)
  const [bikeCount, setBikeCount] = useState()
  const [userCount, setUserCount] = useState()
  const [customerCount, setCustomerCount] = useState()
  const [userData, setUserData] = useState([])
  const [userDataLoaded, setUserDataLoaded] = useState(false)
  const [stats, setStats] = useState({})

  const adminUserList = useAdminUserList()
  const { userList, subscriptionList, removeUserFromList, updateUserInList } =
    adminUserList

  const adminCustomerList = useAdminCustomerList()
  const { customerList } = adminCustomerList

  const adminBikeList = useAdminBikeList()
  const { bikeList } = adminBikeList

  const adminShopList = useAdminShopList()
  const { shopList, shopListLoaded } = adminShopList

  const recordsCreated = (records, begin, end) => {
    return records.filter(
      (record) =>
        dateToStamp(record.created) >= begin &&
        dateToStamp(record.created) <= end
    ).length
  }

  const newSubscriptions = (records, begin, end) => {
    return records.filter(
      (record) =>
        record.subscriptionData.created * 1000 >= begin &&
        record.subscriptionData.created * 1000 <= end
    ).length
  }

  const canceledSubscriptions = (records, begin, end) => {
    return records.filter(
      (record) =>
        record.subscriptionData.canceled_at * 1000 >= begin &&
        record.subscriptionData.canceled_at * 1000 <= end
    ).length
  }

  useEffect(() => {
    if (userList && subscriptionList) {
      const userData = userList.map((user) => {
        let subscriptionData = subscriptionList.find(
          (subscription) => subscription.id === user.stripe_subscription_id
        ) || { status: 'not subscribed', created: 0, canceled_at: 0 }

        if (subscriptionData.status === 'not subscribed') {
          if (
            !user.last_login &&
            user.updated <= yesterday().toISOString() &&
            new Date(user.updated).toISOString().substring(10) !==
              new Date(user.created).toISOString().substring(10)
          )
            subscriptionData.status = 'profile modified'

          if (subscriptionData.status === 'not subscribed' && user.last_login) {
            if (getMonthDifference(user.last_login) > 12)
              subscriptionData.status = 'inactive free user'
            else subscriptionData.status = 'active free user'
          }

          // const launchDate = new Date('2022/10/31').toISOString()

          if (
            subscriptionData.status !== 'profile modified' &&
            !user.last_login
          )
            subscriptionData.status = 'never logged in'

          if (subscriptionData.status === 'canceled') {
            if (
              user.last_login >
                new Date(subscriptionData.canceled_at).toISOString() &&
              getMonthDifference(user.last_login) < 12
            )
              subscriptionData.status = 'canceled active'
            else subscriptionData.status = 'canceled inactive'
          }
          if (
            subscriptionData.status === 'never logged in' &&
            user.approved_user
          )
            subscriptionData.status = 'admin approved'
        }

        return {
          ...user,
          subscriptionData
        }
      })
      setUserData(userData)
      setUserCount(userData.length)
      setUserDataLoaded(true)
    }
  }, [userList, subscriptionList])

  useEffect(() => {
    const fetchStats = async () => {
      const bikeCount = await store.dispatch(countBikes())
      setBikeCount(bikeCount)
      const customerCount = await store.dispatch(countCustomers())
      setCustomerCount(customerCount)
      setCountsLoaded(true)
    }
    fetchStats()
  }, [])

  useEffect(() => {
    if (
      userData !== undefined &&
      customerList !== undefined &&
      bikeList !== undefined
    ) {
      const getStats = (begin, end) => {
        return {
          usersCreated: recordsCreated(userData, begin, end),
          newSubscriptions: newSubscriptions(userData, begin, end),
          canceledSubscriptions: canceledSubscriptions(userData, begin, end),
          customersCreated: recordsCreated(customerList, begin, end),
          bikesCreated: recordsCreated(bikeList, begin, end)
        }
      }
      const today = getStats(daysAgoStamp(1), todayStamp())
      const sevenDays = getStats(daysAgoStamp(7), todayStamp())
      const thirtyDays = getStats(daysAgoStamp(30), todayStamp())
      const ytd = getStats(beginYearStamp(), todayStamp())
      const lastYear = getStats(beginLastYearStamp(), beginYearStamp())
      const itd = getStats(fksInceptionStamp(), todayStamp())

      setStats({ today, sevenDays, thirtyDays, ytd, lastYear, itd })
      setStatsLoaded(true)
    }
  }, [userData, bikeList, customerList])

  return {
    userList: userData,
    removeUserFromList,
    updateUserInList,
    userCount: userCount,
    subscriptionList,
    bikeCount,
    customerCount,
    customerList,
    bikeList,
    shopList,

    activeSubscriptions: userData.filter(
      (user) => user.subscriptionData.status === 'active'
    ).length,
    trialSubscriptions: userData.filter(
      (user) => user.subscriptionData.status === 'trialing'
    ).length,
    canceledSubscriptions: userData.filter(
      (user) => user.subscriptionData.status === 'canceled'
    ).length,
    notSubscribed: userData.filter(
      (user) => user.subscriptionData.status === 'not subscribed'
    ).length,
    activeFreeUsers: userData.filter(
      (user) => user.subscriptionData.status === 'active free user'
    ).length,
    inActiveFreeUsers: userData.filter(
      (user) => user.subscriptionData.status === 'inactive free user'
    ).length,
    neverLoggedIn: userData.filter(
      (user) => user.subscriptionData.status === 'never logged in'
    ).length,
    profileModified: userData.filter(
      (user) => user.subscriptionData.status === 'profile modified'
    ).length,
    canceledActive: userData.filter(
      (user) => user.subscriptionData.status === 'canceled active'
    ).length,
    canceledInactive: userData.filter(
      (user) => user.subscriptionData.status === 'canceled inactive'
    ).length,

    stats,
    statsLoaded: userDataLoaded && statsLoaded && countsLoaded && shopListLoaded
  }
}

export default useAdminStats
