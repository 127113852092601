import React from 'react'
import { Table } from 'react-bootstrap'

import DashboardCard from '../../layout/cards-containers/DashboardCard'
import CustomerRow from './CustomerRow'
import NextPreviousFooter from '../../hook-components/NextPreviousFooter'
import QuickFitDashboardItemCounter from '../../quickfit/QuickFitDashboardItemCounter'

import useNextPrevious from '../../hooks/action-hooks/useNextPrevious'

const CustomerList = ({ adminStats: { customerList, userList } }) => {
  const nextPrevious = useNextPrevious(100, customerList.length)

  const { start, stop, length } = nextPrevious

  if (customerList.length !== length)
    nextPrevious.setcurrentLength(customerList.length)
  if (stop > customerList.length) nextPrevious.setStop(customerList.length)

  return (
    <>
      <DashboardCard
        title={
          <>
            Customer List
            <QuickFitDashboardItemCounter
              start={start}
              stop={stop}
              length={length}
            />
          </>
        }
        footer={
          <>
            <NextPreviousFooter {...nextPrevious} />
          </>
        }
      >
        <Table size='sm' style={{ fontSize: '.8rem' }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Gender</th>
              <th>Age</th>
              <th>Customer of</th>
            </tr>
          </thead>
          <tbody>
            {customerList.slice(start - 1, stop).map((customer) => {
              return (
                <CustomerRow
                  key={customer._id}
                  customer={customer}
                  userList={userList}
                />
              )
            })}
          </tbody>
        </Table>
      </DashboardCard>
    </>
  )
}

export default CustomerList
