import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { CREDIT_CARD_ICON } from '../../../utils/fontAwesomeDefaults'
import UpdatePaymentModal from './UpdatePaymentModal'

const UpdatePaymentButton = () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Button
        className='shadow-none'
        variant='link-dark'
        onClick={() => setShowModal(true)}
      >
        <i className={CREDIT_CARD_ICON}> </i> Update
      </Button>
      <UpdatePaymentModal showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}

export default UpdatePaymentButton
