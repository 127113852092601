import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import MainHeading from '../layout/MainHeading'

import { POLICY_ICON } from '../../utils/fontAwesomeDefaults'

import SEO from '../seo/SEO'

const TermsOfUse = () => {
  useEffect(() => {
    document.body.classList.add('bodyPad')

    return () => {
      document.body.classList.remove('bodyPad')
    }
  }, [])
  return (
    <>
      <SEO
        title='Fit Kit Studio | Terms of Use'
        description='Fit Kit Studio Terms of Use'
        type='website'
        name='Fit Kit Systems'
      />
      <MainHeading
        subHeadingIcon={POLICY_ICON}
        heading='Terms of Use'
        subheading='(Last Updated November 25, 2022)'
      />
      <p>
        Welcome, and thank you for your interest in Fit Kit Studio, a service
        provided by GoBike LLC d/b/a Fit Kit Systems (“Fit Kit Systems,” “we,”
        or “us”) and our website at fitkitstudio.com, along with our related
        websites, networks, applications, mobile applications, and other
        services provided by us (collectively, the “Service”). These Terms of
        Use are a legally binding contract between you and Fit Kit Systems
        regarding your use of the Service.
      </p>
      <strong>Please read the following terms carefully:</strong>
      <p>
        By signing up for access to fitkitstudio.com or otherwise accessing the
        Service, you agree that you have read and understood, and, as a
        condition to your use of the Service, you agree to be bound by, the
        following terms and conditions, including our privacy policy and any
        additional terms and policies we may provide from time to time
        (together, these "Terms"). If you are not eligible, or do not agree to
        the Terms, then you do not have our permission to use the Service. Your
        use of the Service, and Fit Kit System's provision of the Service to
        you, constitutes an agreement by Fit Kit Systems and you to be bound by
        these Terms.
      </p>
      <p>
        Please read our privacy policy{' '}
        <Link to='/privacy-policy'>(Privacy Policy)</Link> carefully for
        information relating to our collection, use, storage and disclosure of
        your personal information. The Privacy Policy is incorporated by this
        reference into, and made a part of, these Terms.{' '}
      </p>

      <ol className='fks-ol'>
        <strong>
          <li>Fit Kit Studio Service Overview</li>
        </strong>
        <p>
          Fit Kit Studio is a web application for bike sizing and bike fit
          documentation. The Service includes a free tool, Quick Size, which is
          free of charge to all registered users. The Service includes other
          premium features, which are available only to paid subscribers.
        </p>
        <strong>
          <li>Eligibility</li>
        </strong>
        <p>
          By agreeing to these Terms, you represent and warrant to us that: (a)
          you are at least 18 years old; (b) you have not previously been
          suspended or removed from the Service; and (c) your registration and
          your use of the Service is in compliance with any and all applicable
          laws and regulations. If you are an entity, organization, or company,
          the individual accepting these Terms on your behalf represents and
          warrants that they have authority to bind you to these Terms and
          references to you herein (and all of your obligations hereunder) will
          refer to such entity and any individual using the Service on such
          entity’s behalf.
        </p>
        <strong>
          <li>Accounts and Registration</li>
        </strong>
        <p>
          To access most features of the Service, you must register for an
          account. When you register for an account, you may be required to
          provide us with some information about yourself, such as your name,
          email address, or other contact information. You agree that the
          information you provide to us is accurate and that you will keep it
          accurate and up-to-date at all times. When you register, you will be
          asked to provide a password. You are solely responsible for
          maintaining the confidentiality of your account and password, and you
          accept responsibility for all activities that occur under your
          account. If you believe that your account is no longer secure, then
          you must immediately notify us at{' '}
          <a href='mailto:studio@fitkitsystems.com'>studio@fitkitsystems.com</a>
          .
        </p>
        <strong>
          <li>General Payment Terms</li>
        </strong>
        <p>
          Premium features of the Service will require you to pay fees upon
          registering for the applicable premium service. Before you pay any
          fees, you will have an opportunity to review and accept the fees that
          you will be charged. All fees are in U.S. Dollars and are
          non-refundable. Fees may vary based on the plan.
        </p>
        <ol className='fks-ol' type='a'>
          <strong>
            <li>Price</li>
          </strong>
          <p>
            We reserve the exclusive right to determine pricing for the Service.
            We will make reasonable efforts to keep pricing information
            published on the website up to date. We encourage you to check our
            website periodically for current pricing information, located{' '}
            <Link to='/product/quick-fit'>here.</Link>
          </p>
          <p>
            We may change the fees for any feature of the Service, including
            additional fees or charges, if we give you advance notice of changes
            before they apply. In our sole discretion, we may make promotional
            offers with different features and different pricing to any of our
            customers. These promotional offers, unless made to you, will not
            apply to your offer or these Terms.
          </p>
          <strong>
            <li>Authorization</li>
          </strong>
          <p>
            You authorize us to charge all sums for the orders that you make and
            any level of Service you select as described in these Terms or
            published by us, to the payment method specified in your account. If
            you pay any fees with a credit card, we may seek pre-authorization
            of your credit card account prior to your purchase to verify that
            the credit card is valid and has the necessary funds or credit
            available to cover your purchase.
          </p>
          <strong>
            <li>Subscription Service and Cancellation Policy</li>
          </strong>
          <p>
            The Service may include automatically recurring payments for
            periodic charges (“Subscription Service”). If you activate a
            Subscription Service, you authorize us to periodically charge, on a
            going-forward basis and until cancelation of either the recurring
            payments or your account, all accrued sums on or before the payment
            due date for the accrued sums. The “Subscription Billing Date” is
            the date when you purchase your first subscription to the Service.
            For information on the “Subscription Fee”, please see our{' '}
            <Link to='/product/quick-fit'>pricing page.</Link>. Your account
            will be charged automatically on the Subscription Billing Date all
            applicable fees for the next subscription period. The subscription
            will continue unless and until you cancel your subscription or we
            terminate it. You must cancel your subscription before it renews in
            order to avoid billing of the next periodic Subscription Fee to your
            account. We will bill the periodic Subscription Fee to the payment
            method you provide to us during registration (or to a different
            payment method if you change your payment information). You may
            cancel the Subscription Service by accessing your account settings
            and clicking on the "Manage Subscription" option or by contacting us
            at:{' '}
            <a href='mailto:studio@fitkitsystems.com'>
              studio@fitkitsystems.com
            </a>
            . When your Subscription Service ends, your account will remain and
            you can continue to use the free tool, Quick Size, free of charge.
            You can purchase a new subscription at any time without opening a
            new account, although the subscription fees may have increased. You
            can delete your account at any time. If you delete your account, you
            will no longer have access to the customer data entered for that
            account.
          </p>
          <strong>
            <li>Free Trials</li>
          </strong>
          <p>
            Your access to the Service may start with a free trial subscription
            for premium features. The free trial period for any subsciption will
            last for the period of time specified when you signed up or were
            otheriwse offered a free trial. If you begin your subscription with
            a free trial, we will begin charging you automatically the
            applicable Subscription Fee at the end of the free trial period of
            your subscription unless you cancel your subscription prior to the
            end of the free trial period. Your payment method will be authorized
            for an initial subscription payment as soon as you register for a
            free trial. In some instances, your available balance or credit
            limit may be reduced to reflect the authorization; however, no
            charges will be made against the payment method if you cancel prior
            to the end of your free trial period.
          </p>
          <strong>
            <li>Delinquent Accounts</li>
          </strong>
          <p>
            We may suspend or terminate access to the Service for any account
            for which any amount is due but unpaid. In addition to the amount
            due for the Service, a delinquent account will be charged with fees
            or charges that are incidental to any chargeback or collection of
            any the unpaid amount, including collection fees.
          </p>
        </ol>
        <strong>
          <li>Licenses</li>
        </strong>
        <ol className='fks-ol' type='a'>
          <strong>
            <li>Permission to Use</li>
          </strong>
          <p>
            Subject to your complete and ongoing compliance with these Terms,
            Fit Kit Systems grants you limited, non-transferable,
            non-sublicensable, revocable permission to access and use the
            Service for your personal, internal use during the Term at the level
            of service for which you have paid all applicable Fees.
          </p>
          <strong>
            <li>Restrictions</li>
          </strong>
          <p>
            Except and solely to the extent such a restriction is impermissible
            under applicable law, you may not: (i) reproduce, distribute,
            publicly display, or publicly perform the Service; (ii) make
            modifications to the Service; or (iii) interfere with or circumvent
            any feature of the Service, including any security or access control
            mechanism. If you are prohibited under applicable law from using the
            Service, you may not use it. You may not use the Service on behalf
            of any third party, or in a service bureau or similar capacity.
          </p>
          <strong>
            <li>Feedback</li>
          </strong>
          <p>
            If you choose to provide input or suggestions regarding problems
            with or proposed modifications or improvements to the Service
            (“Feedback”), then you hereby grant Fit Kit Systems an unrestricted,
            perpetual, irrevocable, non-exclusive, fully-paid, royalty-free
            right to exploit the Feedback in any manner and for any purpose,
            including to improve the Service and create other products and
            services.
          </p>
        </ol>
        <strong>
          <li>Ownership</li>
        </strong>
        <p>
          {' '}
          The Service is owned and operated by Fit Kit Systems. The visual
          interfaces, graphics, design, compilation, information, data, computer
          code (including source code or object code), products, software,
          services, templates, and all other elements of the Service
          (“Materials”) provided by Fit Kit Systems are protected by
          intellectual property and other laws. All Materials included in the
          Service are the property of Fit Kit Systems or its third party
          licensors. Except as expressly authorized by Fit Kit Systems, you may
          not make use of the Materials. We reserve all rights to the Materials
          not granted expressly in these Terms.
        </p>
        <strong>
          <li>User Content</li>
        </strong>
        <ol className='fks-ol' type='a'>
          <strong>
            <li>User Content Generally</li>
          </strong>
          Certain features of the Service may permit users to upload content to
          the Service, including photos, images, logos, data, text, and other
          types of works (“User Content”) and to publish User Content on the
          Service. You retain any copyright and other proprietary rights that
          you may hold in the User Content that you post to the Service.
          However, if you upload User Content, you must give us certain
          permissions.
          <strong>
            <li>Limited License Grant to Fit Kit Systems</li>
          </strong>
          By providing User Content to or via the Service, you grant Fit Kit
          Systems a worldwide, non-exclusive, royalty-free, fully paid right and
          license (with the right to sublicense) to host, store, transfer,
          display, perform, reproduce, modify for the purpose of formatting for
          display, and distribute your User Content, in whole or in part, in any
          media formats and through any media channels now known or hereafter
          developed.
          <strong>
            <li>User Content Disclaimer</li>
          </strong>
          <p>
            Fit Kit Systems disclaims any and all liability in connection with
            User Content. You are solely responsible for your User Content and
            the consequences of providing User Content via the Service. We are
            under no obligation to edit or control User Content that you or
            other users post or publish, and will not be in any way responsible
            or liable for User Content. We may, however, at any time and without
            prior notice, screen, remove, edit, or block any User Content that
            in our sole judgment violates these Terms or is otherwise
            objectionable. You understand that when using the Service you may be
            exposed to User Content from a variety of sources and acknowledge
            that User Content may be inaccurate, offensive, indecent, or
            objectionable. You agree to waive, and do waive, any legal or
            equitable right or remedy you have or may have against Fit Kit
            Systems with respect to User Content. If notified by a user or
            content owner that User Content allegedly does not conform to these
            Terms, we may investigate the allegation and determine in our sole
            discretion whether to remove the User Content, which we reserve the
            right to do at any time and without notice. For clarity, Fit Kit
            Systems does not permit copyright-infringing activities on the
            Service.
          </p>
        </ol>
        <strong>
          <li>Prohibited Conduct</li>
        </strong>
        <p>By using the Service you agree not to:</p>
        <ul>
          <li>
            use the Service for any illegal purpose or in violation of any
            local, state, national, or international law;
          </li>
          <li>
            violate, or encourage others to violate, any right of a third party,
            including by infringing or misappropriating any third party
            intellectual property right;
          </li>
          <li>
            interfere with security-related features of the Service, including
            by: (i) disabling or circumventing features that prevent or limit
            use or copying of any content; (ii) reverse engineering or otherwise
            attempting to discover the source code of any portion of the Service
            except to the extent that the activity is expressly permitted by
            applicable law; or (iii) hacking, password “mining” or using any
            other illegitimate means of interference;
          </li>
          <li>modify or create derivatives of any part of the Service;</li>
          <li>
            interfere with the operation of the Service or any user’s enjoyment
            of the Service, including by: (i) uploading or otherwise
            disseminating any virus, adware, spyware, worm, or other malicious
            code; (ii) making any unsolicited offer or advertisement to another
            user of the Service; (iii) collecting personal information about
            another user or third party without consent; or (iv) interfering
            with or disrupting any network, equipment, or server connected to or
            used to provide the Service;
          </li>
          <li>
            perform any fraudulent activity including impersonating any person
            or entity, claiming a false affiliation, accessing any other Service
            account without permission, or falsifying your age or date of birth;
          </li>
          <li>
            take action that imposes an unreasonable or disproportionately large
            load on the systems or networks used to operate the Service, or any
            systems or networks connected to the Service or Fit Kit Systems;
          </li>
          <li>
            sell or otherwise transfer the access granted under these Terms or
            any Materials (as defined in Section 6) or any right or ability to
            view, access, or use any Materials; or
          </li>
          <li>
            attempt to do any of the acts described in this Section 8 or assist
            or permit any person in engaging in any of the acts described in
            this Section 8.
          </li>
        </ul>
        <strong>
          <li>Modification of these Terms</li>
        </strong>
        <p>
          We reserve the right to change these Terms on a going-forward basis at
          any time. Please check these Terms periodically for changes.
          Modifications will become effective upon the earlier of (a) your
          acceptance of the modified Terms, (b) your use of the Service with
          actual knowledge of the modified Terms, or (c) thirty (30) days
          following our publication of the modified Terms through the Service.
          Except as expressly permitted in this Section 9, these Terms may be
          amended only by a written agreement signed by authorized
          representatives of the parties to these Terms. Disputes arising under
          these Terms will be resolved in accordance with the version of these
          Terms that was in effect at the time the dispute arose.
        </p>
        <strong>
          <li>Term, Termination and Modification of the Service</li>
        </strong>
        <ol className='fks-ol' type='a'>
          <strong>
            <li>Term</li>
          </strong>
          <p>
            These Terms are effective beginning when you accept the Terms or
            first download, install, access, or use the Service, and ending when
            terminated as described in Section 10(b).
          </p>
          <strong>
            <li>Termination</li>
          </strong>
          <p>
            If you violate any provision of these Terms, your authorization to
            access the Service and these Terms automatically terminate. In
            addition, Fit Kit Systems may, at its sole discretion, terminate
            these Terms or your account on the Service, or suspend or terminate
            your access to the Service, at any time for any reason or no reason,
            with or without notice. Fit Kit Systems reserves the right to delete
            your account if you have not purchased a subscription and you do not
            access the Service for a period of 12 months. You may terminate your
            account and these Terms at any time as provided in Section 4(c) or
            by contacting customer service at{' '}
            <a href='mailto:studio@fitkitsystems.com'>
              studio@fitkitsystems.com
            </a>
            .
          </p>
          <strong>
            <li>Effect of Termination</li>
          </strong>
          <p>
            Upon termination of these Terms: (i) your license rights will
            terminate and you must immediately cease all use of the Service;
            (ii) you will no longer be authorized to access your account or the
            Service; (iii) you must pay Fit Kit Systems any unpaid amount that
            was due prior to termination; and (iv) all payment obligations
            accrued prior to termination and under these Terms will survive.
          </p>
          <strong>
            <li>Modification of the Service</li>
          </strong>
          <p>
            {' '}
            Fit Kit Systems reserves the right to modify or discontinue the
            Service at any time (including by limiting or discontinuing certain
            features of the Service), temporarily or permanently, without notice
            to you. Fit Kit Systems will have no liability for any change to the
            Service or any suspension or termination of your access to or use of
            the Service. Premium service fees are not refundable.
          </p>
          <strong>
            <li>Indemnity</li>
          </strong>
          <p>
            You are responsible for your use of the Service, and you will defend
            and indemnify Fit Kit Systems and its officers, directors,
            employees, consultants, affiliates, subsidiaries and agents
            (together, the “Fit Kit Entities”) from and against every claim
            brought by a third party, and any related liability, damage, loss,
            and expense, including reasonable attorneys’ fees and costs, arising
            out of or connected with: (a) your use of, or misuse of, the
            Service; (b) your violation of any portion of these Terms, any
            representation, warranty, or agreement referenced in these Terms, or
            any applicable law or regulation; (c) your violation of any third
            party right, including any intellectual property right or publicity,
            confidentiality, other property, or privacy right; or (d) any
            dispute or issue between you and any third party. We reserve the
            right, at our own expense, to assume the exclusive defense and
            control of any matter otherwise subject to indemnification by you
            (without limiting your indemnification obligations with respect to
            that matter), and in that case, you agree to cooperate with our
            defense of those claims.
          </p>
        </ol>
        <strong>
          <li>Disclaimer; No Warranties</li>
        </strong>
        <p>
          THE SERVICE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE
          SERVICE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS. FIT KIT
          SYSTEMS DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
          IMPLIED, RELATING TO THE SERVICE AND ALL MATERIALS AND CONTENT
          AVAILABLE THROUGH THE SERVICE, INCLUDING: (A) ANY IMPLIED WARRANTY OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
          ENJOYMENT, OR NON-INFRINGEMENT; AND (B) ANY WARRANTY ARISING OUT OF
          COURSE OF DEALING, USAGE, OR TRADE. FIT KIT SYSTEMS DOES NOT WARRANT
          THAT THE SERVICE OR ANY PORTION OF THE SERVICE, OR ANY MATERIALS OR
          CONTENT OFFERED THROUGH THE SERVICE, WILL BE UNINTERRUPTED, SECURE, OR
          FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND FIT KIT
          SYSTEMS DOES NOT WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.
        </p>
        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM THE SERVICE OR FIT KIT ENTITIES OR ANY MATERIALS OR CONTENT
          AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING ANY
          OF THE FIT KIT ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY STATED IN
          THESE TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT
          FROM THE SERVICE AND YOUR DEALING WITH ANY OTHER SERVICE USER. YOU
          UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE SERVICE AT YOUR
          OWN DISCRETION AND RISK, AND THAT WE ARE NOT RESPONSIBLE FOR ANY
          DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE
          DEVICE USED IN CONNECTION WITH THE SERVICE) OR ANY LOSS OF DATA,
          INCLUDING USER CONTENT.
        </p>
        <p>
          HOWEVER, FIT KIT SYSTEMS DOES NOT DISCLAIM ANY WARRANTY OR OTHER RIGHT
          THAT FIT KIT SYSTEMS IS PROHIBITED FROM DISCLAIMING UNDER APPLICABLE
          LAW.
        </p>
        <strong>
          <li>Limitation of Liabilty</li>
        </strong>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE FIT KIT
          ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF
          PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR
          RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS OR
          USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE, WHETHER
          BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR
          ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY FIT KIT ENTITY HAS BEEN
          INFORMED OF THE POSSIBILITY OF DAMAGE.
        </p>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE
          FIT KIT ENTITIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO
          THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE SERVICE OR
          OTHERWISE UNDER THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE,
          IS LIMITED TO THE GREATER OF: (A) THE AMOUNT YOU HAVE PAID TO FIT KIT
          SYSTEMS FOR ACCESS TO AND USE OF THE SERVICE IN THE 12 MONTHS PRIOR TO
          THE EVENT OR CIRCUMSTANCE GIVING RISE TO CLAIM; OR (B) $100.
        </p>
        <p>
          EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF
          LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS
          INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER
          THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF
          THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE
          AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS. THE
          LIMITATIONS IN THIS SECTION 15 WILL APPLY EVEN IF ANY LIMITED REMEDY
          FAILS OF ITS ESSENTIAL PURPOSE.
        </p>
        <strong>
          <li>General Terms</li>
        </strong>
        <p>
          These Terms, together with the Privacy Policy and any other agreements
          expressly incorporated by reference into these Terms, are the entire
          and exclusive understanding and agreement between you and Fit Kit
          Systems regarding your use of the Service. You may not assign or
          transfer these Terms or your rights under these Terms, in whole or in
          part, by operation of law or otherwise, without our prior written
          consent. We may assign these Terms at any time without notice or
          consent. The failure to require performance of any provision will not
          affect our right to require performance at any other time after that,
          nor will a waiver by us of any breach or default of these Terms, or
          any provision of these Terms, be a waiver of any subsequent breach or
          default or a waiver of the provision itself. Use of section headers in
          these Terms is for convenience only and will not have any impact on
          the interpretation of any provision. Throughout these Terms the use of
          the word “including” means “including but not limited to”. If any part
          of these Terms is held to be invalid or unenforceable, the
          unenforceable part will be given effect to the greatest extent
          possible, and the remaining parts will remain in full force and
          effect.
        </p>
        <ol className='fks-ol' type='a'>
          <strong>
            <li>Governing Law</li>
          </strong>
          <p>
            These Terms are governed by the laws of the State of Utah without
            regard to conflict of law principles. You and Fit Kit Systems submit
            to the personal and exclusive jurisdiction of the state courts and
            federal courts located within Salt Lake County, Utah for resolution
            of any lawsuit or court proceeding permitted under these Terms.
          </p>
          <strong>
            <li>Consent to Electronic Communications</li>
          </strong>
          <p>
            By using the Service, you consent to receiving certain electronic
            communications from us as further described in our Privacy Policy.
            Please read our Privacy Policy to learn more about our electronic
            communications practices. You agree that any notices, agreements,
            disclosures, or other communications that we send to you
            electronically will satisfy any legal communication requirements,
            including that those communications be in writing.
          </p>
        </ol>
      </ol>
    </>
  )
}

export default TermsOfUse
