import React from 'react'
import { calculateAge } from '../../../utils/dateFormats'

const CustomerRow = ({ customer, userList }) => {
  const { firstName, lastName, email, gender, birthDate } =
    customer.cyclistProfile

  let userData = userList.find((user) => user._id === customer.createdBy)

  if (!userData) userData = { name: 'Deleted User' }

  return (
    <tr>
      <td>
        {firstName} {lastName}{' '}
      </td>
      <td>{email} </td>
      <td>{gender}</td>
      <td>{calculateAge(birthDate)} </td>
      <td>{userData.name} </td>
    </tr>
  )
}

export default CustomerRow
