import { Routes, Route } from 'react-router-dom'
import AccountDashboard from '../account/dashboard/AccountDashboard'
import Settings from '../account/Settings'
import NotFound from '../layout/NotFound'

const AccountRoutes = ({ user, shop, subscription, last4, activeStatus }) => {
  return (
    <Routes>
      <Route
        path='dashboard'
        element={
          <AccountDashboard
            user={user}
            shop={shop}
            subscription={subscription}
            last4={last4}
            activeStatus={activeStatus}
          />
        }
      />

      <Route
        path='settings'
        element={
          <Settings
            user={user}
            shop={shop}
            subscription={subscription}
            last4={last4}
            activeStatus={activeStatus}
          />
        }
      />
      <Route path='*' status={404} element={<NotFound />} />
    </Routes>
  )
}

export default AccountRoutes
