import React from 'react'
import { Card, Image, Row, Col } from 'react-bootstrap'

const InvalidLink = () => {
  return (
    <Card body>
      <Row>
        <Col md='3'>
          <Image
            style={{ maxHeight: '150px' }}
            src='/favicon.ico'
            alt='Shop Logo'
          />
        </Col>
        <Col className='d-flex align-items-center'>
          <h3>Fit Kit Studio (Pre Bike Fit Interview Form)</h3>
        </Col>
      </Row>
      <hr />
      This pre-fit interview link is not valid. Please contact your bike fitting
      professional.
    </Card>
  )
}

export default InvalidLink
