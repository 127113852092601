import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'react-bootstrap'
import { getIcon } from '../../../utils/generalFunctions'

import {
  ADMIN_ICON,
  EDIT_ICON,
  PLUS_ICON
} from '../../../utils/fontAwesomeDefaults'

import useEditResourceCategoryForm from '../../hooks/form-hooks/admin/useEditResourceCategoryForm'
import useEditResourceForm from '../../hooks/form-hooks/admin/useEditResourceForm'

import EditFormButton from '../../hook-components/EditFormButton'
import DeleteRecordButton from '../../hook-components/DeleteRecordButton'

import {
  updateResourceCategory,
  deleteResourceCategory
} from '../../../redux/actions/resources'

const CategoryRow = ({
  resource,
  setResourcesLoaded,
  updateResourceCategory,
  deleteResourceCategory
}) => {
  const editResourceCategoryForm = useEditResourceCategoryForm(resource)

  const initialState = {
    resourceName: '',
    resourceLink: '',
    resourceDescription: '',
    resourceType: 'link'
  }

  const editResourceForm = useEditResourceForm(initialState)

  const saveFunction = async () => {
    await updateResourceCategory(
      resource._id,
      editResourceCategoryForm.values,
      'Resource Category updated.'
    )
    editResourceCategoryForm.reset()
    setResourcesLoaded(false)
  }

  const addResourceSaveFunction = () => {
    let newResourceList = resource.resourceList
    newResourceList.push(editResourceForm.values)
    console.log(newResourceList)
    updateResourceCategory(
      resource._id,
      {
        category: resource.category,
        tab: resource.tab,
        resourceList: newResourceList
      },
      'Resource added.'
    )
    setResourcesLoaded(false)
  }

  return (
    <>
      <Table size='sm' style={{ fontSize: '.8rem' }}>
        <thead>
          <tr>
            <th style={{ width: '10%' }}>Category</th>
            <th style={{ width: '10%', textAlign: 'center' }}>Tab</th>
            <th style={{ width: '10%', textAlign: 'center' }}>Add</th>
            <th style={{ width: '10%', textAlign: 'center' }}>Edit</th>
            <th style={{ width: '10%', textAlign: 'center' }}>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{resource.category}</td>
            <td style={{ textAlign: 'center' }}>{resource.tab}</td>
            <td style={{ textAlign: 'center' }}>
              {' '}
              <EditFormButton
                modalTitle={`Add Resource`}
                modalForm={editResourceForm}
                modalTitleIcon={ADMIN_ICON}
                confirmationButtonIcon={ADMIN_ICON}
                confirmationButtonText='Save Changes'
                saveFunction={addResourceSaveFunction}
                resetFunction={editResourceForm.reset}
                disabled={
                  !editResourceForm.validateForm(editResourceForm.form) ||
                  !editResourceForm.changesMade(
                    initialState,
                    editResourceForm.values
                  )
                }
                link={getIcon(PLUS_ICON)}
                tip={`Add resource to this category.`}
                fksmode='stacked'
              />
            </td>

            <td style={{ textAlign: 'center' }}>
              <EditFormButton
                modalTitle={`Edit Category`}
                modalForm={editResourceCategoryForm}
                modalTitleIcon={ADMIN_ICON}
                confirmationButtonIcon={ADMIN_ICON}
                confirmationButtonText='Save Changes'
                saveFunction={saveFunction}
                resetFunction={editResourceCategoryForm.reset}
                disabled={
                  !editResourceCategoryForm.validateForm(
                    editResourceCategoryForm.form
                  ) ||
                  !editResourceCategoryForm.changesMade(
                    { category: resource.category, tab: resource.tab },
                    {
                      category: editResourceCategoryForm.values.category,
                      tab: editResourceCategoryForm.values.tab
                    }
                  )
                }
                link={getIcon(EDIT_ICON)}
                tip={`Edit category name and/or tab order.`}
                fksmode='stacked'
              />
            </td>
            <td style={{ textAlign: 'center' }}>
              <DeleteRecordButton
                title='Delete Resource Category?'
                confirmationPrompt='To confirm deletion'
                confirmationText='delete category'
                confirmationButtonText='Delete Resource Category'
                tip='Delete this category and all resources in this category.'
                deleteFunction={deleteResourceCategory}
                recordId={resource._id}
              >
                Delete the resource category{' '}
                <strong>"{resource.category}"</strong> and its{' '}
                <strong>{resource.resourceList.length}</strong> resources?
              </DeleteRecordButton>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

export default connect(null, {
  updateResourceCategory,
  deleteResourceCategory
})(CategoryRow)
