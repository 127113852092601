const PricingInfo = () => {
  return (
    <>
      <p>
        Quick Fit is a subscription service available under the following plans:
      </p>
      <ul>
        <li> One Year for U.S. ${process.env.REACT_APP_SUBSCRIPTION_PRICE}</li>
        <li>
          Six Months for U.S. $
          {process.env.REACT_APP_SIX_MONTH_SUBSCRIPTION_PRICE}
        </li>
        <li>
          Monthly for U.S. ${process.env.REACT_APP_MONTHLY_SUBSCRIPTION_PRICE}
        </li>
      </ul>
      <p>
        All registered users are eligible for a free 60-day trial of Quick Fit.
        You will not be charged until your trial period ends. If you cancel your
        subscription prior to the end of the trial period, you will not be
        charged. If you do not cancel your subscription before the end of your
        trial period, you will be charged the subscription fee applicable to
        your selected subscription plan.
      </p>
    </>
  )
}

export default PricingInfo
