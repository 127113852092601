import React from 'react'
import { Card } from 'react-bootstrap'

import store from '../../../redux/store'
import { updateUser } from '../../../redux/actions/users'

import { createShop } from '../../../redux/actions/shops'

import useEditShopForm from '../../hooks/form-hooks/shop/useEditShopForm'

import EditFormButton from '../../hook-components/EditFormButton'

import { SHOP_ICON } from '../../../utils/fontAwesomeDefaults'
import { validateForm } from '../../../utils/formValidation'

const ActivateShop = ({ user }) => {
  const activateShopForm = useEditShopForm({
    name: '',
    address: '',
    address2: '',
    phone: '',
    website: ''
  })

  const saveFunction = () => {
    const { values } = activateShopForm
    store.dispatch(
      updateUser({
        name: user.name,
        email: user.email,
        shop_owner: true
      })
    )
    store.dispatch(
      createShop({
        owner: user._id,
        active: true,
        name: values.name,
        address: values.address,
        address2: values.address2,
        phone: values.phone,
        website: values.website
      })
    )
  }
  return (
    <>
      <Card className='mb-3'>
        <Card.Header className='bg-white'>
          <Card.Title>Shop/Studio</Card.Title>
        </Card.Header>
        <Card.Body>
          <p>
            <strong>
              Shop or Studio Branding (for PDF Reports and Pre-Fit Interviews)
            </strong>
          </p>
          <p>
            You are currently using the default Fit Kit Studio Branding for PDF
            reports and Pre-Fit Interviews.
          </p>{' '}
          <p>
            Create a shop to customize PDF reports and Pre-Fit Interview forms
            with your shop/studio information and logo.
          </p>
        </Card.Body>
        <Card.Footer>
          <EditFormButton
            title='Create Shop'
            editButtonText='Create Shop'
            modalTitle='Create Shop'
            modalForm={activateShopForm}
            modalTitleIcon={SHOP_ICON}
            confirmationButtonIcon={SHOP_ICON}
            saveFunction={saveFunction}
            disabled={!validateForm({ name: activateShopForm.form.name })}
          />
        </Card.Footer>
      </Card>
    </>
  )
}

export default ActivateShop
