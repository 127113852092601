import { Form, Row, Col } from 'react-bootstrap'

const GenForm = (props) => {
  const { form, config, values } = props

  const renderedInputRows = form.map((input, index) => {
    return (
      <Row className='mb-3' key={input.label}>
        {config[index].render(input, values)}
      </Row>
    )
  })

  return <Form>{renderedInputRows}</Form>
}
export default GenForm
