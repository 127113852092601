import useInputWithReset from '../../input-hooks/useInputWithReset'

import { fitPositionForm as form } from '../../../forms/form-objects/fitPositionFormObjects'

import {
  getInput,
  getValidation,
  getForm,
  applyOverrides
} from '../../input-hooks/inputFunctions'

const useFitPositionForm = (record, fieldOverrides) => {
  applyOverrides('Fit Position', form, fieldOverrides)
  const keyArray = Object.keys(form)
  const i = []

  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )

  const { form: fitPositionForm, values, reset } = getForm(keyArray, i)

  const {
    saddleNoseToBar,
    saddleNoseToHood,
    saddleToBarDrop,
    handlebarReachHX,
    handlebarStackHY,
    handlebarAngle,
    hoodAngle,
    mtbSaddleNoseToGripEnd,
    mtbSaddleToGripCenterDropRise,
    ...ttBikeFitPositionForm
  } = fitPositionForm

  const {
    saddleNoseToHood: toHood,
    saddleToBarDrop: toBarDrop,
    handlebarAngle: barAngle,
    hoodAngle: hangle,
    ttSaddleToPadCenterDrop,
    ttSaddleNoseToPadRear,
    ttSaddleNoseToEndOfExtensions,
    ttExtensionWidthAtClamps,
    ttExtensionWidthAtEnd,
    ttExtensionAngle,
    ttPadWidth,
    ttPadXReachRearOfPad,
    ttPadXReachCenterOfPad,
    ttPadYStackRearOfPad,
    ttBasebarReachX,
    ttBasebarStackY,
    ttBasebarAngle,

    ...mountainBikeFitPositionForm
  } = fitPositionForm

  const {
    mtbSaddleNoseToGripEnd: gripEnd,
    mtbSaddleToGripCenterDropRise: dropRise,
    ttSaddleToPadCenterDrop: centerDrop,
    ttSaddleNoseToPadRear: padRear,
    ttSaddleNoseToEndOfExtensions: toEnd,
    ttExtensionWidthAtClamps: atClamps,
    ttExtensionWidthAtEnd: atEnd,
    ttExtensionAngle: extAngle,
    ttPadWidth: padWidth,
    ttPadXReachRearOfPad: rearOfPad,
    ttPadXReachCenterOfPad: centerOfPad,
    ttPadYStackRearOfPad: stackRearPad,
    ttBasebarReachX: bbReach,
    ttBasebarStackY: stackY,
    ttBasebarAngle: ttbarAngle,

    ...roadBikeFitPositionForm
  } = fitPositionForm

  values.make = record.make
  values.model = record.model

  return {
    roadBikeFitPositionForm,
    mountainBikeFitPositionForm,
    ttBikeFitPositionForm,
    values,
    reset
  }
}

export default useFitPositionForm
