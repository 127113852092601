import React from 'react'

import Tip from '../layout/overlays/Tip'

import { VIEW_RECORD_ICON } from '../../utils/fontAwesomeDefaults'

import useViewRecordModal from '../hooks/modal-hooks/useViewRecordModal'

import ViewRecordModal from '../layout/modals/ViewRecordModal'
import GenerateRecord from '../forms/form-components/GenerateRecord'

const ViewRecordButton = ({
  record,
  children,
  editButtonText,
  modalTitle,
  modalForm,
  modalTitleIcon,
  confirmationButtonIcon,
  confirmationButtonText,
  saveFunction,
  user,
  disabled,
  formGroup,
  cols,
  fksmode,
  link,
  tip,
  dataObject,
  title,

  error
}) => {
  const viewRecordModal = useViewRecordModal({
    icon: modalTitleIcon || VIEW_RECORD_ICON,
    title: modalTitle || 'View Record',
    confirmationButtonIcon: confirmationButtonIcon || VIEW_RECORD_ICON
  })

  return (
    <>
      <Tip message='View Record' placement='top'>
        <i
          style={{
            cursor: 'pointer',
            textDecoration: 'none',
            color: '#0d6efd',
            fontSize: '.8rem'
          }}
          className={VIEW_RECORD_ICON}
          onClick={viewRecordModal.setShowModal}
        />
      </Tip>
      <ViewRecordModal {...viewRecordModal}>
        {children}
        <GenerateRecord record={record} />
      </ViewRecordModal>
    </>
  )
}

export default ViewRecordButton
