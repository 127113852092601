import {
  firstName,
  lastName,
  email,
  phone,
  zipCode,
  birthDate,
  gender,
  height,
  weight,
  ridingStyle
} from '../field-objects/customerFields'

import {
  referralSource,
  referralSourceDetails,
  heightUnits,
  weightUnits,
  physicalComments,
  discomfortFeet,
  discomfortKnees,
  discomfortHips,
  discomfortButt,
  discomfortPerineumGenitalia,
  discomfortLowerBack,
  discomfortShouldersNeck,
  discomfortHandsWristsElbows,
  discomfortOther,
  discomfortOtherText,
  otherPhysicalActivities,
  priorBikeFit,
  objectiveMeasureAndAdvise,
  objectiveSetUp,
  objectiveGeneral,
  objectiveRelieve,
  objectiveImprove,
  objectiveReplicate,
  objectiveShoe,
  objectiveSaddle,
  objectiveAero,
  objectiveComments,
  yearsCycling,
  hoursPerWeek,
  skillsCrashing,
  skillsCornering,
  skillsDescending,
  skillsClimbing,
  skillsFlats,
  skillsWeightDistribution,
  cyclingGoals,
  mediaConsent,
  bikeNewStyle,
  bikeReasons,
  channelsNew,
  channelsUsed,
  channelsCustomFrameBuilder,
  channelsFullyBuilt,
  channelsFrameOnly,
  channelsInStore,
  channelsOnline,
  bikeBudget,
  bikeBrandsModels,
  factorsBrandModel,
  factorsComponents,
  factorsValue,
  factorsAvailability,
  factorsReviews,
  factorsReputation,
  factorsCost,
  factorsFit,
  factorsAppearance,
  factorsOther,
  factorsOtherText
} from '../field-objects/prefitInterviewFields'

import {
  type as bikeType,
  make as bikeMake,
  model as bikeModel,
  frameSize as bikeFrameSize,
  notes as bikeComments
} from '../field-objects/bikeFields'

export const contactDetailsForm = {
  firstName,
  lastName,
  email,
  phone,
  zipCode,
  referralSource,
  referralSourceDetails
}

export const physicalProfileForm = {
  birthDate,
  gender,
  height,
  heightUnits,
  weight,
  weightUnits,
  physicalComments,
  discomfortFeet,
  discomfortKnees,
  discomfortHips,
  discomfortButt,
  discomfortPerineumGenitalia,
  discomfortLowerBack,
  discomfortShouldersNeck,
  discomfortHandsWristsElbows,
  discomfortOther,
  discomfortOtherText,
  otherPhysicalActivities
}

export const bikeFitDetailsForm = {
  priorBikeFit,
  objectiveMeasureAndAdvise,
  objectiveSetUp,
  objectiveGeneral,
  objectiveRelieve,
  objectiveImprove,
  objectiveReplicate,
  objectiveShoe,
  objectiveSaddle,
  objectiveAero,
  objectiveComments
}

export const ridingProfileForm = {
  ridingStyle,
  yearsCycling,
  hoursPerWeek,
  skillsCrashing,
  skillsCornering,
  skillsDescending,
  skillsClimbing,
  skillsFlats,
  skillsWeightDistribution,
  cyclingGoals
}

export const currentBikeDetailsForm = {
  bikeType,
  bikeMake,
  bikeModel,
  bikeFrameSize,
  bikeComments
}

export const newBikeDetailsForm = {
  bikeType,
  bikeNewStyle,
  bikeReasons,
  channelsNew,
  channelsUsed,
  channelsInStore,
  channelsOnline,
  channelsCustomFrameBuilder,
  channelsFullyBuilt,
  channelsFrameOnly,
  bikeBudget,
  bikeBrandsModels,
  factorsBrandModel,
  factorsComponents,
  factorsValue,
  factorsAvailability,
  factorsReviews,
  factorsReputation,
  factorsCost,
  factorsFit,
  factorsAppearance,
  factorsOther,
  factorsOtherText
}

export const mediaPermissionForm = {
  mediaConsent
}
