import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Table, Button, Modal } from 'react-bootstrap'

import Tip from '../../layout/overlays/Tip'

import { updatePreFitInterview } from '../../../redux/actions/prefitInterviews'
import { updateCyclist } from '../../../redux/actions/cyclists'

import AddInterviewRow from './AddInterviewRow'

import { CYCLIST_ICON, CANCEL_ICON } from '../../../utils/fontAwesomeDefaults'

const AddInterviewToExistingCustomer = ({
  cyclists: { cyclists },
  updatePreFitInterview,
  updateCyclist,
  tip,
  link,
  interview
}) => {
  const [showModal, setShowModal] = useState(false)

  const matchingCyclists = cyclists.filter(
    (cyclist) =>
      cyclist.cyclistProfile.birthDate === interview.birthDate ||
      cyclist.cyclistProfile.lastName === interview.lastName
  )

  const navigate = useNavigate()

  const handleAddInterview = (interviewId, interview, cyclist) => {
    updatePreFitInterview(interviewId, interview)
    updateCyclist(
      cyclist._id,
      {
        cyclistProfile: {
          ...cyclist.cyclistProfile,
          birthDate: cyclist.cyclistProfile.birthDate
        }
      },
      'Pre-Fit Interview Added to Cyclist'
    )

    navigate(`/quickfit/customer/${cyclist._id}`)
  }

  const handleClick = () => {
    setShowModal(true)
  }

  const clickCancel = () => {
    setShowModal(false)
  }

  return (
    <>
      <Tip message={tip} placement='top'>
        <span
          style={{
            cursor: 'pointer',
            textDecoration: 'none',
            color: '#0d6efd',
            fontSize: '.8rem'
          }}
          onClick={handleClick}
        >
          {link}
        </span>
      </Tip>
      <Modal className='bikeFormModal' centered show={showModal}>
        <Modal.Header>
          <Modal.Title>
            <>
              <i className={CYCLIST_ICON} /> Add Interview
            </>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {' '}
          {matchingCyclists.length > 0 ? (
            <div>
              <p>
                The following customers match the birth date and/or last name
                specified in the interview for:
              </p>
              <p>
                <strong>
                  {interview.firstName} {interview.lastName} ({interview.email})
                </strong>
              </p>

              <p>
                Do you want to add the pre-fit interview to one of these
                customers?{' '}
              </p>

              <Table size='sm'>
                <tbody>
                  {matchingCyclists.map((cyclist) => {
                    return (
                      <AddInterviewRow
                        key={cyclist._id}
                        cyclist={cyclist}
                        interview={interview}
                        handleAddInterview={handleAddInterview}
                      />
                    )
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <p>No customers found with matching birthdate or last name.</p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant='dark' onClick={clickCancel}>
            <i className={CANCEL_ICON} /> Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

AddInterviewToExistingCustomer.propTypes = {}

const mapStateToProps = (state) => ({
  prefitinterviews: state.prefitinterviews,
  cyclists: state.cyclists
})

export default connect(mapStateToProps, {
  updatePreFitInterview,
  updateCyclist
})(AddInterviewToExistingCustomer)
