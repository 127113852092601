import { useEffect } from 'react'
import MainHeading from '../../layout/MainHeading'
import { Link } from 'react-router-dom'
import { TOOLBOX_ICON } from '../../../utils/fontAwesomeDefaults'
import SEO from '../../seo/SEO'

const FreeToolsDescription = () => {
  useEffect(() => {
    document.body.classList.add('bodyPad')

    return () => {
      document.body.classList.remove('bodyPad')
    }
  }, [])
  return (
    <>
      <SEO
        title='Fit Kit Studio | Free Tools'
        description='Fit Kit Studio: software for bike fitting professionals. Fit Kit Studio Free Tools include Quick Size bike sizing calculator, Bar XY to Frame XY Reach and Stack Calculator, Frame XY to Bar XY Reach and Stack Calculator, and Stem Delta Calculator. No credit card required.'
        type='website'
        name='Fit Kit Systems'
      />
      <MainHeading
        heading='Free Tools'
        headingIcon={TOOLBOX_ICON}
        subheading={'Free to all registered users.'}
      />
      <p>
        Fit Kit Studio offers a variety of free tools and resources to all
        registered users, including:
        <br />
        <br />
        <ul>
          <li>Quick Size bike sizing calculator</li>
          <li>Bar XY to Frame XY Reach and Stack Calculator</li>
          <li>Frame XY to Bar XY Reach and Stack Calculator</li>
          <li>Stem Delta Calculator</li>
        </ul>{' '}
        <Link to='/register'>Register</Link> today to explore the free tools. No
        credit card required.
      </p>
    </>
  )
}

export default FreeToolsDescription
