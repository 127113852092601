import React from 'react'
import { Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap'

import DashboardCard from '../../layout/cards-containers/DashboardCard'

const AccountInfo = ({ user }) => {
  const { avatar, name, email, created, _id } = user
  return (
    <>
      <DashboardCard
        style={{ minHeight: '100px', fontSize: '.8rem' }}
        title='Account Information'
      >
        <Row>
          <Col sm='4'>
            <img style={{ maxHeight: '145px' }} src={avatar} alt='' />
          </Col>
          <Col>
            <ListGroup>
              <ListGroupItem>{name}</ListGroupItem>
              <ListGroupItem>{email}</ListGroupItem>
              <ListGroupItem>Joined: {created.substring(0, 10)}</ListGroupItem>
              <ListGroupItem>User Id: {_id}</ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </DashboardCard>
    </>
  )
}

export default AccountInfo
