import { useState, useEffect } from 'react'
import store from '../../../redux/store'
import { loadCustomerBikes } from '../../../redux/actions/bikes'

const useCustomerBikes = (customerId) => {
  const [bikesLoaded, setBikesLoaded] = useState(false)

  useEffect(() => {
    const fetchCustomerBikes = async () => {
      await store.dispatch(loadCustomerBikes(customerId))
      setBikesLoaded(true)
    }
    fetchCustomerBikes()
  }, [customerId])

  return bikesLoaded
}

export default useCustomerBikes
