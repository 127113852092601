import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'
import ToolsRoutes from '../routing/ToolsRoutes'
import NewsAndUpdates from '../admin/message-header/NewsAndUpdates'

const ToolsAndResources = ({ users: { user }, fields: { fieldOverrides } }) => {
  return (
    <>
      <NewsAndUpdates />
      <ToolsRoutes user={user} fieldOverrides={fieldOverrides} />
    </>
  )
}

ToolsAndResources.propTypes = {
  users: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  users: state.users,
  fields: state.fields
})

export default connect(mapStateToProps, {})(ToolsAndResources)
