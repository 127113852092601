import React from 'react'
import { Link } from 'react-router-dom'
import { BIKE_ICON } from '../../../utils/fontAwesomeDefaults'

import DeleteRecordButton from '../../hook-components/DeleteRecordButton'

const BikeRow = ({
  make,
  model,
  updated,
  _id,
  ownedBy,
  bikeTableMode,
  deleteBike
}) => {
  return (
    <tr>
      <td>
        {' '}
        <Link to={`/quickfit/bike/${ownedBy}/${_id}`}>
          <span>
            <i className={BIKE_ICON} /> {make}{' '}
          </span>
        </Link>
      </td>
      <td>{model}</td>
      <td>{updated}</td>
      {bikeTableMode ? (
        <td>
          <DeleteRecordButton
            title='Permanently Delete Bike?'
            confirmationPrompt='To confirm deletion'
            confirmationText='delete bike'
            confirmationButtonText='Delete Bike'
            tip='Delete this Bike.'
            deleteFunction={deleteBike}
            recordId={_id}
          >
            Delete{' '}
            <strong>
              {make} {model}
            </strong>
            ? This action cannot be undone.
          </DeleteRecordButton>
        </td>
      ) : null}
    </tr>
  )
}

export default BikeRow
