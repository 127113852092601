import { Form, Row, Col } from 'react-bootstrap'

const DefaultInput = (props) => {
  const {
    type,
    inline,
    label,
    inputSize,
    helpmessage,
    error,
    errormessage,
    xs
  } = props

  return inline ? (
    <>
      <Row className='align-items-center'>
        {label && (
          <Col xs={xs || '2'}>
            <Form.Label size={inputSize || 'sm'}>{label}</Form.Label>
          </Col>
        )}
        <Col>
          <Form.Group className={helpmessage ? 'mb-1' : 'mb-3'}>
            <Form.Control {...props} size={inputSize || 'sm'} />
          </Form.Group>
        </Col>
      </Row>
      {helpmessage && (
        <Row className='mb-3'>
          <Form.Text muted>{helpmessage}</Form.Text>
        </Row>
      )}
    </>
  ) : (
    <>
      <Form.Group className='mb-3'>
        {label && <Form.Label size={inputSize || 'sm'}>{label} </Form.Label>}
        {type === 'password' && error && (
          <span className='ms-3 text-danger' style={{ fontSize: '.8rem' }}>
            <strong>{errormessage}</strong>
          </span>
        )}
        <Form.Control {...props} error={null} size={inputSize || 'sm'} />
        {helpmessage && <Form.Text muted>{helpmessage}</Form.Text>}
      </Form.Group>
    </>
  )
}

export default DefaultInput
