import React from 'react'
import { Link } from 'react-router-dom'
import { Nav, Navbar, Container } from 'react-bootstrap'

import {
  ADMIN_ICON_FKS,
  DASHBOARD_ICON,
  SETTINGS_ICON,
  DATA_ICON
} from '../../utils/fontAwesomeDefaults'

const AdminNav = () => {
  return (
    <Navbar className='mb-3' fill='true' variant='dark' bg='dark'>
      <Container>
        <Navbar.Brand>
          <i className={ADMIN_ICON_FKS} /> Admin
        </Navbar.Brand>

        <Nav>
          <Nav.Item>
            <Nav.Link as={Link} to='/admin/dashboard'>
              <i className={DASHBOARD_ICON} /> Dashboard
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to='/admin/settings'>
              <i className={SETTINGS_ICON} /> Admin Tools
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to='/admin/data'>
              <i className={DATA_ICON} /> Admin Data
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default AdminNav
