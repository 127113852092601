const GenerateRecord = ({ record }) => {
  const processObject = (key, value, level) => {
    let keyClassName, subKeyClassName
    switch (level) {
      case 1:
        keyClassName = 'text-secondary text-wrap mb-3'
        subKeyClassName = 'text-info ms-3'
        break
      case 2:
        keyClassName = 'text-info ms-3 text-wrap'
        subKeyClassName = 'text-secondary ms-3 text-wrap mb-3'
        break
      case 3:
        keyClassName = 'text-danger ms-3 text-wrap'
        subKeyClassName = 'text-warning ms-3 text-wrap mb-3'
        break
      default:
        keyClassName = 'text-primary text-wrap mb-3'
        subKeyClassName = 'text-info ms-3'
    }
    return (
      <div key={key} className={keyClassName}>
        {key}
        <br />
        {Object.entries(value).map(([subKey, subValue]) => {
          if (subValue && typeof subValue === 'object')
            return processObject(subKey, subValue, level + 1)

          return (
            <span style={{ wordWrap: 'break-word' }} key={subKey}>
              <span className={subKeyClassName}>{subKey}: </span>
              <span className='text-primary'> {subValue}</span>

              <br />
            </span>
          )
        })}
      </div>
    )
  }

  return (
    <>
      {Object.entries(record).map(([key, value]) => {
        if (value && typeof value === 'object')
          return processObject(key, value, 1)
        return (
          <p className='text-secondary' key={key}>
            <span>{`${key}: `}</span>
            <span className='text-primary'> {`${value}`}</span>
          </p>
        )
      })}
    </>
  )
}

export default GenerateRecord
