import React from 'react'
import { connect } from 'react-redux'

import QuickFitTabContent from '../QuickFitTabContent'

import useBodyMeasurementsForm from '../../hooks/form-hooks/customers/useBodyMeasurementsForm'

import { updateCyclist } from '../../../redux/actions/cyclists'

import { getMetricHeight, getMetricWeight } from '../../../utils/heightWeight'

import { bodyMeasurementsForm as dataForm } from '../../forms/form-objects/customerFormObjects'

import { CYCLIST_ICON } from '../../../utils/fontAwesomeDefaults'

const BodyMeasurements = ({
  customer,
  user,
  fields: { fieldOverrides },
  updateCyclist
}) => {
  const { height_units, weight_units } = user.preferences
  const bodyMeasurementsForm = useBodyMeasurementsForm(
    customer.bodyMeasurements,
    fieldOverrides
  )

  const { changesMade, values } = bodyMeasurementsForm

  const saveFunction = () => {
    const customerObject = {
      cyclistProfile: customer.cyclistProfile,
      bodyMeasurements: bodyMeasurementsForm.values
    }
    if (height_units === 'Imperial')
      customerObject.bodyMeasurements.height = getMetricHeight(
        bodyMeasurementsForm.values.height
      )
    if (weight_units === 'Imperial')
      customerObject.bodyMeasurements.weight = getMetricWeight(
        bodyMeasurementsForm.values.weight
      )

    updateCyclist(customer._id, customerObject, 'Body Measurements Updated')
  }

  return (
    <QuickFitTabContent
      title='Body Measurements'
      dataForm={dataForm}
      dataObject={customer.bodyMeasurements}
      editButtonText='Edit Body Measurements'
      modalTitle='Edit Body Measurements'
      modalForm={bodyMeasurementsForm}
      modalTitleIcon={CYCLIST_ICON}
      confirmationButtonIcon={CYCLIST_ICON}
      saveFunction={saveFunction}
      user={user}
      formGroup='quickfit'
      colSize='7'
      disabled={
        /*  !validateForm({ footLength, inseam, torso, arm }) || */
        !changesMade(customer.bodyMeasurements, values)
      }
    />
  )
}

const mapStateToProps = (state) => ({
  fields: state.fields
})

export default connect(mapStateToProps, { updateCyclist })(BodyMeasurements)
