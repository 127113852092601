import React from 'react'
import { connect } from 'react-redux'
import { createAdminMessage } from '../../../redux/actions/adminmessages'

import EditFormButton from '../../hook-components/EditFormButton'

import useEditMessageForm from '../../hooks/form-hooks/admin/useEditMessageForm'

import { BIKE_ICON } from '../../../utils/fontAwesomeDefaults'

const AddNewMessage = ({ createAdminMessage }) => {
  const addMessageForm = useEditMessageForm({
    icon: 'INFO',
    title: '',
    content: '',
    start: new Date().toDateString(),
    stop: null
  })

  const saveFunction = () => {
    createAdminMessage(addMessageForm.values, 'Message Created.')
    addMessageForm.reset()
  }

  return (
    <>
      <EditFormButton
        editButtonText='Add Message'
        modalTitle={`Add Message?`}
        modalForm={addMessageForm}
        modalTitleIcon={BIKE_ICON}
        confirmationButtonIcon={BIKE_ICON}
        confirmationButtonText='Add Message'
        saveFunction={saveFunction}
        //disabled={!validateForm({ make: form.make, model: form.model })}
        fksmode='stacked'
      />
    </>
  )
}

export default connect(null, {
  createAdminMessage
})(AddNewMessage)
