import { connect } from 'react-redux'
import { Card, Button } from 'react-bootstrap'

import { deleteAccount } from '../../../redux/actions/users'

import ConfirmCancelModal from '../../layout/modals/ConfirmCancelModal'

import useConfirmCancelModal from '../../hooks/modal-hooks/useConfirmCancelModal'

import { WARNING_ICON, TRASH_ICON } from '../../../utils/fontAwesomeDefaults'

const DeleteAccount = ({
  cyclists: { cyclists },
  stripe: { subscription, activeStatus },
  deleteAccount
}) => {
  const deleteAccountModal = useConfirmCancelModal({
    icon: WARNING_ICON,
    title: 'Permanently Delete Account?',
    confirmationPrompt: 'To confirm deletion',
    confirmationText: 'permanently delete account',
    confirmationButtonIcon: TRASH_ICON,
    confirmationButtonText: 'Delete Account',

    confirmationAction: () => {
      deleteAccount()
    }
  })

  return (
    <>
      <Card>
        <Card.Header className='bg-white'>
          <Card.Title>Delete Account</Card.Title>
        </Card.Header>
        <Card.Body>
          <p>
            You may delete your account at any time. If you delete your account,
            your account profile will be deleted permanently and you will no
            longer be able to access your account or any stored data.
          </p>
          {activeStatus && (
            <>
              <p>
                {' '}
                <i className={WARNING_ICON + ' text-danger'} /> You have an
                active subscription. You must cancel your subscription before
                you can delete your account. If you have any stored customer or
                bike data, consider canceling your subscription without deleting
                your account. This will allow you to restore access to your
                customer and bike data in the future if you decide to
                resubscribe. If you delete your account, you will not be able to
                access your existing customer and bike data in the future.
              </p>
            </>
          )}
          {!activeStatus && cyclists.length > 0 && (
            <>
              <p>
                {' '}
                <i className={WARNING_ICON + ' text-danger'} /> You have{' '}
                {cyclists.length} stored customers. If you delete your account
                you will not be able to access these customers and any bike data
                in the future if you decide to resubscribe. We recommend that
                you maintain your account even if you have canceled your
                subscription. This will allow you to restore access to your
                existing customer and bike data in the future if you decide to
                resubscribe.
              </p>
            </>
          )}
        </Card.Body>
        {!activeStatus ? (
          <Card.Footer className='bg-white'>
            <div className='d-grid gap-2'>
              <Button variant='dark' onClick={deleteAccountModal.setShowModal}>
                <i className='' /> Delete Account
              </Button>
            </div>
          </Card.Footer>
        ) : null}
      </Card>
      <ConfirmCancelModal {...deleteAccountModal}>
        This action cannot be undone. Your profile and all of your stored data
        will be deleted permanently and you will no longer be able to access
        your account.{' '}
      </ConfirmCancelModal>
    </>
  )
}

const mapStateToProps = (state) => ({
  users: state.users,
  cyclists: state.cyclists,
  stripe: state.stripe
})

export default connect(mapStateToProps, { deleteAccount })(DeleteAccount)
