import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

const Select = ({
  options,
  value,
  onChange,
  label,
  colSize,
  fksmode,
  cols
}) => {
  switch (fksmode) {
    case 'stacked':
      return (
        <>
          <Form.Group className='mb-3'>
            <Form.Label>{label}</Form.Label>

            <Form.Select value={value} onChange={onChange} size={'sm'}>
              {options.map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                )
              })}
            </Form.Select>
          </Form.Group>
        </>
      )
    default:
      if (!cols) cols = ['2', '3', '']
      return (
        <Form.Group className='mb-3'>
          <Row>
            <Col xs={cols[0]}></Col>
            <Col xs={cols[1]}>
              <Form.Label column xs='auto'>
                {label}
              </Form.Label>
            </Col>

            <Col xs={cols[2] || 'auto'}>
              <Form.Select value={value} onChange={onChange} size={'sm'}>
                {options.map((option) => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
      )
  }
}

export default Select
