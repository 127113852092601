import React from 'react'
import { Card } from 'react-bootstrap'
import { CARD_HEADER_BG } from '../../../utils/styleAliases'
import CardLink from './CardLink'

const ResourceList = ({ resource }) => {
  const { category, resourceList } = resource
  return (
    <Card>
      <Card.Header className={CARD_HEADER_BG}>
        <Card.Title>{category}</Card.Title>
      </Card.Header>
      <Card.Body>
        {resourceList.map((item) =>
          item.resourceType === 'link' ? (
            <p key={item.resourceName}>
              <a href={item.resourceLink} target='_blank' rel='noreferrer'>
                {item.resourceName}
              </a>
            </p>
          ) : (
            <CardLink key={item.resourceName} item={item} />
          )
        )}
      </Card.Body>
    </Card>
  )
}

export default ResourceList
