import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import QuickFitRoutes from '../routing/QuickFitRoutes'

const QuickFit = ({
  prefitinterviews: { newInterviews },
  cyclists: { cyclists },
  users: { user },
  shops: { shop },
  bikes: { customerBikes }
}) => {
  return (
    <>
      {/* <QuickFitNav /> */}

      <QuickFitRoutes
        cyclists={cyclists}
        customerBikes={customerBikes}
        newInterviews={newInterviews}
        user={user}
        shop={shop}
      />
    </>
  )
}

QuickFit.propTypes = {
  prefitinterviews: PropTypes.object.isRequired,
  cyclists: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  prefitinterviews: state.prefitinterviews,
  cyclists: state.cyclists,
  users: state.users,
  shops: state.shops,
  bikes: state.bikes
})

export default connect(mapStateToProps, {})(QuickFit)
