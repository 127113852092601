export const name = {
  type: 'text',
  label: 'Shop or Studio Name',
  validationType: 'inputLength',
  length: 1
}
export const address = {
  type: 'text',
  label: 'Address',
  validationType: 'inputLength',
  length: 1
}
export const address2 = {
  type: 'text',
  label: 'Address (line 2)',
  validationType: 'inputLength',
  length: 1
}

export const phone = {
  type: 'tel',
  label: 'Phone',
  validationType: 'phone'
}

export const website = {
  type: 'text',
  label: 'Website',
  validationType: 'inputLength',
  length: 1
}
