//PDF Functions

import { utcToutc, yearMonthDayEval } from '../utils/dateFormats'
import { heightMunit, weightMunit } from '../utils/heightWeight'
import { convertedHeight, convertedWeight } from '../utils/heightWeight'

import {
  roadBikeForm,
  mountainBikeForm,
  ttBikeForm
} from '../components/forms/form-objects/bikeFormObjects'

import {
  roadBikeFitPositionForm,
  mountainBikeFitPositionForm,
  ttBikeFitPositionForm
} from '../components/forms/form-objects/fitPositionFormObjects'

import {
  roadBikeSizingForm,
  mountainBikeSizingForm,
  ttBikeSizingForm
} from '../components/forms/form-objects/sizingFormObjects'

import {
  roadBikeFitHistoryForm,
  mountainBikeFitHistoryForm,
  ttBikeFitHistoryForm
} from '../components/forms/form-objects/fitHistoryFormObjects'

export const TOP_MARGIN = 5
export const BOTTOM_MARGIN = 5
export const LEFT_MARGIN = 5
export const RIGHT_MARGIN = 5
export const SHOP_TEXT_MARGIN = 10
export const SHOP_WEBSITE_MARGIN = 21
//export const BLOCK_HEADER_MARGIN = 5
export const BLOCK_HEADER_MARGIN = 5
export const RIGHT_COLUMN_X = 70
export const HR_ONE_Y = 25
export const HR_TWO_Y = 210
export const FOOTER_HEIGHT = 12
export const HEADER_HEIGHT = 25

//export const BLOCK_ONE_Y = TOP_MARGIN + HEADER_HEIGHT + BLOCK_HEADER_MARGIN
export const BLOCK_ONE_Y = TOP_MARGIN + HEADER_HEIGHT //+ BLOCK_HEADER_MARGIN
export const BLOCK_TWO_Y = BLOCK_ONE_Y + BLOCK_HEADER_MARGIN + 23
export const BLOCK_THREE_Y = BLOCK_TWO_Y + BLOCK_HEADER_MARGIN + 35
export const BLOCK_FOUR_Y = BLOCK_THREE_Y + BLOCK_HEADER_MARGIN + 19
export const BLOCK_FIVE_Y = BLOCK_FOUR_Y + BLOCK_HEADER_MARGIN + 108
export const NOTES_BLOCK_Y = BLOCK_FOUR_Y + BLOCK_HEADER_MARGIN + 73

export const DATE_MARGIN = 4

export const getBlockY = (blockName, reportFields) => {
  const {
    cyclist_name,
    cyclist_profile,
    fk_body_measurements,
    fk_soft_score,
    equipment,
    fit_position,
    bike_diagram
  } = reportFields
  let y = BLOCK_ONE_Y
  switch (blockName) {
    case 'cyclist_profile':
      return BLOCK_ONE_Y
    case 'body_measurements':
      if (cyclist_name && cyclist_profile) return BLOCK_TWO_Y
      if (cyclist_name && !cyclist_profile) return BLOCK_ONE_Y + 13

      break
    case 'physical_profile':
      if (cyclist_name) y = y + 15
      if (cyclist_profile) y = y + BLOCK_HEADER_MARGIN + 8
      if (fk_body_measurements) y = y + BLOCK_HEADER_MARGIN + 35
      return y
    case 'equipment':
      if (cyclist_name) y = y + 15
      if (cyclist_profile) y = y + BLOCK_HEADER_MARGIN + 8
      if (fk_body_measurements) y = y + BLOCK_HEADER_MARGIN + 35
      if (fk_soft_score) y = y + BLOCK_HEADER_MARGIN + 19
      //return y
      return BLOCK_FOUR_Y
    case 'feet_cleat':
      y = BLOCK_FOUR_Y
      if (equipment) y = BLOCK_FIVE_Y
      return y

    case 'fit_position':
      y = TOP_MARGIN + 28
      if (bike_diagram) y = BLOCK_FOUR_Y
      return y
    case 'notes_recommendations':
      y = TOP_MARGIN + 28
      if (bike_diagram) y = BLOCK_FOUR_Y
      if (fit_position) y = NOTES_BLOCK_Y
      return y

    default:
      return BLOCK_ONE_Y
  }
}

export const getFormat = (user) => {
  const { report_format } = user.preferences
  return report_format === 'US Letter' ? 'letter' : 'a4'
}

export const getHeader = (
  pdf,
  user,
  shop,
  reportTitle,
  customer,
  interview
) => {
  const { logo, name, address, address2, phone, website } = shop
  let imageSource
  if (logo) imageSource = `/api/shops/photo/${shop._id}?${Date.now()}`
  else imageSource = '/fksicon.jpg'
  const imageProps = pdf.getImageProperties(imageSource)
  const aspectRatio = imageProps.width / imageProps.height
  const maxHeight = 15
  const adjustedWidth = maxHeight * aspectRatio

  pdf.addImage(
    imageSource,
    'JPG',
    LEFT_MARGIN,
    TOP_MARGIN,
    adjustedWidth,
    maxHeight
  )

  pdf.setTextColor(0, 0, 0)

  let shopArray

  if (!name && !address && !address2 && !phone)
    shopArray = [' ', ' ', 'Fit Kit Studio']
  else shopArray = [name, address, address2, phone]

  pdf.text(
    LEFT_MARGIN + adjustedWidth + SHOP_TEXT_MARGIN,
    TOP_MARGIN,
    shopArray
  )
  pdf.setTextColor(11, 0, 128)
  if (website)
    pdf.textWithLink(
      website,
      LEFT_MARGIN + adjustedWidth + SHOP_TEXT_MARGIN,
      SHOP_WEBSITE_MARGIN,
      {
        url: website
      }
    )
  pdf.setTextColor(user.preferences.report_color)
  const FITTER_TEXT_MARGIN = pdf.getPageWidth(1) - 80

  pdf.text(FITTER_TEXT_MARGIN, TOP_MARGIN, reportTitle)
  pdf.setTextColor(0, 0, 0)
  if (reportTitle !== 'Pre-Fit Interview Summary') {
    pdf.text(FITTER_TEXT_MARGIN, TOP_MARGIN + 10, 'Evaluated by: ' + user.name)
    pdf.text(FITTER_TEXT_MARGIN, TOP_MARGIN + 15, 'Email: ' + user.email)
  } else {
    pdf.setFont(pdf.getFont().fontName, 'bold')
    pdf.text(
      FITTER_TEXT_MARGIN,
      TOP_MARGIN + 10,
      'Customer: ' + customer.firstName + ' ' + customer.lastName
    )
    pdf.setFont(pdf.getFont().fontName, 'normal')
    if (!interview.objectiveMeasureAndAdvise)
      pdf.text(
        FITTER_TEXT_MARGIN,
        TOP_MARGIN + 15,
        'Bike: ' + interview.bikeMake + ' ' + interview.bikeModel
      )
    else pdf.text(FITTER_TEXT_MARGIN, TOP_MARGIN + 15, 'Bike: New Bike')
  }

  getHR(pdf, HR_ONE_Y)
}

export const getHR = (pdf, y) => {
  pdf.line(LEFT_MARGIN, y, pdf.getPageWidth(1) - RIGHT_MARGIN, y)
}

export const getCustomerProfile = (pdf, user, customer) => {
  const custBlock = { x: LEFT_MARGIN, y: TOP_MARGIN + 30 }
  const { cyclistProfile } = customer
  const { firstName, lastName, email, gender } = cyclistProfile
  pdf.setTextColor(user.preferences.report_color)
  pdf.text(custBlock.x, custBlock.y, 'Cyclist')
  pdf.setTextColor(0, 0, 0)

  const custInfo = [
    'Name: ' + firstName + ' ' + lastName,
    'Email: ' + email,
    'Gender: ' + gender
  ]
  pdf.text(custBlock.x, custBlock.y + BLOCK_HEADER_MARGIN, custInfo)
}

export const getCustomerProfileForms = (customer, user) => {
  const { updated, cyclistProfile } = customer
  const { firstName, lastName, email, gender, birthDate } = cyclistProfile
  const { report_fields } = user.preferences
  let dataForm = {}
  let dataObject = {}

  if (report_fields.cyclist_name) {
    dataForm.name = { label: 'Name', type: 'text' }
    dataObject.name = firstName + ' ' + lastName
  }

  if (report_fields.cyclist_profile) {
    dataForm.email = { label: 'Email', type: 'text' }
    dataForm.gender = { label: 'Gender', type: 'text' }
    dataForm.birthDate = { label: 'DOB', type: 'text' }
    dataForm.updated = { label: 'Updated', type: 'text' }

    dataObject.email = email
    dataObject.gender = gender
    dataObject.birthDate = utcToutc(birthDate)
    dataObject.updated = utcToutc(updated)
  }

  return [dataForm, dataObject]
}

export const getTableBlock = ({
  pdf,
  dataForm,
  dataObject,
  heading,
  user,
  shop,
  customer,
  interview,
  cols,
  x,
  y,
  headerMargin,
  lineHeightFactor
}) => {
  const { height_units, weight_units } = user.preferences
  const labels = Object.entries(dataForm).map(
    ([key, value]) => value.label + ':'
  )

  if (!cols[1]) cols[1] = cols[0]

  const mUnitText = []

  const additionalPages = []

  const text = Object.entries(dataForm).map(([key, value]) => {
    let text = dataObject[key]
    if (value.type === 'number' && text === null) text = ''
    if (value.type === 'number') text = text.toString()

    let mUnit = ' '
    if (value.type === 'number' && value.munit !== 'none') mUnit = 'mm.'
    if (key === 'birthDate') text = utcToutc(text)
    if (key === 'height') {
      mUnit = heightMunit(height_units)
      if (height_units === 'Metric') text = convertedHeight(text, height_units)
    }
    if (key === 'weight') {
      mUnit = weightMunit(weight_units)
      if (weight_units === 'Metric') text = convertedWeight(text, weight_units)
    }
    if (text === true) text = 'Yes'
    if (text === false) text = 'No'

    value.type === 'number' && value.munit && value.munit !== 'none'
      ? mUnitText.push(value.munit)
      : mUnitText.push(mUnit)

    if (text.length > 95) {
      additionalPages.push({ label: value.label, text: text })
      text = 'See following pages.'
    }

    return text
  })

  if (lineHeightFactor) pdf.setLineHeightFactor(lineHeightFactor)

  pdf.setTextColor(user.preferences.report_color)
  pdf.text(x, y, heading)
  pdf.setTextColor(0, 0, 0)
  pdf.text(x, y + headerMargin || y + BLOCK_HEADER_MARGIN, labels)
  pdf.text(text, x + cols[0], y + headerMargin || y + BLOCK_HEADER_MARGIN, {
    maxWidth: 140
  })
  pdf.text(
    x + cols[0] + cols[1],
    y + headerMargin || y + BLOCK_HEADER_MARGIN,
    mUnitText
  )

  if (additionalPages.length > 0) {
    additionalPages.forEach((page) => {
      pdf.addPage(user.preferences.report_format, 'p')
      getHeader(
        pdf,
        user,
        shop,
        'Pre-Fit Interview Summary',
        customer,
        interview
      )
      pdf.setTextColor(user.preferences.report_color)
      pdf.text(LEFT_MARGIN, TOP_MARGIN + HEADER_HEIGHT, page.label)
      pdf.setTextColor(0, 0, 0)
      pdf.text(
        LEFT_MARGIN,
        TOP_MARGIN + HEADER_HEIGHT + BLOCK_HEADER_MARGIN * 2,
        pdf.splitTextToSize(page.text, 187)
      )
      getFooter({ pdf })
    })
  }

  pdf.setLineHeightFactor(1.15)
}

export const getBikeImage = ({ pdf, bikeType, x, y, reportType }) => {
  let imageSource
  switch (bikeType) {
    case 'MTB':
    case 'Mountain Bike':
    case 'Fat Bike':
      reportType !== 'fitting'
        ? (imageSource = '/mtbsizingannotated.png')
        : (imageSource = '/mtbannotated.png')
      break
    case 'TT/Tri':
    case 'TT/Tri Bike':
      reportType !== 'fitting'
        ? (imageSource = '/ttsizingannotated.png')
        : (imageSource = '/ttannotated.png')
      break
    default:
      reportType !== 'fitting'
        ? (imageSource = '/roadsizingannotated.png')
        : (imageSource = '/roadannotated.png')
  }

  const imageProps = pdf.getImageProperties(imageSource)
  const aspectRatio = imageProps.width / imageProps.height
  const maxHeight = 75
  const adjustedWidth = maxHeight * aspectRatio
  pdf.addImage(imageSource, 'JPG', x, y, adjustedWidth, maxHeight)
}

export const getSizingRecommendationForms = (
  sizingRecommendations,
  bikeType
) => {
  const {
    frameSize,
    minSaddleHeight,
    maxSaddleHeight,
    maxStandoverHeight,
    handlebarWidth,
    minSaddleWidth,
    maxSaddleWidth,
    topTubeAndStemDropBar,
    topTubeAndStemFlatBar,
    topTubeAndStemAeroBar,
    AdjustedTopTubeAndStemDropBar,
    AdjustedTopTubeAndStemFlatBar,
    AdjustedTopTubeAndStemAeroBar
  } = sizingRecommendations

  let dataForm

  switch (bikeType) {
    case 'Road':
      dataForm = roadBikeSizingForm
      break
    case 'MTB':
      dataForm = mountainBikeSizingForm
      break
    case 'TT/Tri':
      dataForm = ttBikeSizingForm
      break
    default:
      dataForm = roadBikeSizingForm
  }

  const dataObject = {
    frameSize: frameSize.toString(),
    saddleHeight: minSaddleHeight.toString() + '-' + maxSaddleHeight.toString(),
    maxStandoverHeight: maxStandoverHeight.toString(),
    handlebarWidth: handlebarWidth.toString(),
    saddleWidth: minSaddleWidth.toString() + '-' + maxSaddleWidth.toString(),
    topTubeAndStemDropBar: topTubeAndStemDropBar.toString(),
    topTubeAndStemFlatBar: topTubeAndStemFlatBar.toString(),
    topTubeAndStemAeroBar: topTubeAndStemAeroBar.toString(),
    AdjustedTopTubeAndStemDropBar: AdjustedTopTubeAndStemDropBar.toString(),
    AdjustedTopTubeAndStemFlatBar: AdjustedTopTubeAndStemFlatBar.toString(),
    AdjustedTopTubeAndStemAeroBar: AdjustedTopTubeAndStemAeroBar.toString()
  }
  return [dataForm, dataObject]
}

export const getFooter = ({ pdf }) => {
  const PAGE_END = pdf.getPageHeight(1)
  const FOOTER_END = PAGE_END - BOTTOM_MARGIN

  const FOOTER_START = FOOTER_END - FOOTER_HEIGHT
  const FOOTER_MID_POINT = FOOTER_END - FOOTER_HEIGHT / 2
  getHR(pdf, FOOTER_START)
  pdf.addImage('/fksicon.jpg', 'JPG', LEFT_MARGIN, FOOTER_START + 2, 9, 9)
  const copyrightstring =
    'Copyright © ' + new Date().getFullYear() + ' Fit Kit Systems'
  pdf.text(
    copyrightstring,
    LEFT_MARGIN + 15,
    FOOTER_MID_POINT + pdf.getLineHeightFactor()
  )

  pdf.text(
    'Proper use of recommendations is the sole responsibility of the user.',
    RIGHT_COLUMN_X + 22,
    FOOTER_MID_POINT + pdf.getLineHeightFactor()
  )
}

export const getBikeStrings = (bike) => {
  const { make, model, saddleMake, saddleModel } = bike
  let bikeString = 'Unspecified Bike'
  if (make && model) bikeString = make + ' ' + model
  if (bikeString.length > 25) bikeString = bikeString.substring(0, 25)
  let saddleString = saddleMake + ' ' + saddleModel
  if (saddleString.length > 25) saddleString = saddleString.substring(0, 25)
  let bikeUpdatedString = new Date().toDateString()
  if (bike.updated) bikeUpdatedString = utcToutc(bike.updated)
  return { bikeString, saddleString, bikeUpdatedString }
}

export const getBikeForm = (bikeType) => {
  let dataForm
  switch (bikeType) {
    case 'MTB':
    case 'Mountain Bike':
    case 'Fat Bike':
      dataForm = mountainBikeForm
      break
    case 'TT/Tri':
    case 'TT/Tri Bike':
      dataForm = ttBikeForm
      break
    default:
      dataForm = roadBikeForm
  }
  return dataForm
}
export const getFitPositionForm = (bikeType) => {
  let dataForm
  switch (bikeType) {
    case 'MTB':
    case 'Mountain Bike':
    case 'Fat Bike':
      dataForm = mountainBikeFitPositionForm
      break
    case 'TT/Tri':
    case 'TT/Tri Bike':
      dataForm = ttBikeFitPositionForm
      break
    default:
      dataForm = roadBikeFitPositionForm
  }
  return dataForm
}

export const getFitHistoryForm = (bikeType) => {
  let dataForm
  switch (bikeType) {
    case 'MTB':
    case 'Mountain Bike':
    case 'Fat Bike':
      dataForm = mountainBikeFitHistoryForm
      break
    case 'TT/Tri':
    case 'TT/Tri Bike':
      dataForm = ttBikeFitHistoryForm
      break
    default:
      dataForm = roadBikeFitHistoryForm
  }
  return dataForm
}

export const getBikeNotes = ({ pdf, notes, user, shop, bike, x, y }) => {
  pdf.setTextColor(user.preferences.report_color)
  pdf.text(x, y, 'Notes')
  pdf.setTextColor(0, 0, 0)
  if (notes) {
    const splitNotes = pdf.splitTextToSize(notes, 100)

    pdf.text(x, y + BLOCK_HEADER_MARGIN, splitNotes.slice(0, 13))
    if (splitNotes.length > 13) {
      pdf.addPage(user.preferences.report_format, 'p')
      pdf.setTextColor(user.preferences.report_color)
      pdf.text(LEFT_MARGIN, TOP_MARGIN + HEADER_HEIGHT, 'Notes continued. . .')
      pdf.setTextColor(0, 0, 0)

      pdf.text(
        LEFT_MARGIN,
        TOP_MARGIN + HEADER_HEIGHT + BLOCK_HEADER_MARGIN,
        pdf.splitTextToSize(splitNotes.slice(13).toString(), 187)
      )
      getHeader(pdf, user, shop, [
        'Bike Fit Results: ' + getBikeStrings(bike).bikeString,
        getBikeStrings(bike).bikeUpdatedString
      ])
      getFooter({ pdf })
    }
  }
}

export const getCustomerNotes = ({ pdf, notes, user, shop, x, y }) => {
  pdf.setTextColor(user.preferences.report_color)
  pdf.text(x, y, 'Notes')
  pdf.setTextColor(0, 0, 0)
  if (notes) {
    const splitNotes = pdf.splitTextToSize(notes, 187)
    pdf.text(LEFT_MARGIN, y + BLOCK_HEADER_MARGIN, splitNotes.slice(0, 13))

    if (splitNotes.length > 13) {
      pdf.addPage(user.preferences.report_format, 'p')
      pdf.setTextColor(user.preferences.report_color)
      pdf.text(LEFT_MARGIN, TOP_MARGIN + HEADER_HEIGHT, 'Notes continued. . .')
      pdf.setTextColor(0, 0, 0)

      pdf.text(
        LEFT_MARGIN,
        TOP_MARGIN + HEADER_HEIGHT + BLOCK_HEADER_MARGIN,
        pdf.splitTextToSize(splitNotes.slice(13).toString(), 187)
      )
      getHeader(pdf, user, shop, 'Fit Kit Studio Sizing Recommendations')
      getFooter({ pdf })
    }
  }
}

export const getFitPositionData = ({ dataForm, dataObject, user }) => {
  const { height_units, weight_units } = user.preferences

  const mUnitText = []

  const text = Object.entries(dataForm).map(([key, value]) => {
    let text = dataObject[key]
    if (value.type === 'number') text = text.toString()
    let mUnit = ' '
    if (value.type === 'number') mUnit = 'mm.'
    if (key === 'birthDate') text = utcToutc(text)
    if (key === 'height') {
      mUnit = heightMunit(height_units)
      if (height_units === 'Metric') text = convertedHeight(text, height_units)
    }
    if (key === 'weight') {
      mUnit = weightMunit(weight_units)
      if (weight_units === 'Metric') text = convertedWeight(text, weight_units)
    }
    if (text === true) text = 'Yes'
    if (text === false) text = 'No'

    value.type === 'number' && value.munit
      ? mUnitText.push(value.munit)
      : mUnitText.push(mUnit)

    return text
  })
  return { text, mUnitText }
}

export const compareHistory = (fit, priorFit, mUnits) => {
  const changes = fit.map((value, index) => {
    if (value === priorFit[index]) return ''
    return value
  })
  let unitChanges = []
  if (mUnits) {
    unitChanges = fit.map((value, index) => {
      if (value === priorFit[index]) return ''
      return mUnits[index]
    })
  }

  return { text: changes, mUnitText: unitChanges }
}

export const getHistoryTableBlock = ({
  pdf,
  dataObject,
  bike,
  heading,
  user,
  x,
  y,
  headerMargin,
  lineHeightFactor
}) => {
  const dataForm = getFitHistoryForm(bike.type)
  const labels = Object.entries(dataForm).map(
    ([key, value]) => value.label + ':'
  )

  const pageWidth = pdf.getPageWidth(1)

  const colWidth = 15
  const colGap = 10

  const hColX = [
    pageWidth - RIGHT_MARGIN - colWidth,
    pageWidth - RIGHT_MARGIN - colWidth * 2 - colGap,
    pageWidth - RIGHT_MARGIN - colWidth * 3 - colGap * 2,
    pageWidth - RIGHT_MARGIN - colWidth * 4 - colGap * 3,
    pageWidth - RIGHT_MARGIN - colWidth * 5 - colGap * 4
  ]

  if (lineHeightFactor) pdf.setLineHeightFactor(lineHeightFactor)

  pdf.setTextColor(user.preferences.report_color)
  pdf.text(x, y, heading)
  pdf.text(hColX[0] - DATE_MARGIN, y, yearMonthDayEval(bike.updated))
  pdf.setTextColor(0, 0, 0)
  pdf.text(x, y + headerMargin || y + BLOCK_HEADER_MARGIN, labels)

  pdf.text(
    hColX[0],
    y + headerMargin || y + BLOCK_HEADER_MARGIN,
    getFitPositionData({ dataForm, dataObject, user }).text
  )
  pdf.text(
    hColX[0] + 7,
    y + headerMargin || y + BLOCK_HEADER_MARGIN,
    getFitPositionData({ dataForm, dataObject, user }).mUnitText
  )

  //Begin History

  //get up to the four most recent fit histories and reverse the order

  const fitHistory = bike.fitHistory.slice(-4).reverse()

  //Print Dates
  pdf.setTextColor(user.preferences.report_color)
  fitHistory.map((fit, index) =>
    pdf.text(hColX[index + 1] - DATE_MARGIN, y, yearMonthDayEval(fit.date))
  )
  pdf.setTextColor(0, 0, 0)

  //Print up to four most recent fit histories

  fitHistory.forEach((fit, index) => {
    pdf.text(
      hColX[index + 1],
      y + headerMargin || y + BLOCK_HEADER_MARGIN,
      getFitPositionData({
        dataForm,
        dataObject: { ...bike, ...fitHistory[index] },
        user
      }).text
    )
    pdf.text(
      hColX[index + 1] + 7,
      y + headerMargin || y + BLOCK_HEADER_MARGIN,
      getFitPositionData({ dataForm, dataObject, user }).mUnitText
    )
  })

  //Compare current fit to most recent history and highlight changes

  compareHistory(
    getFitPositionData({
      dataForm,
      dataObject,
      user
    }).text,
    getFitPositionData({
      dataForm,
      dataObject: { ...bike, ...fitHistory[0] },
      user
    }).text
  )
  //pdf.setTextColor('#e9722e')
  pdf.setTextColor(200, 0, 0)
  pdf.text(
    hColX[0],
    y + headerMargin || y + BLOCK_HEADER_MARGIN,
    compareHistory(
      getFitPositionData({
        dataForm,
        dataObject,
        user
      }).text,
      getFitPositionData({
        dataForm,
        dataObject: { ...bike, ...fitHistory[0] },
        user
      }).text
    ).text
  )
  pdf.text(
    hColX[0] + 7,
    y + headerMargin || y + BLOCK_HEADER_MARGIN,
    compareHistory(
      getFitPositionData({
        dataForm,
        dataObject,
        user
      }).text,
      getFitPositionData({
        dataForm,
        dataObject: { ...bike, ...fitHistory[0] },
        user
      }).text,
      getFitPositionData({
        dataForm,
        dataObject,
        user
      }).mUnitText
    ).mUnitText
  )

  //Compare fit histories and highlight changes

  fitHistory.forEach((fit, index) => {
    if (index < fitHistory.length - 1) {
      pdf.text(
        hColX[index + 1],
        y + headerMargin || y + BLOCK_HEADER_MARGIN,
        compareHistory(
          getFitPositionData({
            dataForm,
            dataObject: { ...bike, ...fitHistory[index] },
            user
          }).text,
          getFitPositionData({
            dataForm,
            dataObject: { ...bike, ...fitHistory[index + 1] },
            user
          }).text,
          getFitPositionData({
            dataForm,
            dataObject,
            user
          }).mUnitText
        ).text
      )

      pdf.text(
        hColX[index + 1] + 7,
        y + headerMargin || y + BLOCK_HEADER_MARGIN,
        compareHistory(
          getFitPositionData({
            dataForm,
            dataObject: { ...bike, ...fitHistory[index] },
            user
          }).text,
          getFitPositionData({
            dataForm,
            dataObject: { ...bike, ...fitHistory[index + 1] },
            user
          }).text,
          getFitPositionData({
            dataForm,
            dataObject,
            user
          }).mUnitText
        ).mUnitText
      )
    }
  })

  //if total fit History is greater than 4, check highlight changes for the oldest fit History printed

  if (fitHistory.length < bike.fitHistory.length) {
    const priorFitIndex =
      bike.fitHistory.findIndex(
        (fit) => fit.date === fitHistory[fitHistory.length - 1].date
      ) - 1

    pdf.text(
      hColX[fitHistory.length],
      y + headerMargin || y + BLOCK_HEADER_MARGIN,
      compareHistory(
        getFitPositionData({
          dataForm,
          dataObject: { ...bike, ...fitHistory[fitHistory.length - 1] },
          user
        }).text,
        getFitPositionData({
          dataForm,
          dataObject: { ...bike, ...bike.fitHistory[priorFitIndex] },
          user
        }).text
      ).text
    )

    pdf.text(
      hColX[fitHistory.length] + 7,

      y + headerMargin || y + BLOCK_HEADER_MARGIN,
      compareHistory(
        getFitPositionData({
          dataForm,
          dataObject: { ...bike, ...fitHistory[fitHistory.length - 1] },
          user
        }).text,
        getFitPositionData({
          dataForm,
          dataObject: { ...bike, ...bike.fitHistory[priorFitIndex] },
          user
        }).text,
        getFitPositionData({
          dataForm,
          dataObject,
          user
        }).mUnitText
      ).mUnitText
    )
  }

  pdf.setTextColor(0, 0, 0)
  pdf.setLineHeightFactor(1.15)
}
