import React from 'react'

import { PRINT_PDF_ICON } from '../utils/fontAwesomeDefaults'

import Tip from '../components/layout/overlays/Tip'

const PrintPDFButton = (props) => {
  const { pdfFunction, tip } = props
  const printPDF = () => {
    pdfFunction({ ...props })
  }

  return (
    <Tip message={tip} placement='top'>
      <i
        style={{ cursor: 'pointer' }}
        className={PRINT_PDF_ICON}
        onClick={printPDF}
      />
    </Tip>
  )
}

export default PrintPDFButton
