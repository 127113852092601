//import road bike fit position fields

import {
  saddleHeight,
  saddleHeightBB,
  saddleSetBack,
  saddleAngle,
  saddleNoseToBar,
  saddleNoseToHood,
  saddleToBarDrop,
  handlebarReachHX,
  handlebarStackHY,
  handlebarAngle,
  hoodAngle
} from '../field-objects/fitPositionFields'

//import mtb specific fit position fields

import {
  mtbSaddleNoseToGripEnd,
  mtbSaddleToGripCenterDropRise
} from '../field-objects/fitPositionFields'

//import tt specific fit position fields

import {
  ttSaddleToPadCenterDrop,
  ttSaddleNoseToPadRear,
  ttSaddleNoseToEndOfExtensions,
  ttExtensionWidthAtClamps,
  ttExtensionWidthAtEnd,
  ttExtensionAngle,
  ttPadWidth,
  ttPadXReachRearOfPad,
  ttPadXReachCenterOfPad,
  ttPadYStackRearOfPad,
  ttBasebarReachX,
  ttBasebarStackY,
  ttBasebarAngle
} from '../field-objects/fitPositionFields'

//import selected bike equipment fields

import {
  seatPostOffset,
  saddleMake,
  saddleModel,
  saddleWidth,
  crankLength,
  pedalType,
  pedalMakeModel,
  stemType,
  stemLength,
  stemAngle,
  spacersBelow,
  spacersAbove,
  handlebarWidth,
  handlebarReach,
  ttBasebarWidth,
  ttAerobarType,
  ttAerobarMakeModel,
  ttExtensionsShape,
  ttRisers,
  ttPadsMakeModel
} from '../field-objects/bikeFields'

export const roadBikeFitHistoryForm = {
  saddleHeight,
  saddleHeightBB,
  saddleSetBack,
  saddleAngle,
  saddleMake,
  saddleModel,
  saddleWidth,
  seatPostOffset,
  crankLength,
  pedalType,
  pedalMakeModel,
  stemType,
  stemLength,
  stemAngle,
  spacersBelow,
  spacersAbove,
  handlebarWidth,
  saddleNoseToBar,
  saddleNoseToHood,
  saddleToBarDrop,
  handlebarReachHX,
  handlebarStackHY,
  handlebarAngle,
  hoodAngle
}

export const mountainBikeFitHistoryForm = {
  saddleHeight,
  saddleHeightBB,
  saddleSetBack,
  saddleAngle,
  saddleMake,
  saddleModel,
  saddleWidth,
  seatPostOffset,
  crankLength,
  pedalType,
  pedalMakeModel,
  stemType,
  stemLength,
  stemAngle,
  spacersBelow,
  spacersAbove,
  handlebarWidth,
  saddleNoseToBar,
  mtbSaddleNoseToGripEnd,
  mtbSaddleToGripCenterDropRise,
  handlebarReachHX,
  handlebarStackHY
}

export const ttBikeFitHistoryForm = {
  saddleHeight,
  saddleHeightBB,
  saddleSetBack,
  saddleAngle,
  saddleMake,
  saddleModel,
  saddleWidth,
  crankLength,
  pedalType,
  pedalMakeModel,
  stemType,
  stemLength,
  stemAngle,
  spacersBelow,
  spacersAbove,
  handlebarReach,
  ttBasebarWidth,
  ttAerobarType,
  ttAerobarMakeModel,
  ttExtensionsShape,
  ttRisers,
  ttPadsMakeModel,
  ttSaddleToPadCenterDrop,
  ttSaddleNoseToPadRear,
  ttSaddleNoseToEndOfExtensions,
  ttExtensionWidthAtClamps,
  ttExtensionWidthAtEnd,
  ttExtensionAngle,
  ttPadWidth,
  ttPadXReachRearOfPad,
  ttPadXReachCenterOfPad,
  ttPadYStackRearOfPad,
  ttBasebarReachX,
  ttBasebarStackY,
  ttBasebarAngle
}

//export form containing all fit position fields

/* export const fitPositionForm = {
  saddleHeight,
  saddleHeightBB,
  saddleSetBack,
  saddleAngle,
  saddleNoseToBar,
  mtbSaddleNoseToGripEnd,
  mtbSaddleToGripCenterDropRise,
  saddleNoseToHood,
  saddleToBarDrop,
  handlebarReachHX,
  handlebarStackHY,
  handlebarAngle,
  hoodAngle,
  ttSaddleToPadCenterDrop,
  ttSaddleNoseToPadRear,
  ttSaddleNoseToEndOfExtensions,
  ttExtensionWidthAtClamps,
  ttExtensionWidthAtEnd,
  ttExtensionAngle,
  ttPadWidth,
  ttPadXReachRearOfPad,
  ttPadXReachCenterOfPad,
  ttPadYStackRearOfPad,
  ttBasebarReachX,
  ttBasebarStackY,
  ttBasebarAngle
} */
