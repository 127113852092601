import { useState, useEffect } from 'react'
import store from '../../../redux/store'
import { loadCustomerPrefitInterviews } from '../../../redux/actions/prefitInterviews'

const useCustomerInterviews = (customerId) => {
  const [interviewsLoaded, setInterviewsLoaded] = useState(false)

  useEffect(() => {
    const fetchCustomerInterviews = async () => {
      await store.dispatch(loadCustomerPrefitInterviews(customerId))
      setInterviewsLoaded(true)
    }
    fetchCustomerInterviews()
  }, [customerId])

  return interviewsLoaded
}

export default useCustomerInterviews
