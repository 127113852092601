import React from 'react'
import { Spinner } from 'react-bootstrap'
import ResourcesTabs from './ResourcesTabs'
import useResources from '../../hooks/data-fetch-hooks/useResources'

const Resources = () => {
  const resourcesObject = useResources()
  const { resourcesLoaded, resources } = resourcesObject

  if (!resourcesLoaded)
    return (
      <Spinner
        animation='border'
        variant='primary'
        className='d-block mx-auto'
      />
    )

  return <ResourcesTabs resources={resources} />
}

export default Resources
