import React from 'react'
import { Form, Row, Col, InputGroup, Badge } from 'react-bootstrap'

import InputGroupText from './InputGroupText'

const QuickFitFormGroup = ({
  label,
  value,
  type,
  name,
  isValid,
  isInvalid,
  onChange,
  placeholder,
  as,
  rows,
  spellCheck,
  min,
  max,
  step,
  munit,
  colSize,
  poslabel,
  neglabel,
  user,
  cols
}) => {
  if (type === 'date') value = value.substring(0, 10)
  if (!cols) cols = ['2', '3', '']

  if (type === 'static-text')
    return (
      <>
        <Row className='mb-2'>
          <Col xs={cols[0]}></Col>
          <Col xs={cols[1]}>
            <Form.Text>{label} </Form.Text>
          </Col>

          <Col xs={cols[2]}>
            <Form.Text>{value}</Form.Text>
          </Col>
          <Col xs={cols[4]}></Col>
        </Row>
      </>
    )

  return (
    <>
      <Row className='mb-2'>
        <Col xs={cols[0]}></Col>
        <Col xs={cols[1]}>
          <Form.Label>
            {label}{' '}
            {(poslabel || neglabel) && value < 0 && (
              <Badge bg={'danger'}>{neglabel} </Badge>
            )}
            {(poslabel || neglabel) && value > 0 && (
              <Badge bg={'success'}>{poslabel} </Badge>
            )}
          </Form.Label>
        </Col>

        <Col xs={cols[2]}>
          <InputGroup size='sm'>
            <Form.Control
              size={'sm'}
              as={as}
              rows={rows}
              spellCheck={spellCheck}
              value={value}
              type={type}
              isValid={isValid}
              isInvalid={isInvalid}
              onChange={onChange}
              name={name}
              placeholder={placeholder}
              min={min || 0}
              max={max}
              step={step}
            />
            {type === 'number' && munit !== 'none' && (
              <InputGroupText munit={munit} user={user} label={label} />
            )}
          </InputGroup>
        </Col>
        <Col xs={cols[4]}></Col>
      </Row>
    </>
  )
}

export default QuickFitFormGroup
