import Tip from '../../layout/overlays/Tip'
import { Table } from 'react-bootstrap'

const TableData = ({ dataForm, dataObject, user, fontStyle }) => {
  if (!user || !dataForm || !dataObject) return null
  return (
    <Table
      striped
      borderless
      size='sm'
      style={fontStyle || { fontSize: '.8rem' }}
    >
      <tbody>
        {dataForm.map((field) => {
          return (
            <tr key={field.name}>
              <td>
                <>
                  {field.tip ? (
                    <Tip message={field.tip} placement='top'>
                      <span style={{ cursor: 'help' }}>{field.label}</span>
                    </Tip>
                  ) : (
                    <>{field.label}</>
                  )}
                </>
              </td>
              <td style={{ textAlign: 'right' }}>
                {dataObject[field.name]}{' '}
                {field.type === 'number' &&
                  field.munit !== 'none' &&
                  (field.munit || '.mm')}
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default TableData
