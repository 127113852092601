import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import DashboardCard from '../../layout/cards-containers/DashboardCard'
import UpgradeModal from './UpgradeModal'
import PricingInfo from '../dashboard/PricingInfo'

const UnSubscribed = () => {
  const [showModal, setShowModal] = useState(false)

  const clickUpgrade = () => {
    setShowModal(true)
  }
  return (
    <>
      <UpgradeModal showModal={showModal} setShowModal={setShowModal} />
      <DashboardCard
        title='Subscription'
        footer={
          <div className='d-grid gap-2'>
            <Button onClick={clickUpgrade} variant='dark'>
              Upgrade to Quick Fit (start free trial)
            </Button>
          </div>
        }
      >
        You currently have access to the Fit Kit Studio{' '}
        <Link to='/tools-resources/tools'>Toolbox</Link>, which is free of
        charge. <p />
        Upgrade to Quick Fit for the following additional features:
        <p />
        <ul>
          <li>
            Additional calculations based on shoulder width and sit bone width.
          </li>
          <li>
            Cloud storage of customer sizing data, contact information, notes
            and recommendations.
          </li>
          <li>
            Cloud storage of key bike fit measurements for each bike owned by a
            customer.
          </li>
          <li>Summary PDF reports for printing or emailing to customers.</li>
          <li>
            Pre-fit interview forms and automated customer intake process.
          </li>
          <li>Customized bike shop or fitting studio branding.</li>
        </ul>
        <PricingInfo />
        <p>You may cancel your subscription at any time.</p>
      </DashboardCard>
    </>
  )
}

export default UnSubscribed
