import React from 'react'
import { Card, Tab, Row, Col, Nav } from 'react-bootstrap'

import ShoesCleats from './ShoesCleats'
import ConfidentialBikeNotes from './ConfidentialBikeNotes'
import SharedBikeNotes from './SharedBikeNotes'

import BikeEquipment from './BikeEquipment'
import FitPosition from './FitPosition'
import FitHistory from './FitHistory'

const BikeTabs = ({ bike, customer, user, shop, fieldOverrides }) => {
  return (
    <Tab.Container id='left-tabs-example' defaultActiveKey='equipment'>
      <Row>
        <Col sm={3}>
          <Card>
            <Nav variant='pills' className='flex-column'>
              <Nav.Item>
                <Nav.Link eventKey='equipment'>Equipment</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='fit-position'>Fit Position</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='shoes-cleats'>Shoes/Cleats</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='shared-bike-notes'>
                  Shared Bike Notes
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='confidential-bike-notes'>
                  Confidential Bike Notes
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='fit-history'>Fit History</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey='customer'>Customer Summary</Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Card>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey='equipment'>
              <BikeEquipment
                bike={bike}
                user={user}
                fieldOverrides={fieldOverrides}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='fit-position'>
              <FitPosition
                bike={bike}
                user={user}
                fieldOverrides={fieldOverrides}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='shoes-cleats'>
              <ShoesCleats
                bike={bike}
                user={user}
                fieldOverrides={fieldOverrides}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='shared-bike-notes'>
              <SharedBikeNotes
                bike={bike}
                user={user}
                fieldOverrides={fieldOverrides}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='confidential-bike-notes'>
              <ConfidentialBikeNotes
                bike={bike}
                user={user}
                fieldOverrides={fieldOverrides}
              />
            </Tab.Pane>
            <Tab.Pane eventKey='fit-history'>
              <FitHistory
                bike={bike}
                user={user}
                customer={customer}
                shop={shop}
                fieldOverrides={fieldOverrides}
              />
            </Tab.Pane>
            {/*  <Tab.Pane eventKey='customer'>
              <CustomerSummary
                customer={customer}
                user={user}
                fieldOverrides={fieldOverrides}
              />
            </Tab.Pane> */}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
}

export default BikeTabs
