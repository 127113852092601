import {
  feedbackMessage,
  read,
  resolved
} from '../field-objects/feedbackFields'

export const feedbackForm = {
  feedbackMessage
}

export const feedbackActionsForm = {
  read,
  resolved
}
