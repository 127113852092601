import { Form, Row, Col } from 'react-bootstrap'

const Radio = (props) => {
  const { label, inline, options, value, xs } = props

  return (
    <Row className='align-items-center mb-3'>
      {label && (
        <Col xs={xs || '2'}>
          <Form.Label className={inline ? 'me-3' : ''}>{label}</Form.Label>
        </Col>
      )}
      <Col>
        {options.map((option) => {
          return (
            <Form.Check
              key={option}
              {...props}
              label={option}
              value={option}
              checked={value === option}
            />
          )
        })}
      </Col>
    </Row>
  )
}

export default Radio
