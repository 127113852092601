import React from 'react'
import DashboardCard from '../../../layout/cards-containers/DashboardCard'
import TableData from '../../../layout/cards-containers/TableData'
import StatTableData from '../../../layout/cards-containers/StatTableData'

import DownloadDataButton from '../../../hook-components/DownloadRecordsButton'
import {
  customerDataCSV,
  bikeDataCSV,
  userDataCSV
} from '../../../../utils/csvFunctions'

const KeyStats = ({ adminStats, user }) => {
  const dataForm = {
    activeSubscriptions: { label: 'Active Subscriptions' },
    trialSubscriptions: { label: 'Active Trial Subscriptions' },
    activeFreeUsers: { label: 'Active Free Users' },
    inActiveFreeUsers: { label: 'Inactive Free Users' },
    neverLoggedIn: { label: 'Never Logged In' },
    profileModified: { label: 'Never Logged In With Modified Profiles' },
    canceledActive: { label: 'Canceled Active Free Users' },
    canceledInactive: { label: 'Canceled Inactive Free Users' },
    notSubscribed: { label: 'Users with Unknown Status' }
  }

  const {
    activeSubscriptions,
    notSubscribed,
    trialSubscriptions,
    activeFreeUsers,
    inActiveFreeUsers,
    neverLoggedIn,
    profileModified,
    canceledActive,
    canceledInactive
  } = adminStats

  return (
    <DashboardCard
      style={{ minHeight: '100px', fontSize: '.8rem' }}
      title='Key Stats'
    >
      <TableData
        dataForm={dataForm}
        dataObject={{
          activeSubscriptions: activeSubscriptions,
          trialSubscriptions: trialSubscriptions,
          activeFreeUsers: activeFreeUsers,
          inActiveFreeUsers: inActiveFreeUsers,
          neverLoggedIn: neverLoggedIn,
          profileModified: profileModified,
          canceledActive: canceledActive,
          canceledInactive: canceledInactive,

          notSubscribed: notSubscribed
        }}
        user={user}
      />
      <StatTableData
        dataForm={{
          usersCreated: {
            label: (
              <span>
                Users Created{' '}
                <DownloadDataButton
                  tip='Download User Data as CSV File'
                  filename='all-users'
                  csv={userDataCSV(
                    adminStats.userList,
                    user,
                    'User List Generated By',
                    adminStats.subscriptionList
                  )}
                />
              </span>
            )
          },

          customersCreated: {
            label: (
              <span>
                Customers Created{' '}
                <DownloadDataButton
                  tip='Download Customer Data as CSV File'
                  filename='all-customers'
                  csv={customerDataCSV(
                    adminStats.customerList,
                    user,
                    'Customer List Generated By'
                  )}
                />
              </span>
            )
          },
          bikesCreated: {
            label: (
              <span>
                Bikes Created{' '}
                <DownloadDataButton
                  tip='Download Bike Data as CSV File'
                  filename='all-bikes'
                  csv={bikeDataCSV(
                    adminStats.bikeList,
                    user,
                    'Bike List Generated By'
                  )}
                />
              </span>
            )
          },
          newSubscriptions: { label: 'New Subscriptions' },
          canceledSubscriptions: { label: 'Canceled Subscriptions' }
        }}
        dataObject={adminStats.stats}
      />
    </DashboardCard>
  )
}

export default KeyStats
