import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Card, Tab, Row, Col, Nav } from 'react-bootstrap'

import EditProfile from './user/EditProfile'
import EditPassword from './user/EditPassword'
import EditGravatar from './user/EditGravatar'
import EditPreferences from './user/EditPreferences'
import EditShop from './shop/EditShop'
import ManageSubscription from './subscription/ManageSubscription'
import DeleteAccount from './user/DeleteAccount'
import ApiAccess from './api-access/ApiAccess'

const SettingsTabs = ({ user, activeStatus }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [tab, setTab] = useState('profile')

  useEffect(() => {
    if (tab !== searchParams.get('tab') && searchParams.get('tab') !== null)
      setTab(searchParams.get('tab'))
    if (searchParams.get('tab') === null) setTab('profile')
  }, [tab, searchParams])

  const onSelect = (activeKey) => {
    setSearchParams(`tab=${activeKey}`)
    setTab(activeKey)
  }

  return (
    <Tab.Container
      id='account-settings-tabs'
      activeKey={tab}
      onSelect={onSelect}
    >
      <Row>
        <Col sm={3}>
          <Card>
            <Nav variant='pills' className='flex-column'>
              <Nav.Item>
                <Nav.Link eventKey='profile'>Profile</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='preferences'>Preferences</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='shop'>Shop/Studio</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='subscription'>Subscription</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey='delete'>Delete Account</Nav.Link>
              </Nav.Item>
              {(user.role === 'user+api' || user.role === 'admin') && (
                <Nav.Item>
                  <Nav.Link eventKey='api'>API Access</Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Card>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey='profile'>
              <EditProfile />
              <EditPassword />

              <EditGravatar user={user} />
            </Tab.Pane>
            <Tab.Pane eventKey='preferences'>
              <EditPreferences />
            </Tab.Pane>
            <Tab.Pane eventKey='shop'>
              <EditShop activeStatus={activeStatus} />
            </Tab.Pane>
            <Tab.Pane eventKey='subscription'>
              <ManageSubscription />
            </Tab.Pane>
            <Tab.Pane eventKey='delete'>
              <DeleteAccount />
            </Tab.Pane>
            {(user.role === 'user+api' || user.role === 'admin') && (
              <Tab.Pane eventKey='api'>
                <ApiAccess />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
}

export default SettingsTabs
