import { useState } from 'react'
import DashboardCard from '../../layout/cards-containers/DashboardCard'
import { dateToSecondsStamp } from '../../../utils/dateFormats'

import UpdatePaymentModal from './UpdatePaymentModal'
import UpdatePaymentButton from './UpdatePaymentButton'
import PurchaseNewSubscriptionButton from './PurchaseNewSubscriptionButton'
import ActivateNewTrialButton from './ActivateNewTrialButton'

import useConfirmCancelModal from '../../hooks/modal-hooks/useConfirmCancelModal'

const Canceled = ({ subscription, last4 }) => {
  const { canceled_at } = subscription
  const canceledDate = new Date(canceled_at * 1000).toDateString()

  const launchDate = dateToSecondsStamp(new Date('2022/10/31'))
  //const launchDate = dateToSecondsStamp(new Date('2022/11/20'))

  const trialEligible = canceled_at < launchDate

  const [showModal, setShowModal] = useState(false)

  const purchaseAfterCancelationModal = useConfirmCancelModal({
    confirmationPrompt: 'To confirm activation',
    confirmationText: 'activate',
    confirmationButtonText: 'Purchase Subscription'
  })
  const activateNewTrialModal = useConfirmCancelModal({
    confirmationPrompt: 'To confirm activation',
    confirmationText: 'activate',
    confirmationButtonText: 'Activate Free Trial'
  })

  if (trialEligible)
    return (
      <>
        <DashboardCard
          title='Subscription'
          footer={
            <ActivateNewTrialButton
              subscription={subscription}
              {...activateNewTrialModal}
            />
          }
        >
          {' '}
          <p>
            Your subscription to Quick Fit was canceled on <b>{canceledDate}</b>{' '}
            and is no longer active.
          </p>
          <p>But don't worry, all of your data is saved.</p>
          <p>
            A lot has changed since you last used Quick Fit, and we want you to
            exprience the all new Fit Kit Studio 3 Quick Fit service with a new
            60 day free trial. To activate your free trial, click the button
            below.
          </p>
          <p>
            Your current payment method is: Credit Card ending in:{' '}
            <b>{last4}</b> <UpdatePaymentButton />
          </p>
        </DashboardCard>
        <UpdatePaymentModal showModal={showModal} setShowModal={setShowModal} />
      </>
    )
  else
    return (
      <>
        <DashboardCard
          title='Subscription'
          footer={
            <PurchaseNewSubscriptionButton
              subscription={subscription}
              {...purchaseAfterCancelationModal}
            />
          }
        >
          {' '}
          <p>
            Your subscription to Quick Fit was canceled on <b>{canceledDate}</b>{' '}
            and is no longer active.
          </p>
          <p>But don't worry, all of your data is saved.</p>
          <p>
            To use Quick Fit again and restore access to your data, please
            purchase a new subscription.
          </p>
          <p>
            Your current payment method is: Credit Card ending in:{' '}
            <b>{last4}</b> <UpdatePaymentButton />
          </p>
        </DashboardCard>
        <UpdatePaymentModal showModal={showModal} setShowModal={setShowModal} />
      </>
    )
}

export default Canceled
