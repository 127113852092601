import { Table } from 'react-bootstrap'
import BikeSearchListRow from './BikeSearchListRow'

const BikeSearchList = ({ searchData }) => {
  return (
    <Table size='sm' style={{ fontSize: '.8rem' }}>
      <thead>
        <tr>
          <th>Make</th>
          <th>Model</th>
          <th>Frame Size</th>
          <th>View</th>
        </tr>
      </thead>
      <tbody>
        {searchData.map((bike) => {
          return <BikeSearchListRow key={bike._id} bike={bike} />
        })}
      </tbody>
    </Table>
  )
}

export default BikeSearchList
