import React from 'react'
import { Link } from 'react-router-dom'
import DashboardCard from '../../layout/cards-containers/DashboardCard'

const ShopUpgradeNotice = () => {
  return (
    <DashboardCard title='Shop/Studio Information'>
      <p className='fw-bold'>Available to subscribers only.</p>
      <p>
        Upgrade your subscription to create customized PDF reports containing
        shop or studio details, contact info and logo.
      </p>
      <p>PDF reports can be printed and/or emailed to customers.</p>
      <p>
        <a
          href='/FKS_Fit_Report-Sample-Custom.pdf'
          target='_blank'
          rel='noreferrer noopener'
        >
          Sample Custom PDF Report
        </a>
      </p>
      <p>
        <Link to='/account/settings?tab=subscription'>
          Upgrade Subscription
        </Link>{' '}
      </p>
    </DashboardCard>
  )
}

export default ShopUpgradeNotice
