import {
  calculateFrameSize,
  calculateMinimumSaddleHeight,
  calculateMaximumSaddleHeight,
  calculateMaximumStandoverHeight,
  calculateHandlebarWidth,
  calculateMinimumSaddleWidth,
  calculateMaximumSaddleWidth,
  calculateUpperBody,
  calculateTopTubeStemCombination,
  calculateSoftScore
} from '../../../lib/fitkit-js-functions-v3'

import { calculateAge } from '../../../utils/dateFormats'

const useSizingRecommendations = (bodyMeasurements, softScores, birthDate) => {
  const { footLength, inseam, torso, arm, shoulders, sitBones } =
    bodyMeasurements

  const { ridingStyle, flexibility, preconditions } = softScores

  /*   const ageDifMs = Date.now() - new Date(birthDate).getTime()
  const ageDate = new Date(ageDifMs)
  const age = Math.abs(ageDate.getUTCFullYear() - 1970) */

  const age = calculateAge(birthDate)

  const frameSize = calculateFrameSize(inseam)
  const minSaddleHeight = calculateMinimumSaddleHeight(frameSize, footLength)
  const maxSaddleHeight = calculateMaximumSaddleHeight(frameSize, footLength)
  const maxStandoverHeight = calculateMaximumStandoverHeight(inseam)
  const handlebarWidth = calculateHandlebarWidth(shoulders)
  const minSaddleWidth = calculateMinimumSaddleWidth(sitBones)
  const maxSaddleWidth = calculateMaximumSaddleWidth(sitBones)
  const upperBody = calculateUpperBody(torso, arm)
  const softScore = calculateSoftScore(
    age,
    ridingStyle,
    flexibility,
    preconditions
  )
  const topTubeAndStemDropBar = calculateTopTubeStemCombination(
    upperBody,
    0,
    'Drop'
  )
  const topTubeAndStemFlatBar = calculateTopTubeStemCombination(
    upperBody,
    0,
    'Flat'
  )
  const topTubeAndStemAeroBar = topTubeAndStemDropBar - 2

  const AdjustedTopTubeAndStemDropBar = calculateTopTubeStemCombination(
    upperBody,
    softScore,
    'Drop'
  )
  const AdjustedTopTubeAndStemFlatBar = calculateTopTubeStemCombination(
    upperBody,
    softScore,
    'Flat'
  )
  const AdjustedTopTubeAndStemAeroBar = AdjustedTopTubeAndStemDropBar - 2

  return {
    frameSize,
    minSaddleHeight,
    maxSaddleHeight,
    maxStandoverHeight,
    handlebarWidth,
    minSaddleWidth,
    maxSaddleWidth,
    topTubeAndStemDropBar,
    topTubeAndStemFlatBar,
    topTubeAndStemAeroBar,
    AdjustedTopTubeAndStemDropBar,
    AdjustedTopTubeAndStemFlatBar,
    AdjustedTopTubeAndStemAeroBar
  }
}

export default useSizingRecommendations
