import React from 'react'

import {
  TRASH_ICON,
  TRASH_ICON_FKS,
  WARNING_ICON
} from '../../utils/fontAwesomeDefaults'

import Tip from '../layout/overlays/Tip'
import ConfirmCancelModal from '../layout/modals/ConfirmCancelModal'

import useConfirmCancelModal from '../hooks/modal-hooks/useConfirmCancelModal'

const DeleteRecordButton = ({
  icon,
  recordId,
  tip,
  title,
  confirmationPrompt,
  confirmationText,
  confirmationButtonText,
  deleteFunction,
  disabled,
  children
}) => {
  const deleteRecordModal = useConfirmCancelModal({
    icon: icon || WARNING_ICON,
    title: title,
    confirmationPrompt: confirmationPrompt || 'To confirm deletion',
    confirmationText: confirmationText,
    confirmationButtonIcon: TRASH_ICON,
    confirmationButtonText: confirmationButtonText,

    confirmationAction: () => {
      deleteFunction(recordId)
    }
  })

  return (
    <>
      <Tip message={tip} placement='top'>
        <i
          style={{ cursor: 'pointer' }}
          className={TRASH_ICON_FKS}
          onClick={deleteRecordModal.setShowModal}
          id={recordId}
        />
      </Tip>
      <ConfirmCancelModal
        {...deleteRecordModal}
        children={children}
        disabled={disabled}
      />
    </>
  )
}

export default DeleteRecordButton
