import { Routes, Route, useMatch } from 'react-router-dom'
import NavRoutes from '../routing/NavRoutes'
import AlertRoutes from '../routing/AlertRoutes'
import SubNavRoutes from '../routing/SubNavRoutes'

const HeaderRoutes = () => {
  const match = useMatch('/')

  return (
    <Routes>
      <Route
        exact
        path='/pre-fit-interview/:userId'
        element={<div style={{ minHeight: '0px' }}></div>}
      />

      <Route
        exact
        path='/upgrade'
        element={
          <div className='smallHeaderDiv'>
            <NavRoutes />
            <AlertRoutes />
          </div>
        }
      />
      <Route
        exact
        path='/terms-of-use'
        element={
          <div className='smallHeaderDiv'>
            <NavRoutes />
            <AlertRoutes />
          </div>
        }
      />
      <Route
        exact
        path='/privacy-policy'
        element={
          <div className='smallHeaderDiv'>
            <NavRoutes />
            <AlertRoutes />
          </div>
        }
      />
      <Route
        exact
        path='/product/*'
        element={
          <div className='smallHeaderDiv'>
            <NavRoutes />
            <AlertRoutes />
          </div>
        }
      />
      {/* <Route
        exact
        path='/'
        element={
          <div className='smallHeaderDiv'>
            <NavRoutes />
            <AlertRoutes />
          </div>
        }
      /> */}
      <Route
        path='*'
        element={
          match ? (
            <div className='smallHeaderDiv'>
              <NavRoutes />
              <AlertRoutes />
            </div>
          ) : (
            <div className='headerDiv'>
              <NavRoutes />
              <AlertRoutes />
              <div className='subHeaderDiv'>
                <SubNavRoutes />
              </div>
            </div>
          )
        }
      />
    </Routes>
  )
}

export default HeaderRoutes
