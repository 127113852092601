import React from 'react'
import { Modal, Form, Button } from 'react-bootstrap'

import { CANCEL_ICON } from '../../../utils/fontAwesomeDefaults'

const ViewRecordModal = ({ showModal, title, icon, children, clickCancel }) => {
  return (
    <Modal size='lg' centered show={showModal} onHide={clickCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          {icon && <i className={icon} />} {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {children}
        <Form className='mt-3'></Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='dark' onClick={clickCancel}>
          <i className={CANCEL_ICON} /> Exit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ViewRecordModal
