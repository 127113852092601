import React from 'react'
import QuickFitDashboardCard from '../QuickFitDashboardCard'
import QuickFitDashboardTitle from '../QuickFitDashboardTitle'

const InverviewLinks = ({ user, shop }) => {
  const Url = `${process.env.REACT_APP_URL_BASE}/pre-fit-interview/${user._id}`
  let name = user.name

  if (shop._id && shop.name) name = shop.name

  return (
    <QuickFitDashboardCard
      style={{ minHeight: '250px', fontSize: '.8rem' }}
      title={
        <>
          <QuickFitDashboardTitle title='Pre-Fit Interview Links' />
        </>
      }
    >
      <p>
        These links direct customers to the pre-fit interview form linked to
        your account.
      </p>
      <p>Completed interviews will appear in the table above</p>
      <p>
        {' '}
        <a href={Url}>{Url} </a>{' '}
      </p>
      <p>
        <a href={Url}>Pre-Fit Interview</a>{' '}
      </p>
      <p>
        {' '}
        {name && (
          <>
            <a href={Url}>{name + ' Pre-Fit Interview'}</a>
          </>
        )}
      </p>
      <p>
        Compose email:{' '}
        <a
          href={`mailto:?subject= ${name}-Pre-Fit Interview&body=This email is being sent to you from ${name}. Please use the link below to complete your pre-fit interview.%0D%0A%0D%0A ${Url}`}
        >
          <i className='far fa-envelope' />
        </a>
      </p>
    </QuickFitDashboardCard>
  )
}

export default InverviewLinks
