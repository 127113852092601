import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { loadCustomerBikes } from '../../../redux/actions/bikes'
import { convertedHeight, convertedWeight } from '../../../utils/heightWeight'

import CustomerHeader from './CustomerHeader'
import CustomerTabs from './CustomerTabs'

import useRecord from '../../hooks/data-fetch-hooks/useRecord'

const EditCustomerData = ({ cyclists, user, shop }) => {
  const [customer, setCustomer] = useState({})

  const customerId = useParams().customerId

  const customerRecord = useRecord(cyclists, customerId)

  const { height_units, weight_units } = user.preferences

  useEffect(() => {
    if (customerRecord._id) {
      const modifiedCustomer = {
        ...customerRecord,
        bodyMeasurements: {
          ...customerRecord.bodyMeasurements,
          height: convertedHeight(
            customerRecord.bodyMeasurements.height,
            height_units
          ),
          weight: convertedWeight(
            customerRecord.bodyMeasurements.weight,
            weight_units
          )
        }
      }
      setCustomer(modifiedCustomer)
      //setCustomer(customerRecord)
    }
  }, [customerRecord, customerId, height_units, weight_units])

  if (!customer._id || !user._id) return null

  return (
    <>
      <CustomerHeader
        {...customer.cyclistProfile}
        created={customer.created}
        updated={customer.updated}
      />
      <CustomerTabs customer={customer} user={user} shop={shop} />
    </>
  )
}

const mapStateToProps = (state) => ({
  bikes: state.bikes,
  users: state.users
})

export default connect(mapStateToProps, { loadCustomerBikes })(EditCustomerData)
