import React from 'react'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

const RequireAdmin = ({
  children,
  redirectTo,
  users: { isAuthenticated, userLoading, user }
}) => {
  if (userLoading)
    return (
      <Spinner
        animation='border'
        variant='primary'
        className='d-block mx-auto'
      />
    )

  const { role } = user

  return isAuthenticated && role === 'admin' ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  )
}

const mapStateToProps = (state) => ({
  users: state.users
})

export default connect(mapStateToProps)(RequireAdmin)
