import React from 'react'
import { connect } from 'react-redux'
import { getIcon, getMessageIcon } from '../../../utils/generalFunctions'
import { utcToutc } from '../../../utils/dateFormats'

import { ADMIN_ICON, EDIT_ICON } from '../../../utils/fontAwesomeDefaults'

import useEditMessageForm from '../../hooks/form-hooks/admin/useEditMessageForm'

import EditFormButton from '../../hook-components/EditFormButton'
import DeleteRecordButton from '../../hook-components/DeleteRecordButton'

import {
  deleteAdminMessage,
  updateAdminMessage
} from '../../../redux/actions/adminmessages'

const MessageRow = ({ message, deleteAdminMessage, updateAdminMessage }) => {
  const start = message.start.substring(0, 10)
  let stop = null
  if (message.stop) stop = message.stop.substring(0, 10)

  const editMessageForm = useEditMessageForm(message)

  const oldValues = {
    icon: message.icon,
    title: message.title,
    content: message.content,
    start: message.start,
    stop: message.stop
  }
  const newValues = {
    icon: editMessageForm.values.icon,
    title: editMessageForm.values.title,
    content: editMessageForm.values.content,
    start: editMessageForm.values.start,
    stop: editMessageForm.values.stop
  }

  const saveFunction = () => {
    updateAdminMessage(message._id, editMessageForm.values, 'Message updated.')
    editMessageForm.reset()
  }

  return (
    <tr>
      <td>{getMessageIcon(message.icon)}</td>
      <td>{message.title}</td>
      <td>{message.content}</td>
      <td>
        {utcToutc(start)}
        <br></br>
        {stop === null ? 'Never' : utcToutc(message.stop)}
      </td>
      <td>
        <EditFormButton
          modalTitle={`Edit Message`}
          modalForm={editMessageForm}
          modalTitleIcon={ADMIN_ICON}
          confirmationButtonIcon={ADMIN_ICON}
          confirmationButtonText='Save Changes'
          saveFunction={saveFunction}
          resetFunction={editMessageForm.reset}
          disabled={
            !editMessageForm.validateForm({
              content: editMessageForm.form.content
            }) || !editMessageForm.changesMade(oldValues, newValues)
          }
          link={getIcon(EDIT_ICON)}
          tip={`Edit message.`}
          fksmode='stacked'
        />
      </td>
      <td>
        <DeleteRecordButton
          title='Delete Message?'
          confirmationPrompt='To confirm deletion'
          confirmationText='delete message'
          confirmationButtonText='Delete Message'
          tip='Delete this message.'
          deleteFunction={deleteAdminMessage}
          recordId={message._id}
        >
          Delete message?
        </DeleteRecordButton>
      </td>
    </tr>
  )
}

export default connect(null, { deleteAdminMessage, updateAdminMessage })(
  MessageRow
)
