import React from 'react'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

const RequireSubscription = ({
  children,
  redirectTo,
  users: { isAuthenticated, userLoading },
  stripe: { subscription }
}) => {
  const { status } = subscription

  const activeStatus = status === 'active' || status === 'trialing'
  if (userLoading)
    return (
      <Spinner
        animation='border'
        variant='primary'
        className='d-block mx-auto'
      />
    )

  return isAuthenticated && activeStatus ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  )
}

const mapStateToProps = (state) => ({
  users: state.users,
  stripe: state.stripe
})

export default connect(mapStateToProps)(RequireSubscription)
