import React from 'react'

const QuickFitDashboardItemCounter = ({ start, stop, length }) => {
  if (start === stop)
    return (
      <>
        <span style={{ fontSize: '.9rem' }}>
          {' ('}
          {start + ' of ' + length}
          {') '}
        </span>
      </>
    )
  if (length === 0)
    return (
      <>
        <span style={{ fontSize: '.9rem' }}>(0-0 of 0)</span>
      </>
    )
  return (
    <>
      <span style={{ fontSize: '.9rem' }}>
        {' ('}
        {start + '-' + stop + ' of ' + length}
        {') '}
      </span>
    </>
  )
}

export default QuickFitDashboardItemCounter
