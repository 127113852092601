const Colorize = ({ value, range, children }) => {
  let className
  switch (true) {
    case value <= 0 + range && value >= 0 - range:
      className = 'text-success'
      break
    case value < 0 - range:
      className = 'text-danger'
      break
    case value > 0 + range:
      className = 'text-warning'
      break
    default:
      className = ''
  }
  return <span className={className}>{children}</span>
}
export default Colorize
