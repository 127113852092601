import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import QuickFitTabContent from '../QuickFitTabContent'

import useShoesCleatsForm from '../../hooks/form-hooks/bikes/useShoesCleatsForm'

import { updateBike } from '../../../redux/actions/bikes'

import { BIKE_ICON } from '../../../utils/fontAwesomeDefaults'

const ShoesCleats = ({ bike, updateBike, user, fieldOverrides }) => {
  const shoesCleatsForm = useShoesCleatsForm(bike, fieldOverrides)

  const [currentBike, setCurrentBike] = useState(bike)

  const saveFunction = () => {
    const bikeObject = {
      make: bike.make,
      model: bike.model,
      ...shoesCleatsForm.values
    }
    updateBike(bike._id, bikeObject, 'Shoes/Cleats Updated')
  }

  const { form, changesMade } = shoesCleatsForm

  const { shoeBrand, shoeModel, shoeSize, insoles, cleatModel } = form

  const oldValuesForm = { ...form }

  const oldValues = {}
  const newValues = {}

  Object.entries(oldValuesForm).map(([key, value]) => {
    oldValues[key] = bike[key]
    newValues[key] = shoesCleatsForm.values[key]
    return null
  })

  useEffect(() => {
    if (bike !== currentBike) {
      shoesCleatsForm.reset()
      setCurrentBike(bike)
    }
  }, [bike, currentBike, shoesCleatsForm])

  return (
    <>
      <QuickFitTabContent
        title='Shoes/Cleats'
        dataForm={{ shoeBrand, shoeModel, shoeSize, insoles, cleatModel }}
        dataObject={bike}
        editButtonText='Edit Shoes/Cleats'
        modalTitle='Edit Shoes/Cleats'
        modalForm={shoesCleatsForm}
        modalTitleIcon={BIKE_ICON}
        confirmationButtonIcon={BIKE_ICON}
        saveFunction={saveFunction}
        user={user}
        mode='shoesCleatsMode'
        formGroup='quickfit'
        cols={['0', '4', '8', '0']}
        disabled={!changesMade(oldValues, newValues)}
      />
    </>
  )
}

export default connect(null, { updateBike })(ShoesCleats)
