import {
  name,
  address,
  address2,
  phone,
  website
} from '../field-objects/shopFields'

export const editShopForm = {
  name,
  address,
  address2,
  phone,
  website
}
