import React from 'react'
import { connect } from 'react-redux'

import QuickFitTabContent from '../QuickFitTabContent'

import useSizingNotesForm from '../../hooks/form-hooks/customers/useSizingNotesForm'

import { updateCyclist } from '../../../redux/actions/cyclists'

import { CYCLIST_ICON } from '../../../utils/fontAwesomeDefaults'

const SizingNotes = ({
  customer,
  updateCyclist,
  user,
  fields: { fieldOverrides }
}) => {
  const sizingNotesForm = useSizingNotesForm(
    {
      notes: customer.notes
    },
    fieldOverrides
  )

  const { validateForm, form, changesMade, values } = sizingNotesForm

  const saveFunction = () => {
    const customerObject = {
      cyclistProfile: customer.cyclistProfile,
      notes: sizingNotesForm.values.notes
    }

    updateCyclist(customer._id, customerObject, 'Sizing Notes Updated')
  }

  return (
    <QuickFitTabContent
      title='Sizing Notes'
      dataForm={sizingNotesForm}
      dataObject={{
        notes: customer.notes
      }}
      editButtonText='Edit Sizing Notes'
      modalTitle='Edit Sizing Notes'
      modalForm={sizingNotesForm}
      modalTitleIcon={CYCLIST_ICON}
      confirmationButtonIcon={CYCLIST_ICON}
      saveFunction={saveFunction}
      user={user}
      mode='notesMode'
      disabled={
        !validateForm(form) || !changesMade(customer.notes, values.notes)
      }
    />
  )
}

const mapStateToProps = (state) => ({
  fields: state.fields
})

export default connect(mapStateToProps, { updateCyclist })(SizingNotes)
