import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'

import DashboardCard from '../../layout/cards-containers/DashboardCard'
import FeedbackRow from './FeedbackRow'
import NextPreviousFooter from '../../hook-components/NextPreviousFooter'
import QuickFitDashboardItemCounter from '../../quickfit/QuickFitDashboardItemCounter'

import useNextPrevious from '../../hooks/action-hooks/useNextPrevious'

import FeedbackActions from './FeedbackActions'

const FeedbackList = (props) => {
  const { adminStats, feedbackList } = props

  const nextPrevious = useNextPrevious(1, feedbackList.length)

  const { start, stop, length } = nextPrevious

  if (feedbackList.length !== length)
    nextPrevious.setcurrentLength(feedbackList.length)
  if (stop > feedbackList.length) nextPrevious.setStop(feedbackList.length)

  const [unReadCount, setUnReadCount] = useState(
    feedbackList.filter((element) => element.read === false).length
  )
  const [unResolvedCount, setUnResolvedCount] = useState(
    feedbackList.filter((element) => element.resolved === false).length
  )

  useEffect(() => {
    setUnReadCount(
      feedbackList.filter((element) => element.read === false).length
    )
  }, [unReadCount, setUnReadCount, feedbackList])
  useEffect(() => {
    setUnResolvedCount(
      feedbackList.filter((element) => element.resolved === false).length
    )
  }, [unResolvedCount, setUnResolvedCount, feedbackList])

  return (
    <>
      <DashboardCard
        title={
          <>
            Feedback
            <QuickFitDashboardItemCounter
              start={start}
              stop={stop}
              length={length}
            />
            <span style={{ fontSize: '.8rem' }}>
              {unReadCount} Unread {unResolvedCount} Unresolved
            </span>
          </>
        }
        footer={
          <>
            <NextPreviousFooter {...nextPrevious} />
          </>
        }
      >
        {feedbackList.slice(start - 1, stop).map((feedback) => {
          return (
            <div key={feedback._id}>
              <Table size='sm' style={{ fontSize: '.8rem' }}>
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Email</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <FeedbackRow
                    key={feedback._id}
                    feedback={feedback}
                    adminStats={adminStats}
                  />
                </tbody>
              </Table>
              <Table size='sm' style={{ fontSize: '.8rem' }}>
                <thead>
                  <tr>
                    <th>Origin Url</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{feedback.originUrl}</td>
                  </tr>
                </tbody>
              </Table>
              <Table size='sm' style={{ fontSize: '.8rem' }}>
                <thead>
                  <tr>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{feedback.feedbackMessage}</td>
                  </tr>
                </tbody>
              </Table>
              <Table size='sm' style={{ fontSize: '.8rem' }}>
                <thead>
                  <tr>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>
              </Table>
              <FeedbackActions
                feedback={feedback}
                key={feedback._id}
                feedbackList={feedbackList}
              />
            </div>
          )
        })}
      </DashboardCard>
    </>
  )
}

export default FeedbackList
