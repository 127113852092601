//import road bike fit position fields

import {
  saddleHeight,
  saddleHeightBB,
  saddleSetBack,
  saddleAngle,
  saddleNoseToBar,
  saddleNoseToHood,
  saddleToBarDrop,
  handlebarReachHX,
  handlebarStackHY,
  handlebarAngle,
  hoodAngle
} from '../field-objects/fitPositionFields'

//import mtb specific fit position fields

import {
  mtbSaddleNoseToGripEnd,
  mtbSaddleToGripCenterDropRise
} from '../field-objects/fitPositionFields'

//import tt specific fit position fields

import {
  ttSaddleToPadCenterDrop,
  ttSaddleNoseToPadRear,
  ttSaddleNoseToEndOfExtensions,
  ttExtensionWidthAtClamps,
  ttExtensionWidthAtEnd,
  ttExtensionAngle,
  ttPadWidth,
  ttPadXReachRearOfPad,
  ttPadXReachCenterOfPad,
  ttPadYStackRearOfPad,
  ttBasebarReachX,
  ttBasebarStackY,
  ttBasebarAngle
} from '../field-objects/fitPositionFields'

export const roadBikeFitPositionForm = {
  saddleHeight,
  saddleHeightBB,
  saddleSetBack,
  saddleAngle,
  saddleNoseToBar,
  saddleNoseToHood,
  saddleToBarDrop,
  handlebarReachHX,
  handlebarStackHY,
  handlebarAngle,
  hoodAngle
}

export const mountainBikeFitPositionForm = {
  saddleHeight,
  saddleHeightBB,
  saddleSetBack,
  saddleAngle,
  saddleNoseToBar,
  mtbSaddleNoseToGripEnd,
  mtbSaddleToGripCenterDropRise,
  handlebarReachHX,
  handlebarStackHY
}

export const ttBikeFitPositionForm = {
  saddleHeight,
  saddleHeightBB,
  saddleSetBack,
  saddleAngle,
  ttSaddleToPadCenterDrop,
  ttSaddleNoseToPadRear,
  ttSaddleNoseToEndOfExtensions,
  ttExtensionWidthAtClamps,
  ttExtensionWidthAtEnd,
  ttExtensionAngle,
  ttPadWidth,
  ttPadXReachRearOfPad,
  ttPadXReachCenterOfPad,
  ttPadYStackRearOfPad,
  ttBasebarReachX,
  ttBasebarStackY,
  ttBasebarAngle
}

//export form containing all fit position fields

export const fitPositionForm = {
  saddleHeight,
  saddleHeightBB,
  saddleSetBack,
  saddleAngle,
  saddleNoseToBar,
  mtbSaddleNoseToGripEnd,
  mtbSaddleToGripCenterDropRise,
  saddleNoseToHood,
  saddleToBarDrop,
  handlebarReachHX,
  handlebarStackHY,
  handlebarAngle,
  hoodAngle,
  ttSaddleToPadCenterDrop,
  ttSaddleNoseToPadRear,
  ttSaddleNoseToEndOfExtensions,
  ttExtensionWidthAtClamps,
  ttExtensionWidthAtEnd,
  ttExtensionAngle,
  ttPadWidth,
  ttPadXReachRearOfPad,
  ttPadXReachCenterOfPad,
  ttPadYStackRearOfPad,
  ttBasebarReachX,
  ttBasebarStackY,
  ttBasebarAngle
}
