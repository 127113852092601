import { useState, useEffect } from 'react'
import store from '../../../redux/store'
import { loadAdminUserList } from '../../../redux/actions/admindata'
import { loadStripeSubscriptionList } from '../../../redux/actions/stripe'

const useAdminUserList = () => {
  const [userListLoaded, setUserListLoaded] = useState(false)
  const [subscriptionListLoaded, setSubscriptionListLoaded] = useState(false)
  const [userList, setUserList] = useState()
  const [subscriptionList, setSubscriptionList] = useState()

  useEffect(() => {
    const fetchUserList = async () => {
      const userList = await store.dispatch(loadAdminUserList())
      setUserList(userList)
      setUserListLoaded(true)
    }
    fetchUserList()
  }, [])

  useEffect(() => {
    const fetchSubscriptionList = async () => {
      const subscriptionList = await store.dispatch(
        loadStripeSubscriptionList(null)
      )
      setSubscriptionList(subscriptionList)

      setSubscriptionListLoaded(true)
    }
    fetchSubscriptionList()
  }, [])

  const removeUserFromList = (userId) => {
    const updatedList = userList.filter((user) => user._id !== userId)
    setUserList(updatedList)
  }

  const updateUserInList = (userId) => {
    const index = userList.findIndex((user) => user._id === userId)
    const updatedList = [...userList]
    updatedList[index].approved_user = true
    setUserList(updatedList)
  }

  return {
    userListLoaded,
    subscriptionListLoaded,
    subscriptionList,
    userList,
    removeUserFromList,
    updateUserInList
  }
}

export default useAdminUserList
