import {
  FEEDBACK_MESSAGES_LOADED,
  UPDATE_FEEDBACK,
  DELETE_FEEDBACK,
  CLEAR_FEEDBACK_MESSAGES
} from '../actions/types'

const initialState = {
  feedbackList: []
}

export default function adminmessages(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case FEEDBACK_MESSAGES_LOADED:
      return {
        ...state,
        feedbackList: payload
      }

    case UPDATE_FEEDBACK:
      const newArray = state.feedbackList.map((message) => {
        if (message._id === payload._id) return payload
        return message
      })

      return {
        ...state,
        feedbackList: newArray
      }

    case DELETE_FEEDBACK:
      return {
        ...state,
        feedbackList: state.feedbackList.filter(
          (message) => message._id !== payload
        )
      }

    case CLEAR_FEEDBACK_MESSAGES:
      return {
        ...initialState
      }

    default:
      return state
  }
}
