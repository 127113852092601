import { Nav } from 'react-bootstrap'
import { Button } from 'react-bootstrap'

import Tip from '../layout/overlays/Tip'

import { BIKE_ICON } from '../../utils/fontAwesomeDefaults'

import useFormModal from '../hooks/modal-hooks/useFormModal'

import FormModal from '../layout/modals/FormModal'
import GenerateForm from '../forms/form-components/GenerateForm'

const EditFormButton = ({
  children,
  editButtonText,
  modalTitle,
  modalForm,
  modalTitleIcon,
  confirmationButtonIcon,
  confirmationButtonText,
  saveFunction,
  user,
  disabled,
  formGroup,
  cols,
  fksmode,
  link,
  navLink,
  tip,
  dataObject,
  title,

  error
}) => {
  const formModal = useFormModal({
    icon: modalTitleIcon || BIKE_ICON,
    title: modalTitle,
    confirmationButtonIcon: confirmationButtonIcon || BIKE_ICON,
    confirmationButtonText: confirmationButtonText || 'Save Changes',

    cancelAction: () => {
      modalForm.reset()
    },

    confirmationAction: () => {
      saveFunction()
    }
  })

  const getButton = () => {
    if (link)
      return (
        <>
          <Tip message={tip} placement='top'>
            <span
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: '#0d6efd',
                fontSize: '.8rem'
              }}
              onClick={formModal.setShowModal}
            >
              {link}
            </span>
          </Tip>
        </>
      )
    if (navLink)
      return <Nav.Link onClick={formModal.setShowModal}>{navLink}</Nav.Link>

    return (
      <div className='d-grid gap-2'>
        <Button variant='dark' onClick={formModal.setShowModal}>
          {editButtonText || children}
        </Button>
      </div>
    )
  }

  //if (modalForm.form.type)
  //console.log('ModalForm in EditFormButton:', modalForm)

  //if (title === 'Shoes/Cleats') console.log(title, dataObject.make)

  return (
    <>
      {getButton()}
      <FormModal {...formModal} disabled={disabled}>
        <GenerateForm
          {...modalForm}
          user={user}
          mode={modalTitle}
          error={error}
          formGroup={formGroup}
          cols={cols}
          fksmode={fksmode}
          dataObject={dataObject}
          title={title}
        />
        {children}
      </FormModal>
    </>
  )
}

export default EditFormButton
