import React from 'react'

import { Card, Container } from 'react-bootstrap'

import { USER_ICON } from '../../utils/fontAwesomeDefaults'

const UserFormContainer = ({ children, heading, icon }) => {
  if (!icon) icon = USER_ICON
  return (
    <Container fluid className='userForms mt-3'>
      <Card border='dark' text='dark'>
        <Card.Header>
          <h1>
            <span>
              <i className={icon} /> {heading}
            </span>
          </h1>
        </Card.Header>
        <Card.Body>{children}</Card.Body>
      </Card>
    </Container>
  )
}

export default UserFormContainer
