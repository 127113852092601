import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'

const SearchBox = ({ formData, onChange }) => {
  return (
    <>
      <InputGroup>
        <InputGroup.Text>
          <i style={{ fontSize: '.8rem' }} className='fas fa-search' />
        </InputGroup.Text>
        <Form.Control
          style={{ maxWidth: '50%', fontSize: '.8rem' }}
          className='search-box'
          type='text'
          autoComplete='off'
          value={formData.search}
          name='search'
          placeholder='Search by Last Name'
          onChange={onChange}
        />
      </InputGroup>
    </>
  )
}

export default SearchBox
