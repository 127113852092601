import React from 'react'

import NextPreviousFooter from '../../hook-components/NextPreviousFooter'
import AddBike from './AddBike'

const BikesFooter = ({ nextPrevious, customerId, firstName, lastName }) => {
  return (
    <>
      <NextPreviousFooter {...nextPrevious} />
      <br />
      <br />

      <AddBike
        customerId={customerId}
        firstName={firstName}
        lastName={lastName}
      />
    </>
  )
}

export default BikesFooter
