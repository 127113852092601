import React from 'react'
import UserHeader from './UserHeader'
import SettingsTabs from './SettingsTabs'

const Settings = ({ user, activeStatus }) => {
  return (
    <div>
      <UserHeader {...user} />
      <SettingsTabs user={user} activeStatus={activeStatus} />
    </div>
  )
}

export default Settings
