const StackReachRecommendationRow = ({ label, value, unit }) => {
  return (
    <tr>
      <td>{label}</td>
      <td style={{ textAlign: 'right' }}>
        {value} {unit || 'cm.'}
      </td>
    </tr>
  )
}

export default StackReachRecommendationRow
