import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Card } from 'react-bootstrap'

import { updateUser } from '../../../redux/actions/users'

import useUserPreferencesForm from '../../hooks/form-hooks/users-auth/useUserPreferencesForm'

import AccountSettingsTabContent from '../AccountSettingsTabContent'

import { userPreferencesForm as dataForm } from '../../forms/form-objects/userPreferencesFormObjects'

import { USER_ICON } from '../../../utils/fontAwesomeDefaults'

const EditPreferences = ({
  users: { user },
  updateUser,
  stripe: { activeStatus }
}) => {
  const userPreferencesForm = useUserPreferencesForm({
    ...user.preferences.report_fields,
    ...user.preferences
  })

  const saveFunction = () => {
    const {
      cyclist_name,
      cyclist_profile,
      fk_body_measurements,
      fk_soft_score,
      sizing_recommendations,
      equipment,
      fit_position,
      feet_cleat,
      bike_diagram,
      notes_recommendations,
      ...newPreferences
    } = userPreferencesForm.values
    newPreferences.report_fields = {
      cyclist_name,
      cyclist_profile,
      fk_body_measurements,
      fk_soft_score,
      sizing_recommendations,
      equipment,
      fit_position,
      feet_cleat,
      bike_diagram,
      notes_recommendations
    }

    updateUser({
      name: user.name,
      email: user.email,
      preferences: newPreferences
    })
  }

  const { changesMade } = userPreferencesForm

  const getOldValues = () => {
    return { ...user.preferences }
  }

  const getNewValues = () => {
    const {
      cyclist_name,
      cyclist_profile,
      fk_body_measurements,
      fk_soft_score,
      sizing_recommendations,
      equipment,
      fit_position,
      feet_cleat,
      bike_diagram,
      notes_recommendations,
      height_units,
      weight_units,
      report_format,
      report_color
    } = userPreferencesForm.values
    const newPreferences = {
      report_fields: {
        cyclist_name,
        cyclist_profile,
        fk_body_measurements,
        fk_soft_score,
        sizing_recommendations,
        equipment,
        fit_position,
        feet_cleat,
        bike_diagram,
        notes_recommendations
      },
      height_units,
      weight_units,
      report_format,
      report_color
    }

    return newPreferences
  }

  return (
    <>
      {activeStatus ? (
        <AccountSettingsTabContent
          title='User Preferences'
          dataForm={dataForm}
          dataObject={{
            ...user.preferences.report_fields,
            ...user.preferences
          }}
          editButtonText='Edit Preferences'
          modalTitle='Edit Preferences'
          modalForm={userPreferencesForm}
          modalTitleIcon={USER_ICON}
          confirmationButtonIcon={USER_ICON}
          saveFunction={saveFunction}
          user={user}
          activeStatus={activeStatus}
          mode='preferences'
          disabled={!changesMade(getOldValues(), getNewValues())}
          cols={['0', '4', '']}
        />
      ) : (
        <Card>
          <Card.Header className='bg-white'>
            <Card.Title>User Preferences</Card.Title>
          </Card.Header>
          <Card.Body>
            <p>
              {' '}
              <strong>Available to subscribers only.</strong>
            </p>
            Quick Fit subscribers can specify preferences for:
            <br />
            <br />
            <ul>
              <li>height units</li>
              <li> weight units</li>
              <li> report fields</li>
              <li>paper format</li>
              <li>heading color</li>
            </ul>
            <p>
              <Link to='/account/settings?tab=subscription'>
                Upgrade Subscription
              </Link>{' '}
            </p>
          </Card.Body>
        </Card>
      )}
    </>
  )
}

EditPreferences.propTypes = {
  users: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  users: state.users,
  stripe: state.stripe
})

export default connect(mapStateToProps, { updateUser })(EditPreferences)
