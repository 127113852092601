import React from 'react'
import DashBoardCard from '../../layout/cards-containers/DashboardCard'

const PreFitUpgradeNotice = () => {
  return (
    <DashBoardCard title='Pre-Fit Interview Links'>
      <p className='fw-bold'>Available to subscribers only.</p>
      <p>
        Upgrade to Quick Fit to use Pre-Fit interviews. Pre-fit interviews can
        be used to collect customer data and automate the customer intake
        process. Completed customer interviews appear on the main Quick Fit
        page, where the data can be imported to a new customer record or,
        alternatively, linked to an existing customer. The pre-fit interview
        form will be customized with your shop or studio branding information if
        you have activated that feature.
      </p>

      <p>
        Once activated, links to your customized pre-fit interview forms will
        appear here. You can share these links with customers to enable them to
        complete and submit the form.
      </p>
    </DashBoardCard>
  )
}

export default PreFitUpgradeNotice
