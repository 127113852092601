export const saddleHeight = {
  type: 'number',
  label: 'Saddle Height (saddle to pedal spindle)',
  validationType: 'measurement',
  tip: 'Distance from pedal spindle center to saddle top with crank in line with seat tube.'
}

export const saddleHeightBB = {
  type: 'number',
  label: 'Saddle Height (saddle to bottom bracket)',
  validationType: 'measurement',
  tip: ' Distance from center of bottom bracket to saddle top.'
}

export const saddleSetBack = {
  type: 'number',
  label: 'Saddle Setback (from bottom bracket)',
  poslabel: '(back)',
  neglabel: '(forward)',
  min: -200,
  validationType: 'measurementneg',
  tip: 'Horizontal distance from nose of saddle to bottom bracket (positive values = saddle behind bottom bracket; negative values = saddle forward of bottom bracket).'
}

export const saddleAngle = {
  type: 'number',
  label: 'Saddle Angle',
  min: -45,
  munit: 'deg.',
  validationType: 'measurementneg',
  tip: 'Angle of saddle relative to level. Angle can be zero (level) positive (nose up) or negative (nose down).'
}

export const saddleNoseToBar = {
  type: 'number',
  label: 'Saddle Nose to Bar Center',
  validationType: 'measurement',
  tip: 'Horizontal distance from nose of saddle to center of handlebars.'
}
export const saddleNoseToHood = {
  type: 'number',
  label: 'Saddle Nose to Hood Trough',
  validationType: 'measurement',
  tip: 'Distance from nose of saddle to middle of hood trough--measured directly to account for any distance added by drop or rise.'
}

export const saddleToBarDrop = {
  type: 'number',
  label: 'Saddle to Bar Drop/Rise',
  poslabel: '(drop)',
  neglabel: '(rise)',
  min: -200,
  validationType: 'measurementneg',
  tip: 'Vertical distance from nose of saddle to handlebar (positive values = drop; negative values = rise).'
}
export const handlebarReachHX = {
  type: 'number',
  label: 'Handlebar Reach (HX)',
  min: 111,
  max: 999,
  validationType: 'measurement',
  tip: 'Horizontal distance from bottom bracket to handlebar.'
}
export const handlebarStackHY = {
  type: 'number',
  label: 'Handlebar Stack (HY)',
  min: 111,
  max: 999,
  validationType: 'measurement',
  tip: 'Vertical distance from bottom bracket to handlebar.'
}

export const handlebarAngle = {
  type: 'number',
  label: 'Handlebar Angle',
  min: -45,
  munit: 'deg.',
  validationType: 'measurementneg',
  tip: 'Angle of handlebars relative to level.'
}
export const hoodAngle = {
  type: 'number',
  label: 'Hood Angle',
  min: -45,
  munit: 'deg.',
  validationType: 'measurementneg',
  tip: 'Angle of hoods relative to level.'
}

//MTB Specific Fields

export const mtbSaddleNoseToGripEnd = {
  type: 'number',
  label: 'Saddle Nose to Grip End',
  validationType: 'measurement',
  tip: 'Horizontal distance from nose of saddle to end of handlebar grips.'
}
export const mtbSaddleToGripCenterDropRise = {
  type: 'number',
  label: 'Saddle to Grip Center Drop/Rise',
  poslabel: '(drop)',
  neglabel: '(rise)',
  min: -200,
  validationType: 'measurementneg',
  tip: 'Vertical distance (positive or negative) from nose of saddle to center of handlebar grip (positive values = drop; negative values = rise).'
}

//TT/Tri Specific Fields

export const ttSaddleToPadCenterDrop = {
  type: 'number',
  label: 'Saddle to Pad (center) Drop/Rise',
  validationType: 'measurementneg',
  min: -200,
  poslabel: '(drop)',
  neglabel: '(rise)',
  tip: 'Vertical distance from top of saddle to center of aerobar pads (positive values = drop; negative values = rise).'
}
export const ttSaddleNoseToPadRear = {
  type: 'number',
  label: 'Saddle Nose to Pad Rear',
  validationType: 'measurement',
  tip: ' Distance from nose of saddle to rear of aerobar pads.'
}
export const ttSaddleNoseToEndOfExtensions = {
  type: 'number',
  label: 'Saddle Nose to End of Extensions',
  validationType: 'measurement',
  tip: 'Distance from nose of saddle to end of aerobar extensions.'
}
export const ttExtensionWidthAtClamps = {
  type: 'number',
  label: 'Extension Width at Clamps',
  validationType: 'measurement',
  tip: 'Distance between areobar extensions at clamps.'
}
export const ttExtensionWidthAtEnd = {
  type: 'number',
  label: 'Extension Width at End',
  validationType: 'measurement',
  tip: 'Distance between areobar extensions at end of aerobar extensions.'
}
export const ttExtensionAngle = {
  type: 'number',
  label: 'Extension Angle',
  min: -45,
  munit: 'deg.',
  validationType: 'measurementneg',
  tip: ' Angle of extensions relative to level.'
}
export const ttPadWidth = {
  type: 'number',
  label: 'Pad Width',
  validationType: 'measurement',
  tip: 'Distance between center point of the aerobar pads.'
}
export const ttPadXReachRearOfPad = {
  type: 'number',
  label: 'Pad X Reach (rear of pad)',
  validationType: 'measurement',
  tip: 'Distance from saddle nose to rear of aerobar pads.'
}
export const ttPadXReachCenterOfPad = {
  type: 'number',
  label: 'Pad X Reach (center of pad)',
  validationType: 'measurement',
  tip: 'Distance from saddle nose to center of aerobar pads.'
}
export const ttPadYStackRearOfPad = {
  type: 'number',
  label: 'Pad Y Stack (rear of pad)',
  validationType: 'measurement',
  tip: 'Vertical distance from bottom bracket to rear of aerobar pads.'
}
export const ttBasebarReachX = {
  type: 'number',
  label: 'Basebar Reach (X)',
  validationType: 'measurement',
  tip: 'Distance from saddle nose to basebar.'
}
export const ttBasebarStackY = {
  type: 'number',
  label: 'Basebar Stack (Y)',
  validationType: 'measurement',
  tip: 'Vertical distance from bottom bracket to basebar.'
}
export const ttBasebarAngle = {
  type: 'number',
  label: 'Basebar Angle',
  min: -45,
  munit: 'deg.',
  validationType: 'measurementneg',
  tip: 'Angle of basebar relative to level.'
}
