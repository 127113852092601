import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

const Radio = ({
  label,
  type,
  options,
  value,
  onChange,
  inline,
  cols,
  noMargin
}) => {
  if (!cols) cols = ['0', '3', '']
  let className = 'mb-3'
  if (noMargin) className = ''
  return (
    <div>
      <Row className={className}>
        <Col xs={cols[0]}></Col>
        <Col xs={cols[1]}>
          <Form.Label>{label}</Form.Label>
        </Col>

        <Col xs={cols[2]}>
          {options.map((option) => {
            return (
              <Form.Check
                key={option}
                type={type}
                inline={inline}
                className='custom-control-input black'
                onChange={onChange}
                label={option}
                value={option}
                checked={value === option}
              />
            )
          })}
        </Col>
      </Row>
    </div>
  )
}

export default Radio
