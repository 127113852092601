import React from 'react'
import { InputGroup } from 'react-bootstrap'

import { heightMunit, weightMunit } from '../../../utils/heightWeight'

const InputGroupText = ({ munit, user, label }) => {
  const { height_units, weight_units } = user.preferences

  const width = '45px'

  if (label === 'Height')
    return (
      <InputGroup.Text style={{ minWidth: width }}>
        {heightMunit(height_units)}
      </InputGroup.Text>
    )
  if (label === 'Weight')
    return (
      <InputGroup.Text style={{ minWidth: width }}>
        {weightMunit(weight_units)}
      </InputGroup.Text>
    )
  return (
    <InputGroup.Text style={{ minWidth: width }}>
      {munit || 'mm.'}
    </InputGroup.Text>
  )
}

export default InputGroupText
