import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { requestPasswordReset } from '../../redux/actions/users'

import PropTypes from 'prop-types'

import UserFormContainer from './UserFormContainer'

import usePasswordResetRequestForm from '../hooks/form-hooks/users-auth/usePasswordResetRequestForm'
import GenerateForm from '../forms/form-components/GenerateForm'
import SEO from '../seo/SEO'

const PasswordResetRequest = ({ requestPasswordReset, isAuthenticated }) => {
  const passwordResetRequestForm = usePasswordResetRequestForm({ email: '' })

  const { validateForm, form } = passwordResetRequestForm

  const onSubmit = (e) => {
    e.preventDefault()
    const { email } = passwordResetRequestForm.values

    requestPasswordReset(email)
    passwordResetRequestForm.reset()
  }

  //Redirect if logged in

  if (isAuthenticated) {
    return <Navigate to='/dashboard' />
  }

  return (
    <>
      <SEO
        title='Fit Kit Studio | Reset Password'
        description='Fit Kit Studio Bike Fitting Software Reset Password'
        type='website'
        name='Fit Kit Systems'
      />
      <UserFormContainer heading='Reset Password'>
        <p>
          Enter the email address associated with your account to receive a
          password reset email.
        </p>
        <GenerateForm
          {...passwordResetRequestForm}
          buttonText='Send Email'
          disabled={!validateForm(form)}
          onSubmit={onSubmit}
        />{' '}
        <p></p>
        <p className='text-center'>
          <a href='https://fitkitstudio.com/login'>Cancel</a>
          {/* <Link to='/login'>Cancel</Link> */}
        </p>
      </UserFormContainer>
    </>
  )
}

PasswordResetRequest.propTypes = {
  requestPasswordReset: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.users.isAuthenticated
})

export default connect(mapStateToProps, { requestPasswordReset })(
  PasswordResetRequest
)
