import { validateForm, changesMade } from '../../../utils/formValidation'

export const getInput = (form, keyArray, key, record) => {
  return {
    type: form[keyArray[key]].type,
    label: form[keyArray[key]].label,
    as: form[keyArray[key]].as,
    rows: form[keyArray[key]].rows,
    min: form[keyArray[key]].min,
    max: form[keyArray[key]].max,
    step: form[keyArray[key]].step,
    munit: form[keyArray[key]].munit,
    poslabel: form[keyArray[key]].poslabel,
    neglabel: form[keyArray[key]].neglabel,
    initialValue: record[keyArray[key]],
    options: form[keyArray[key]].options,
    helpmessage: form[keyArray[key]].helpmessage,
    errormessage: form[keyArray[key]].errormessage,
    placeholder: form[keyArray[key]].placeholder,
    grouplabel: form[keyArray[key]].grouplabel,
    fksmode: form[keyArray[key]].fksmode,
    inline: form[keyArray[key]].inline,
    tip: form[keyArray[key]].tip
  }
}

export const getValidation = (form, keyArray, key) => {
  if (!form[keyArray[key]].validationType) return null

  const validationObject = {
    validationType: form[keyArray[key]].validationType
  }
  if (form[keyArray[key]].length)
    validationObject.length = form[keyArray[key]].length
  return validationObject
}

export const getForm = (keyArray, inputs) => {
  const exportForm = {}

  for (let x = 0; x < inputs.length; x++) {
    exportForm[keyArray[x]] = inputs[x][0]
  }

  const inputArray = Object.entries(exportForm)
  const values = {}

  inputArray.forEach(([key, input], index) => {
    values[keyArray[index]] = input.value
    if (input.type === 'number' && (input.value === '' || input.value === null))
      values[keyArray[index]] = 0
  })

  const resetForm = () => {
    for (let x = 0; x < inputs.length; x++) {
      inputs[x][1]()
    }
  }

  return {
    form: exportForm,
    values,
    reset: resetForm,
    validateForm,
    changesMade
  }
}

export const applyOverrides = (formName, form, fieldOverrides) => {
  //let updatedForm = { ...form }
  fieldOverrides.map((override) => {
    if (override.formName === formName) {
      form[override.fieldName] = {
        ...form[override.fieldName],
        ...override
      }
      /*       editForm[override.fieldName] = {
        ...editForm[override.fieldName],
        ...override
      } */
    }
    return null
  })
}
