import React from 'react'
import { connect } from 'react-redux'

import { Card } from 'react-bootstrap'

import { getMessageIcon } from '../../utils/generalFunctions'

const UserMessage = ({ users: { userMessage }, icon }) => {
  if (userMessage === '') return null

  return (
    <Card body className='mb-3'>
      {getMessageIcon(icon)}

      <span className='ms-3'>
        <strong>{userMessage}</strong>
      </span>
    </Card>
  )
}

const mapStateToProps = (state) => ({
  users: state.users
})

export default connect(mapStateToProps, {})(UserMessage)
