import React from 'react'
import { connect } from 'react-redux'
import { Navigate, useMatch } from 'react-router-dom'
import Spinner from '../layout/Spinner'
import PropTypes from 'prop-types'

import { login } from '../../redux/actions/users'

import UserFormContainer from './UserFormContainer'

import GenerateForm from '../forms/form-components/GenerateForm'

import LandingLoginForm from '../forms/form-components/LandingLoginForm'

import useLoginForm from '../hooks/form-hooks/users-auth/useLoginForm'

import UserMessage from './UserMessage'

import SEO from '../seo/SEO'

const Login = ({
  login,
  isAuthenticated,
  userLoading,
  stripe: { activeStatus }
}) => {
  const loginForm = useLoginForm({ email: '', password: '' })

  const { form, validateForm } = loginForm

  const match = useMatch('/')

  const onSubmit = (e) => {
    e.preventDefault()
    login(loginForm.values.email, loginForm.values.password)
  }

  //Redirect if logged in

  if (isAuthenticated && userLoading)
    return <Spinner message='Loading user data . . .' />

  if (isAuthenticated && !userLoading && activeStatus)
    return <Navigate to='/quickfit/dashboard' />
  if (isAuthenticated && !userLoading && !activeStatus)
    return <Navigate to='/account/dashboard' />

  if (match)
    return (
      <>
        <LandingLoginForm
          {...loginForm}
          value='Login'
          onSubmit={onSubmit}
          disabled={!validateForm(form)}
        />
      </>
    )

  return (
    <>
      <SEO
        title='Fit Kit Studio | Login'
        description='Fit Kit Studio Bike Fitting Software Login Page'
        type='website'
        name='Fit Kit Systems'
      />
      <UserMessage />
      <UserFormContainer heading='Login'>
        <GenerateForm
          {...loginForm}
          buttonText='Login'
          onSubmit={onSubmit}
          disabled={!validateForm(form)}
        />
        <p></p>
        <p className='text-center'>
          Don't have an account?{' '}
          <a href='https://fitkitstudio.com/register'>Sign Up</a>{' '}
          {/* <Link to='/register'>Sign Up</Link> */}
        </p>
        <p className='text-center'>
          <a href='https://fitkitstudio.com/password-reset-request'>
            Forgot password?
          </a>
          {/* <Link to='/password-reset-request'>Forgot password?</Link> */}
        </p>
      </UserFormContainer>
    </>
  )
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.users.isAuthenticated,
  userLoading: state.users.userLoading,
  stripe: state.stripe
})

export default connect(mapStateToProps, { login })(Login)
