import React from 'react'
import { Link } from 'react-router-dom'

import Tip from '../../layout/overlays/Tip'

import { utcToutc, usNormal } from '../../../utils/dateFormats'

import { CYCLIST_ICON, CALENDAR_ICON } from '../../../utils/fontAwesomeDefaults'

const RecentCustomerRow = ({ cyclist }) => {
  const { firstName, lastName, birthDate, gender, email } =
    cyclist.cyclistProfile
  const { updated } = cyclist
  return (
    <>
      <tr>
        <td>
          <Link to={`/quickfit/customer/${cyclist._id}`}>
            <Tip
              message={
                <>
                  {firstName + ' ' + lastName}
                  <br />
                  Birthdate: {utcToutc(birthDate)} <br />
                  Gender: {gender}
                  <br />
                  {email}
                </>
              }
            >
              <span>
                <i className={CYCLIST_ICON} /> {firstName + ' ' + lastName}
              </span>
            </Tip>
          </Link>
        </td>
        <td>
          <Tip message={<>Last updated {usNormal(updated)} </>}>
            <span>
              <i className={CALENDAR_ICON} /> {usNormal(updated)}
            </span>
          </Tip>
        </td>
      </tr>
    </>
  )
}

export default RecentCustomerRow
