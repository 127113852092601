import { Link } from 'react-router-dom'
import { Nav, Navbar, Container } from 'react-bootstrap'
import {
  CYCLIST_ICON,
  DASHBOARD_ICON,
  DATA_ICON,
  PLUS_ICON
} from '../../utils/fontAwesomeDefaults'
import AddNewCustomer from './customers/AddNewCustomer'

const QuickFitNav = () => {
  return (
    <>
      <Navbar className='mb-3' fill='true' variant='dark' bg='dark'>
        <Container>
          <Navbar.Brand>
            <i className={CYCLIST_ICON + ' text-fitkit'} /> Quick Fit
          </Navbar.Brand>

          <Nav>
            <Nav.Item>
              <Nav.Link as={Link} to='/quickfit/dashboard'>
                <i className={DASHBOARD_ICON} /> Dashboard
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <AddNewCustomer
                navLink={
                  <>
                    <i className={PLUS_ICON} /> New Customer
                  </>
                }
              />
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to='/quickfit/retrieve-customer'>
                <i className={CYCLIST_ICON} /> Retrieve Customer
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to='/quickfit/my-data'>
                <i className={DATA_ICON} /> My Data
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>
    </>
  )
}

export default QuickFitNav
