import DashboardCard from '../../layout/cards-containers/DashboardCard'
import UpdatePaymentButton from './UpdatePaymentButton'

const PastDue = ({ subscription, last4 }) => {
  return (
    <DashboardCard title='Subscription'>
      {' '}
      <p>
        Your subscription to Quick Fit expired and we were unable to charge the
        renewal fee automatically.
      </p>
      <p>But don't worry, all of your data is saved.</p>
      <p>
        To use Quick Fit again and restore access to your data, please update
        your payment method.
      </p>
      <p>
        Your current payment method is: Credit Card ending in: <b>{last4}</b>{' '}
        <UpdatePaymentButton />
      </p>
    </DashboardCard>
  )
}

export default PastDue
