import React from 'react'

const ShopRow = ({ shop, userList }) => {
  const { name, address, phone, website, logo } = shop

  let userData = userList.find((user) => user._id === shop.owner)

  if (!userData) userData = { name: 'Deleted User' }

  let imageSource = {}
  if (logo) imageSource = `/api/shops/photo/${shop._id}?${Date.now()}`

  return (
    <tr>
      <td>{name}</td>
      <td>{address} </td>
      <td>{phone}</td>
      <td>{website} </td>
      <td>{userData.name} </td>
      <td>
        {logo && (
          <img
            style={{ maxHeight: '20px' }}
            src={imageSource}
            alt='shop logo'
          />
        )}
      </td>
    </tr>
  )
}

export default ShopRow
