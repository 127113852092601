import React from 'react'
import { connect } from 'react-redux'

import { Card } from 'react-bootstrap'

import { getMessageIcon } from '../../../utils/generalFunctions'

const NewsAndUpdates = ({ adminmessages: { adminMessages } }) => {
  const currentMessages = adminMessages.filter((message) => {
    const stopTime = Math.floor(new Date(message.stop) / 1000)
    const currentTime = Math.floor(new Date() / 1000)
    if (currentTime <= stopTime || stopTime === 0) return message
    return null
  })

  if (currentMessages.length === 0) return null
  return (
    <Card body className='mb-3'>
      {currentMessages.map((message, index) => {
        let margin
        index !== currentMessages.length - 1 ? (margin = 'mb-3') : (margin = '')
        return (
          <div key={message._id} className={margin}>
            {getMessageIcon(message.icon)}

            {message.title && (
              <span className='ms-3'>
                <strong>{message.title}</strong>
              </span>
            )}

            <span className={'ms-3'}>{message.content}</span>
          </div>
        )
      })}
    </Card>
  )
}

const mapStateToProps = (state) => ({
  adminmessages: state.adminmessages
})

export default connect(mapStateToProps, {})(NewsAndUpdates)
