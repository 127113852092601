import React from 'react'
import { Routes, Route } from 'react-router-dom'

import MainContentRoutes from './MainContentRoutes'
import RequireAuth from './RequireAuth'
import RequireAdmin from './RequireAdmin'
import Landing from '../layout/Landing'
import Admin from '../admin/Admin'

const ContentRoutes = () => {
  return (
    <Routes>
      <Route exact path='/' element={<Landing />} />
      <Route
        path='/admin/*'
        element={
          <RequireAuth redirectTo='/login'>
            <RequireAdmin redirectTo='/account/dashboard'>
              <Admin />
            </RequireAdmin>
          </RequireAuth>
        }
      />
      <Route path='*' element={<MainContentRoutes />} />
    </Routes>
  )
}

export default ContentRoutes
