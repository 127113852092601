import React from 'react'
import { connect } from 'react-redux'

import { weekday } from '../../../utils/dateFormats'

import ActiveSubscription from './ActiveSubscription'
import ActiveCancelationPending from './ActiveCancelationPending'
import TrialingCancelationPending from './TrialingCancelationPending'
import UnSubscribed from './UnSubscribed'
import Trialing from './Trialing'
import Canceled from './Canceled'
import PastDue from './PastDue'

const ManageSubscription = ({ stripe: { subscription, last4 } }) => {
  const { status, cancel_at_period_end, current_period_end } = subscription

  /*   const status = 'past_due'
  const cancel_at_period_end = false
  const { current_period_end } = subscription */

  const currentPeriodEndDate = new Date(current_period_end * 1000)

  const currentStop = weekday(currentPeriodEndDate)

  switch (status) {
    case 'active':
      if (cancel_at_period_end)
        return (
          <ActiveCancelationPending
            currentStop={currentStop}
            subscription={subscription}
          />
        )
      else
        return (
          <ActiveSubscription
            currentStop={currentStop}
            subscription={subscription}
            last4={last4}
          />
        )
    case 'trialing':
      if (cancel_at_period_end)
        return (
          <TrialingCancelationPending
            currentStop={currentStop}
            subscription={subscription}
            last4={last4}
          />
        )
      else
        return (
          <Trialing
            currentStop={currentStop}
            subscription={subscription}
            last4={last4}
          />
        )
    case 'canceled':
      return <Canceled subscription={subscription} last4={last4} />
    case 'past_due':
      return <PastDue subscription={subscription} last4={last4} />

    default:
      return <UnSubscribed />
  }
}

const mapStateToProps = (state) => ({
  stripe: state.stripe,
  users: state.users
})

export default connect(mapStateToProps, {})(ManageSubscription)
