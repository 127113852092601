export const calculateFrameReach = (inputs) => {
  const {
    handlebarReachHX,
    headTubeAngle,
    headSetHeight,
    spacersBelow,
    stemClampHeight,
    stemLength,
    stemAngle
  } = inputs

  return Math.round(
    handlebarReachHX -
      Math.sin(
        Math.asin(
          (stemLength * Math.sin(((90 + stemAngle) * Math.PI) / 180)) /
            Math.sqrt(
              Math.pow(stemClampHeight / 2 + spacersBelow + headSetHeight, 2) +
                Math.pow(stemLength, 2) -
                2 *
                  (stemClampHeight / 2 + spacersBelow + headSetHeight) *
                  stemLength *
                  Math.cos(((90 + stemAngle) * Math.PI) / 180)
            )
        ) +
          ((headTubeAngle - 90) * Math.PI) / 180
      ) *
        Math.sqrt(
          Math.pow(stemClampHeight / 2 + spacersBelow + headSetHeight, 2) +
            Math.pow(stemLength, 2) -
            2 *
              (stemClampHeight / 2 + spacersBelow + headSetHeight) *
              stemLength *
              Math.cos(((90 + stemAngle) * Math.PI) / 180)
        )
  )
}

export const calculateFrameStack = (inputs) => {
  const {
    handlebarStackHY,
    headTubeAngle,
    headSetHeight,
    spacersBelow,
    stemClampHeight,
    stemLength,
    stemAngle
  } = inputs

  return Math.round(
    handlebarStackHY -
      Math.cos(
        Math.asin(
          (stemLength * Math.sin(((90 + stemAngle) * Math.PI) / 180)) /
            Math.sqrt(
              Math.pow(stemClampHeight / 2 + spacersBelow + headSetHeight, 2) +
                Math.pow(stemLength, 2) -
                2 *
                  (stemClampHeight / 2 + spacersBelow + headSetHeight) *
                  stemLength *
                  Math.cos(((90 + stemAngle) * Math.PI) / 180)
            )
        ) +
          ((headTubeAngle - 90) * Math.PI) / 180
      ) *
        Math.sqrt(
          Math.pow(stemClampHeight / 2 + spacersBelow + headSetHeight, 2) +
            Math.pow(stemLength, 2) -
            2 *
              (stemClampHeight / 2 + spacersBelow + headSetHeight) *
              stemLength *
              Math.cos(((90 + stemAngle) * Math.PI) / 180)
        )
  )
}

export const calculateHandlebarReach = (inputs) => {
  const {
    frameReach,
    headTubeAngle,
    headSetHeight,
    spacersBelow,
    stemClampHeight,
    stemLength,
    stemAngle
  } = inputs

  return Math.round(
    frameReach +
      Math.sin(
        Math.asin(
          (stemLength * Math.sin(((90 + stemAngle) * Math.PI) / 180)) /
            Math.sqrt(
              Math.pow(stemClampHeight / 2 + spacersBelow + headSetHeight, 2) +
                Math.pow(stemLength, 2) -
                2 *
                  (stemClampHeight / 2 + spacersBelow + headSetHeight) *
                  stemLength *
                  Math.cos(((90 + stemAngle) * Math.PI) / 180)
            )
        ) +
          ((headTubeAngle - 90) * Math.PI) / 180
      ) *
        Math.sqrt(
          Math.pow(stemClampHeight / 2 + spacersBelow + headSetHeight, 2) +
            Math.pow(stemLength, 2) -
            2 *
              (stemClampHeight / 2 + spacersBelow + headSetHeight) *
              stemLength *
              Math.cos(((90 + stemAngle) * Math.PI) / 180)
        )
  )
}
export const calculateHandlebarStack = (inputs) => {
  const {
    frameStack,
    headTubeAngle,
    headSetHeight,
    spacersBelow,
    stemClampHeight,
    stemLength,
    stemAngle
  } = inputs

  return Math.round(
    frameStack +
      Math.cos(
        Math.asin(
          (stemLength * Math.sin(((90 + stemAngle) * Math.PI) / 180)) /
            Math.sqrt(
              Math.pow(stemClampHeight / 2 + spacersBelow + headSetHeight, 2) +
                Math.pow(stemLength, 2) -
                2 *
                  (stemClampHeight / 2 + spacersBelow + headSetHeight) *
                  stemLength *
                  Math.cos(((90 + stemAngle) * Math.PI) / 180)
            )
        ) +
          ((headTubeAngle - 90) * Math.PI) / 180
      ) *
        Math.sqrt(
          Math.pow(stemClampHeight / 2 + spacersBelow + headSetHeight, 2) +
            Math.pow(stemLength, 2) -
            2 *
              (stemClampHeight / 2 + spacersBelow + headSetHeight) *
              stemLength *
              Math.cos(((90 + stemAngle) * Math.PI) / 180)
        )
  )
}

export const calculateStemReachDelta = (inputs) => {
  const {
    headTubeAngle,
    stemLengthA,
    stemAngleA,
    spacersBelowA,
    stemLengthB,
    stemAngleB,
    spacersBelowB
  } = inputs

  let stemReachA, stemReachB

  const acuteAngleA = 90 - headTubeAngle + stemAngleA
  const acuteAngleB = 90 - headTubeAngle + stemAngleB

  stemReachA = stemLengthA * Math.cos((Math.PI * acuteAngleA) / 180.0)

  stemReachB = stemReachB =
    stemLengthB * Math.cos((Math.PI * acuteAngleB) / 180.0)

  if (spacersBelowA !== spacersBelowB) {
    const stemClampDelta = spacersBelowB - spacersBelowA
    const adjustmentFactor =
      stemClampDelta * Math.sin((Math.PI * (90 - headTubeAngle)) / 180.0)

    stemReachB = stemReachB - adjustmentFactor
  }

  const stemReachDelta = stemReachB - stemReachA

  return stemReachDelta.toFixed(1)
}
export const calculateStemHeightDelta = (inputs) => {
  const {
    headTubeAngle,
    stemLengthA,
    stemAngleA,
    spacersBelowA,
    stemLengthB,
    stemAngleB,
    spacersBelowB
  } = inputs
  let stemHeightA, stemHeightB

  const acuteAngleA = 90 - headTubeAngle + stemAngleA
  const acuteAngleB = 90 - headTubeAngle + stemAngleB

  stemHeightA =
    stemLengthA * Math.sin((Math.PI * acuteAngleA) / 180.0) + spacersBelowA

  stemHeightB =
    stemLengthB * Math.sin((Math.PI * acuteAngleB) / 180.0) + spacersBelowB

  const stemHeightDelta = stemHeightB - stemHeightA
  return stemHeightDelta.toFixed(1)
}
