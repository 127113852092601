//import customer fields

import {
  shoeBrand,
  shoeModel,
  shoeSize,
  insoles,
  cleatModel,
  cleatAdjustments,
  cleatModifications
} from '../field-objects/shoesCleatsFields'

/* import * as shoesCleatsFields from './shoesCleatsFields'
export const shoesCleatsForm = shoesCleatsFields
 */
//export shoesCleats form

export const shoesCleatsForm = {
  shoeBrand,
  shoeModel,
  shoeSize,
  insoles,
  cleatModel,
  cleatAdjustments,
  cleatModifications
}

export const shoesCleatsTableForm = {
  shoeBrand,
  shoeModel,
  shoeSize,
  insoles,
  cleatModel
}
