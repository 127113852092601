import React from 'react'
import { Card } from 'react-bootstrap'
import { WARNING_ICON } from '../../../utils/fontAwesomeDefaults'
import ReactivateSubscriptionButton from './ReactivateSubscriptionButton'

const TrialingCancelationPending = ({ subscription, currentStop }) => {
  const trialPeriod = process.env.REACT_APP_TRIAL_PERIOD

  return (
    <Card>
      <Card.Header className='bg-white'>
        <Card.Title>Subscription</Card.Title>
      </Card.Header>{' '}
      <Card.Body>
        <p className='text-danger'>
          <i className={WARNING_ICON} /> Cancelation Pending.
        </p>
        <p>
          You are currently subscribed to <b>Quick Fit</b> and your{' '}
          {trialPeriod}
          -day trial is active.
        </p>
        <p>However, You previously canceled your subscription.</p>
        <p>
          Your subscription will be canceled automatically at the end of the
          free trial period on: <b>{currentStop}</b>
        </p>
        <p>
          You may reactivate your subscription at any time prior to cancelation.
        </p>
      </Card.Body>
      <Card.Footer className='bg-white'>
        <ReactivateSubscriptionButton
          subscription={subscription}
          currentStop={currentStop}
        />
      </Card.Footer>
    </Card>
  )
}

export default TrialingCancelationPending
