import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { reactivateStripeSubscription } from '../../../redux/actions/stripe'
import { WARNING_ICON, INVOICE_ICON } from '../../../utils/fontAwesomeDefaults'
import ConfirmCancelModal from '../../layout/modals/ConfirmCancelModal'
import useConfirmCancelModal from '../../hooks/modal-hooks/useConfirmCancelModal'

const ReactivateSubscriptionButton = ({
  subscription,
  currentStop,
  reactivateStripeSubscription
}) => {
  const reactivateSubscriptionModal = useConfirmCancelModal({
    icon: WARNING_ICON,
    title: 'Reactivate Subscription?',
    confirmationPrompt: 'To confirm activation',
    confirmationText: 'activate',
    confirmationButtonIcon: INVOICE_ICON,
    confirmationButtonText: 'Reactivate Subscription',

    confirmationAction: () => {
      reactivateStripeSubscription(subscription.id)
    }
  })

  return (
    <>
      <div className='d-grid gap-2'>
        <Button
          variant='dark'
          onClick={reactivateSubscriptionModal.setShowModal}
        >
          <i className={INVOICE_ICON} /> Reactivate Subscription
        </Button>
      </div>
      <ConfirmCancelModal {...reactivateSubscriptionModal}>
        <p>Do you want to reactivate your subscription?</p>{' '}
        <p>
          This will reactivate your previoulsy canceled subscription. Your
          subscription will automatically renew on {currentStop}.
        </p>{' '}
      </ConfirmCancelModal>
    </>
  )
}

export default connect(null, {
  reactivateStripeSubscription
})(ReactivateSubscriptionButton)
