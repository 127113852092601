import React from 'react'
import { Table } from 'react-bootstrap'
import { connect } from 'react-redux'

import FieldOverrides from '../../admin/manage-fields/FieldOverrides'

import EditFieldButton from '../../admin/manage-fields/EditFieldButton'
import DeleteRecordButton from '../../hook-components/DeleteRecordButton'

import { deleteOverrides } from '../../../redux/actions/fields'

const FieldData = ({
  dataForm,
  dataObject,
  fieldOverrides,
  deleteOverrides
}) => {
  return (
    <Table striped borderless size='sm' style={{ fontSize: '.8rem' }}>
      <thead>
        <tr>
          <th style={{ width: '15%' }}>Field Name/Form</th>
          <th tyle={{ width: '35%' }}>Default Properties</th>
          <th tyle={{ width: '35%' }}>Overrides</th>
          <th tyle={{ width: '15%' }}>Edit</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(dataForm).map(([key, value]) => {
          return (
            <tr key={key}>
              <td>
                {key}
                <br></br>
                {value.formName}
              </td>
              <td>
                <table style={{ width: '100%' }}>
                  <tbody>
                    {Object.entries(value).map(([prop, val], index) => {
                      return (
                        <tr key={index}>
                          <td style={{ verticalAlign: 'top' }}>{prop}</td>

                          {typeof val !== 'object' ? (
                            <td style={{ textAlign: 'right' }}>{val}</td>
                          ) : (
                            <td style={{ textAlign: 'right' }}>
                              {val.map((item) => {
                                return (
                                  <div key={item}>
                                    {' '}
                                    {item}
                                    <br></br>
                                  </div>
                                )
                              })}
                            </td>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </td>

              <td>
                <FieldOverrides
                  fieldName={key}
                  fieldOverrides={fieldOverrides}
                  fieldFormName={value.formName}
                />
              </td>
              <td>
                <EditFieldButton
                  fieldName={key}
                  fieldFormName={value.formName}
                  type={value.type}
                  fieldOverride={fieldOverrides.find(
                    (field) =>
                      field.fieldName === key ||
                      field.fieldName === key.substring(5)
                  )}
                />
                {fieldOverrides.find(
                  (field) =>
                    (field.fieldName === key ||
                      field.fieldName === key.substring(5)) &&
                    field.formName === value.formName
                ) && (
                  <DeleteRecordButton
                    title='Delete Overrides?'
                    confirmationPrompt='To confirm deletion'
                    confirmationText='delete overrides'
                    confirmationButtonText='Delete Overrides'
                    tip='Delete these Overrides'
                    deleteFunction={deleteOverrides}
                    recordId={
                      fieldOverrides.find(
                        (field) =>
                          field.fieldName === key ||
                          field.fieldName === key.substring(5)
                      )
                        ? fieldOverrides.find(
                            (field) =>
                              field.fieldName === key ||
                              field.fieldName === key.substring(5)
                          )._id
                        : null
                    }
                  >
                    Delete Overrides for field: <strong>{key}</strong>?
                  </DeleteRecordButton>
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default connect(null, { deleteOverrides })(FieldData)
