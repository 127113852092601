import useInputWithReset from '../../input-hooks/useInputWithReset'

import { contactForm as form } from '../../../forms/form-objects/usersAuthFormObjects'

import {
  getForm,
  getInput,
  getValidation
} from '../../input-hooks/inputFunctions'

const useContactForm = (record) => {
  const keyArray = Object.keys(form)
  const i = []

  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )
  i[i.length] = useInputWithReset(
    getInput(form, keyArray, i.length, record),
    getValidation(form, keyArray, i.length)
  )

  return getForm(keyArray, i)
}

export default useContactForm
