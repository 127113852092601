import ViewRecordButton from '../../hook-components/ViewRecordButton'

const BikeSearchListRow = ({ bike }) => {
  const { make, model, frameSize } = bike
  return (
    <tr>
      <td>{make}</td>
      <td>{model}</td>
      <td>{frameSize}</td>
      <td>
        <ViewRecordButton
          record={bike}
          modalTitle={'View Record (' + make + ' ' + model + ')'}
        />
      </td>
    </tr>
  )
}

export default BikeSearchListRow
