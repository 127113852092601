import React from 'react'
import DashboardCard from '../../layout/cards-containers/DashboardCard'
import StatTableData from '../../layout/cards-containers/StatTableData'

import DownloadDataButton from '../../hook-components/DownloadRecordsButton'
import { customerDataCSV, bikeDataCSV } from '../../../utils/csvFunctions'

const KeyStats = ({ userStats, user }) => {
  return (
    <DashboardCard
      style={{ minHeight: '100px', fontSize: '.8rem' }}
      title='My Data'
    >
      <StatTableData
        dataForm={{
          customersCreated: {
            label: (
              <span>
                Customers Created{' '}
                <DownloadDataButton
                  tip='Download Customer Data as CSV File'
                  filename='customers'
                  csv={customerDataCSV(
                    userStats.customerList,
                    user,
                    'Customers Created By'
                  )}
                />
              </span>
            )
          },
          bikesCreated: {
            label: (
              <span>
                Bikes Created{' '}
                <DownloadDataButton
                  tip='Download Bike Data as CSV File'
                  filename='bikes'
                  csv={bikeDataCSV(
                    userStats.bikeList,
                    user,
                    'Bikes Created By'
                  )}
                />
              </span>
            )
          },
          maleCustomers: { label: 'Male Customers' },
          femaleCustomers: { label: 'Female Customers' },
          nonBinaryCustomers: { label: 'Non-Binary Customers' },
          roadBikes: { label: 'Road Bikes' },
          mountainBikes: { label: 'Mountain Bikes' },
          ttBikes: { label: 'TT/Tri Bikes' },
          gravelBikes: { label: 'Gravel Bikes' },
          cyclocrossBikes: { label: 'Cyclocross Bikes' },
          fatBikes: { label: 'Fat Bikes' },
          touringBikes: { label: 'Touring or Commuting Bikes' },
          tandemBikes: { label: 'Tandem Bikes' }
        }}
        dataObject={userStats.stats}
      />
    </DashboardCard>
  )
}

export default KeyStats
