export const height_units = {
  type: 'radio',
  label: 'Height Display Units',
  options: ['Metric', 'Imperial'],
  inline: true
}
export const weight_units = {
  type: 'radio',
  label: 'Weight Display Units',
  options: ['Metric', 'Imperial'],
  inline: true
}

export const cyclist_name = {
  type: 'checkbox',
  label: 'Customer Name',
  grouplabel: 'PDF Report Fields:'
}
export const cyclist_profile = {
  type: 'checkbox',
  label: 'Customer Profile'
}
export const fk_body_measurements = {
  type: 'checkbox',
  label: 'Body Measurements'
}
export const fk_soft_score = {
  type: 'checkbox',
  label: 'Physical Profile'
}
export const sizing_recommendations = {
  type: 'checkbox',
  label: 'Sizing Recommendations'
}
export const equipment = {
  type: 'checkbox',
  label: 'Equipment'
}
export const fit_position = {
  type: 'checkbox',
  label: 'Fit Position'
}
export const feet_cleat = {
  type: 'checkbox',
  label: 'Shoes/Cleats Info'
}
export const bike_diagram = {
  type: 'checkbox',
  label: 'Bike Diagram'
}
export const notes_recommendations = {
  type: 'checkbox',
  label: 'Notes/Recommendations'
}

export const report_format = {
  type: 'radio',
  label: 'PDF Report Format',
  options: ['US Letter', 'A4'],
  inline: true
}
export const report_color = {
  type: 'blockpicker',
  label: 'PDF Report Heading Color',
  options: [
    '#e9722e',
    '#C0C0C0',
    '#808080',
    '#000000',
    '#FF0000',
    '#800000',
    '#FFFF00',
    '#808000',
    '#00FF00',
    '#008000',
    '#00FFFF',
    '#008080',
    '#0000FF',
    '#000080',
    '#800080'
  ]
}
