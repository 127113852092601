import { Card, Tab, Row, Col, Nav } from 'react-bootstrap'

import EditFields from './manage-fields/EditFields'
import EditMessages from './message-header/EditMessages'
import EditResources from './manage-resources/EditResources'
import FeedbackList from './lists/FeedbackList'
import QuickSize2 from '../tools/quicksize2/QuickSize2'
import MainContentContainer from '../layout/cards-containers/MainContentContainer'

import { COMMENT_ICON } from '../../utils/fontAwesomeDefaults'

import { getIcon } from '../../utils/generalFunctions'

const SettingsTabs = (props) => {
  const { feedbackList } = props

  const newMessages = feedbackList.filter(
    (element) => element.read === false
  ).length

  return (
    <MainContentContainer>
      <Tab.Container
        id='left-tabs-example'
        /* onSelect={(k) => {
          if (k !== 'search') {
            setSearchData(null)
            setCollection(null)
            setErrors(null)
          }
        }} */
        defaultActiveKey='messages'
      >
        <Row>
          <Col sm={3}>
            <Card>
              <Nav variant='pills' className='flex-column'>
                <Nav.Item>
                  <Nav.Link eventKey='messages'>Manage Messages</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='resources'>Manage Resources</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='fields'>Manage Form Fields</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='feedback'>
                    Feedback{' '}
                    {newMessages > 0 && (
                      <>
                        {getIcon(COMMENT_ICON)} {newMessages} New
                      </>
                    )}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey='messages'>
                <EditMessages {...props} />
              </Tab.Pane>
              <Tab.Pane eventKey='resources'>
                <EditResources {...props} />
              </Tab.Pane>
              <Tab.Pane eventKey='fields'>
                <EditFields {...props} />
              </Tab.Pane>

              <Tab.Pane eventKey='feedback'>
                <FeedbackList {...props} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </MainContentContainer>
  )
}

export default SettingsTabs
