import React from 'react'
import { Table } from 'react-bootstrap'
import QuickFitDashboardCard from '../QuickFitDashboardCard'

import useSizingRecommendations from '../../hooks/fit-kit-hooks/useSizingRecommendations'
import SizingRecommendationRow from './SizingRecommendationRow'

import PrintPDFButton from '../../../pdf/PrintPDFButton'
import BikeSelectionRadio from '../../tools/quicksize/BikeSelectionRadio'
import RoadBikeImage from '../../tools/quicksize/RoadBikeImage'
import MTBImage from '../../tools/quicksize/MTBImage'
import TTBikeImage from '../../tools/quicksize/TTBikeImage'

import useSingleInputForm from '../../hooks/form-hooks/tools/singleInputForm'

import {
  roadBikeSizingForm,
  mountainBikeSizingForm,
  ttBikeSizingForm
} from '../../forms/form-objects/sizingFormObjects'

import { sizingPDF } from '../../../pdf/SizingPdf'
import TableData from '../../layout/cards-containers/TableData'

const SizingRecommendations = ({
  bodyMeasurements,
  softScores,
  birthDate,
  children,
  noPrint,
  bikeImage,
  bikeSelection,
  user,
  shop,
  customer
}) => {
  const sizingRecommendations = useSizingRecommendations(
    bodyMeasurements,
    softScores,
    birthDate
  )

  const {
    frameSize,
    minSaddleHeight,
    maxSaddleHeight,
    maxStandoverHeight,
    handlebarWidth,
    minSaddleWidth,
    maxSaddleWidth,
    topTubeAndStemDropBar,
    topTubeAndStemFlatBar,
    topTubeAndStemAeroBar,
    AdjustedTopTubeAndStemDropBar,
    AdjustedTopTubeAndStemFlatBar,
    AdjustedTopTubeAndStemAeroBar
  } = sizingRecommendations

  const radioForm = {
    bikeType: {
      type: 'radio',
      options: ['Road', 'MTB', 'TT/Tri'],
      inline: true
    }
  }

  const inputForm = useSingleInputForm(radioForm, { bikeType: 'Road' })

  let BikeImage, dataForm
  const { bikeType } = inputForm.values || 'Road'

  switch (bikeType) {
    case 'Road':
      BikeImage = <RoadBikeImage />
      dataForm = roadBikeSizingForm
      break
    case 'MTB':
      BikeImage = <MTBImage />
      dataForm = mountainBikeSizingForm
      break
    case 'TT/Tri':
      BikeImage = <TTBikeImage />
      dataForm = ttBikeSizingForm
      break
    default:
      BikeImage = <RoadBikeImage />
      dataForm = roadBikeSizingForm
  }

  return (
    <>
      <QuickFitDashboardCard
        title={<>Sizing Recommendations</>}
        headerIcon={
          !noPrint ? (
            <PrintPDFButton
              pdfFunction={sizingPDF}
              sizingRecommendations={sizingRecommendations}
              tip='Print PDF Report'
              user={user}
              shop={shop}
              customer={customer}
              fileName='Sizing_Recommendations'
              bikeType={bikeType}
            />
          ) : null
        }
        style={{ minHeight: '305px' }}
        footer={
          bikeSelection ? <BikeSelectionRadio inputForm={inputForm} /> : null
        }
      >
        {noPrint && (
          <Table striped size='sm' style={{ fontSize: '.8rem' }}>
            <tbody>
              <SizingRecommendationRow
                label='Frame Size (virtual seat tube)'
                value={frameSize}
                tip='Virtual seat tube length from center to center. (Based solely on inseam and may not reflect optimal size.)'
              />
              <SizingRecommendationRow
                label='Saddle Height (to pedal spindle)'
                value={minSaddleHeight + '-' + maxSaddleHeight}
                tip='Distance from pedal spindle center to saddle top with crank in line with seat tube.'
              />
              <SizingRecommendationRow
                label='Maximum Standover Height'
                value={maxStandoverHeight}
                tip='The maximum distance from the ground to the top of the top tube that will be safe and comfortable for a cyclist based on their inseam.'
              />
              <SizingRecommendationRow
                label='Handlebar Width (drop bar)'
                value={handlebarWidth}
                tip='Recommended handlebar size measured from the center of the hoods.'
              />
              <SizingRecommendationRow
                label='Saddle Width'
                value={minSaddleWidth + '-' + maxSaddleWidth}
                tip={`Recommended saddle width at the saddle's widest point based on sit bone measurement.`}
              />
              <SizingRecommendationRow
                label='Top Tube + Stem (drop bar)'
                value={topTubeAndStemDropBar}
                tip='Effective (horizontal) top tube length plus stem length.  
                To arrive at the starting stem to be used, subtract the bicylce top tube measurement from this value.'
              />
              <SizingRecommendationRow
                label='Top Tube + Stem (flat bar)'
                value={topTubeAndStemFlatBar}
                tip='Effective (horizontal) top tube length plus stem length.  
                To arrive at the starting stem to be used, subtract the bicylce top tube measurement from this value.'
              />
              <SizingRecommendationRow
                label='Top Tube + Stem (aero bar)'
                value={topTubeAndStemAeroBar}
                tip='Effective (horizontal) top tube length plus stem length.  
                To arrive at the starting stem to be used, subtract the bicylce top tube measurement from this value.'
              />
              <SizingRecommendationRow
                label='Adjusted Top Tube + Stem (drop bar)'
                value={AdjustedTopTubeAndStemDropBar}
                tip='Effective (horizontal) top tube length plus stem length (as adusted for physical considerations). To arrive at the starting stem to be used, subtract the bicycle top tube measurement from this value.'
              />
              <SizingRecommendationRow
                label='Adjusted Top Tube + Stem (flat bar)'
                value={AdjustedTopTubeAndStemFlatBar}
                tip='Effective (horizontal) top tube length plus stem length (as adusted for physical considerations). To arrive at the starting stem to be used, subtract the bicycle top tube measurement from this value.'
              />
              <SizingRecommendationRow
                label='Adjusted Top Tube + Stem (aero bar)'
                value={AdjustedTopTubeAndStemAeroBar}
                tip='Effective (horizontal) top tube length plus stem length (as adusted for physical considerations). To arrive at the starting stem to be used, subtract the bicycle top tube measurement from this value.'
              />
            </tbody>
          </Table>
        )}
        <TableData
          dataForm={dataForm}
          dataObject={{
            ...sizingRecommendations,
            saddleHeight: minSaddleHeight + '-' + maxSaddleHeight,
            saddleWidth: minSaddleWidth + '-' + maxSaddleWidth
          }}
          user={user}
        />

        {bikeImage && BikeImage}
      </QuickFitDashboardCard>
    </>
  )
}

export default SizingRecommendations
