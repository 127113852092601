import { useState } from 'react'

const useNextPrevious = (limit, initialLength) => {
  const [start, setStart] = useState(1)
  const [stop, setStop] = useState(limit)
  const [currentLength, setcurrentLength] = useState(initialLength)

  const handleNext = () => {
    if (start + limit - 1 >= currentLength) return

    setStart(start + limit)
    if (stop + limit < currentLength) setStop(stop + limit)
    else setStop(currentLength)
    testValues()
  }

  const handlePrevious = () => {
    if (start <= 1) return

    setStart(start - limit)
    if (stop === currentLength && currentLength % limit > 0)
      setStop(stop - (currentLength % limit))
    else setStop(stop - limit)

    testValues()
  }

  const handleDelete = () => {
    setcurrentLength(currentLength - 1)
    handlePrevious()
    testValues()
  }

  const testValues = () => {
    if (currentLength > 1 && currentLength < limit) setStop(currentLength)
    if (currentLength > 0 && stop > currentLength) setStop(currentLength)
    if (currentLength > 0 && start > stop) {
      currentLength % limit === 0
        ? setStart(currentLength - limit + 1)
        : setStart(currentLength - (currentLength % limit) + 1)
    }
  }

  return {
    start,
    stop,
    limit,
    length: currentLength,
    handleNext,
    handlePrevious,
    handleDelete,
    setcurrentLength,
    setStart,
    setStop
  }
}

export default useNextPrevious
