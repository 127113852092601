import { Routes, Route } from 'react-router-dom'
import QuickFitDashboard from '../quickfit/QuickFitDashboard'
import EditCustomerData from '../quickfit/customers/EditCustomerData'
import RetrieveCustomer from '../quickfit/customers/RetrieveCustomer'
import MyData from '../quickfit/data/MyData'
import BikeFit from '../quickfit/bikes/BikeFit'
import NotFound from '../layout/NotFound'

const QuickFitRoutes = ({
  newInterviews,
  cyclists,
  customerBikes,
  user,
  shop
}) => {
  return (
    <Routes>
      <Route
        path='/customer/:customerId'
        exact
        element={
          <EditCustomerData cyclists={cyclists} user={user} shop={shop} />
        }
      />

      <Route
        path='/bike/:customerId/:bikeId'
        exact
        element={<BikeFit cyclists={cyclists} user={user} />}
      />
      <Route
        path='/bike/:bikeId'
        exact
        element={
          <BikeFit
            customerBikes={customerBikes}
            cyclists={cyclists}
            user={user}
          />
        }
      />
      <Route
        path='dashboard'
        element={
          <QuickFitDashboard
            cyclists={cyclists}
            newInterviews={newInterviews}
            user={user}
            shop={shop}
          />
        }
      />

      <Route
        path='retrieve-customer'
        element={<RetrieveCustomer cyclists={cyclists} />}
      />
      <Route
        path='my-data'
        element={<MyData cyclists={cyclists} user={user} />}
      />
      <Route path='*' status={404} element={<NotFound />} />
    </Routes>
  )
}

export default QuickFitRoutes
