import React, { useEffect } from 'react'

import { connect } from 'react-redux'

import { updateFeedback, deleteFeedback } from '../../../redux/actions/feedback'

import GenerateForm from '../../forms/form-components/GenerateForm'

import useFeedbackActionsForm from '../../hooks/form-hooks/admin/useFeedbackActionsForm'

import DeleteRecordButton from '../../hook-components/DeleteRecordButton'

const FeedbackActions = ({ feedback, updateFeedback, deleteFeedback }) => {
  const feedbackActionsForm = useFeedbackActionsForm({
    read: feedback.read,
    resolved: feedback.resolved
  })

  const { read, resolved } = feedbackActionsForm.values

  const { changesMade } = feedbackActionsForm

  useEffect(() => {
    if (
      changesMade(
        { read: feedback.read, resolved: feedback.resolved },
        feedbackActionsForm.values
      )
    ) {
      updateFeedback(
        feedback._id,
        feedbackActionsForm.values,
        'Feedback Updated.'
      )
    }
  }, [
    read,
    resolved,
    changesMade,
    updateFeedback,
    feedback._id,
    feedback.read,
    feedback.resolved,
    feedbackActionsForm.values
  ])

  return (
    <>
      <GenerateForm {...feedbackActionsForm} />
      <DeleteRecordButton
        title='Delete Feedback?'
        confirmationPrompt='To confirm deletion'
        confirmationText='delete feedback'
        confirmationButtonText='Delete Feedback'
        tip='Delete this Feedback.'
        deleteFunction={deleteFeedback}
        recordId={feedback._id}
      >
        Delete Feedback from {feedback.userName}?
      </DeleteRecordButton>
    </>
  )
}

export default connect(null, { updateFeedback, deleteFeedback })(
  FeedbackActions
)
