import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Row, Col } from 'react-bootstrap'
import { yearMonthDayEval } from '../../../utils/dateFormats'
import DeleteRecordButton from '../../hook-components/DeleteRecordButton'
import PrintPDFButton from '../../../pdf/PrintPDFButton'

import { bikePdf } from '../../../pdf/BikePdf'
import BikeSelector from './BikeSelector'

const BikeFitHeader = ({
  firstName,
  lastName,
  deleteFunction,
  customer,
  user,
  shop,
  bike
}) => {
  const { make, model, updated, _id } = bike
  const customerId = customer._id
  return (
    <Card className='mb-3' body>
      <Row className='d-flex align-items-center'>
        <Col xs={2}>
          <Link to={`/quickfit/customer/${customerId}`}>
            <strong>{firstName + ' ' + lastName}</strong>
          </Link>
        </Col>
        <Col xs={4}>
          <BikeSelector bike={bike} customerId={customerId} />
        </Col>{' '}
        <Col xs={4}>Last Updated: {yearMonthDayEval(updated)}</Col>
        <Col xs={1}>
          <DeleteRecordButton
            title='Permanently Delete Bike?'
            confirmationPrompt='To confirm deletion'
            confirmationText='delete bike'
            confirmationButtonText='Delete Bike'
            tip='Delete this Bike.'
            deleteFunction={deleteFunction}
            recordId={_id}
          >
            Delete{' '}
            <strong>
              {make} {model}
            </strong>
            ? This action cannot be undone.
          </DeleteRecordButton>
        </Col>
        <Col xs={1}>
          <PrintPDFButton
            pdfFunction={bikePdf}
            dataObject={bike}
            tip='Print PDF Report'
            user={user}
            shop={shop}
            bike={bike}
            customer={customer}
            fileName='FKS_Fit_Report'
          />
        </Col>
      </Row>
    </Card>
  )
}

export default BikeFitHeader
