import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useUserBikeList from './useUserBikeList'

import {
  todayStamp,
  daysAgoStamp,
  beginYearStamp,
  beginLastYearStamp,
  fksInceptionStamp,
  dateToStamp
} from '../../../utils/dateFormats'

const useUserStats = () => {
  const [statsLoaded, setStatsLoaded] = useState(false)
  const [stats, setStats] = useState({})

  const customerList = useSelector((state) => state.cyclists.cyclists)

  const userBikeList = useUserBikeList()

  const { bikeList } = userBikeList

  const recordsCreated = (records, begin, end) => {
    return records.filter(
      (record) =>
        dateToStamp(record.created) >= begin &&
        dateToStamp(record.created) <= end
    ).length
  }

  const recordsFiltered = (records, begin, end, filterfunction) => {
    return records.filter(
      (record) =>
        dateToStamp(record.created) >= begin &&
        dateToStamp(record.created) <= end &&
        filterfunction(record)
    ).length
  }

  useEffect(() => {
    if (
      customerList &&
      bikeList &&
      customerList.length !== 0 &&
      bikeList.length !== 0
    ) {
      const getStats = (begin, end) => {
        return {
          customersCreated: recordsCreated(customerList, begin, end),
          bikesCreated: recordsCreated(bikeList, begin, end),
          maleCustomers: recordsFiltered(
            customerList,
            begin,
            end,
            (record) => record.cyclistProfile.gender === 'Male'
          ),
          femaleCustomers: recordsFiltered(
            customerList,
            begin,
            end,
            (record) => record.cyclistProfile.gender === 'Female'
          ),
          nonBinaryCustomers: recordsFiltered(
            customerList,
            begin,
            end,
            (record) => record.cyclistProfile.gender === 'Non-Binary'
          ),
          roadBikes: recordsFiltered(
            bikeList,
            begin,
            end,
            (record) => record.type === 'Road Bike'
          ),
          mountainBikes: recordsFiltered(
            bikeList,
            begin,
            end,
            (record) => record.type === 'Mountain Bike'
          ),
          ttBikes: recordsFiltered(
            bikeList,
            begin,
            end,
            (record) => record.type === 'TT/Tri Bike'
          ),
          gravelBikes: recordsFiltered(
            bikeList,
            begin,
            end,
            (record) => record.type === 'Gravel'
          ),
          cyclocrossBikes: recordsFiltered(
            bikeList,
            begin,
            end,
            (record) => record.type === 'Cyclocross'
          ),
          fatBikes: recordsFiltered(
            bikeList,
            begin,
            end,
            (record) => record.type === 'Fat Bike'
          ),
          touringBikes: recordsFiltered(
            bikeList,
            begin,
            end,
            (record) => record.type === 'Touring or Commuting'
          ),
          tandemBikes: recordsFiltered(
            bikeList,
            begin,
            end,
            (record) => record.type === 'Tandem'
          )
        }
      }

      const today = getStats(daysAgoStamp(1), todayStamp())
      const sevenDays = getStats(daysAgoStamp(7), todayStamp())
      const thirtyDays = getStats(daysAgoStamp(30), todayStamp())
      const ytd = getStats(beginYearStamp(), todayStamp())
      const lastYear = getStats(beginLastYearStamp(), beginYearStamp())
      const itd = getStats(fksInceptionStamp(), todayStamp())

      setStats({ today, sevenDays, thirtyDays, ytd, lastYear, itd })
      setStatsLoaded(true)
    }
  }, [bikeList, customerList])

  return {
    customerList,
    bikeList,
    stats,
    statsLoaded: statsLoaded
  }
}

export default useUserStats
