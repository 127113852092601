import React from 'react'
import { connect } from 'react-redux'
import { createBike } from '../../../redux/actions/bikes'
import { useNavigate } from 'react-router-dom'

import EditFormButton from '../../hook-components/EditFormButton'

import useAddBikeForm from '../../hooks/form-hooks/bikes/useAddBikeForm'

import { BIKE_ICON } from '../../../utils/fontAwesomeDefaults'

const AddBike = ({ customerId, firstName, lastName, createBike }) => {
  const addBikeForm = useAddBikeForm({
    type: 'Road Bike',
    make: '',
    model: '',
    frameSize: '',
    notes: ''
  })

  const { validateForm, form } = addBikeForm

  const navigate = useNavigate()

  const saveFunction = async () => {
    //get bikeId and navigate to this bike
    const newBikeId = await createBike(addBikeForm.values, customerId, true)
    addBikeForm.reset()

    navigate(`/quickfit/bike/${customerId}/${newBikeId}`)
  }

  return (
    <>
      <EditFormButton
        editButtonText='Add Bike'
        modalTitle={`Add Bike for ${firstName} ${lastName}?`}
        modalForm={addBikeForm}
        modalTitleIcon={BIKE_ICON}
        confirmationButtonIcon={BIKE_ICON}
        confirmationButtonText='Add Bike'
        saveFunction={saveFunction}
        disabled={!validateForm({ make: form.make, model: form.model })}
        fksmode='stacked'
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  prefitinterviews: state.prefitinterviews,
  bikes: state.bikes
})

export default connect(mapStateToProps, {
  createBike
})(AddBike)
