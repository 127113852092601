import api from '../../utils/api.js'
import { setAlert } from './alert'

import {
  CREATE_SHOP,
  SHOP_LOADED,
  UPDATE_SHOP,
  DELETE_SHOP,
  CLEAR_SHOP
} from './types'

// Load Shop
export const loadShop = () => async (dispatch) => {
  try {
    const res = await api.get('/shops/by-user')

    dispatch({
      type: SHOP_LOADED,
      payload: res.data
    })
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

export const loadShopPublic = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/shops/by-user-public/${userId}`)

    dispatch({
      type: SHOP_LOADED,
      payload: res.data
    })
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

// Create Shop
export const createShop = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/shops/by-userId', formData)

    dispatch({
      type: CREATE_SHOP,
      payload: res.data
    })
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

// Update Shop

export const updateShop = (shopId, formData) => async (dispatch) => {
  try {
    const res = await api.put(`/shops/${shopId}`, formData)

    dispatch({ type: UPDATE_SHOP, payload: res.data })

    dispatch(setAlert('Shop Information Updated', 'success'))
    //dispatch(loadUser())
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

//Delete Shop
export const deleteShop = (id) => async (dispatch) => {
  try {
    await api.delete(`/shops/${id}`)
    dispatch({ type: DELETE_SHOP, payload: id })
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

//Clear Shop

export const clearShop = () => (dispatch) => {
  dispatch({ type: CLEAR_SHOP })
}
