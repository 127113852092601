export const name = {
  type: 'text',
  label: 'Name',
  validationType: 'inputLength',
  length: 1
}

export const email = {
  type: 'email',
  label: 'Email Address',
  validationType: 'email'
}

export const password = {
  type: 'password',
  label: 'Password',
  validationType: 'password',
  errormessage: '(passwords do not match)',
  helpmessage:
    'Password must contain at least eight characters, one uppercase letter, one lowercase letter and one number. Special characters are allowed.'
}

export const confirmPassword = {
  type: 'password',
  label: 'Confirm Password',
  validationType: 'password',
  errormessage: '(passwords do not match)'
}

export const subject = {
  type: 'text',
  label: 'Subject',
  validationType: 'inputLength',
  length: 1
}

export const message = {
  type: 'text',
  as: 'textarea',
  rows: '8',
  placeholder: 'Enter messsage.',
  validationType: 'inputLength',
  length: 1
}
