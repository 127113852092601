import api from '../../utils/api.js'
import { setAlert } from './alert'

import {
  ADMIN_MESSAGES_LOADED,
  CLEAR_ADMIN_MESSAGES,
  UPDATE_ADMIN_MESSAGE,
  DELETE_ADMIN_MESSAGE,
  CREATE_ADMIN_MESSAGE
} from './types'

// Get all messsages
export const loadAdminMessages = () => async (dispatch) => {
  try {
    const res = await api.get('/admin-messages')

    dispatch({
      type: ADMIN_MESSAGES_LOADED,
      payload: res.data
    })
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}

// Create Admin Message
export const createAdminMessage = (formData, alert) => async (dispatch) => {
  try {
    const res = await api.post('/admin-messages', formData)

    dispatch({
      type: CREATE_ADMIN_MESSAGE,
      payload: res.data
    })
    if (alert) dispatch(setAlert(alert, 'success'))
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

//Update Message

export const updateAdminMessage =
  (messageId, formData, alertMessage) => async (dispatch) => {
    try {
      const res = await api.put(`/admin-messages/${messageId}`, formData)

      dispatch({ type: UPDATE_ADMIN_MESSAGE, payload: res.data })

      if (alertMessage) dispatch(setAlert(alertMessage, 'success'))
      // dispatch(loadAdminMessages())
    } catch (err) {
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
      }
    }
  }

//Delete Message
export const deleteAdminMessage = (id) => async (dispatch) => {
  try {
    await api.delete(`/admin-messages/${id}`)
    dispatch({ type: DELETE_ADMIN_MESSAGE, payload: id })
    dispatch(setAlert('Message Deleted', 'success'))
  } catch (err) {
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
    }
  }
}

//Clear Admin Messages

export const clearAdminMessages = () => (dispatch) => {
  dispatch({ type: CLEAR_ADMIN_MESSAGES })
}
