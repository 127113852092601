import { useEffect, useState } from 'react'

import api from '../../../utils/api.js'
import { Card } from 'react-bootstrap'
import {
  collectionField,
  userParam,
  customerParam,
  bikeParam,
  searchParam
} from '../../../forms-new/fields-and-forms/adminFields'
import useForm from '../../../forms-new/form-hooks/useForm'
import GenerateForm from '../../../forms-new/form-components/GenerateForm'
import SearchReport from './SearchReport.js'
import SearchError from './SearchError.js'
import Spinner from '../../layout/Spinner.js'

const SearchData = ({
  setSearchData,
  setCollection,
  searchedCollection,
  setErrors,
  searchData,
  errors
}) => {
  const collectionForm = useForm(collectionField, { collection: 'users' })
  const userParamForm = useForm(userParam, { userField: 'name' })
  const customerParamForm = useForm(customerParam, {
    customerField: 'firstName'
  })
  const bikeParamForm = useForm(bikeParam, { bikeField: 'make' })
  const searchParamForm = useForm(searchParam, { searchParam: '' })
  const { collection } = collectionForm.values

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (searchData === null && errors === null) {
      collectionForm.reset()
      userParamForm.reset()
      customerParamForm.reset()
      bikeParamForm.reset()
      searchParamForm.reset()
    }
    // eslint-disable-next-line
  }, [searchData, errors])

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setSearchData(null)
    const field =
      collection === 'users'
        ? userParamForm.values.userField
        : collection === 'customers'
        ? customerParamForm.values.customerField
        : bikeParamForm.values.bikeField

    try {
      const res = await api.get(
        `/admin-data/search?collection=${collection}&field=${field}&searchParam=${searchParamForm.values.searchParam}`
      )

      setCollection(collection)
      setSearchData(res.data)
      setLoading(false)
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        setErrors(errors)
      }
    }
  }

  return (
    <>
      <Card body style={{ minHeight: '322px' }}>
        <GenerateForm {...collectionForm} />
        {collection === 'users' && <GenerateForm {...userParamForm} />}
        {collection === 'customers' && <GenerateForm {...customerParamForm} />}
        {collection === 'bikes' && <GenerateForm {...bikeParamForm} />}
        <GenerateForm
          {...searchParamForm}
          onSubmit={onSubmit}
          buttonText='Search'
          buttonVariant='dark'
        />
      </Card>
      {loading && <Spinner className='mt-5' message='Loading Data' />}
      <SearchReport collection={searchedCollection} searchData={searchData} />
      {errors && <SearchError errors={errors} />}
    </>
  )
}

export default SearchData
