import jsPDF from 'jspdf'

import {
  getFormat,
  getHeader,
  getHR,
  getCustomerProfileForms,
  getSizingRecommendationForms,
  getTableBlock,
  getBikeImage,
  getCustomerNotes,
  getFooter,
  LEFT_MARGIN,
  TOP_MARGIN,
  RIGHT_COLUMN_X,
  HR_TWO_Y,
  BLOCK_ONE_Y,
  BLOCK_TWO_Y,
  BLOCK_THREE_Y,
  BLOCK_FOUR_Y
} from './pdfFunctions'

import {
  bodyMeasurementsForm,
  physicalProfileForm
} from '../components/forms/form-objects/customerFormObjects'

import { calculateAge } from '../utils/dateFormats'

export const sizingPDF = (props) => {
  const { sizingRecommendations, fileName, user, shop, customer, bikeType } =
    props
  let pdf = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: getFormat(user)
  })

  pdf.setFontSize(10)
  pdf.setTextColor(user.preferences.report_color)
  // Header
  getHeader(pdf, user, shop, 'Fit Kit Studio Sizing Recommendations')

  //Table Blocks

  const {
    cyclist_name,
    cyclist_profile,
    fk_body_measurements,
    fk_soft_score,
    sizing_recommendations,
    bike_diagram,
    notes_recommendations
  } = user.preferences.report_fields

  //Cyclist Profile

  if (cyclist_name || cyclist_profile) {
    const [profileForm, profileObject] = getCustomerProfileForms(customer, user)

    getTableBlock({
      pdf,
      dataForm: profileForm,
      dataObject: profileObject,
      user,
      cols: [25],
      heading: 'Cyclist',
      x: LEFT_MARGIN,
      y: BLOCK_ONE_Y
    })
  }

  //Body Measurements

  if (fk_body_measurements) {
    getTableBlock({
      pdf,
      dataForm: bodyMeasurementsForm,
      dataObject: customer.bodyMeasurements,
      user,
      cols: [25],
      heading: 'Body Measurements',
      x: LEFT_MARGIN,
      y: BLOCK_TWO_Y
    })
  }

  //Physical Profile
  if (fk_soft_score) {
    getTableBlock({
      pdf,
      dataForm: { age: { label: 'Age', type: 'text' }, ...physicalProfileForm },
      dataObject: {
        age: calculateAge(customer.cyclistProfile.birthDate).toString(),
        ...customer.softScores
      },
      user,
      cols: [25],
      heading: 'Physical Profile',
      x: LEFT_MARGIN,
      y: BLOCK_THREE_Y
    })
  }

  const [sizingForm, sizingObject] = getSizingRecommendationForms(
    sizingRecommendations,
    bikeType
  )

  if (sizing_recommendations) {
    getTableBlock({
      pdf,
      dataForm: sizingForm,
      dataObject: sizingObject,
      user,
      colWidth: 65,
      heading: 'Sizing Recommendations',
      x: LEFT_MARGIN,
      y: BLOCK_FOUR_Y,
      cols: [65, 25]
    })
  }

  //Bike Image
  if (bike_diagram) {
    getBikeImage({
      pdf,
      bikeType,
      x: RIGHT_COLUMN_X,
      y: TOP_MARGIN + 30
    })
  }

  //Horizontal Rules

  getHR(pdf, HR_TWO_Y)

  //Footer
  getFooter({ pdf })

  //Notes

  if (notes_recommendations) {
    getCustomerNotes({
      pdf,
      notes: customer.notes,
      user,
      shop,
      x: LEFT_MARGIN,
      y: TOP_MARGIN + 210
    })
  }

  pdf.save(fileName + '.pdf')
}
