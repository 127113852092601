import { useState, useEffect } from 'react'
import store from '../../../redux/store'
import { loadAdminBikeList } from '../../../redux/actions/admindata'

const useAdminBikeList = () => {
  const [bikeListLoaded, setBikeListLoaded] = useState(false)
  const [bikeList, setBikeList] = useState()

  useEffect(() => {
    const fetchUserList = async () => {
      const bikeList = await store.dispatch(loadAdminBikeList())
      setBikeList(bikeList)
      setBikeListLoaded(true)
    }
    fetchUserList()
  }, [])

  return { bikeListLoaded, bikeList }
}

export default useAdminBikeList
