import jsPDF from 'jspdf'

import {
  getFormat,
  getHeader,
  getCustomerProfileForms,
  getTableBlock,
  getHistoryTableBlock,
  getFooter,
  getBikeStrings,
  getFitPositionForm,
  LEFT_MARGIN,
  BLOCK_ONE_Y,
  BLOCK_TWO_Y
} from './pdfFunctions'

export const historyPdf = (props) => {
  const { bike, fileName, user, shop, customer } = props
  const { type: bikeType } = bike

  let pdf = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: getFormat(user)
  })

  pdf.setFontSize(10)
  pdf.setTextColor(user.preferences.report_color)
  // Header
  getHeader(pdf, user, shop, [
    'Bike Fit History: ' + getBikeStrings(bike).bikeString,
    getBikeStrings(bike).bikeUpdatedString
  ])

  //Table Blocks

  const { cyclist_name, cyclist_profile, fit_position } =
    user.preferences.report_fields

  //Cyclist Profile

  if (cyclist_name || cyclist_profile) {
    const [profileForm, profileObject] = getCustomerProfileForms(customer, user)

    getTableBlock({
      pdf,
      dataForm: profileForm,
      dataObject: profileObject,
      user,
      cols: [25],
      heading: 'Cyclist',
      x: LEFT_MARGIN,
      y: BLOCK_ONE_Y
    })
  }

  if (fit_position) {
    //Fit Position
    getHistoryTableBlock({
      pdf,
      dataForm: getFitPositionForm(bikeType),
      dataObject: bike,
      bike: bike,
      user,
      heading: 'Fit Position',
      x: LEFT_MARGIN,
      y: BLOCK_TWO_Y
    })
  }

  //Footer
  getFooter({ pdf })

  pdf.save(fileName + '.pdf')
}
