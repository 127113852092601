import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Button, Form } from 'react-bootstrap'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import {
  createStripeCustomer,
  createStripeSubscription
} from '../../../redux/actions/stripe'

import { getSubscriptionPrice } from '../../../utils/stripeUtils'

import Spinner from '../../layout/Spinner'
import PaymentFrequencyForm from './PaymentFrequencyForm'
import PropTypes from 'prop-types'

const UpgradeModal = ({
  users: { user },
  createStripeCustomer,
  createStripeSubscription,
  showModal,
  setShowModal
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [disableSubmit, setDisableSubmit] = useState(true)

  const [processing, setProcessing] = useState(false)

  const [frequency, setFrequency] = useState('yearly')

  const clickCancel = () => {
    setShowModal(false)
  }

  const clickActivate = async (e) => {
    if (!stripe || !elements) {
      return
    }
    setProcessing(true)
    const cardElement = elements.getElement(CardElement)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })

    if (error) {
      console.log('[error]', error)
    } else {
      const newCustomer = await createStripeCustomer({
        name: user.name,
        email: user.email,
        payment_method: paymentMethod.id
      })

      await createStripeSubscription({
        name: user.name,
        email: user.email,
        customerId: newCustomer.id,
        trial: true,
        frequency
      })
    }

    setShowModal(false)
    setProcessing(false)
  }

  const validateForm = (ev) => {
    if (stripe && ev.complete === true) setDisableSubmit(false)
    if (ev.error || !ev.complete) setDisableSubmit(true)
    if (ev.error) console.log(ev.error)
  }

  return (
    <Modal
      show={showModal}
      onHide={clickCancel}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          Activate Subscription to Quick Fit
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        Subscription: Quick Fit
        <br />
        <span className='me-3'>Payment Frequency:</span>{' '}
        <PaymentFrequencyForm
          inline
          frequency={frequency}
          setFrequency={setFrequency}
        />
        <Form.Label>Amount: U.S. ${getSubscriptionPrice(frequency)}</Form.Label>
        <p className='mt-3'>
          Please enter a payment method to start your{' '}
          {process.env.REACT_APP_TRIAL_PERIOD} day trial period.
        </p>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4'
                }
              },
              invalid: {
                color: '#9e2146'
              }
            }
          }}
          onChange={validateForm}
        />
        <p className='mt-4'>
          {' '}
          You will not be charged until your trial period ends. After your trial
          period, you will be charged ${getSubscriptionPrice(frequency)} for
          your {frequency === 'six months' ? 'six month' : frequency}{' '}
          subscription. You may cancel or change plans at any time.
        </p>
      </Modal.Body>

      {processing && (
        <Modal.Footer className='d-flex justify-content-center'>
          <span>
            <Spinner message='Processing . . .' />
          </span>
        </Modal.Footer>
      )}
      {!processing && (
        <Modal.Footer>
          <Button
            disabled={disableSubmit}
            onClick={clickActivate}
            variant='danger'
          >
            <i className='fa-regular fa-dollar-sign' /> Activate Subscription
          </Button>{' '}
          <Button variant='secondary' onClick={clickCancel}>
            Cancel
          </Button>
          <Button disabled variant='outline-light'>
            <img src='/stripe-black.svg' alt='Powered by Stripe' width='100' />
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}

UpgradeModal.propTypes = {
  createStripeCustomer: PropTypes.func.isRequired,
  createStripeSubscription: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  users: state.users,
  mode: PropTypes.func.isRequired,
  createStripeCustomer: PropTypes.func.isRequired,
  createStripeSubscription: PropTypes.func.isRequired
})

export default connect(mapStateToProps, {
  createStripeCustomer,
  createStripeSubscription
})(UpgradeModal)
