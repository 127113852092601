import { Card } from 'react-bootstrap'

import { getPaymentFrequency } from '../../../utils/stripeUtils'

import UpdatePaymentFrequencyButton from './UpdatePaymentFrequencyButton'
import CancelSubscriptionButton from './CancelSubscriptionButton'
import UpdatePaymentButton from './UpdatePaymentButton'

const ActiveSubscription = ({ currentStop, subscription, last4 }) => {
  const { plan } = subscription

  return (
    <>
      <Card>
        <Card.Header className='bg-white'>
          <Card.Title>Subscription</Card.Title>
        </Card.Header>
        <Card.Body>
          <p>
            You have an Active Subscription to: <b>Quick Fit</b>
          </p>
          <p>
            Your payment frequency is: <b>{getPaymentFrequency(plan)}</b>{' '}
            <UpdatePaymentFrequencyButton
              subscription={subscription}
              currentStop={currentStop}
            />
          </p>
          <p>
            Your subscription will automatically renew on:
            <b> {currentStop}</b>
          </p>
          <p>
            Your current payment method is: Credit Card ending in:{' '}
            <b>{last4}</b> <UpdatePaymentButton />
          </p>
        </Card.Body>
        <Card.Footer className='bg-white'>
          <CancelSubscriptionButton
            subscription={subscription}
            currentStop={currentStop}
          />
        </Card.Footer>
      </Card>
    </>
  )
}

export default ActiveSubscription
