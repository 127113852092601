import { Routes, Route } from 'react-router-dom'

import Tools from '../tools/Tools'
import Resources from '../tools/resources/Resources'
import NotFound from '../layout/NotFound'

const ToolsRoutes = ({ user, fieldOverrides }) => {
  return (
    <Routes>
      <Route
        path='tools'
        element={<Tools user={user} fieldOverrides={fieldOverrides} />}
      />

      <Route path='resources' element={<Resources />} />
      <Route path='*' status={404} element={<NotFound />} />
    </Routes>
  )
}

export default ToolsRoutes
