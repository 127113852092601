import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'

const CardLink = ({ item }) => {
  return (
    <Card body>
      <Row>
        <Col className='d-flex align-items-center'>
          {item.resourceImageUrl && (
            <img
              style={{ maxWidth: '100px' }}
              src={item.resourceImageUrl}
              alt='Resource'
            />
          )}
        </Col>
        <Col className='d-flex align-items-center'>
          <a href={item.resourceLink} target='_blank' rel='noreferrer'>
            {item.resourceName}
          </a>
        </Col>
        <Col className='d-flex align-items-center'>
          {item.resourceDescription}
        </Col>
      </Row>
    </Card>
  )
}

export default CardLink
