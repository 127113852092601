import {
  SET_LOADING,
  SET_REFRESH,
  REGISTER_SUCCESS,
  USER_LOADED,
  USER_ID_CONFIRMED,
  USER_ID_NOT_CONFIRMED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  AUTO_LOGOUT,
  ACCOUNT_DELETED,
  PASSWORD_RESET_TOKEN_INVALID,
  PASSWORD_RESET_TOKEN_VALID,
  RESET_USER_PASSWORD,
  UPDATE_USER_SUCCESS
} from '../actions/types'

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  userLoading: true,
  userRefreshing: false,
  user: null,
  validResetToken: false,
  deleteAccountModalShow: false,
  userIdConfirmed: null,
  preFitUser: { shop_owner: false },
  userMessage: ''
}

export default function users(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        userLoading: payload
      }

    case SET_REFRESH:
      return {
        ...state,
        userRefreshing: payload
      }
    case USER_LOADED:
      return {
        ...state,
        user: payload,
        isAuthenticated: true,
        userLoading: false,
        userRefreshing: false
      }
    case USER_ID_CONFIRMED:
      return {
        ...state,
        preFitUser: payload,
        userIdConfirmed: true
      }
    case USER_ID_NOT_CONFIRMED:
      return {
        ...state,
        userIdConfirmed: false
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        userLoading: true
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        userLoading: true
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload
        },
        loading: false
      }

    case ACCOUNT_DELETED:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        userLoading: false,
        user: null
      }

    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        userLoading: false,
        user: null,
        userMessage: ''
      }
    case AUTO_LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        userLoading: false,
        user: null,
        userMessage: 'Your session has expired. Please login again.'
      }
    case PASSWORD_RESET_TOKEN_INVALID:
      return {
        ...state,
        validResetToken: false,
        userLoading: false
      }
    case PASSWORD_RESET_TOKEN_VALID:
      return {
        ...state,
        validResetToken: true,
        userLoading: false
      }

    case RESET_USER_PASSWORD:
      return {
        ...state,
        userLoading: false
      }

    default:
      return state
  }
}
