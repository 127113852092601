import {
  CREATE_BIKE,
  BIKE_LOADED,
  CUSTOMER_BIKES_LOADED,
  UPDATE_BIKE,
  DELETE_BIKE,
  CLEAR_BIKE,
  CLEAR_CUSTOMER_BIKES
} from '../actions/types'

const initialState = {
  bike: null,
  customerBikes: []
}

export default function bikes(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case BIKE_LOADED:
      return {
        ...state,
        bike: payload
      }
    case CUSTOMER_BIKES_LOADED:
      return {
        ...state,
        customerBikes: payload
      }
    case CREATE_BIKE:
    case UPDATE_BIKE:
      return {
        ...state,
        bike: payload
      }

    case DELETE_BIKE:
      return {
        ...state,
        customerBikes: state.customerBikes.filter(
          (bike) => bike._id !== payload
        )
      }
    case CLEAR_CUSTOMER_BIKES:
    case CLEAR_BIKE:
      return {
        ...initialState
      }

    default:
      return state
  }
}
