import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { register } from '../../redux/actions/users'

import { registerFields } from '../../forms-new/fields-and-forms/authFields'

import useForm from '../../forms-new/form-hooks/useForm'

import UserFormContainer from './UserFormContainer'

import GenerateForm from '../../forms-new/form-components/GenerateForm'

import { validateForm, checkPasswordMatch } from '../../utils/formValidation'
import SEO from '../seo/SEO'

const Register = ({ register, isAuthenticated }) => {
  const [startTime, setStartTime] = useState()

  useEffect(() => {
    const now = Date.now()
    setStartTime(now)
  }, [])

  const signupForm = useForm(registerFields, {
    name: '',
    email: '',
    age: '',
    password: '',
    confirmPassword: ''
  })

  const {
    form,
    values: { password, confirmPassword }
  } = signupForm

  const onSubmit = (e) => {
    e.preventDefault()
    const stopTime = Date.now()

    const submissionObject = {
      name: signupForm.values.name,
      email: signupForm.values.email,
      password: signupForm.values.password,
      honey_pot: signupForm.values.age,
      fill_time: (stopTime - startTime) / 1000
    }

    register(submissionObject)
    //console.log(submissionObject)
  }

  if (isAuthenticated) {
    return <Navigate to='/account/dashboard' />
  }

  return (
    <>
      <SEO
        title='Fit Kit Studio | Register'
        description='Fit Kit Studio Bike Fitting Software Registration Page'
        type='website'
        name='Fit Kit Systems'
      />
      <UserFormContainer heading='Register'>
        <GenerateForm
          {...signupForm}
          buttonText='Register'
          onSubmit={onSubmit}
          disabled={
            !validateForm(form) ||
            !checkPasswordMatch(password, confirmPassword)
          }
          error={
            validateForm(signupForm.form) &&
            !checkPasswordMatch(password, confirmPassword)
          }
        />
        <p></p>
        <p className='text-center'>
          Already have an account?{' '}
          <a href='https://fitkitstudio.com/login'>Sign In</a>{' '}
          {/* <Link to='/login'>Sign In</Link> */}
        </p>
      </UserFormContainer>
    </>
  )
}

Register.propTypes = {
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.users.isAuthenticated
})

export default connect(mapStateToProps, { register })(Register)
