import React from 'react'
import { Card } from 'react-bootstrap'

import TableData from '../layout/cards-containers/TableData'

const ModalOutputs = ({ inputs, user, dataForm, dataObject }) => {
  return (
    <Card className='mt-3'>
      <Card.Body>
        <Card.Title>Outputs</Card.Title>
        <TableData
          dataForm={dataForm}
          dataObject={dataObject}
          user={user}
          fontStyle={{ fontSize: '1.0rem' }}
        />
      </Card.Body>
    </Card>
  )
}

export default ModalOutputs
