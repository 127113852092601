import { Form } from 'react-bootstrap'

const HoneyPot = (props) => {
  const { label, inputSize } = props

  return (
    <>
      <Form.Group className='mb-3 d-none'>
        {label && <Form.Label>{label} </Form.Label>}
        <Form.Control
          {...props}
          error={null}
          type='text'
          size={inputSize || 'sm'}
        />
      </Form.Group>
    </>
  )
}

export default HoneyPot
