import React from 'react'
import { Link } from 'react-router-dom'

import { Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap'

import DashboardCard from '../../layout/cards-containers/DashboardCard'

const ShopStudioInfo = ({ shop }) => {
  const { _id: shopId, logo, name, address, address2, phone, website } = shop
  let logoSource = '/favicon.ico'
  if (logo) logoSource = `/api/shops/photo/${shopId}`

  return (
    <>
      <DashboardCard
        style={{ minHeight: '100px', fontSize: '.8rem' }}
        title='Shop/Studio Information'
      >
        {shopId ? (
          <Row>
            <Col>
              <img
                style={{ maxHeight: '75px', maxWidth: '100%' }}
                src={logoSource}
                alt=''
              />
            </Col>
            <Col>
              <ListGroup>
                <ListGroupItem>
                  {name ? name : 'Shop name not specified'}
                </ListGroupItem>
                <ListGroupItem>
                  {address ? address : 'Address not specified'}
                  {address2 && (
                    <>
                      <br />
                      {address2}
                    </>
                  )}
                </ListGroupItem>
                <ListGroupItem>
                  {phone ? phone : 'Phone not specfified'}
                </ListGroupItem>
                <ListGroupItem>
                  {website ? website : 'Website not specified'}
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
        ) : (
          <p>
            <i className='fas fa-store-alt' /> You have not created a shop yet.
            Edit your <Link to='/account/settings?tab=shop'>shop/studio</Link>{' '}
            details to create a shop.
          </p>
        )}
      </DashboardCard>
    </>
  )
}

export default ShopStudioInfo
