import { Form } from 'react-bootstrap'

const PaymentFrequencyForm = ({ frequency, setFrequency, inline }) => {
  return (
    <>
      <Form.Check
        checked={frequency === 'yearly'}
        onChange={(e) => setFrequency(e.target.id)}
        id='yearly'
        inline={inline}
        label={`Yearly ($${process.env.REACT_APP_SUBSCRIPTION_PRICE})`}
        name='frequency'
        type='radio'
      />
      <Form.Check
        checked={frequency === 'six months'}
        onChange={(e) => setFrequency(e.target.id)}
        id='six months'
        inline={inline}
        label={`Six Months ($${process.env.REACT_APP_SIX_MONTH_SUBSCRIPTION_PRICE})`}
        name='frequency'
        type='radio'
      />
      <Form.Check
        checked={frequency === 'monthly'}
        onChange={(e) => setFrequency(e.target.id)}
        id='monthly'
        inline={inline}
        label={`Monthly ($${process.env.REACT_APP_MONTHLY_SUBSCRIPTION_PRICE})`}
        name='frequency'
        type='radio'
      />
    </>
  )
}

export default PaymentFrequencyForm
