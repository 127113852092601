import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import QuickFitTabContent from '../QuickFitTabContent'

import useFitPositionForm from '../../hooks/form-hooks/bikes/useFitPositionForm'

import { updateBike } from '../../../redux/actions/bikes'

import { BIKE_ICON } from '../../../utils/fontAwesomeDefaults'

import { validateForm, changesMade } from '../../../utils/formValidation'

import { getFitHistory } from '../../../utils/generalFunctions'

const FitPosition = ({ bike, updateBike, user, fieldOverrides }) => {
  const fitPositionForm = useFitPositionForm(bike, fieldOverrides)

  const [currentBike, setCurrentBike] = useState(bike)

  const { type } = bike
  let editForm

  switch (type) {
    case 'Mountain Bike':
    case 'Fat Bike':
      editForm = fitPositionForm.mountainBikeFitPositionForm
      break
    case 'TT/Tri Bike':
      editForm = fitPositionForm.ttBikeFitPositionForm
      break
    default:
      editForm = fitPositionForm.roadBikeFitPositionForm
  }

  const saveFunction = () => {
    const bikeObject = {
      ...fitPositionForm.values,
      fitHistory: getFitHistory(bike)
    }
    updateBike(bike._id, bikeObject, 'Fit Position Updated')
  }

  const oldValues = {}
  const newValues = {}
  Object.entries(editForm).map(([key, value]) => {
    oldValues[key] = bike[key]
    newValues[key] = fitPositionForm.values[key]
    return null
  })

  useEffect(() => {
    if (bike !== currentBike) {
      fitPositionForm.reset()
      setCurrentBike(bike)
    }
  }, [bike, currentBike, fitPositionForm])

  const { handlebarStackHY, handlebarReachHX, ...validationForm } = editForm

  return (
    <>
      <QuickFitTabContent
        title='Fit Position'
        dataForm={editForm}
        dataObject={bike}
        editButtonText='Edit Fit Position'
        modalTitle='Edit Fit Position'
        modalForm={{ form: editForm, reset: fitPositionForm.reset }}
        modalTitleIcon={BIKE_ICON}
        confirmationButtonIcon={BIKE_ICON}
        saveFunction={saveFunction}
        user={user}
        formGroup='quickfit'
        cols={['0', '8', '4', '0']}
        disabled={
          !validateForm(validationForm) || !changesMade(oldValues, newValues)
        }
      />
    </>
  )
}

export default connect(null, { updateBike })(FitPosition)
