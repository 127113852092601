import React from 'react'

import { utcToutc, usNormal } from '../../../utils/dateFormats'

import Tip from '../../layout/overlays/Tip'

import {
  PLUS_ICON,
  CYCLIST_ICON,
  CALENDAR_ICON,
  EMAIL_ICON
} from '../../../utils/fontAwesomeDefaults'

const AddInterviewRow = ({ cyclist, interview, handleAddInterview }) => {
  const { updated, _id } = cyclist
  const { firstName, lastName, birthDate, gender, email } =
    cyclist.cyclistProfile

  const clickAdd = () => {
    handleAddInterview(
      interview._id,
      { ...interview, createdForCyclist: _id },
      cyclist
    )
  }

  return (
    <>
      <tr>
        <td>
          <Tip
            message={
              <>
                {firstName + ' ' + lastName}
                <br />
                Birthdate: {utcToutc(birthDate)} <br />
                Gender: {gender}
                <br />
                {email}
              </>
            }
          >
            <span>
              <i className={CYCLIST_ICON} /> {firstName + ' ' + lastName}
            </span>
          </Tip>
        </td>
        <td>
          <i className={EMAIL_ICON} /> {email}
        </td>

        <td>
          <Tip message={<>Last updated {usNormal(updated)} </>}>
            <span>
              <i className={CALENDAR_ICON} /> {usNormal(updated)}
            </span>
          </Tip>
        </td>
        <td>
          <Tip message={<>Add interview to customer.</>}>
            <span
              className='text-fitkit'
              style={{ cursor: 'pointer' }}
              onClick={clickAdd}
            >
              <i className={PLUS_ICON} /> Add
            </span>
          </Tip>
        </td>
      </tr>
    </>
  )
}

export default AddInterviewRow
