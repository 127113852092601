import React from 'react'
import { connect } from 'react-redux'
import QuickFitTabContent from '../QuickFitTabContent'

import usePhysicalProfileForm from '../../hooks/form-hooks/customers/usePhysicalProfileForm'

import { updateCyclist } from '../../../redux/actions/cyclists'

import { physicalProfileForm as dataForm } from '../../forms/form-objects/customerFormObjects'

import { CYCLIST_ICON } from '../../../utils/fontAwesomeDefaults'

const PhysicalProfile = ({
  customer,
  user,
  updateCyclist,
  fields: { fieldOverrides }
}) => {
  const physicalProfileForm = usePhysicalProfileForm(
    customer.softScores,
    fieldOverrides
  )

  const { validateForm, form, changesMade, values } = physicalProfileForm

  const saveFunction = () => {
    const customerObject = {
      cyclistProfile: customer.cyclistProfile,
      softScores: physicalProfileForm.values
    }
    if (physicalProfileForm.values.preconditions === 'Yes (soft tissue)')
      customerObject.softScores.preconditions = 'Yes'
    if (physicalProfileForm.values.preconditions === 'Spinal (lumbar fusion)')
      customerObject.softScores.preconditions = 'Spinal (-1)'
    if (physicalProfileForm.values.preconditions === 'Spinal (cervical fusion)')
      customerObject.softScores.preconditions = 'Spinal (-2)'

    updateCyclist(customer._id, customerObject, 'Physical Profile Updated')
  }

  return (
    <QuickFitTabContent
      title='Physical Considerations'
      dataForm={dataForm}
      dataObject={customer.softScores}
      editButtonText='Edit Physical Considerations'
      modalTitle='Edit Physical Considerations'
      modalForm={physicalProfileForm}
      modalTitleIcon={CYCLIST_ICON}
      confirmationButtonIcon={CYCLIST_ICON}
      saveFunction={saveFunction}
      user={user}
      cols={[2, 3, 6, 1]}
      disabled={
        !validateForm(form) || !changesMade(customer.softScores, values)
      }
    />
  )
}

const mapStateToProps = (state) => ({
  fields: state.fields
})

export default connect(mapStateToProps, { updateCyclist })(PhysicalProfile)
