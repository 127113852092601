//React and Components
import { useEffect } from 'react'

//Redux
import { Provider } from 'react-redux'
import store from './redux/store'
import { LOGOUT } from './redux/actions/types'
import { checkAuthToken, loadUser, setLoading } from './redux/actions/users'

//Stripe.js Components
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

//Utils
import setAuthToken from './utils/setAuthToken'
import setPublicAPIKeyHeader from './utils/setPublicAPIKey'

//Routes
import HeaderRoutes from './components/routing/HeaderRoutes'
import FooterRoutes from './components/routing/FooterRoutes'
import ContentRoutes from './components/routing/ContentRoutes'

//Helmet
import { HelmetProvider } from 'react-helmet-async'

//Styles
import './App.scss'

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`)

const App = () => {
  useEffect(() => {
    //set Public API Key Header

    setPublicAPIKeyHeader(process.env.REACT_APP_API_KEY)
  }, [])

  useEffect(() => {
    //check for token in local storage
    if (localStorage.token) {
      setAuthToken(localStorage.token)
    }
    if (localStorage.token) {
      store.dispatch(setLoading(true))
      store.dispatch(loadUser())
    } else store.dispatch(setLoading(false))

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) {
        store.dispatch({ type: LOGOUT })
      }
    })
  }, [])

  const checkToken = () => {
    if (localStorage.token) {
      store.dispatch(checkAuthToken(localStorage.token))
    }
  }

  useEffect(() => {
    setInterval(checkToken, 900000)
  }, [])

  return (
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <HelmetProvider>
          <HeaderRoutes />
          <ContentRoutes />
          <FooterRoutes />
        </HelmetProvider>
      </Provider>
    </Elements>
  )
}

export default App
