import React from 'react'
import { Card, Container, Image } from 'react-bootstrap'

const NotFound = () => {
  return (
    <Container fluid className='userForms'>
      <Card border='dark' text='light' className='bg-dark text-center'>
        <Card.Header>Error 404</Card.Header>
        <Card.Body className='d-flex justify-content-center'>
          <Card.Text>
            <Image className='' src='/favicon.ico' />
          </Card.Text>
        </Card.Body>
        <Card.Footer>Page Not Found</Card.Footer>
      </Card>
    </Container>
  )
}

export default NotFound
