import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'

const EditGravatar = ({ user: { avatar } }) => {
  return (
    <Card className='mt-3'>
      <Card.Header className='bg-white'>
        {' '}
        <Card.Title>Gravatar</Card.Title>
      </Card.Header>

      <Card.Body className='card-text-size'>
        {' '}
        <Row>
          <Col>
            <img style={{ maxHeight: '160px' }} src={avatar} alt='' />
          </Col>
          <Col sm={8} xs={6}>
            <p>
              {' '}
              Fit Kit Studio supports the Gravatar service for profile images.{' '}
            </p>
            <p>
              You can create a free gravatar at{' '}
              <a
                href='https://www.gravatar.com'
                target='_blank'
                rel='noreferrer'
              >
                {' '}
                Gravatar.com{' '}
              </a>{' '}
            </p>
            <p>
              Your Fit Kit Studio login email must be an email addresses
              registered to your Gravatar account.
            </p>
            <p>
              Profile images are optional and only appear on your "My Account"
              pages.
            </p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default EditGravatar
