import React from 'react'
import { connect } from 'react-redux'

import QuickFitTabContent from '../QuickFitTabContent'

import useCustomerProfileForm from '../../hooks/form-hooks/customers/useCustomerProfileForm'

import { updateCyclist } from '../../../redux/actions/cyclists'

import { customerProfileForm as dataForm } from '../../forms/form-objects/customerFormObjects'
import { CYCLIST_ICON } from '../../../utils/fontAwesomeDefaults'

const CustomerProfile = ({
  customer,
  updateCyclist,
  user,
  fields: { fieldOverrides }
}) => {
  const customerProfileForm = useCustomerProfileForm(
    customer.cyclistProfile,
    fieldOverrides
  )

  /*   customerProfileForm.form.birthDate.value =
    customerProfileForm.form.birthDate.value.substring(0, 10) */

  const { validateForm, form, values, changesMade } = customerProfileForm

  const saveFunction = () => {
    const customerObject = { cyclistProfile: customerProfileForm.values }
    updateCyclist(customer._id, customerObject, 'Customer Profile Updated')
  }

  return (
    <QuickFitTabContent
      title='Customer Profile'
      dataForm={dataForm}
      dataObject={customer.cyclistProfile}
      editButtonText='Edit Profile'
      modalTitle='Edit Profile'
      modalForm={customerProfileForm}
      modalTitleIcon={CYCLIST_ICON}
      confirmationButtonIcon={CYCLIST_ICON}
      saveFunction={saveFunction}
      disabled={
        !validateForm({
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          phone: form.phone,
          birthDate: form.birthDate
        }) || !changesMade(customer.cyclistProfile, values)
      }
      user={user}
    />
  )
}

const mapStateToProps = (state) => ({
  fields: state.fields
})

export default connect(mapStateToProps, { updateCyclist })(CustomerProfile)
