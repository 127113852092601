//Components
import SortableTable from '../../../tables/SortableTable'

import Colorize from './Colorize'
import NewColorize from './NewColorize'
import DownloadDataButton from '../../hook-components/DownloadRecordsButton'
import { stackReachDataCSV } from '../../../utils/csvFunctions'

import { stackAndReachFromSaddleHeight } from '../../../lib/fitkit-js-functions-v3.mjs'

const StackReachReport = ({ stackReachData, bikeType }) => {
  const getStackReachResults = (data) => {
    const results = stackAndReachFromSaddleHeight(
      data.customer.bodyMeasurements.inseam,
      data.customer.bodyMeasurements.torso,
      data.customer.bodyMeasurements.arm,
      data.customerBikes[0]?.saddleHeight
    )
    return results
  }
  const config = [
    {
      label: 'Customer',
      render: (data) => (
        <>
          {data.customer.cyclistProfile.firstName}{' '}
          {data.customer.cyclistProfile.lastName}
        </>
      ),
      sortValue: (data) => data.customer.cyclistProfile.lastName
    },
    {
      label: 'Bike',
      render: (data) => (
        <>
          {data.customerBikes[0]?.make} {data.customerBikes[0]?.model}
        </>
      )
    },
    {
      label: 'Height',
      render: (data) => data.customer.bodyMeasurements.height,
      sortValue: (data) => data.customer.bodyMeasurements.height
    },

    {
      label: 'Inseam',
      render: (data) => data.customer.bodyMeasurements.inseam,
      sortValue: (data) => data.customer.bodyMeasurements.inseam
    },
    {
      label: 'Torso',
      render: (data) => data.customer.bodyMeasurements.torso,
      sortValue: (data) => data.customer.bodyMeasurements.torso
    },
    {
      label: 'Arm',
      render: (data) => data.customer.bodyMeasurements.arm,
      sortValue: (data) => data.customer.bodyMeasurements.arm
    },

    {
      label: 'ETT',
      render: (data) => data.customerBikes[0]?.effectiveTopTube,

      sortValue: (data) => data.sizing.effectiveTopTube
    },

    {
      label: 'FS',
      render: (data) => data.customerBikes[0]?.frameSize,
      sortValue: (data) => data.customerBikes[0]?.frameSize
    },

    {
      label: 'QS FS',
      render: (data) => (
        <Colorize
          value={data.sizing.frameSize - data.customerBikes[0]?.frameSize}
          range={0}
        >
          {data.sizing.frameSize}
        </Colorize>
      ),
      sortValue: (data) => data.sizing.frameSize
    },

    {
      label: 'Saddle Height',
      render: (data) => data.customerBikes[0]?.saddleHeight
    },
    {
      label: 'Stem',
      render: (data) => data.customerBikes[0]?.stemLength
    },

    {
      label: 'Actual Stack',
      render: (data) => {
        const results = getStackReachResults(data)
        return (
          <NewColorize
            value={data.customerBikes[0]?.frameStack}
            min={results.minStack}
            max={results.enduranceMaxStack}
          >
            {data.customerBikes[0]?.frameStack}
          </NewColorize>
        )
      },
      sortValue: (data) => data.customerBikes[0]?.frameStack
    },

    {
      label: 'Actual Reach',
      render: (data) => {
        const results = getStackReachResults(data)
        return (
          <NewColorize
            value={data.customerBikes[0]?.frameReach}
            min={results.enduranceMinReach}
            max={results.maxReach}
          >
            {data.customerBikes[0]?.frameReach}
          </NewColorize>
        )
      },
      sortValue: (data) => data.customerBikes[0]?.frameReach
    },

    {
      label: 'Race Stack',
      render: (data) => {
        const results = getStackReachResults(data)
        return (
          <>
            {results.targetStack} ({results.minStack}-{results.maxStack})
          </>
        )
      },
      sortValue: (data) => {
        const results = getStackReachResults(data)
        return results.targetStack
      }
    },
    {
      label: 'Race Reach',
      render: (data) => {
        const results = getStackReachResults(data)
        return (
          <>
            {results.targetReach} ({results.minReach}-{results.maxReach})
          </>
        )
      },
      sortValue: (data) => {
        const results = getStackReachResults(data)
        return results.targetReach
      }
    },
    {
      label: 'Endurance Stack',
      render: (data) => {
        const results = getStackReachResults(data)
        return (
          <>
            {results.enduranceTargetStack} ({results.enduranceMinStack}-
            {results.enduranceMaxStack})
          </>
        )
      },
      sortValue: (data) => {
        const results = getStackReachResults(data)
        return results.enduranceTargetStack
      }
    },
    {
      label: 'Endurance Reach',
      render: (data) => {
        const results = getStackReachResults(data)

        return (
          <>
            {results.enduranceTargetReach} ({results.enduranceMinReach}-
            {results.enduranceMaxReach})
          </>
        )
      },
      sortValue: (data) => {
        const results = getStackReachResults(data)
        return results.enduranceTargetReach
      }
    }
  ]

  const keyFn = (data) => {
    return data.customer._id
  }

  // const { start, stop } = nextPrevious

  /*  const npConfig = [
    {
      label: 'first',
      render: () =>
        nextPrevious.showPrevious && (
          <IconButton
            className='text-primary me-3'
            icon='fa-regular fa-backward-fast'
            onClick={nextPrevious.handleFirst}
          />
        )
    },
    {
      label: 'previous',
      render: () =>
        nextPrevious.showPrevious && (
          <IconButton
            className='text-primary me-3'
            icon='fa-regular fa-backward-step'
            onClick={nextPrevious.handlePrevious}
          />
        )
    },
    {
      label: 'next',
      render: () =>
        nextPrevious.showNext && (
          <IconButton
            className='text-primary me-3'
            icon='fa-regular fa-forward-step'
            onClick={nextPrevious.handleNext}
          />
        )
    },
    {
      label: 'last',
      render: () =>
        nextPrevious.showNext && (
          <IconButton
            className='text-primary me-3'
            icon='fa-regular fa-forward-fast'
            onClick={nextPrevious.handleLast}
          />
        )
    }
  ] */

  if (!stackReachData) return null

  const stackInseamRatios = stackReachData.map((data) => {
    return (
      data.customerBikes[0]?.frameStack /
      (data.customer.bodyMeasurements.inseam * 10)
    )
  })
  const reachUpperRatios = stackReachData.map((data) => {
    return (
      data.customerBikes[0]?.frameReach /
      (data.customer.bodyMeasurements.torso * 10 +
        data.customer.bodyMeasurements.arm * 10)
    )
  })
  const sizeStackRatios = stackReachData.map((data) => {
    return (
      (data.customerBikes[0]?.frameSize * 10) /
      data.customerBikes[0]?.frameStack
    )
  })

  const average = (array) => array.reduce((a, b) => a + b) / array.length

  return (
    <>
      <p>
        {stackReachData.length} matching results{' '}
        <DownloadDataButton
          tip='Download Stack Reach Data as CSV File'
          filename='stack-reach-data'
          csv={stackReachDataCSV(
            stackReachData,

            'Stack Reach Data Generated By'
          )}
        />
      </p>
      <SortableTable
        striped
        size='sm'
        //nextPrevious={nextPrevious}
        data={stackReachData}
        config={config}
        keyFn={keyFn}
      />
      <p>
        Low Stack/Inseam Ratio: {Math.min(...stackInseamRatios).toFixed(5)}
        <br />
        High Stack/Inseam Ratio: {Math.max(...stackInseamRatios).toFixed(5)}
        <br />
        Average Stack/Inseam Ratio: {average(stackInseamRatios).toFixed(5)}
      </p>
      <p>
        Low Reach/Upper Ratio: {Math.min(...reachUpperRatios).toFixed(5)}
        <br />
        High Reach/Upper Ratio: {Math.max(...reachUpperRatios).toFixed(5)}
        <br />
        Average Reach/Upper Ratio: {average(reachUpperRatios).toFixed(5)}
      </p>
      <p>
        Low Size/Stack Ratio: {Math.min(...sizeStackRatios).toFixed(5)}
        <br />
        High Size/Stack Ratio: {Math.max(...sizeStackRatios).toFixed(5)}
        <br />
        Average Size/Stack Ratio: {average(sizeStackRatios).toFixed(5)}
      </p>
      {/* <NextPrevious
        {...nextPrevious}
        variant='outline-primary'
        size='sm'
        divClass='d-flex justify-content-end'
        first
        last
        //bootstrap
        //config={npConfig}
      /> */}
    </>
  )
}
export default StackReachReport
