import { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, Navigate } from 'react-router-dom'
import { Card, Button, Row, OverlayTrigger, Popover } from 'react-bootstrap'

import { confirmUserId } from '../../redux/actions/users'
import { loadShopPublic } from '../../redux/actions/shops'
import { createPreFitInterview } from '../../redux/actions/prefitInterviews'

import InvalidLink from './InvalidLink'
import PreFitHeader from './PreFitHeader'

import useContactDetailsForm from '../hooks/form-hooks/interviews/useContactDetailsForm'
import usePhysicalProfileForm from '../hooks/form-hooks/interviews/usePysicalProfileForm'
import useBikeFitDetailsForm from '../hooks/form-hooks/interviews/useBikeFitDetailsForm'
import useRidingProfileForm from '../hooks/form-hooks/interviews/useRidingProfileForm'
import useCurrentBikeDetailsForm from '../hooks/form-hooks/interviews/useCurrentBikeDetailsForm'
import useNewBikeDetailsForm from '../hooks/form-hooks/interviews/useNewBikeDetailsForm'
import useMediaPermissionForm from '../hooks/form-hooks/interviews/useMediaPermissionForm'

import PrefitCard from './PrefitCard'

import GenerateForm from '../forms/form-components/GenerateForm'

import { getMetricHeight, getMetricWeight } from '../../utils/heightWeight'

const PrefitInterview = ({
  users: { preFitUser, userIdConfirmed },
  shops: { shop },
  prefitinterviews: { redirectToConfirmationPage },
  confirmUserId,
  loadShopPublic,
  createPreFitInterview
}) => {
  const params = useParams()

  const physicalProfileForm = usePhysicalProfileForm({
    birthDate: '',
    gender: 'Male',
    height: 0,
    heightUnits: 'in.',
    weight: 0,
    weightUnits: 'lbs.'
  })

  useEffect(() => {
    const getUserData = async () => {
      await confirmUserId(params.userId)
    }

    getUserData().catch(console.error)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const getShopData = async () => {
      if (preFitUser.shop_owner) {
        await loadShopPublic(params.userId)
      }
    }
    getShopData().catch(console.error)
  }, [loadShopPublic, params.userId, preFitUser])

  const contactDetailsForm = useContactDetailsForm({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    zipCode: '',
    referralSource: 'Returning Client'
  })

  const bikeFitDetailsForm = useBikeFitDetailsForm({ priorBikeFit: 'No' })

  const ridingProfileForm = useRidingProfileForm({ ridingStyle: 'Relaxed' })

  const currentBikeDetailsForm = useCurrentBikeDetailsForm({
    bikeType: 'Road Bike'
  })

  const newBikeDetailsForm = useNewBikeDetailsForm({
    bikeType: 'Road Bike',
    bikeNewStyle: 'No'
  })

  const mediaPermissionForm = useMediaPermissionForm({ mediaConsent: 'No' })

  const { firstName, lastName, email, phone } = contactDetailsForm.form

  useEffect(() => {
    if (preFitUser?.preferences) {
      const { height_units, weight_units } = preFitUser.preferences

      if (height_units !== 'Imperial') {
        physicalProfileForm.form.heightUnits.onChange({
          target: { value: 'cm.' }
        })
        // physicalProfileForm.form.heightUnits.value = 'cm.'
        // physicalProfileForm.values.heightUnits = 'cm.'
      }

      if (weight_units !== 'Imperial') {
        physicalProfileForm.form.weightUnits.onChange({
          target: { value: 'kgs.' }
        })
        // physicalProfileForm.form.weightUnits.value = 'kgs.'
        // physicalProfileForm.values.weightUnits = 'kgs.'
      }
    }
    //eslint-disable-next-line
  }, [preFitUser.preferences])

  const {
    factorsBrandModel,
    factorsComponents,
    factorsValue,
    factorsAvailability,
    factorsReviews,
    factorsReputation,
    factorsCost,
    factorsFit,
    factorsAppearance,
    factorsOther,
    factorsOtherText,
    channelsNew,
    channelsUsed,
    channelsInStore,
    channelsOnline,
    channelsCustomFrameBuilder,
    channelsFullyBuilt,
    channelsFrameOnly,
    ...newBikeDetailsValues
  } = newBikeDetailsForm.values

  const { birthDate } = physicalProfileForm.form

  const {
    discomfortFeet,
    discomfortKnees,
    discomfortHips,
    discomfortButt,
    discomfortPerineumGenitalia,
    discomfortLowerBack,
    discomfortShouldersNeck,
    discomfortHandsWristsElbows,
    discomfortOther,
    discomfortOtherText,
    ...physicalProfileValues
  } = physicalProfileForm.values

  const {
    skillsCrashing,
    skillsCornering,
    skillsDescending,
    skillsClimbing,
    skillsFlats,
    skillsWeightDistribution,
    ...ridingProfileValues
  } = ridingProfileForm.values

  const validateForm = () => {
    return (
      firstName.isValid &&
      lastName.isValid &&
      email.isValid &&
      phone.isValid &&
      birthDate.isValid
    )
  }

  const newBikeChecked = bikeFitDetailsForm.values.objectiveMeasureAndAdvise

  const formatFactors = () => {
    let factors = ''
    if (factorsBrandModel) factors = factors + 'Brand/Model - '
    if (factorsComponents) factors = factors + 'Components - '
    if (factorsValue) factors = factors + 'Value - '
    if (factorsAvailability) factors = factors + 'Availability - '
    if (factorsReviews) factors = factors + 'Reviews - '
    if (factorsReputation) factors = factors + 'Repuation - '
    if (factorsCost) factors = factors + 'Cost - '
    if (factorsFit) factors = factors + 'Fit - '
    if (factorsAppearance) factors = factors + 'Appearance - '
    if (factorsOther) factors = factors + 'Other: ' + factorsOtherText + ' - '
    if (factors.length > 3) factors = factors.substring(0, factors.length - 3)
    return factors
  }

  const formatChannels = () => {
    let channels = ''
    if (channelsNew) channels = channels + 'New - '
    if (channelsUsed) channels = channels + 'Used - '
    if (channelsInStore) channels = channels + 'In Store - '
    if (channelsOnline) channels = channels + 'Online - '
    if (channelsCustomFrameBuilder)
      channels = channels + 'Custom Frame Builder - '
    if (channelsFullyBuilt) channels = channels + 'Fully Built - '
    if (channelsFrameOnly) channels = channels + 'Frame Only - '
    if (channels.length > 3)
      channels = channels.substring(0, channels.length - 3)
    return channels
  }

  const formatDiscomfortAreas = () => {
    let discomfortAreas = ''
    if (discomfortFeet) discomfortAreas = discomfortAreas + 'Feet - '
    if (discomfortKnees) discomfortAreas = discomfortAreas + 'Knees - '
    if (discomfortHips) discomfortAreas = discomfortAreas + 'Hips - '
    if (discomfortButt) discomfortAreas = discomfortAreas + 'Butt - '
    if (discomfortPerineumGenitalia)
      discomfortAreas = discomfortAreas + 'Perineum or Genitalia - '
    if (discomfortLowerBack) discomfortAreas = discomfortAreas + 'Lower Back - '
    if (discomfortShouldersNeck)
      discomfortAreas = discomfortAreas + 'Shoulders or Neck - '
    if (discomfortHandsWristsElbows)
      discomfortAreas = discomfortAreas + 'Hands, Wrists or Elbows - '
    if (discomfortOther)
      discomfortAreas =
        discomfortAreas + 'Other: ' + discomfortOtherText + ' - '
    if (discomfortAreas.length > 3)
      discomfortAreas = discomfortAreas.substring(0, discomfortAreas.length - 3)
    return discomfortAreas
  }

  const formatSkillsAndConfidence = () => {
    let skillsAndConfidence = ''
    if (skillsCrashing)
      skillsAndConfidence = skillsAndConfidence + 'Crashing - '
    if (skillsCornering)
      skillsAndConfidence = skillsAndConfidence + 'Cornering - '
    if (skillsDescending)
      skillsAndConfidence = skillsAndConfidence + 'Descending - '
    if (skillsClimbing)
      skillsAndConfidence = skillsAndConfidence + 'Climbing - '
    if (skillsFlats) skillsAndConfidence = skillsAndConfidence + 'Flats - '
    if (skillsWeightDistribution)
      skillsAndConfidence = skillsAndConfidence + 'Weight Distribution - '
    if (skillsAndConfidence.length > 3)
      skillsAndConfidence = skillsAndConfidence.substring(
        0,
        skillsAndConfidence.length - 3
      )
    return skillsAndConfidence
  }

  const onSubmit = (e) => {
    //e.preventDefault()

    const interview = {
      ...contactDetailsForm.values,
      ...physicalProfileValues,
      ...bikeFitDetailsForm.values,
      ...ridingProfileValues,
      ...currentBikeDetailsForm.values,
      ...newBikeDetailsValues,
      ...mediaPermissionForm.values,
      bikeDecisionFactors: formatFactors(),
      bikeChannels: formatChannels(),
      discomfortAreas: formatDiscomfortAreas(),
      skillsAndConfidence: formatSkillsAndConfidence()
    }
    newBikeChecked
      ? (interview.bikeType = newBikeDetailsForm.values.bikeType)
      : (interview.bikeType = currentBikeDetailsForm.values.bikeType)
    if (!shop._id) interview.createdForShop = null
    else interview.createdForShop = shop._id
    interview.priorBikeFit === 'No'
      ? (interview.priorBikeFit = false)
      : (interview.priorBikeFit = true)
    interview.bikeNewStyle === 'No'
      ? (interview.bikeNewStyle = false)
      : (interview.bikeNewStyle = true)

    if (interview.heightUnits === 'in.')
      interview.height = getMetricHeight(interview.height)
    if (interview.weightUnits === 'lbs.')
      interview.weight = getMetricWeight(interview.weight)

    createPreFitInterview(params.userId, interview)
  }

  let popTitle = ''
  if (validateForm()) popTitle = 'Please Click Submit to Complete Interview'
  else popTitle = 'Please complete these fields:'

  const popoverSubmit = (
    <Popover id='popover-submit'>
      {' '}
      <Popover.Header as='h3'>{popTitle}</Popover.Header>
      <Popover.Body>
        {!firstName.isValid && <p>First Name</p>}
        {!lastName.isValid && <p>Last Name</p>}
        {!email.isValid && <p>Email</p>}
        {!phone.isValid && <p>Phone</p>}
        {!birthDate.isValid && <p>Birth Date</p>}
      </Popover.Body>
    </Popover>
  )

  useEffect(() => {
    document.body.classList.add('preFitBodyPad')

    return () => {
      document.body.classList.remove('preFitBodyPad')
    }
  }, [])

  if (userIdConfirmed === null) return null

  if (userIdConfirmed && preFitUser.shop_owner && !shop.name) return null

  if (userIdConfirmed === false || preFitUser.stripe_subscription_id === null)
    return <InvalidLink />

  if (redirectToConfirmationPage)
    return <Navigate to='/pre-fit-interview/confirmation' state={shop} />

  return (
    <div style={{ paddingTop: 0 }}>
      <Card body>
        <PreFitHeader preFitUser={preFitUser} shop={shop} />
        <Row>
          <p>
            This Pre-Fit Interview Form is provided by your bike fitting
            professional. Providing this information will enhance your bike fit.
          </p>
        </Row>
        <PrefitCard title='Contact Details'>
          <GenerateForm {...contactDetailsForm} />
        </PrefitCard>
        <PrefitCard title='Physical Profile'>
          <GenerateForm
            {...physicalProfileForm}
            cols={['0', '2', '']}
            inLineGroup={[
              [
                physicalProfileForm.form.height,
                physicalProfileForm.form.heightUnits
              ],
              [
                physicalProfileForm.form.weight,
                physicalProfileForm.form.weightUnits
              ]
            ]}
          />
        </PrefitCard>
        <PrefitCard title='Bike Fit Details'>
          <GenerateForm {...bikeFitDetailsForm} cols={['0', '4', '']} />
        </PrefitCard>
        <PrefitCard title='Riding Profile'>
          <GenerateForm {...ridingProfileForm} />
        </PrefitCard>
        {newBikeChecked ? (
          <PrefitCard title='New Bike Details'>
            <GenerateForm {...newBikeDetailsForm} fksmode='stacked' />
          </PrefitCard>
        ) : (
          <PrefitCard title='Current Bike Details'>
            <GenerateForm {...currentBikeDetailsForm} fksmode='stacked' />
          </PrefitCard>
        )}
        <PrefitCard title='Media Permission'>
          <p>
            You may be filmed or photographed during your bike fitting session.
            We will not post video or images of you on our website and/or social
            media pages without your consent.
          </p>
          <GenerateForm {...mediaPermissionForm} />
        </PrefitCard>
        <OverlayTrigger
          trigger={['hover', 'focus']}
          placement='top'
          overlay={popoverSubmit}
        >
          <Card.Footer>
            Thanks for completing the pre-fit interview. Please click:{' '}
            <Button
              className='ms-3'
              variant='dark'
              disabled={!validateForm()}
              type='button'
              onClick={onSubmit}
            >
              <i className='far fa-check-circle' /> Submit
            </Button>{' '}
          </Card.Footer>
        </OverlayTrigger>
      </Card>
    </div>
  )
}

const mapStateToProps = (state) => ({
  shops: state.shops,
  prefitinterviews: state.prefitinterviews,
  users: state.users
})

export default connect(mapStateToProps, {
  confirmUserId,
  loadShopPublic,
  createPreFitInterview
})(PrefitInterview)
