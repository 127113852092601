import React, { useEffect, useState } from 'react'
import { Card, Form } from 'react-bootstrap'
import { connect } from 'react-redux'

import { updateShop, deleteShop } from '../../../redux/actions/shops'
import { updateUser } from '../../../redux/actions/users'

import UploadLogo from './UploadLogo'
import ShopUpgradeNotice from './ShopUpgradeNotice'
import ActivateShop from './ActivateShop'

import useEditShopForm from '../../hooks/form-hooks/shop/useEditShopForm'

import AccountSettingsTabContent from '../AccountSettingsTabContent'

import { editShopForm as dataForm } from '../../forms/form-objects/shopFormObjects'

import DeleteRecordButton from '../../hook-components/DeleteRecordButton'

import { SHOP_ICON } from '../../../utils/fontAwesomeDefaults'
import { validateForm, changesMade } from '../../../utils/formValidation'

const EditShop = ({
  users: { user },
  shops: { shop },
  activeStatus,

  updateShop,
  updateUser,
  deleteShop
}) => {
  const [imageSource, setImageSource] = useState('/favicon.ico')

  const uploadFormData = new FormData()

  useEffect(() => {
    if (shop) {
      if (shop.logo)
        setImageSource(`/api/shops/photo/${shop._id}?${Date.now()}`)
    }
  }, [shop])

  const handleNewLogo = (logo, file) => {
    uploadFormData.set('name', shop.name)
    uploadFormData.set('logo', file)
    updateShop(shop._id, uploadFormData)
    setImageSource(logo)
  }

  const { shop_owner } = user

  const editShopForm = useEditShopForm(shop)

  if (!activeStatus) return <ShopUpgradeNotice />

  if (!shop_owner) return <ActivateShop user={user} />

  const saveFunction = () => {
    const { name, address, address2, phone, website } = editShopForm.values
    updateShop(shop._id, {
      name,
      address,
      address2,
      phone,
      website
    })
  }

  const deleteFunction = () => {
    updateUser({
      name: user.name,
      email: user.email,
      shop_owner: false
    })
    deleteShop(shop._id)
  }

  return (
    <>
      <AccountSettingsTabContent
        title='Shop or Studio Branding (for PDF Reports and Pre-fit Interviews)'
        dataForm={dataForm}
        dataObject={shop}
        editButtonText='Edit Shop'
        modalTitle='Edit Shop'
        modalForm={editShopForm}
        modalTitleIcon={SHOP_ICON}
        confirmationButtonIcon={SHOP_ICON}
        saveFunction={saveFunction}
        disabled={
          !validateForm({ name: editShopForm.form.name }) ||
          !changesMade(
            {
              name: shop.name,
              address: shop.address,
              address2: shop.address2,
              phone: shop.phone,
              website: shop.website
            },
            editShopForm.values
          )
        }
        user={user}
        bodyPart={
          <Form.Group>
            <Card className='mb-2'>
              <Card.Body>
                <UploadLogo
                  imageSource={imageSource}
                  setImageSource={setImageSource}
                  handleNewLogo={handleNewLogo}
                />
              </Card.Body>
            </Card>
          </Form.Group>
        }
        deleteRecordButton={
          <span className='float-end' style={{ cursor: 'pointer' }}>
            <DeleteRecordButton
              title='Permanently Delete Shop?'
              confirmationPrompt='To confirm deletion'
              confirmationText='delete shop'
              confirmationButtonText='Delete Shop'
              tip='Delete Shop.'
              deleteFunction={deleteFunction}
              recordId={shop._id}
            >
              Delete shop and revert to the default Fit Kit Studio Branding for
              PDF reports and Pre-Fit Interviews?
            </DeleteRecordButton>
          </span>
        }
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  shops: state.shops,
  users: state.users
})

export default connect(mapStateToProps, {
  updateShop,
  updateUser,
  deleteShop
})(EditShop)
