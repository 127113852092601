import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import { loadCustomerBikes, deleteBike } from '../../../redux/actions/bikes'

import BikeFitHeader from './BikeFitHeader'

import BikeTabs from './BikeTabs'

const BikeFit = ({
  cyclists,
  loadCustomerBikes,
  deleteBike,
  bikes: { customerBikes },
  shops: { shop },
  fields: { fieldOverrides },
  user
}) => {
  const [bike, setBike] = useState({})
  const [customer, setCustomer] = useState({})
  const bikeId = useParams().bikeId
  const customerId = useParams().customerId
  const navigate = useNavigate()

  useEffect(() => {
    const customer = cyclists.find((customer) => customer._id === customerId)
    if (customer) setCustomer(customer)
  }, [cyclists, customerId])

  useEffect(() => {
    loadCustomerBikes(customerId)
  }, [customerId, loadCustomerBikes])

  useEffect(() => {
    const bike = customerBikes.find((bike) => bike._id === bikeId)
    if (bike) {
      setBike(bike)
      const customer = cyclists.find(
        (customer) => customer._id === bike.ownedBy
      )
      if (customer) setCustomer(customer)
    }
  }, [cyclists, customerBikes, bikeId])

  if (!customer._id || !bike._id) return null

  const deleteFunction = (bikeId) => {
    deleteBike(bikeId)
    navigate(`/quickfit/customer/${customerId}`)
  }

  return (
    <>
      <BikeFitHeader
        {...customer.cyclistProfile}
        deleteFunction={deleteFunction}
        bike={bike}
        user={user}
        customer={customer}
        shop={shop}
      />
      <BikeTabs
        bike={bike}
        customer={customer}
        user={user}
        shop={shop}
        fieldOverrides={fieldOverrides}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  bikes: state.bikes,
  fields: state.fields,
  shops: state.shops
})

export default connect(mapStateToProps, { loadCustomerBikes, deleteBike })(
  BikeFit
)
