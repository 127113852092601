import React from 'react'
import { Table } from 'react-bootstrap'

import CategoryRow from './CategoryRow'
import ResourceRow from './ResourceRow'
import DashboardCard from '../../layout/cards-containers/DashboardCard'
import AddNewResourceCategory from './AddNewCategory'
import Spinner from '../../layout/Spinner'

import useResources from '../../hooks/data-fetch-hooks/useResources'

const EditResources = (props) => {
  const resourcesObject = useResources()
  const { resourcesLoaded, resources, setResourcesLoaded } = resourcesObject

  if (!resourcesLoaded) return <Spinner message='Loading resources . . .' />
  return (
    <>
      <DashboardCard
        title='Manage Resources'
        footer={
          <>
            {' '}
            <AddNewResourceCategory
              resources={resources}
              setResourcesLoaded={setResourcesLoaded}
            />
          </>
        }
      >
        {resources.map((resource) => {
          return (
            <div key={resource._id}>
              <CategoryRow
                resource={resource}
                setResourcesLoaded={setResourcesLoaded}
              />
              <Table size='sm' style={{ fontSize: '.8rem' }}>
                <thead>
                  <tr>
                    <th style={{ width: '20%' }}>Name</th>
                    <th style={{ width: '10%' }}>Type</th>
                    <th style={{ width: '30%' }}>Link</th>
                    <th style={{ width: '30%' }}>Description</th>
                    <th style={{ width: '5%', textAlign: 'center' }}>Edit</th>
                    <th style={{ width: '5%', textAlign: 'center' }}>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {resource.resourceList.map((item, index) => (
                    <ResourceRow
                      key={index}
                      resource={resource}
                      item={item}
                      itemIndex={index}
                      setResourcesLoaded={setResourcesLoaded}
                    />
                  ))}
                </tbody>
              </Table>
              <hr className='fksHR' />
            </div>
          )
        })}
      </DashboardCard>
    </>
  )
}

export default EditResources
