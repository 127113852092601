import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import store from '../../../redux/store'
import { setAlert } from '../../../redux/actions/alert'

const UploadLogo = ({ disabled, handleNewLogo, imageSource }) => {
  const onChange = (e) => {
    const value = e.target.files[0]

    let reader = new FileReader()
    reader.onload = (e) => {
      let image = e.target.result

      const contentArray = reader.result.split(',')

      if (
        contentArray[0] === 'data:image/png;base64' ||
        contentArray[0] === 'data:image/jpg;base64' ||
        contentArray[0] === 'data:image/jpeg;base64'
      ) {
        handleNewLogo(image, value)
      } else {
        store.dispatch(setAlert('Invalid Image File', 'danger'))
      }
    }
    if (e.target.files[0]) reader.readAsDataURL(e.target.files[0])
  }

  return (
    <>
      <Row>
        <Col>
          {imageSource ? (
            <img
              style={{ maxHeight: '75px' }}
              src={imageSource}
              alt='No Logo on File'
            ></img>
          ) : (
            '   No Logo on File'
          )}
        </Col>
        <Col className='d-flex align-items-center'>
          <label htmlFor={'icon-button-file'}>
            <Button variant='dark' id='load' as='span'>
              <i className='fas fa-images' /> Change Logo
            </Button>
          </label>
          <input
            className='d-none '
            disabled={disabled}
            accept='image/*'
            onChange={onChange}
            id='icon-button-file'
            type='file'
          />
        </Col>
      </Row>
    </>
  )
}

export default UploadLogo
