import {
  INFO_ICON,
  CYCLIST_ICON,
  BIKE_ICON,
  POLICY_ICON,
  WARNING_ICON,
  CALENDAR_ICON
} from './fontAwesomeDefaults'

export const getFitHistory = (bike) => {
  let fitHistory = []
  if (bike.fitHistory.length > 0) fitHistory = bike.fitHistory.slice(0)
  if (
    new Date(bike.updated).toLocaleDateString() ===
    new Date().toLocaleDateString()
  )
    return fitHistory
  fitHistory.push({
    // date: new Date(bike.updated).toISOString(),
    date: bike.updated,
    saddleHeight: bike.saddleHeight,
    saddleHeightBB: bike.saddleHeightBB,
    saddleSetBack: bike.saddleSetBack,
    saddleAngle: bike.saddleAngle,
    saddleNoseToBar: bike.saddleNoseToBar,
    saddleNoseToHood: bike.saddleNoseToHood,
    saddleToBarDrop: bike.saddleToBarDrop,
    handlebarReachHX: bike.handlebarReachHX,
    handlebarStackHY: bike.handlebarStackHY,
    handlebarAngle: bike.handlebarAngle,
    hoodAngle: bike.hoodAngle,
    seatPostOffset: bike.seatPostOffset,
    saddleMake: bike.saddleMake,
    saddleModel: bike.saddleModel,
    saddleWidth: bike.saddleWidth,
    crankLength: bike.crankLength,
    pedalType: bike.pedalType,
    pedalMakeModel: bike.pedalMakeModel,
    stemLength: bike.stemLength,
    stemType: bike.stemType,
    stemAngle: bike.stemAngle,
    spacersBelow: bike.spacersBelow,
    spacersAbove: bike.spacersAbove,
    handlebarWidth: bike.handlebarWidth,
    handlebarReach: bike.handlebarReach,
    ttBasebarWidth: bike.ttBasebarWidth,
    ttAerobarType: bike.ttAerobarType,
    ttAerobarMakeModel: bike.ttAerobarMakeModel,
    ttExtensionsShape: bike.ttExtensionsShape,
    ttRisers: bike.ttRisers,
    ttPadsMakeModel: bike.ttPadsMakeModel,
    ttSaddleToPadCenterDrop: bike.ttSaddleToPadCenterDrop,
    ttSaddleNoseToPadRear: bike.ttSaddleNoseToPadRear,
    ttSaddleNoseToEndOfExtensions: bike.ttSaddleNoseToEndOfExtensions,
    ttExtensionWidthAtClamps: bike.ttExtensionWidthAtClamps,
    ttExtensionWidthAtEnd: bike.ttExtensionWidthAtEnd,
    ttExtensionAngle: bike.ttExtensionAngle,
    ttPadWidth: bike.ttPadWidth,
    ttPadXReachRearOfPad: bike.ttPadXReachRearOfPad,
    ttPadXReachCenterOfPad: bike.ttPadXReachCenterOfPad,
    ttPadYStackRearOfPad: bike.ttPadYStackRearOfPad,
    ttBasebarReachX: bike.ttBasebarReachX,
    ttBasebarStackY: bike.ttBasebarStackY,
    ttBasebarAngle: bike.ttBasebarAngle,
    mtbSaddleNoseToGripEnd: bike.mtbSaddleNoseToGripEnd,
    mtbSaddleToGripCenterDropRise: bike.mtbSaddleToGripCenterDropRise
  })
  return fitHistory
}

export const highlightChanges = (fitHistory, bike, key, index) => {
  if (index === 0) {
    if (fitHistory.length === 0) return null
    if (bike.fitHistory[0].date === fitHistory[0].date) return null
    const priorFitIndex =
      bike.fitHistory.findIndex((fit) => fit.date === fitHistory[0].date) - 1
    if (fitHistory[index][key] === bike.fitHistory[priorFitIndex][key])
      return null

    return 'highlightChanges'
  }
  if (index === fitHistory.length) {
    const i = index - 1
    if (fitHistory[i][key] === bike[key]) return null
    return 'highlightChanges'
  }
  if (fitHistory[index][key] === fitHistory[index - 1][key]) return null
  return 'highlightChanges'
}

export const getIcon = (iconConstant) => {
  return <i className={iconConstant} />
}
export const getMessageIcon = (messageIcon) => {
  let icon
  switch (messageIcon) {
    case 'INFO':
      icon = INFO_ICON + ' text-fitkit'
      break
    case 'WARNING':
      icon = WARNING_ICON + ' text-fitkit'
      break
    case 'CYCLIST':
      icon = CYCLIST_ICON + ' text-fitkit'
      break
    case 'BIKE':
      icon = BIKE_ICON + ' text-fitkit'
      break
    case 'CALENDAR':
      icon = CALENDAR_ICON + ' text-fitkit'
      break
    case 'POLICY':
      icon = POLICY_ICON + ' text-fitkit'
      break
    default:
      icon = INFO_ICON + ' text-fitkit'
  }
  return <i className={icon} />
}
