import { Container } from 'react-bootstrap'
import { Routes, Route } from 'react-router-dom'
import { useMatch } from 'react-router-dom'
import QuickFitNav from '../quickfit/QuickFitNav'
import ToolsNav from '../tools/ToolsNav'
import AccountNav from '../account/AccountNav'
import AdminNav from '../admin/AdminNav'

const SubNavRoutes = () => {
  const match = useMatch('/admin/data')
  if (match)
    return (
      <Routes>
        <Route path='/admin/data' element={<AdminNav />} />
      </Routes>
    )
  return (
    <Container>
      <Routes>
        <Route exact path='/pre-fit-interview/:userId' element={null} />
        <Route path='/quickfit/*' element={<QuickFitNav />} />
        <Route path='/tools-resources/*' element={<ToolsNav />} />
        <Route exact path='/account/*' element={<AccountNav />} />
        <Route path='/admin/*' element={<AdminNav />} />
      </Routes>
    </Container>
  )
}

export default SubNavRoutes
