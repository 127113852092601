import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const LandingLInks = () => {
  return (
    <div className='mt-5 landing-links'>
      <Container>
        <Row className='landing-links'>
          <Col xs={6} sm={3}>
            <h3>PRODUCT</h3>
            {/* <Link className='landing-links-link' to='/product/free-tools'> */}
            <a
              className='landing-links-link'
              href='https://fitkitstudio.com/product/free-tools'
            >
              <p>Free Tools</p>
            </a>
            {/*  </Link> */}
            {/*  <Link className='landing-links-link' to='/product/quick-fit'> */}
            <a
              className='landing-links-link'
              href='https://fitkitstudio.com/product/quick-fit'
            >
              <p>Quick Fit Pricing</p>
            </a>
            {/* </Link> */}
            {/* <Link className='landing-links-link' to='/product/pricing'>
              <p>Pricing</p>
            </Link> */}

            <p>
              <a
                target='_blank'
                rel='noreferrer'
                className='landing-links-link'
                href='https://www.youtube.com/watch?v=1XsgC1_dXUQ'
              >
                Video Demos
              </a>
            </p>
          </Col>
          <Col xs={6} sm={3}>
            <h3>COMPANY</h3>
            <p>
              <a
                target='_blank'
                rel='noreferrer'
                className='landing-links-link'
                href='https://fitkitsystems.com'
              >
                Fit Kit Systems
              </a>
            </p>
            <p>
              <a
                target='_blank'
                rel='noreferrer'
                className='landing-links-link'
                href='https://fitkitsystems.com/blog'
              >
                Blog
              </a>
            </p>
            <p>
              {/* <Link className='landing-links-link' to='/contact'> */}
              <a
                className='landing-links-link'
                href='https://fitkitstudio.com/contact'
              >
                {' '}
                Contact
              </a>
              {/* </Link> */}
            </p>
          </Col>
          <Col xs={6} sm={3}>
            <h3>RESOURCES</h3>

            <p>
              {' '}
              <a
                target='_blank'
                rel='noreferrer'
                className='landing-links-link'
                href='https://fitkitsystems.com/bike-sizing-and-fitting'
              >
                Bike Sizing and Fitting
              </a>
            </p>

            <p>
              {' '}
              <a
                target='_blank'
                rel='noreferrer'
                className='landing-links-link'
                href='https://fitkitsystems.com/bike-sizing-and-fitting/the-fit-kit-system/'
              >
                The Fit Kit System
              </a>
            </p>

            <p>
              {' '}
              <a
                target='_blank'
                rel='noreferrer'
                className='landing-links-link'
                href='https://fitkitsystems.com/education/education'
              >
                Education
              </a>
            </p>
          </Col>
          <Col xs={6} sm={3}>
            <h3>SOCIAL NETWORKS</h3>

            <p>
              <a
                target='_blank'
                rel='noreferrer'
                className='landing-links-link'
                href='https://facebook.com/fitkitsystems'
              >
                Facebook
              </a>
            </p>
          </Col>
        </Row>{' '}
      </Container>
    </div>
  )
}

export default LandingLInks
