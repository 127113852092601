import {
  DELETE_ADMIN_MESSAGE,
  ADMIN_MESSAGES_LOADED,
  CLEAR_ADMIN_MESSAGES,
  UPDATE_ADMIN_MESSAGE,
  CREATE_ADMIN_MESSAGE
} from '../actions/types'

const initialState = {
  adminMessages: []
}

export default function adminmessages(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case ADMIN_MESSAGES_LOADED:
      return {
        ...state,
        adminMessages: payload
      }
    case CREATE_ADMIN_MESSAGE:
      state.adminMessages.push(payload)
      return {
        ...state
      }
    case UPDATE_ADMIN_MESSAGE:
      const newArray = state.adminMessages.map((message) => {
        if (message._id === payload._id) return payload
        return message
      })

      return {
        ...state,
        adminMessages: newArray
      }
    case DELETE_ADMIN_MESSAGE:
      return {
        ...state,
        adminMessages: state.adminMessages.filter(
          (message) => message._id !== payload
        )
      }

    case CLEAR_ADMIN_MESSAGES:
      return {
        ...initialState
      }

    default:
      return state
  }
}
