import { useEffect } from 'react'
//import { Link } from 'react-router-dom'
import { Container, Button } from 'react-bootstrap'

import HomeBanner from './HomeBanner'
import Login from '../users/Login'

import LandingLinks from './LandingLinks'

import SEO from '../seo/SEO'

const Landing = () => {
  useEffect(() => {
    document.body.classList.add('landingBodyPad')

    return () => {
      document.body.classList.remove('landingBodyPad')
    }
  }, [])
  return (
    <>
      <SEO
        title='Fit Kit Studio'
        description={`Fit Kit Studio: software for bike fitting professionals. Fit Kit Studio, brought to you by Fit Kit Systems, is a cloud-based web application that enables bike fitting professionals to document bike fit information for their customers. Fit Kit Studio may be used with any bike fitting system or method. Fit Kit Studio's Quick Fit subscription service provides a full suite of features for bike fitters, including cloud storage of pre-fit customer interview forms, customer profiles, bike equipment, fit position and fit history data, customizable summary PDF reports for printing or emailing to customers, automated customer intake processes, and customized bike shop or fitting studio branding.`}
        type='website'
        name='Fit Kit Systems'
      />
      <div style={{ minHeight: '1120px' }}>
        <Login />
        <HomeBanner />
        <Container className='mt-3'>
          <p>
            Fit Kit Studio, brought to you by{' '}
            <a href='https://fitkitsystems.com'>Fit Kit Systems</a>, is a
            cloud-based web application that enables bike fitting professionals
            to document bike fit information for their customers. Fit Kit Studio
            may be used with any bike fitting system or method.
          </p>
          <p>
            Fit Kit Studio's Quick Fit subscription service provides a full
            suite of features for bike fitters, including cloud storage of
            pre-fit customer interview forms, customer profiles, bike equipment,
            fit position and fit history data, customizable summary PDF reports
            for printing or emailing to customers, automated customer intake
            processes, and customized bike shop or fitting studio branding.
          </p>{' '}
          <p>
            Register for a free 60-day trial of Quick Fit{' '}
            <Button
              style={{ backgroundColor: '#e9722e', borderColor: '#e9722e' }}
              size='sm'
              /* as={Link}
            to={'/register'} */
              href='https://fitkitstudio.com/register'
            >
              Register
            </Button>
          </p>
          <p>
            Not ready to start the free trial? Fit Kit Studio offers a variety
            of free tools and resources to all registered users, including the
            basic Fit Kit sizing calculator "Quick Size" used with the Fit Kit
            System, XY Reach and Stack calculators, and a Stem Delta Calculator.
            Register today to explore the free tools and start your free trial
            of Quick Fit whenever you are ready to explore the additional
            features.
          </p>{' '}
        </Container>
        <LandingLinks />
      </div>
    </>
  )
}

export default Landing
