import React from 'react'
import { Card } from 'react-bootstrap'

import FieldData from '../layout/cards-containers/FieldData'
import TableData from '../layout/cards-containers/TableData'

import EditFormButton from '../hook-components/EditFormButton'

const AdminSettingsTabContent = (props) => {
  const { title, noFooter, mode } = props
  return (
    <>
      <Card className='mb-3'>
        <Card.Header className='bg-white'>
          <Card.Title>{title}</Card.Title>
        </Card.Header>
        <Card.Body style={{ fontSize: '.8rem' }}>
          {mode === 'fields' ? (
            <FieldData {...props} />
          ) : (
            <TableData {...props} />
          )}
        </Card.Body>
        {!noFooter && (
          <Card.Footer className='bg-white'>
            <EditFormButton {...props} />
          </Card.Footer>
        )}
      </Card>
    </>
  )
}

export default AdminSettingsTabContent
