import React from 'react'
import { Table } from 'react-bootstrap'

import MessageRow from './MessageRow'
import DashboardCard from '../../layout/cards-containers/DashboardCard'
import AddNewMessage from './AddNewMessage'

const EditMessages = (props) => {
  const { adminMessages } = props
  return (
    <DashboardCard
      title='Manage Messages'
      footer={
        <>
          <AddNewMessage />
        </>
      }
    >
      <Table size='sm' style={{ fontSize: '.8rem' }}>
        <thead>
          <tr>
            <th style={{ width: '10%' }}>Icon</th>
            <th style={{ width: '20%' }}>Title</th>
            <th style={{ width: '30%' }}>Content</th>
            <th style={{ width: '20%' }}>Start/Stop</th>
            <th style={{ width: '10%' }}>Edit</th>
            <th style={{ width: '10%' }}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {adminMessages.map((message) => {
            return <MessageRow key={message._id} message={message} />
          })}
        </tbody>
      </Table>
    </DashboardCard>
  )
}

export default EditMessages
