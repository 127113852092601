import { Navigate, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Card } from 'react-bootstrap'

import PreFitHeader from './PreFitHeader'

const PreFitConfirmation = ({
  shops: { shop },
  users: { preFitUser, userIdConfirmed, isAuthenticated },
  prefitinterviews: { interview }
}) => {
  if (!userIdConfirmed) return <Navigate to='/not-found' />

  return (
    <>
      <Card body>
        <PreFitHeader
          userIdConfirmed={userIdConfirmed}
          preFitUser={preFitUser}
          shop={shop}
        />
        <h3>Submission Confirmation</h3>
        Thank you for completing your pre-fit interview. Your submission will be
        provided to your bike fitting professional.
      </Card>

      {isAuthenticated ? (
        <p className='mt-5'>
          {' '}
          <Link to='/quickfit/dashboard'>Return to Fit Kit Studio</Link>
        </p>
      ) : null}
    </>
  )
}

const mapStateToProps = (state) => ({
  shops: state.shops,
  users: state.users,
  prefitinterviews: state.prefitinterviews
})

export default connect(mapStateToProps, {})(PreFitConfirmation)
