import React from 'react'
import { Table } from 'react-bootstrap'

const StatTableData = ({ dataForm, dataObject }) => {
  if (!dataForm || !dataObject) return null

  const { today, sevenDays, thirtyDays, ytd, lastYear, itd } = dataObject

  return (
    <Table striped borderless size='sm' style={{ fontSize: '.8rem' }}>
      {/* <colgroup>
              <col style={{ width: '30%' }} />
        <col style={{ width: '50%' }} />
        <col style={{ width: '20%' }} />
      </colgroup> */}
      <thead>
        <tr>
          <th></th>
          <th style={{ textAlign: 'right' }}>Today</th>
          <th style={{ textAlign: 'right' }}>Last 7 Days</th>
          <th style={{ textAlign: 'right' }}>Last 30 Days</th>
          <th style={{ textAlign: 'right' }}>Year To Date</th>
          <th style={{ textAlign: 'right' }}>Last Year</th>
          <th style={{ textAlign: 'right' }}>Total to Date</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(dataForm).map(([key, value]) => {
          return (
            <tr key={key}>
              <td>
                <>{value.label}</>
              </td>

              <td style={{ textAlign: 'right' }}>{today[key]}</td>
              <td style={{ textAlign: 'right' }}>{sevenDays[key]}</td>
              <td style={{ textAlign: 'right' }}>{thirtyDays[key]}</td>
              <td style={{ textAlign: 'right' }}>{ytd[key]}</td>
              <td style={{ textAlign: 'right' }}>{lastYear[key]}</td>
              <td style={{ textAlign: 'right' }}>{itd[key]}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default StatTableData
