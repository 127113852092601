import React, { useState } from 'react'

import QuickFitTabContent from '../../quickfit/QuickFitTabContent'

import useQuickSizeMeasurementsForm from '../../hooks/form-hooks/tools/useQuickSizeMeasurementsForm'

import { quickSizeMeasurementsForm as dataForm } from '../../forms/form-objects/customerFormObjects'

import { CYCLIST_ICON } from '../../../utils/fontAwesomeDefaults'
import SizingRecommendations from '../../quickfit/customers/SizingRecommendations'
import OutputsPlaceholder from '../OutputsPlaceholder'

import { yearMonthDay } from '../../../utils/dateFormats'
import RoadBikeImage from './RoadBikeImage'

const QuickSize = ({ user, fieldOverrides }) => {
  const initialState = {
    footLength: 21.5,
    inseam: 70,
    torso: 50,
    arm: 50,
    shoulders: 34,
    sitBones: 90,
    age: 'Under 40',
    flexibility: 'Good',
    ridingStyle: 'Relaxed',
    preconditions: 'None'
  }

  const [quickSizeInputs, setQuickSizeInputs] = useState(initialState)

  const quickSizeMeasurementsForm = useQuickSizeMeasurementsForm(
    initialState,
    fieldOverrides
  )

  const { validateForm, form, changesMade, values } = quickSizeMeasurementsForm

  const newValues = {
    footLength: parseFloat(values.footLength),
    inseam: parseFloat(values.inseam),
    torso: parseFloat(values.torso),
    arm: parseFloat(values.arm),
    shoulders: parseFloat(values.shoulders),
    sitBones: parseInt(values.sitBones),
    age: values.age,
    flexibility: values.flexibility,
    ridingStyle: values.ridingStyle,
    preconditions: values.preconditions
  }
  const saveFunction = () => {
    setQuickSizeInputs(newValues)
  }

  const { footLength, inseam, torso, arm } = form

  const today = new Date()

  const tenYearsAgo = new Date(
    today.getFullYear() - 10,
    today.getMonth(),
    today.getDate()
  )
  const fortyFiveYearsAgo = new Date(
    today.getFullYear() - 45,
    today.getMonth(),
    today.getDate()
  )

  let fakeBirthDate = yearMonthDay(tenYearsAgo)

  if (values.age === 'Over 40') fakeBirthDate = yearMonthDay(fortyFiveYearsAgo)

  return (
    <>
      <QuickFitTabContent
        title='Quick Size'
        dataForm={dataForm}
        dataObject={values}
        editButtonText='Edit Inputs'
        modalTitle='Quick Size: Edit Inputs'
        modalForm={quickSizeMeasurementsForm}
        modalTitleIcon={CYCLIST_ICON}
        confirmationButtonIcon={CYCLIST_ICON}
        saveFunction={saveFunction}
        user={user}
        formGroup='quickfit'
        cols={['1', '3', '6', '0']}
        disabled={
          !validateForm({ footLength, inseam, torso, arm }) ||
          !changesMade(quickSizeInputs, values)
        }
      />
      <div className='mt-3'></div>
      {!changesMade(initialState, quickSizeInputs) ? (
        <OutputsPlaceholder
          title='Sizing Recomendations'
          message='Please enter the inputs above to calculate selected sizing recommendations.'
        />
      ) : (
        <>
          <SizingRecommendations
            bodyMeasurements={newValues}
            softScores={newValues}
            birthDate={fakeBirthDate}
            noPrint
          />
          <RoadBikeImage />
        </>
      )}
    </>
  )
}

export default QuickSize
