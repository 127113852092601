export const type = {
  name: 'bikeType',
  type: 'select',
  label: 'Bike Type',
  options: [
    'Road Bike',
    'Mountain Bike',
    'TT/Tri Bike',
    'Gravel',
    'Cyclocross',
    'Fat Bike',
    'Touring or Commuting',
    'Tandem'
  ],
  inline: 'true'
}

export const make = {
  name: 'make',
  type: 'text',
  label: 'Make',
  validationtype: 'inputLength',
  length: 1
}

export const model = {
  name: 'model',
  type: 'text',
  label: 'Model and Year',
  validationtype: 'inputLength',
  length: 1
}

export const frameSize = {
  name: 'frameSize',
  type: 'text',
  label: 'Frame Size (manufacturer)',
  tip: 'Frame Size as stated by manufacturer.'
}

export const seatTubeAngle = {
  name: 'seatTubeAngle',
  type: 'number',
  label: 'Seat Tube Angle',
  min: 72,
  max: 76,
  step: 0.1,
  munit: 'deg.',
  validationtype: 'measurement',
  tip: ' Angle of seat tube relative to horizontal.'
}

export const frameReach = {
  name: 'frameReach',
  type: 'number',
  label: 'Frame Reach',
  min: 111,
  max: 999,
  validationtype: 'measurement',
  tip: 'Horizontal distance from the middle of bottom bracket to middle of the top of the head tube.'
}

export const frameStack = {
  name: 'frameStack',
  type: 'number',
  label: 'Frame Stack',
  min: 111,
  max: 999,
  validationtype: 'measurement',
  tip: 'Vertical distance from the middle of bottom bracket to the top of the head tube.'
}

export const effectiveTopTube = {
  name: 'effectiveTopTube',
  type: 'number',
  label: 'Effective Top Tube',
  validationtype: 'measurement',
  tip: 'Horizontal distance from the center of the intersection of the top tube and head tube, to the centerline of the seat tube or seat post.'
}

export const stemType = {
  name: 'stemType',
  type: 'select',
  label: 'Stem Type',
  options: ['Standard', 'Integrated']
}

export const stemLength = {
  name: 'stemLength',
  type: 'number',
  label: 'Stem Length',
  min: 35,
  max: 140,
  validationtype: 'measurement',
  tip: 'Length of stem measured from center of handlebar clamp to center of steerer tube.'
}
export const stemAngle = {
  name: 'stemAngle',
  type: 'number',
  label: 'Stem Angle',
  min: -45,
  max: 35,
  step: 0.1,
  munit: 'deg.',
  validationtype: 'measurementneg',
  tip: ' Angle of stem relative to steerer tube. Angle can be postive (rise) or negative (drop).'
}
export const spacersBelow = {
  name: 'spacersBelow',
  type: 'number',
  label: 'Spacers Below Stem',
  min: 0,
  max: 75,
  validationtype: 'measurement',
  tip: 'Spacers below stem measured in millimeters.'
}
export const spacersAbove = {
  name: 'spacersAbove',
  type: 'number',
  label: 'Spacers Above Stem',
  validationtype: 'measurement',
  tip: ' Spacers above stem measured in millimeters.'
}
export const handlebarWidth = {
  name: 'handlebarWidth',
  type: 'number',
  label: 'Handlebar Width',
  validationtype: 'measurement',
  tip: 'Handlebar size measured from the center of the hoods (drop bar) or end to end (flat bar).'
}
export const handlebarReach = {
  name: 'handlebarReach',
  type: 'number',
  label: 'Handlebar Reach',
  validationtype: 'measurement',
  tip: 'Horizontal distance from center of handlebar to center of drops at longest point.'
}
export const seatPostOffset = {
  name: 'seatPostOffset',
  type: 'number',
  label: 'Seat Post Offset',
  poslabel: '(back)',
  neglabel: '(forward)',
  min: -200,
  validationtype: 'measurementneg',
  tip: 'Horizontal distance from the center of the seat post to the center of the rail clamp.'
}
export const saddleMake = {
  name: 'saddleMake',
  type: 'text',
  label: 'Saddle Make'
}
export const saddleModel = {
  name: 'saddleModel',
  type: 'text',
  label: 'Saddle Model'
}
export const saddleWidth = {
  name: 'saddleWidth',
  type: 'number',
  label: 'Saddle Width',
  validationtype: 'measurement',
  tip: `Saddle width at the saddle's widest point.`
}
export const crankLength = {
  name: 'crankLength',
  type: 'number',
  label: 'Crank Length',
  min: 160,
  max: 180,
  step: 0.1,
  validationtype: 'measurement',
  tip: 'Length of cranks measured from center of bottom bracket axle to the center of the pedal spindle.'
}
export const pedalType = {
  name: 'pedalType',
  type: 'select',
  label: 'Pedal Type',
  options: ['Road', 'MTB', 'Flat']
}
export const pedalMakeModel = {
  name: 'pedalMakeModel',
  type: 'text',
  label: 'Pedal Make/Model'
}
export const shifterType = {
  name: 'shifterType',
  type: 'select',
  label: 'Shifters',
  options: ['Mechanical', 'Electronic']
}
export const brakeType = {
  name: 'brakeType',
  type: 'select',
  label: 'Brakes',
  options: ['Rim', 'Disc']
}

//Mountain Bike Specific Fields

export const mtbWheelSize = {
  name: 'mtbWheelSize',
  type: 'select',
  label: 'Wheel Size',
  options: ['29', '27.5', '26']
}
export const mtbSeatPostType = {
  name: 'mtbSeatPostType',
  type: 'select',
  label: 'Seat Post Type',
  options: ['Rigid', 'Suspension', 'Dropper']
}

export const mtbHandlebarRise = {
  name: 'mtbHandlebarRise',
  type: 'number',
  label: 'Handlebar Rise',
  validationtype: 'measurement',
  tip: 'Amount of handlebar rise.',
  min: 0,
  max: 45
}

// TT/Tri bike Specific Fields

export const ttBasebarWidth = {
  name: 'ttBasebarWidth',
  type: 'number',
  label: 'Basebar Width',
  validationtype: 'measurement',
  tip: 'Distance between center point of the aerobar basebars.'
}

export const ttAerobarType = {
  name: 'ttAerobarType',
  type: 'select',
  label: 'Aerobar Type',
  options: ['Clip-on', 'Integrated']
}

export const ttAerobarMakeModel = {
  name: 'ttAerobarMakeModel',
  type: 'text',
  label: 'Aerobar Make/Model'
}

export const ttExtensionsShape = {
  name: 'ttExtensionShape',
  type: 'text',
  label: 'Extensions--Shape'
}
export const ttRisers = {
  name: 'ttRisers',
  type: 'text',
  label: 'Risers'
}
export const ttPadsMakeModel = {
  name: 'ttPadsMakeModel',
  type: 'text',
  label: 'Pads Make/Model'
}

//Notes Fields

export const notes = {
  name: 'notes',
  type: 'text',
  as: 'textarea',
  label: 'Fitting Notes',
  rows: '4',
  placeholder: 'Enter any additional comments here',
  tip: 'These notes appear on PDF Fitting Report, if selected'
}
export const confidentialNotes = {
  name: 'confidentialNotes',
  type: 'text',
  as: 'textarea',
  label: 'Confidential Notes',
  rows: '4',
  tip: 'These notes do not appear on PDF reports.'
}

//Tools fields

export const headTubeAngle = {
  name: 'headTubeAngle',
  type: 'number',
  label: 'Head Tube Angle',
  validationtype: 'measurement',
  min: 60,
  max: 75,
  step: 0.1,
  munit: 'deg.',
  inline: true,
  tip: 'Angle of fork steerer tube compared to horizontal level.'
}
export const headSetHeight = {
  name: 'headSetHeight',
  type: 'number',
  label: 'Headset Height',
  validationtype: 'measurement',
  min: 0,
  max: 35,
  munit: 'mm.',
  inline: true,
  tip: 'Height of headset in millimeters, excluding any spacers.'
}

export const stemClampHeight = {
  name: 'stemClampHeight',
  type: 'number',
  label: 'Stem Clamp Height',
  validationtype: 'measurement',
  min: 20,
  max: 50,
  munit: 'mm.',
  inline: true,
  tip: 'Height of stem clamp, excluding any spacers above or below.'
}

export const barToHoodReach = {
  name: 'barToHoodReach',
  type: 'number',
  label: 'Bar To Hood Reach',
  validationtype: 'measurement',
  min: 110,
  max: 130,
  munit: 'mm.',
  inline: true,
  tip: 'Distance from stem/bar to hoods.'
}

export const saddleHeight = {
  name: 'saddleHeight',
  type: 'number',
  label: 'Saddle Height',
  min: 725,
  max: 1092,
  step: 1,
  munit: 'mm.',
  inline: true,
  validationtype: 'measurement',
  tip: 'Distance from pedal spindle to saddle'
}
