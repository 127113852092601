import React from 'react'
import { Card, Tab, Row, Col, Nav } from 'react-bootstrap'
import ResourceList from './ResourceList'

const ToolsTabs = ({ user, resources }) => {
  return (
    <Tab.Container id='left-tabs-example' defaultActiveKey={resources[0]._id}>
      <Row>
        <Col sm={3}>
          <Card>
            <Nav variant='pills' className='flex-column'>
              {resources.map((resource) => (
                <Nav.Item key={resource._id}>
                  <Nav.Link eventKey={resource._id}>
                    {resource.category}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            {resources.map((resource) => (
              <Tab.Pane key={resource._id} eventKey={resource._id}>
                <ResourceList resource={resource} />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
}

export default ToolsTabs
