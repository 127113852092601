export const TRASH_ICON_FKS = 'fa-regular fa-trash text-fitkit'
export const TRASH_ICON = 'fa-regular fa-trash'
export const BIKE_ICON = 'fa-regular fa-bicycle'
export const WARNING_ICON = 'fa-regular fa-triangle-exclamation'
export const CYCLIST_ICON = 'fa-regular fa-person-biking'
export const PHONE_ICON = 'fa-regular fa-phone'
export const EMAIL_ICON = 'fa-regular fa-envelope'
export const CALENDAR_ICON = 'fa-regular fa-calendar-day'
export const CALENDAR_EDIT_ICON = 'fa-regular fa-calendar-lines-pen'
export const PLUS_ICON = 'fa-regular fa-plus'
export const USER_ICON = 'fa-regular fa-user'
export const USER_ICON_FKS = 'fa-regular fa-user text-fitkit'
export const ADMIN_ICON = 'fa-regular fa-user-lock'
export const ADMIN_ICON_FKS = 'fa-regular fa-user-lock text-fitkit'
export const SHOP_ICON = 'fa-regular fa-shop'
export const SUBSCRIPTION_ICON = 'fa-regular fa-file-invoice-dollar'
export const INVOICE_ICON = 'fa-regular fa-file-invoice'
export const CREDIT_CARD_ICON = 'fa-regular fa-credit-card'
export const CANCEL_ICON = 'fa-regular fa-circle-xmark'
export const CHECK_ICON = 'fa-regular fa-circle-check'
export const EDIT_ICON = 'fa-regular fa-edit'
export const SETTINGS_ICON = 'fa-regular fa-gear'
export const DASHBOARD_ICON = 'fa-regular fa-gauge-high'
export const MY_ACCOUNT_ICON = 'fa-regular fa-user-gear'
export const MY_ACCOUNT_ICON_FKS = 'fa-regular fa-user-gear text-fitkit'
export const SIGN_OUT_ICON = 'fa-regular fa-right-from-bracket'
export const POLICY_ICON = 'fa-regular fa-file-contract'
export const INFO_ICON = 'fa-regular fa-square-info'
export const TOOLBOX_ICON = 'fa-regular fa-toolbox'
export const TOOLS_ICON = 'fa-regular fa-screwdriver-wrench'
export const NEXT_ICON = 'fa-regular fa-caret-right fa-lg'
export const PREVIOUS_ICON = 'fa-regular fa-caret-left fa-lg'
export const DOWNLOAD_ICON = 'fa-regular fa-download text-fitkit float-end mt-1'
export const PRINT_PDF_ICON = 'fa-regular fa-print text-fitkit float-end mt-1'
export const COMMENT_ICON = 'fa-regular fa-comment'
export const RESOURCES_ICON = 'fa-regular fa-books'
export const REFRESH_ICON = 'fa-regular fa-rotate-right'
export const SEARCH_ICON = 'fa-regular fa-magnifying-glass'
export const VIEW_RECORD_ICON = 'fa-regular fa-file-magnifying-glass'
export const DATA_ICON = 'fa-regular fa-database'
export const APPROVE_ICON = 'fa-regular fa-hexagon-check'
export const KEY_ICON = 'fa-regular fa-key'
