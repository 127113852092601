// To be replace by Torso to Leg ratio

export const lowerLegLengthValues = [
  35.5, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40, 40.5, 41, 41.5, 42, 42.5,
  43, 43.5, 44, 44.5, 45, 45.5, 46, 46.5, 47, 47.5, 48, 48.5, 49, 49.5, 50,
  50.5, 51
]

export const seatTubeAngles = [
  //Inseem=70 Index=Inseem-70
  [
    74, 74, 74, 74.5, 75.5, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76,
    76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=71 Index=Inseem-70
  [
    72, 74, 74, 74, 74.5, 75, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76,
    76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=72 Index=Inseem-70
  [
    72, 72, 74, 74, 74, 74, 74, 75, 75.5, 76, 76, 76, 76, 76, 76, 76, 76, 76,
    76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=73 Index=Inseem-70
  [
    72, 72, 72, 74, 74, 74, 74, 74, 75, 75, 76, 76, 76, 76, 76, 76, 76, 76, 76,
    76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=74 Index=Inseem-70
  [
    72, 72, 72, 72, 73.5, 74, 74, 74.5, 74, 75, 75, 76, 76, 76, 76, 76, 76, 76,
    76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=75 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 73.5, 74, 74, 74.5, 74, 74.5, 75, 76, 76, 76, 76, 76,
    76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=76 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 73.5, 74, 74, 74, 74, 74.5, 75, 76, 76, 76, 76, 76,
    76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=77 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 73, 73.5, 73.5, 74, 74, 74.5, 75, 76, 76, 76,
    76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=78 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 73, 73.5, 73.5, 74.5, 74, 74.5, 75, 76, 76,
    76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=79 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 73, 73.5, 73.5, 74, 74, 74.5, 75, 76,
    76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=80 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 73, 73.5, 73.5, 74, 74, 74.5, 75,
    75, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=81 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 73, 73.5, 73.5, 74, 74, 74.5,
    75, 75, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=82 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 73, 72.5, 73, 73, 73.5,
    74.5, 75, 75, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=83 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72.5, 72.5, 73, 73,
    73.5, 74.5, 75, 75, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=84 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72.5, 72.5, 73, 73,
    73.5, 74.5, 75, 75, 76, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=85 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72.5, 72.5, 73,
    73, 73.5, 74.5, 75, 75, 76, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=86 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72.5, 72.5,
    73, 73, 73.5, 74, 75, 75, 76, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=87 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72.5,
    72.5, 73, 73, 73.5, 74, 74.5, 75, 76, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=88 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72,
    72.5, 72.5, 73, 73, 73.5, 74, 74.5, 75, 76, 76, 76, 76, 76, 76
  ],
  //Inseem=89 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72,
    72.5, 72.5, 73, 73, 73.5, 74, 74.5, 75, 76, 76, 76, 76, 76
  ],
  //Inseem=90 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72,
    72, 72.5, 72.5, 73, 73, 73.5, 74, 74.5, 75, 75, 76, 76, 76
  ],
  //Inseem=91 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72,
    72, 72, 72, 72.5, 73, 73, 73.5, 74, 74.5, 75, 75, 76, 76
  ],
  //Inseem=92 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72,
    72, 72, 72, 72, 72.5, 73, 73, 73.5, 74, 74.5, 75, 75, 76
  ],
  //Inseem=93 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72,
    72, 72, 72, 72, 72, 72.5, 73, 73, 73.5, 74, 74.5, 75, 75
  ],
  //Inseem=94 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72,
    72, 72, 72, 72, 72, 72, 72.5, 73, 73, 73.5, 74, 74.5, 75
  ],
  //Inseem=95 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72,
    72, 72, 72, 72, 72, 72, 72, 72.5, 73, 73, 73.5, 74, 74.5
  ],
  //Inseem=96 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72,
    72, 72, 72, 72, 72, 72, 72, 72, 72.5, 73, 73, 73.5, 74
  ],
  //Inseem=97 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72,
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72.5, 72.5, 73, 73.5
  ],
  //Inseem=98 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72,
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72.5, 72.5, 73
  ],
  //Inseem=99 Index=Inseem-70
  [
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72,
    72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72, 72.5, 72.5
  ]
]

export const calculateSeatTubeAngle = (rawInseam, rawLowerLegLength) => {
  if (!rawInseam) return 73
  const inseam = Math.round(rawInseam)

  //round lowerleglength to nearest .5 by rounding twice the value and dividing by two.
  const lowerLegLength = Math.round(rawLowerLegLength * 2) / 2

  const index = lowerLegLengthValues.indexOf(lowerLegLength)
  const inseamIndex = inseam - 70
  return seatTubeAngles[inseamIndex][index]
}
