import { saddleHeight } from './bikeFields'
import { inseam, footLength, torso, arm } from './customerMeasurementFields'
import { saddleHeightMode } from './quickStackReachFields'

export const stackReachInputsForm = [
  saddleHeightMode,
  saddleHeight,
  inseam,
  footLength,
  torso,
  arm
]

//export const saddleHeightModeForm = [saddleHeightMode]
