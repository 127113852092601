import { useState } from 'react'
import { Form, Card, Button, Col, InputGroup } from 'react-bootstrap'
import useForm from '../../../forms-new/form-hooks/useForm'
import useStackReachInputsModal from './useStackReachInputsModal'
import { stackReachInputsForm } from '../../../forms-new/fields-and-forms/quickStackReachForms'
import TableData from './TableData'
import FormModal from '../../layout/modals/FormModal'
import { BIKE_ICON } from '../../../utils/fontAwesomeDefaults'
import GenForm from './GenForm'
import OutputsPlaceholder from '../OutputsPlaceholder'
import QuickStackReachRecommendations from './QuickStackReachRecommendations'
import {
  calculateFrameSize,
  calculateMinimumSaddleHeight
} from '../../../lib/fitkit-js-functions-v3.mjs'
const QuickStackReach = ({ user, fieldOverrides }) => {
  const initialState = {
    saddleHeightMode: 'Estimate from Inputs',
    saddleHeight: 862,
    inseam: 82,
    footLength: 24.5,
    torso: 61,
    arm: 59.5
  }
  const [inputsSaved, setInputsSaved] = useState(false)
  const [convertedValues, setConvertedValues] = useState(initialState)
  const stackReachInputs = useForm(stackReachInputsForm, initialState)

  const saveFunction = () => {
    const { inseam, arm, footLength, saddleHeight, torso, saddleHeightMode } =
      stackReachInputs.values
    setConvertedValues({
      inseam: parseFloat(inseam),
      footLength: parseFloat(footLength),
      arm: parseFloat(arm),
      torso: parseFloat(torso),
      saddleHeight: parseFloat(saddleHeight),
      saddleHeightMode
    })
    setInputsSaved(true)
  }

  const formModal = useStackReachInputsModal({
    icon: BIKE_ICON,
    title: 'Edit Stack/Reach Inputs',
    confirmationButtonIcon: BIKE_ICON,
    confirmationButtonText: 'Save Changes',

    cancelAction: () => {},

    confirmationAction: () => {
      saveFunction()
    }
  })
  const standardInput = (input) => {
    return (
      <>
        <Col xs='2'></Col>
        <Col xs='3'>{input.label}</Col>
        <Col xs='4'>
          <InputGroup size={input.inputSize || 'sm'}>
            <Form.Control {...input} />
            <InputGroup.Text>{input.munit}</InputGroup.Text>
          </InputGroup>
        </Col>
      </>
    )
  }
  const config = [
    {
      render: (input) => (
        <>
          <Col xs='2'></Col>
          <Col xs='3'>{input.label}</Col>
          <Col>
            {input.options.map((option) => {
              return (
                <Form.Check
                  key={option}
                  {...input}
                  label={option}
                  value={option}
                  checked={input.value === option}
                />
              )
            })}
          </Col>
        </>
      )
    },
    {
      render: (input, values) => {
        if (values.saddleHeightMode !== 'Estimate from Inputs')
          return standardInput(input)
        else {
          const frameSize = calculateFrameSize(values.inseam)
          const minSaddleHeight =
            calculateMinimumSaddleHeight(frameSize, values.footLength) * 10
          return (
            <>
              <Col xs='2'></Col>
              <Col xs='3'>{input.label}</Col>
              <Col xs='4'>
                <InputGroup size={input.inputSize || 'sm'}>
                  <Form.Control
                    {...input}
                    value={minSaddleHeight}
                    disabled={true}
                  />
                  <InputGroup.Text>{input.munit}</InputGroup.Text>
                </InputGroup>
              </Col>
            </>
          )
        }
      }
    },
    {
      render: (input) => standardInput(input)
    },
    {
      render: (input) => standardInput(input)
    },
    {
      render: (input) => standardInput(input)
    },
    {
      render: (input) => standardInput(input)
    }
  ]
  return (
    <>
      <Card>
        <Card.Header className='bg-white'>
          <h5 style={{ display: 'inline' }}>Quick Stack/Reach</h5>
        </Card.Header>
        <Card.Body>
          <TableData
            dataForm={stackReachInputs.form}
            dataObject={stackReachInputs.values}
            user={user}
          />
        </Card.Body>

        <Card.Footer className='bg-white'>
          <div className='d-grid gap-2'>
            <Button
              variant='dark'
              onClick={() => {
                formModal.setShowModal()
              }}
            >
              Edit Inputs
            </Button>
          </div>
        </Card.Footer>
      </Card>
      <div className='mt-3'></div>
      {!inputsSaved ? (
        <OutputsPlaceholder
          title='Frame Stack and Reach Recomendations'
          message='Please enter the inputs above to calculate stack and reach recommendations.'
        />
      ) : (
        <>
          <QuickStackReachRecommendations {...convertedValues} />
        </>
      )}
      <FormModal {...formModal}>
        <GenForm {...stackReachInputs} config={config} />
      </FormModal>
    </>
  )
}
export default QuickStackReach
