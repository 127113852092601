const QuickFitFeatures = () => {
  return (
    <>
      <p>
        Fit Kit Studio's Quick Fit service provides a full suite of features for
        bike fitters, including:
        <br />
      </p>
      <ul>
        {' '}
        <li>cloud storage of pre-fit customer interview forms</li>
        <li>
          cloud storage of customer profiles, bike equipment, fit position and
          fit history data
        </li>
        <li>
          customizable summary PDF reports for printing or emailing to customers
        </li>{' '}
        <li>automated customer intake processes</li>
        <li>customized bike shop or fitting studio branding</li>
      </ul>
    </>
  )
}

export default QuickFitFeatures
