import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'

const InlineNumberInput = ({
  type,
  value,
  label,
  placeholder,
  isValid,
  isInvalid,
  onChange,
  min,
  max,
  step
}) => {
  return (
    <>
      <Row className='mb-3'>
        <Form.Label column xs='auto'>
          {label}
        </Form.Label>

        <Col>
          <Form.Control
            type={type}
            value={value}
            label={label}
            placeholder={placeholder}
            onChange={onChange}
            isValid={isValid}
            isInvalid={isInvalid}
            min={min}
            max={max}
            step={step}
            size='sm'
          />
        </Col>
      </Row>
    </>
  )
}

export default InlineNumberInput
