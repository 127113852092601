import jsPDF from 'jspdf'

import {
  getFormat,
  getHeader,
  getCustomerProfileForms,
  getTableBlock,
  getBikeImage,
  getBikeNotes,
  getFooter,
  getBikeStrings,
  getBikeForm,
  getFitPositionForm,
  getBlockY,
  LEFT_MARGIN,
  TOP_MARGIN,
  RIGHT_COLUMN_X
  //BLOCK_ONE_Y,
  //BLOCK_TWO_Y,
  //BLOCK_THREE_Y,
  //BLOCK_FOUR_Y,
  //BLOCK_FIVE_Y,
  //NOTES_BLOCK_Y
} from './pdfFunctions'

import {
  bodyMeasurementsForm,
  physicalProfileForm
} from '../components/forms/form-objects/customerFormObjects'

import { calculateAge } from '../utils/dateFormats'
import { shoesCleatsTableForm } from '../components/forms/form-objects/shoesCleatsFormObjects'

export const bikePdf = (props) => {
  const { bike, fileName, user, shop, customer } = props
  const { type: bikeType } = bike

  let pdf = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: getFormat(user)
  })

  pdf.setFontSize(10)
  pdf.setTextColor(user.preferences.report_color)
  // Header
  getHeader(pdf, user, shop, [
    'Bike Fit Results: ' + getBikeStrings(bike).bikeString,
    getBikeStrings(bike).bikeUpdatedString
  ])

  //Table Blocks

  const {
    cyclist_name,
    cyclist_profile,
    fk_body_measurements,
    fk_soft_score,
    equipment,
    fit_position,
    feet_cleat,
    bike_diagram,
    notes_recommendations
  } = user.preferences.report_fields

  //Cyclist Profile

  if (cyclist_name || cyclist_profile) {
    const [profileForm, profileObject] = getCustomerProfileForms(customer, user)

    getTableBlock({
      pdf,
      dataForm: profileForm,
      dataObject: profileObject,
      user,
      cols: [25],
      heading: 'Cyclist',
      x: LEFT_MARGIN,
      y: getBlockY('cyclist_profile', user.preferences.report_fields)
    })
  }

  //Body Measurements

  if (fk_body_measurements) {
    getTableBlock({
      pdf,
      dataForm: bodyMeasurementsForm,
      dataObject: customer.bodyMeasurements,
      user,
      cols: [25],
      heading: 'Body Measurements',
      x: LEFT_MARGIN,
      y: getBlockY('body_measurements', user.preferences.report_fields)
    })
  }

  //Physical Profile
  if (fk_soft_score) {
    getTableBlock({
      pdf,
      dataForm: { age: { label: 'Age', type: 'text' }, ...physicalProfileForm },
      dataObject: {
        age: calculateAge(customer.cyclistProfile.birthDate).toString(),
        ...customer.softScores
      },
      user,
      cols: [25],
      heading: 'Physical Profile',
      x: LEFT_MARGIN,
      y: getBlockY('physical_profile', user.preferences.report_fields)
    })
  }

  //Equipment
  if (equipment) {
    getTableBlock({
      pdf,
      dataForm: getBikeForm(bikeType),
      dataObject: bike,
      user,
      cols: [45, 25],
      heading: 'Equipment',
      x: LEFT_MARGIN,
      y: getBlockY('equipment', user.preferences.report_fields)
    })
  }

  if (fit_position) {
    //Fit Position
    getTableBlock({
      pdf,
      dataForm: getFitPositionForm(bikeType),
      dataObject: bike,
      user,
      cols: [75, 20],
      heading: 'Fit Position',
      x: RIGHT_COLUMN_X + 25,
      y: getBlockY('fit_position', user.preferences.report_fields)
    })
  }

  if (feet_cleat) {
    getTableBlock({
      pdf,
      dataForm: shoesCleatsTableForm,
      dataObject: bike,
      user,
      cols: [45, 25],
      heading: 'Shoes/Cleats',
      x: LEFT_MARGIN,
      y: getBlockY('feet_cleat', user.preferences.report_fields)
    })
  }

  //Bike Image
  if (bike_diagram) {
    getBikeImage({
      pdf,
      bikeType,
      x: RIGHT_COLUMN_X,
      y: TOP_MARGIN + 30,
      reportType: 'fitting'
    })
  }

  //Footer
  getFooter({ pdf })

  // BIke Notes
  if (notes_recommendations) {
    getBikeNotes({
      pdf,
      notes: bike.notes,
      user,
      shop,
      bike,
      x: RIGHT_COLUMN_X + 25,
      y: getBlockY('notes_recommendations', user.preferences.report_fields)
    })
  }
  pdf.save(fileName + '.pdf')
}
