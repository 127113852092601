import { useState, useEffect } from 'react'

const useRecord = (records, recordId) => {
  const [record, setRecord] = useState({})

  useEffect(() => {
    const record = records.find((record) => record._id === recordId)
    if (record) setRecord(record)
  }, [records, recordId])

  return record
}

export default useRecord
