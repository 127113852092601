import { useEffect } from 'react'
import { Table, Spinner as BSSpinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { loadUser, setRefresh, setLoading } from '../../../redux/actions/users'

import NewInterviewRow from './NewInterviewRow'
import QuickFitDashboardCard from '../QuickFitDashboardCard'
import QuickFitDashboardItemCounter from '../QuickFitDashboardItemCounter'
import QuickFitDashboardTitle from '../QuickFitDashboardTitle'
import NextPreviousFooter from '../../hook-components/NextPreviousFooter'

import useNextPrevious from '../../hooks/action-hooks/useNextPrevious'

import { REFRESH_ICON } from '../../../utils/fontAwesomeDefaults'

import { getIcon } from '../../../utils/generalFunctions'
import Tip from '../../layout/overlays/Tip'
import Spinner from '../../layout/Spinner'

const NewInterviewTable = ({
  newInterviews,
  users: { userRefreshing, loading },
  loadUser,
  setRefresh
}) => {
  const limit = 5

  const nextPrevious = useNextPrevious(limit, newInterviews.length)

  const { start, stop, length } = nextPrevious

  const refreshInterviews = () => {
    setRefresh(true)
    setLoading(true)
    loadUser()
  }

  useEffect(() => {
    refreshInterviews()
    //eslint-disable-next-line
  }, [])

  if (newInterviews.length !== length)
    nextPrevious.setcurrentLength(newInterviews.length)
  if (stop > newInterviews.length) nextPrevious.setStop(newInterviews.length)
  if (stop < limit && newInterviews.length >= limit) nextPrevious.setStop(limit)

  return (
    <>
      <QuickFitDashboardCard
        style={{ minHeight: '305px' }}
        title={
          <>
            <QuickFitDashboardTitle title='New Pre-Fit Interviews' />
            <QuickFitDashboardItemCounter
              start={start}
              stop={stop}
              length={newInterviews.length}
            />
            {!userRefreshing && (
              <Tip message='Refresh interview list.'>
                <span
                  style={{ cursor: 'pointer' }}
                  className='float-end'
                  onClick={refreshInterviews}
                >
                  {getIcon(REFRESH_ICON)}
                </span>
              </Tip>
            )}
            {userRefreshing && (
              <BSSpinner
                variant='dark'
                size='sm'
                className='float-end'
                animation='border'
              />
            )}
          </>
        }
        footer={<NextPreviousFooter {...nextPrevious} />}
      >
        {loading || userRefreshing ? (
          <Spinner />
        ) : (
          <Table size='sm' style={{ fontSize: '.8rem' }}>
            <tbody>
              {newInterviews.length === 0 ? (
                <tr>
                  <td>
                    <h6>No new interviews found.</h6>
                  </td>
                </tr>
              ) : (
                newInterviews.slice(start - 1, stop).map((interview) => {
                  return (
                    <NewInterviewRow
                      key={interview._id}
                      interview={interview}
                      handleDelete={nextPrevious.handleDelete}
                    />
                  )
                })
              )}
            </tbody>
          </Table>
        )}
      </QuickFitDashboardCard>
    </>
  )
}

const mapStateToProps = (state) => ({
  users: state.users
})

export default connect(mapStateToProps, { loadUser, setRefresh })(
  NewInterviewTable
)
