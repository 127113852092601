import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { BlockPicker as ReactBlockPicker } from 'react-color'

const BlockPicker = ({ options, value, onChange, label }) => {
  return (
    <>
      <Form.Group as={Row} className='mt-3 mb-1'>
        <Form.Label column>{label}</Form.Label>
        <Col>
          <ReactBlockPicker
            className='mt-2'
            color={value}
            onChangeComplete={onChange}
            colors={options}
          />
        </Col>
      </Form.Group>
    </>
  )
}

export default BlockPicker
