import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'

const QuickFitDashboardCard = ({
  children,
  style,
  title,
  headerIcon,
  headerIcon2,
  footer
}) => {
  return (
    <Card style={style} className='mb-3'>
      <Card.Header className='bg-white'>
        <Row>
          <Col>
            <h5 style={{ display: 'inline' }}>{title}</h5>
          </Col>
          {headerIcon && <Col> {headerIcon}</Col>}
          {headerIcon2 && <Col> {headerIcon2}</Col>}
        </Row>
      </Card.Header>
      <Card.Body>{children}</Card.Body>
      {footer && <Card.Footer className='bg-white'>{footer}</Card.Footer>}
    </Card>
  )
}

export default QuickFitDashboardCard
