import api from '../../utils/api.js'
import { setAlert } from './alert'
import {
  CREATE_INTERVIEW,
  NEW_INTERVIEWS_LOADED,
  CUSTOMER_INTERVIEWS_LOADED,
  UPDATE_INTERVIEW,
  DELETE_INTERVIEW,
  CLEAR_INTERVIEWS,
  CLEAR_CUSTOMER_INTERVIEWS
} from './types'

// Create Pre-Fit Interview
export const createPreFitInterview = (userId, formData) => async (dispatch) => {
  try {
    const res = await api.post(`/prefitinterviews/new/${userId}`, formData)

    dispatch({
      type: CREATE_INTERVIEW,
      payload: res.data
    })
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}

// Get New Prefit Interviews for Logged in User
export const loadNewPrefitInterviews = () => async (dispatch) => {
  try {
    const res = await api.get('/prefitinterviews/by-user/new')

    dispatch({
      type: NEW_INTERVIEWS_LOADED,
      payload: res.data
    })
  } catch (err) {
    console.log(err)
    const errors = err.response.data.errors

    if (errors) {
      errors.forEach((err) => console.log(err.msg))
    }
  }
}

// Get All Prefit Interviews for Customer based on Customer Id
export const loadCustomerPrefitInterviews =
  (customerId) => async (dispatch) => {
    try {
      const res = await api.get(`/prefitinterviews/by-customer/${customerId}`)

      dispatch({
        type: CUSTOMER_INTERVIEWS_LOADED,
        payload: res.data
      })
    } catch (err) {
      console.log(err)
      const errors = err.response.data.errors

      dispatch({
        type: CLEAR_CUSTOMER_INTERVIEWS
      })

      if (errors) {
        errors.forEach((err) => console.log(err.msg))
      }
    }
  }

//Update Pre-Fit Interview

export const updatePreFitInterview =
  (interviewId, formData, alertMessage) => async (dispatch) => {
    try {
      const res = await api.put(`/prefitinterviews/${interviewId}`, formData)

      dispatch({ type: UPDATE_INTERVIEW, payload: res.data })

      if (alertMessage) dispatch(setAlert(alertMessage, 'success'))
      dispatch(loadNewPrefitInterviews())
    } catch (err) {
      const errors = err.response.data.errors

      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')))
      }
    }
  }

//Delete Interview

export const deleteInterview = (interviewId) => async (dispatch) => {
  try {
    await api.delete(`/prefitinterviews/${interviewId}`)

    dispatch({ type: DELETE_INTERVIEW, payload: interviewId })

    dispatch(setAlert('Interview Deleted', 'success'))
  } catch (err) {
    console.error(err)
  }
}

//Clear Pre-Fit Interviews

export const clearPrefitInterviews = () => (dispatch) => {
  dispatch({ type: CLEAR_INTERVIEWS })
}
