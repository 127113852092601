import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'

import CustomerRow from './CustomerRow'
import SearchBox from './SearchBox'
import QuickFitDashboardCard from '../QuickFitDashboardCard'
import QuickFitDashboardTitle from '../QuickFitDashboardTitle'
import QuickFitDashboardItemCounter from '../QuickFitDashboardItemCounter'
import NextPreviousFooter from '../../hook-components/NextPreviousFooter'
import useNextPrevious from '../../hooks/action-hooks/useNextPrevious'

const CustomerTable = ({ cyclists }) => {
  const [formData, setFormData] = useState({ search: '' })

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const [filteredCyclists, setFilteredCyclists] = useState(cyclists)

  const nextPrevious = useNextPrevious(10, cyclists.length)

  const { start, stop, length } = nextPrevious

  useEffect(() => {
    if (formData.search !== '') {
      const regex = new RegExp(formData.search, 'i')

      const matches = cyclists.filter((cyclist) =>
        regex.test(cyclist.cyclistProfile.lastName)
      )

      setFilteredCyclists(matches)
    } else {
      setFilteredCyclists(cyclists)
    }
  }, [formData, cyclists])

  const updateTable = () => {
    setFormData({ search: '' })
    nextPrevious.handleDelete()
  }

  return (
    <>
      <QuickFitDashboardCard
        style={{ minHeight: '500px' }}
        title={
          <>
            <QuickFitDashboardTitle title='Retrieve Customer' />
            {formData.search === '' && (
              <QuickFitDashboardItemCounter
                start={start}
                stop={stop}
                length={length}
              />
            )}
          </>
        }
        footer={
          <>
            {formData.search === '' && <NextPreviousFooter {...nextPrevious} />}
          </>
        }
      >
        <SearchBox formData={formData} onChange={onChange} />
        {formData.search === '' && cyclists.length > 0 ? (
          <Table className='mt-3' size='sm' style={{ fontSize: '.8rem' }}>
            <tbody>
              {filteredCyclists
                .slice(nextPrevious.start - 1, nextPrevious.stop)
                .map((cyclist) => {
                  return (
                    <CustomerRow
                      key={cyclist._id}
                      cyclist={cyclist}
                      updateTable={updateTable}
                    />
                  )
                })}
            </tbody>
          </Table>
        ) : formData.search !== '' && filteredCyclists.length === 0 ? (
          <p className='mt-3'>No matching customers.</p>
        ) : (
          <Table className='mt-3' size='sm' style={{ fontSize: '.8rem' }}>
            <tbody>
              {filteredCyclists
                .slice(0, filteredCyclists.length)
                .map((cyclist) => {
                  return (
                    <CustomerRow
                      key={cyclist._id}
                      cyclist={cyclist}
                      updateTable={updateTable}
                    />
                  )
                })}
            </tbody>
          </Table>
        )}
      </QuickFitDashboardCard>
    </>
  )
}

export default CustomerTable
