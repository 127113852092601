import React from 'react'
import { Link } from 'react-router-dom'
import { Nav, Navbar, Container } from 'react-bootstrap'

import {
  MY_ACCOUNT_ICON_FKS,
  DASHBOARD_ICON,
  SETTINGS_ICON
} from '../../utils/fontAwesomeDefaults'

const AccountNav = () => {
  return (
    <Navbar className='mb-3' fill='true' variant='dark' bg='dark'>
      <Container>
        <Navbar.Brand>
          <i className={MY_ACCOUNT_ICON_FKS} /> My Account
        </Navbar.Brand>

        <Nav>
          <Nav.Item>
            <Nav.Link as={Link} to='/account/dashboard'>
              <i className={DASHBOARD_ICON} /> Dashboard
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={Link} to='/account/settings'>
              <i className={SETTINGS_ICON} /> Settings
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default AccountNav
