import React from 'react'
import { Card } from 'react-bootstrap'

import { CARD_HEADER_BG } from '../../utils/styleAliases'

const preFitCard = ({ title, children }) => {
  return (
    <Card className='mb-3'>
      <Card.Header className={CARD_HEADER_BG}>
        <Card.Title>{title}</Card.Title>
      </Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  )
}

export default preFitCard
