import { Table } from 'react-bootstrap'
import UserSearchListRow from './UserSearchListRow'

const UserSearchList = ({ searchData }) => {
  return (
    <Table size='sm' style={{ fontSize: '.8rem' }}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>View</th>
        </tr>
      </thead>
      <tbody>
        {searchData.map((user) => {
          return <UserSearchListRow key={user._id} user={user} />
        })}
      </tbody>
    </Table>
  )
}

export default UserSearchList
