import React from 'react'

const HomeBanner = () => {
  //  const anchor = { x: 1450, y: 200 }
  const anchor = { x: 275, y: 440 }
  const lineSpacing = 40
  return (
    <div>
      <svg viewBox='0 100 2172 650'>
        {/* <image
          style={{ height: 'auto' }}
          className='homeBannerImage'
          href='/homeBanner.jpg'
        /> */}
        <image
          style={{ height: 'auto' }}
          className='homeBannerImage'
          href='/fkshero.jpg'
        />

        <text
          textAnchor='start'
          x={anchor.x}
          y={anchor.y}
          fill='white'
          className='homeBannerImageText'
          style={{ font: 'bold 40px sans-serif' }}
        >
          {/* <tspan>"Finally! A simple, dedicated</tspan>
          <tspan x={anchor.x + 85} dy={lineSpacing}>
            software solution for
          </tspan>
          <tspan x={anchor.x + 75} dy={lineSpacing}>
            independent bike fitting
          </tspan>
          <tspan x={anchor.x - 5} dy={lineSpacing}>
            professionals and bike shops"
          </tspan> */}
          <tspan>Fit Kit Studio:</tspan>
          <tspan x={anchor.x} dy={lineSpacing}>
            a leading software service for
          </tspan>
          <tspan x={anchor.x} dy={lineSpacing}>
            bike fit documentation and reporting.
          </tspan>
        </text>
      </svg>
    </div>
  )
}

export default HomeBanner
