import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'

const Switch = ({ value, onChange, label, colSize, grouplabel, boxFirst }) => {
  return (
    <>
      {grouplabel && <p>{grouplabel}</p>}

      {!boxFirst ? (
        <Form.Group as={Row}>
          <Col xs='auto'>
            <Form.Label>{label}</Form.Label>
          </Col>
          <Col>
            <Form.Check
              onChange={onChange}
              checked={value === true}
              type='switch'
              inline
            />
          </Col>
        </Form.Group>
      ) : (
        <Form.Group>
          <Form.Check
            onChange={onChange}
            checked={value === true}
            type='switch'
            inline
            label={label}
          />
        </Form.Group>
      )}
    </>
  )
}

export default Switch
