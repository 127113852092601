const LinkButton = ({ id, color, children, onClick }) => {
  return (
    <span
      id={id}
      style={{
        cursor: 'pointer',
        textDecoration: 'none',
        color: color || '#0d6efd',
        fontSize: '.8rem'
      }}
      onClick={onClick}
    >
      {children}
    </span>
  )
}

export default LinkButton
