import React from 'react'

const MainHeading = ({ headingIcon, heading, subHeadingIcon, subheading }) => {
  return (
    <>
      <h4>
        {headingIcon && <i className={headingIcon} />} {heading}
      </h4>

      {subheading && (
        <p className='lead'>
          {subHeadingIcon && <i className={subHeadingIcon} />} {subheading}
        </p>
      )}
    </>
  )
}

export default MainHeading
