import React from 'react'
import { Card } from 'react-bootstrap'

import TableData from '../layout/cards-containers/TableData'

import PreferenceInfo from './dashboard/PreferenceInfo'
import EditFormButton from '../hook-components/EditFormButton'

const AccountSettingsTabContent = (props) => {
  const { title, mode, bodyPart, deleteRecordButton } = props
  return (
    <>
      <Card className='mb-3'>
        <Card.Header className='bg-white'>
          <Card.Title>{title}</Card.Title>
        </Card.Header>
        <Card.Body style={{ fontSize: '.8rem' }}>
          {bodyPart}
          {mode === 'password' ? (
            <p>
              All user passwords must contain at least eight characters, one
              uppercase letter, one lowercase letter and one number. Special
              characters are allowed. (Example: fitKit$3)
            </p>
          ) : mode === 'preferences' ? (
            <PreferenceInfo {...props} />
          ) : (
            <TableData {...props} />
          )}
          {deleteRecordButton}
        </Card.Body>
        <Card.Footer className='bg-white'>
          <EditFormButton {...props} />
        </Card.Footer>
      </Card>
    </>
  )
}

export default AccountSettingsTabContent
