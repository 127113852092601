import {
  category,
  tab,
  resourceName,
  resourceLink,
  resourceDescription,
  resourceType,
  resourceImageUrl
} from '../field-objects/resourceFields'

export const editResourceCategoryForm = {
  category,
  tab
}

export const editResourceForm = {
  resourceName,
  resourceLink,
  resourceDescription,
  resourceType,
  resourceImageUrl
}
