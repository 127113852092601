import React, { useState } from 'react'

import QuickFitTabContent from '../../quickfit/QuickFitTabContent'
import OutputsPlaceholder from '../OutputsPlaceholder'
import FrameToBarOutputs from './FrameToBarOutputs'
import ModalOutputs from '../ModalOutputs'

import useFrameToBarXYForm from '../../hooks/form-hooks/tools/useFrameToBarXYForm'

import {
  frameToBarXYForm as dataForm,
  handlebarReachAndStackForm as modalOutputForm
} from '../../forms/form-objects/toolsFormObjects'

import {
  calculateHandlebarReach,
  calculateHandlebarStack
} from '../../../lib/tools-js-functions'

import { CYCLIST_ICON } from '../../../utils/fontAwesomeDefaults'

const FrameToBar = ({ user, fieldOverrides }) => {
  const initialState = {
    frameReach: 387,
    frameStack: 565,
    headTubeAngle: 72,
    headSetHeight: 10,
    spacersBelow: 20,
    stemClampHeight: 40,
    stemLength: 90,
    stemAngle: -7
  }

  const [inputs, setInputs] = useState(initialState)

  const frameToBarXYForm = useFrameToBarXYForm(initialState, fieldOverrides)

  const { validateForm, form, changesMade, values } = frameToBarXYForm

  const newValues = {
    frameReach: parseInt(values.frameReach),
    frameStack: parseInt(values.frameStack),
    headTubeAngle: parseFloat(values.headTubeAngle),
    headSetHeight: parseInt(values.headSetHeight),
    spacersBelow: parseInt(values.spacersBelow),
    stemClampHeight: parseInt(values.stemClampHeight),
    stemLength: parseInt(values.stemLength),
    stemAngle: parseFloat(values.stemAngle)
  }
  const saveFunction = () => {
    setInputs(newValues)
  }

  const {
    frameReach,
    frameStack,
    headTubeAngle,
    headSetHeight,
    spacersBelow,
    stemClampHeight,
    stemLength,
    stemAngle
  } = form

  return (
    <>
      <QuickFitTabContent
        title='Frame XY to Handlebar XY (reach and stack)'
        dataForm={dataForm}
        dataObject={values}
        editButtonText='Edit Inputs'
        modalTitle='Frame XY To Handlebar XY: Edit Inputs'
        modalForm={frameToBarXYForm}
        modalTitleIcon={CYCLIST_ICON}
        confirmationButtonIcon={CYCLIST_ICON}
        saveFunction={saveFunction}
        user={user}
        formGroup='quickfit'
        cols={['2', '4', '4', '2']}
        disabled={
          !validateForm({
            frameReach,
            frameStack,
            headTubeAngle,
            headSetHeight,
            spacersBelow,
            stemClampHeight,
            stemLength,
            stemAngle
          }) || !changesMade(inputs, values)
        }
      >
        <ModalOutputs
          inputs={inputs}
          user={user}
          dataForm={modalOutputForm}
          dataObject={{
            handlebarReachHX: calculateHandlebarReach(newValues),
            handlebarStackHY: calculateHandlebarStack(newValues)
          }}
        />
      </QuickFitTabContent>
      <div className='mt-3'></div>
      {!changesMade(initialState, inputs) ? (
        <OutputsPlaceholder
          title='Handlebar Reach And Stack'
          message='Please enter the inputs above to calculate handlebar reach and stack.'
        />
      ) : (
        <FrameToBarOutputs inputs={inputs} user={user} />
      )}
    </>
  )
}

export default FrameToBar
