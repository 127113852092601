import { useState, useEffect } from 'react'
import store from '../../../redux/store'
import { loadAdminShopList } from '../../../redux/actions/admindata'

const useAdminShopList = () => {
  const [shopListLoaded, setShopListLoaded] = useState(false)
  const [shopList, setShopList] = useState()

  useEffect(() => {
    const fetchUserList = async () => {
      const shopList = await store.dispatch(loadAdminShopList())
      setShopList(shopList)
      setShopListLoaded(true)
    }
    fetchUserList()
  }, [])

  return { shopListLoaded, shopList }
}

export default useAdminShopList
