import { connect } from 'react-redux'
import { Card, Form, Button } from 'react-bootstrap'
import { KEY_ICON, APPROVE_ICON } from '../../../utils/fontAwesomeDefaults'
import useConfirmCancelModal from '../../hooks/modal-hooks/useConfirmCancelModal'
import ConfirmCancelModal from '../../layout/modals/ConfirmCancelModal'
import { refreshAPIKey } from '../../../redux/actions/users'
import store from '../../../redux/store'
import { setAlert } from '../../../redux/actions/alert'

const ApiAccess = ({
  users: { user },
  stripe: { subscription },
  refreshAPIKey
}) => {
  const refreshKey = () => {
    refreshAPIKey(user, subscription)
  }

  const newAPIKeyModal = useConfirmCancelModal({
    icon: KEY_ICON,
    title: 'Refresh API Key',
    confirmationPrompt: 'To confirm refresh',
    confirmationText: 'refresh',
    confirmationButtonIcon: APPROVE_ICON,
    confirmationButtonText: 'Refresh',

    confirmationAction: refreshKey
  })

  const handleRefresh = () => {
    const { status } = subscription
    if (status !== 'active' && status !== 'trialing') {
      store.dispatch(setAlert('Please activate your subscription', 'danger'))
    } else newAPIKeyModal.setShowModal(true)
  }
  return (
    <>
      <Card className='mb-3'>
        <Card.Header className='bg-white'>
          <Card.Title>Api Access</Card.Title>
        </Card.Header>
        <Card.Body style={{ fontSize: '.8rem' }}>
          <Form.Group>
            <Form.Label>API Key</Form.Label>
            <Form.Control type='text' disabled value={user.api_key || 'none'} />
          </Form.Group>
          <p className='mt-3'>
            {' '}
            This API Key is used to make Fit Kit Systems API requests for use in
            your applications. Do not share your API Key with anyone. If your
            API Key has become compromised, you can disable it and receive a new
            one by clicking the button below.
          </p>
          <p>Rate Limits: 50/15 minutes | 500/day</p>
        </Card.Body>
        <Card.Footer className='bg-white'>
          <div className='d-grid gap-2'>
            <Button variant='dark' onClick={handleRefresh}>
              <i className={KEY_ICON} /> Refresh API Key
            </Button>
          </div>
        </Card.Footer>
      </Card>
      <ConfirmCancelModal {...newAPIKeyModal}>
        This action will disable your current API key, if any, and generate a
        new one.
      </ConfirmCancelModal>
    </>
  )
}

const mapStateToProps = (state) => ({
  users: state.users,
  stripe: state.stripe
})

export default connect(mapStateToProps, { refreshAPIKey })(ApiAccess)
