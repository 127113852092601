export const height = {
  name: 'height',
  type: 'number',
  label: 'Height',
  validationtype: 'measurement',
  min: 0,
  //max: 84.6,
  step: 0.1,
  fksmode: 'inline-number',
  inline: 'true'
}

export const footLength = {
  name: 'footLength',
  type: 'number',
  label: 'Foot Length',
  validationtype: 'measurement',
  //min: 21.5,
  max: 31.8,
  min: 0,
  step: 0.1,
  munit: 'cm.',

  inline: 'true'
}
export const inseam = {
  name: 'inseam',
  type: 'number',
  label: 'Inseam',
  validationtype: 'measurement',
  //min: 70,
  min: 0,
  max: 99.9,
  step: 0.1,
  munit: 'cm.',
  inline: 'true'
}
export const torso = {
  name: 'torso',
  type: 'number',
  label: 'Torso',
  validationtype: 'measurement',
  // min: 48,
  min: 0,
  max: 72,
  step: 0.1,
  munit: 'cm.',
  inline: 'true'
}
export const arm = {
  name: 'arm',
  type: 'number',
  label: 'Arm',
  validationtype: 'measurement',
  //min: 48,
  min: 0,
  max: 72,
  step: 0.1,
  munit: 'cm.',
  inline: 'true'
}

export const shoulders = {
  name: 'shoulders',
  type: 'number',
  label: 'Shoulders',
  validationtype: 'measurement',
  min: 34,
  max: 50,
  step: 0.5,
  munit: 'cm.',
  inline: 'true'
}
export const sitBones = {
  name: 'sitBones',
  type: 'number',
  label: 'Sit Bones',
  validationtype: 'measurement',
  min: 80,
  max: 150,
  step: 1,
  munit: 'mm.',
  inline: 'true'
}

export const torsoAngle = {
  name: 'torsoAngle',
  type: 'number',
  label: 'Torso Angle',
  min: 40,
  max: 50,
  step: 0.1,
  munit: 'deg.',
  validationtype: 'measurement',
  tip: 'Angle of Torso to horizontal with shortest desired reach to bars.'
}

export const spinalCurve = {
  name: 'spinalCurve',
  type: 'number',
  label: 'Spinal Curve',
  min: 160,
  max: 180,
  step: 0.5,
  munit: 'deg.',
  validationtype: 'measurement',
  tip: 'Amount of spinal curve relative to the straight line between the hip and shoulder'
}
export const elbowAngle = {
  name: 'elbowAngle',
  type: 'number',
  label: 'Elbow Angle',
  min: 160,
  max: 180,
  step: 0.5,
  munit: 'deg.',
  validationtype: 'measurement',
  tip: 'Amount of elbow bend relative to the straight line between the shoulder and wrist'
}
