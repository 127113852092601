import { Routes, Route } from 'react-router-dom'
import MainFooter from '../layout/MainFooter'

const FooterRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path='/pre-fit-interview/:userId'
        element={<div style={{ minHeight: '100px' }}></div>}
      />
      <Route path='*' element={<MainFooter />} />
    </Routes>
  )
}

export default FooterRoutes
