export const shoeBrand = {
  type: 'text',
  label: 'Shoe Brand',
  validationType: 'inputLength',
  length: 1
}

export const shoeModel = {
  type: 'text',
  label: 'Shoe Model',
  validationType: 'inputLength',
  length: 1
}

export const shoeSize = {
  type: 'number',
  label: 'Shoe Size',
  step: 0.5,
  munit: 'none',
  validationType: 'measurement'
}

export const insoles = {
  type: 'text',
  label: 'Insoles'
}
export const cleatModel = {
  type: 'text',
  label: 'Cleat Model'
}
export const cleatAdjustments = {
  type: 'text',
  as: 'textarea',
  rows: '4',
  label: 'Cleat Adjustments'
}
export const cleatModifications = {
  type: 'text',
  as: 'textarea',
  rows: '4',
  label: 'Recommendations/Notes'
}
