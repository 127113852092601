import { useState, useEffect } from 'react'
import store from '../../../redux/store'
import { loadUserBikeList } from '../../../redux/actions/bikes'

const useUserBikeList = () => {
  const [bikeListLoaded, setBikeListLoaded] = useState(false)
  const [bikeList, setBikeList] = useState()

  useEffect(() => {
    const fetchBikeList = async () => {
      const bikeList = await store.dispatch(loadUserBikeList())
      setBikeList(bikeList)
      setBikeListLoaded(true)
    }
    fetchBikeList()
  }, [])

  return { bikeListLoaded, bikeList }
}

export default useUserBikeList
