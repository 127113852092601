import { Table } from 'react-bootstrap'
import StackReachRecommendationRow from './StackReachRecommendationRow'
import QuickFitDashboardCard from '../../quickfit/QuickFitDashboardCard'
import {
  stackAndReachFromSaddleHeight,
  calculateFrameSize,
  calculateMinimumSaddleHeight,
  calculateMaximumSaddleHeight
} from '../../../lib/fitkit-js-functions-v3.mjs'
const QuickStackReachRecommendations = ({
  inseam,
  footLength,
  arm,
  torso,
  saddleHeight,
  saddleHeightMode
}) => {
  let recommendations
  let estimatedSaddleHeight, minSaddleHeight, maxSaddleHeight
  if (saddleHeightMode === 'Specify Saddle Height')
    recommendations = stackAndReachFromSaddleHeight(
      inseam,
      torso,
      arm,
      saddleHeight
    )
  else {
    const frameSize = calculateFrameSize(inseam)
    minSaddleHeight = calculateMinimumSaddleHeight(frameSize, footLength)
    maxSaddleHeight = calculateMaximumSaddleHeight(frameSize, footLength)
    //estimatedSaddleHeight = (minSaddleHeight + maxSaddleHeight) / 2
    estimatedSaddleHeight = minSaddleHeight * 10
    recommendations = stackAndReachFromSaddleHeight(
      inseam,
      torso,
      arm,
      estimatedSaddleHeight
    )
  }

  const {
    targetStack,
    minStack,
    maxStack,
    enduranceTargetStack,
    enduranceMinStack,
    enduranceMaxStack,
    targetReach,
    minReach,
    maxReach,
    enduranceTargetReach,
    enduranceMinReach,
    enduranceMaxReach,
    frameSize
  } = recommendations
  return (
    <div>
      <QuickFitDashboardCard title={'Stack/Reach Recommendations'}>
        <Table striped size='sm' style={{ fontSize: '.8rem' }}>
          <tbody>
            {saddleHeightMode === 'Estimate from Inseam' ? (
              <StackReachRecommendationRow
                label='Saddle Height to Pedal Spindle (Estimated)'
                value={
                  estimatedSaddleHeight +
                  ' (' +
                  minSaddleHeight +
                  '-' +
                  maxSaddleHeight +
                  ')'
                }
              />
            ) : (
              <StackReachRecommendationRow
                label='Saddle Height to Pedal Spindle (User specified)'
                value={saddleHeight}
              />
            )}
            <StackReachRecommendationRow
              label='Traditional Frame Size Number:'
              unit='cm.'
              value={<>{frameSize}</>}
            />
            <StackReachRecommendationRow
              label='Target Race Stack:'
              unit='mm.'
              value={
                <>
                  {targetStack} ({minStack}-{maxStack})
                </>
              }
            />
            <StackReachRecommendationRow
              label='Target Race Reach:'
              unit='mm.'
              value={
                <>
                  {targetReach} ({minReach}-{maxReach})
                </>
              }
            />
            <StackReachRecommendationRow
              label='Target Endurance Stack:'
              unit='mm.'
              value={
                <>
                  {enduranceTargetStack} ({enduranceMinStack}-
                  {enduranceMaxStack})
                </>
              }
            />
            <StackReachRecommendationRow
              label='Target Endurance Reach:'
              unit='mm.'
              value={
                <>
                  {enduranceTargetReach} ({enduranceMinReach}-
                  {enduranceMaxReach})
                </>
              }
            />
          </tbody>
        </Table>
      </QuickFitDashboardCard>
    </div>
  )
}
export default QuickStackReachRecommendations
