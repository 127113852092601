export const getPaymentFrequency = (plan) => {
  const { interval, interval_count } = plan
  if (interval === 'year') return 'Yearly'
  if (interval === 'month' && interval_count === 1) return 'Monthly'
  if (interval === 'month' && interval_count === 6) return 'Every Six Months'
  return interval
}

export const getFrequencyValue = (plan) => {
  const { interval, interval_count } = plan
  if (interval === 'year') return 'yearly'
  if (interval === 'month' && interval_count === 1) return 'monthly'
  if (interval === 'month' && interval_count === 6) return 'six months'
  return interval
}

export const getPaymentAmount = (plan) => {
  const { amount } = plan
  return '$' + Math.round(amount / 100).toFixed(2)
}

export const getSubscriptionPrice = (frequency) => {
  switch (frequency) {
    case 'yearly':
      return process.env.REACT_APP_SUBSCRIPTION_PRICE
    case 'six months':
      return process.env.REACT_APP_SIX_MONTH_SUBSCRIPTION_PRICE
    case 'monthly':
      return process.env.REACT_APP_MONTHLY_SUBSCRIPTION_PRICE
    default:
      return process.env.REACT_APP_SUBSCRIPTION_PRICE
  }
}
