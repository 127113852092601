import { Routes, Route } from 'react-router-dom'
import AdminDashboard from '../admin/dashboard/AdminDashboard'
import SettingsTabs from '../admin/SettingsTabs'
import DataToolsTabs from '../admin/DataToolsTabs'
import NotFound from '../layout/NotFound'

const AdminRoutes = (props) => {
  return (
    <Routes>
      <Route path='dashboard' element={<AdminDashboard {...props} />} />
      <Route path='settings' element={<SettingsTabs {...props} />} />
      <Route path='data' element={<DataToolsTabs {...props} />} />
      <Route path='*' status={404} element={<NotFound />} />
    </Routes>
  )
}

export default AdminRoutes
