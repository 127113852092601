import React from 'react'

import { Form } from 'react-bootstrap'

import Select from '../../forms/form-components/Select'
import Radio from '../../forms/form-components/Radio'
import CheckBox from '../../forms/form-components/CheckBox'
import Switch from '../../forms/form-components/Switch'
import BlockPicker from '../../forms/form-components/BlockPicker'
import DefaultFormGroup from '../../forms/form-components/DefaultFormGroup'
import QuickFitFormGroup from '../../forms/form-components/QuickFitFormGroup'
import TextArea from '../../forms/form-components/TextArea'
import InlineInputAndRadio from '../../forms/form-components/InlineInputAndRadio'
import InlineNumberInput from '../../forms/form-components/InlineNumberInput'

const GenerateForm = ({
  form,
  colSize,
  cols,
  user,
  inLineGroup,
  buttonText,
  disabled,
  error,
  onSubmit,
  formGroup,
  noMargin,
  fksmode
}) => {
  return (
    <>
      <Form onSubmit={onSubmit}>
        {Object.values(form).map((input, index) => {
          if (inLineGroup) {
            for (let i = 0; i < inLineGroup.length; i++) {
              if (inLineGroup[i].includes(input) && inLineGroup[i][0] !== input)
                return null
              if (inLineGroup[i][0] === input) {
                return (
                  <InlineInputAndRadio
                    key={index}
                    input={inLineGroup[i][0]}
                    radio={inLineGroup[i][1]}
                    min={input.min}
                    max={input.max}
                    step={input.step}
                    value={input.value}
                  />
                )
              }
            }
          }

          if (
            input.fksmode === 'other-text' &&
            !Object.values(form)[index - 1].value
          )
            return <div key={index} className='mb-2'></div>

          switch (input.type) {
            case 'select':
              return (
                <Select
                  key={index}
                  {...input}
                  colSize={colSize}
                  formGroup={formGroup}
                  cols={cols}
                  fksmode={fksmode || input.fksmode}
                />
              )
            case 'radio':
              return (
                <Radio
                  key={index}
                  {...input}
                  colSize={colSize}
                  cols={cols}
                  noMargin={noMargin}
                />
              )
            case 'checkbox':
              return (
                <CheckBox key={index} {...input} colSize={colSize} boxFirst />
              )
            case 'switch':
              return <Switch key={index} {...input} colSize={colSize} />
            case 'blockpicker':
              return <BlockPicker key={index} {...input} colSize={colSize} />
            case 'text-area':
              return <TextArea key={index} {...input} colSize={colSize} />
            case 'number':
              return formGroup === 'quickfit' ? (
                <QuickFitFormGroup
                  key={index}
                  {...input}
                  colSize={input.as === 'textarea' ? 12 : colSize}
                  user={user}
                  cols={cols}
                />
              ) : input.inline ? (
                <InlineNumberInput key={index} {...input} colSize={colSize} />
              ) : (
                <DefaultFormGroup
                  key={index}
                  {...input}
                  colSize={input.as === 'textarea' ? 12 : colSize}
                  user={user}
                />
              )
            default:
              return formGroup !== 'quickfit' ? (
                <DefaultFormGroup
                  key={index}
                  {...input}
                  colSize={input.as === 'textarea' ? 12 : colSize}
                  user={user}
                  error={error}
                  mode={buttonText}
                />
              ) : (
                <QuickFitFormGroup
                  key={index}
                  {...input}
                  colSize={input.as === 'textarea' ? 12 : colSize}
                  user={user}
                  cols={cols}
                />
              )
          }
        })}
        {onSubmit && (
          <div className='mt-4 d-grid gap-2'>
            <input
              type='submit'
              value={buttonText}
              disabled={disabled}
              className='btn btn-dark'
            />
          </div>
        )}
      </Form>
    </>
  )
}

export default GenerateForm
