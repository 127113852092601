import {
  CREATE_CYCLIST,
  CYCLIST_LOADED,
  UPDATE_CYCLIST,
  DELETE_CYCLIST,
  CLEAR_CYCLIST,
  CLEAR_CYCLISTS,
  CYCLISTS_LOADED
} from '../actions/types'

const initialState = {
  //  cyclistLoading: true,
  cyclist: null,
  cyclists: []
}

export default function cyclists(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case CYCLISTS_LOADED:
      return {
        ...state,
        cyclists: payload
      }
    case CYCLIST_LOADED:
      return {
        ...state,
        cyclist: payload
      }
    case CREATE_CYCLIST:
    case UPDATE_CYCLIST:
      return {
        ...state,
        ...payload,
        cyclist: payload
      }

    case DELETE_CYCLIST:
      return {
        ...state,
        cyclists: state.cyclists.filter((cyclist) => cyclist._id !== payload)
      }
    case CLEAR_CYCLIST:
    case CLEAR_CYCLISTS:
      return {
        ...initialState
      }

    default:
      return state
  }
}
