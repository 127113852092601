export const referralSource = {
  type: 'select',
  label: 'How did you hear about us?',
  options: [
    'Returning Client',
    'Internet Search',
    'Social Media',
    'Friend or Family',
    'Bike Shop',
    'Print Media',
    'Other'
  ],
  fksmode: 'stacked'
}
export const referralSourceDetails = {
  type: 'text',
  placeholder: 'Enter name or other referral details'
}

export const physicalComments = {
  type: 'text-area',
  label: 'Physical Considerations',
  placeholder:
    'Describe any physical injuries, limitations or areas of concern, including any injuries or surgeries impacting your riding; back issues; leg length difference; or hearing loss.',
  initialValue: ''
}

export const heightUnits = {
  type: 'radio',
  options: ['in.', 'cm.'],
  initialValue: 'in.',
  inline: true
}

export const weightUnits = {
  type: 'radio',
  options: ['lbs.', 'kgs.'],
  initialValue: 'lbs.',
  inline: true
}

export const discomfortFeet = {
  type: 'checkbox',
  label: 'Feet',
  grouplabel: 'Current Areas of Discomfort (Check all that apply)'
}

export const discomfortKnees = {
  type: 'checkbox',
  label: 'Knees'
}
export const discomfortHips = {
  type: 'checkbox',
  label: 'Hips'
}
export const discomfortButt = {
  type: 'checkbox',
  label: 'Butt'
}
export const discomfortPerineumGenitalia = {
  type: 'checkbox',
  label: 'Perineum or genitalia'
}
export const discomfortLowerBack = {
  type: 'checkbox',
  label: 'Lower back'
}
export const discomfortShouldersNeck = {
  type: 'checkbox',
  label: 'Shoulders or neck'
}
export const discomfortHandsWristsElbows = {
  type: 'checkbox',
  label: 'Hands, wrists or elbows'
}
export const discomfortOther = {
  type: 'checkbox',
  label: 'Other',
  fksmode: 'other-check'
}

export const discomfortOtherText = {
  type: 'text',
  placeholder: 'Describe other areas of discomfort',
  fksmode: 'other-text'
}

export const otherPhysicalActivities = {
  type: 'text-area',
  label: 'Other Physical Activities',
  placeholder: 'Any other physical activities?'
}

export const priorBikeFit = {
  type: 'radio',
  label: 'Have you had a bike fit before?',
  options: ['No', 'Yes'],
  inline: true
}

export const objectiveMeasureAndAdvise = {
  type: 'checkbox',
  label: 'Measure and Advise for New Bike Purchase',
  grouplabel: 'Bike Fit Objectives (Check all that apply)'
}
export const objectiveSetUp = {
  type: 'checkbox',
  label: 'Set Up New Bike'
}
export const objectiveGeneral = {
  type: 'checkbox',
  label: 'General Check Up and Refinement'
}
export const objectiveRelieve = {
  type: 'checkbox',
  label: 'Relieve Pain or Discomfort'
}
export const objectiveImprove = {
  type: 'checkbox',
  label: 'Improve Performance'
}
export const objectiveReplicate = {
  type: 'checkbox',
  label: 'Replicate Fit from Another Bike'
}
export const objectiveShoe = {
  type: 'checkbox',
  label: 'Shoe or Cleat Fit'
}
export const objectiveSaddle = {
  type: 'checkbox',
  label: 'Saddle Fit'
}
export const objectiveAero = {
  type: 'checkbox',
  label: 'Aero Fit'
}
export const objectiveComments = {
  type: 'text-area',
  placeholder: 'Enter any additional comments here.'
}

export const yearsCycling = {
  type: 'number',
  label: 'Years of Cycling Experience',
  // fksmode: 'inline-number',
  inline: true
}
export const hoursPerWeek = {
  type: 'number',
  label: 'Average Riding Hours Per Week',
  //fksmode: 'inline-number',
  inline: true
}

export const skillsCrashing = {
  type: 'checkbox',
  label: 'Crashing (prone to)',
  grouplabel: 'Any Issues with Skills and Confidence? (Check all that apply)'
}

export const skillsCornering = {
  type: 'checkbox',
  label: 'Cornering (confidence)',
  initialValue: false
}

export const skillsDescending = {
  type: 'checkbox',
  label: 'Descending (max speed/braking)',
  initialValue: false
}

export const skillsClimbing = {
  type: 'checkbox',
  label: 'Climbing (performance/cadence)',
  initialValue: false
}

export const skillsFlats = {
  type: 'checkbox',
  label: 'Flats (speed/cadence)',
  initialValue: false
}

export const skillsWeightDistribution = {
  type: 'checkbox',
  label: 'Weight Distribution (heavy on saddle or bars)',
  initialValue: false
}

export const cyclingGoals = {
  type: 'text-area',
  label: 'Cycling Goals/Additional Information',
  placeholder: 'Any specific goals or additional information?'
}

export const mediaConsent = {
  type: 'radio',
  options: ['No', 'Yes'],
  label: 'Would you like to provide us with consent?',
  inline: true
}

export const bikeNewStyle = {
  type: 'radio',
  options: ['No', 'Yes'],
  label: 'Is this a new style of bike for you?',
  inline: true
}
export const bikeReasons = {
  type: 'text-area',
  label: 'Reasons for Purchase',
  placeholder: 'Describe your main reasons for getting a new bike.'
}

export const channelsNew = {
  type: 'checkbox',
  label: 'New'
}
export const channelsUsed = {
  type: 'checkbox',
  label: 'Used'
}
export const channelsInStore = {
  type: 'checkbox',
  label: 'In Store'
}
export const channelsOnline = {
  type: 'checkbox',
  label: 'Online'
}
export const channelsCustomFrameBuilder = {
  type: 'checkbox',
  label: 'Custom Frame Builder'
}
export const channelsFullyBuilt = {
  type: 'checkbox',
  label: 'Fully Built and Ready to Ride'
}
export const channelsFrameOnly = {
  type: 'checkbox',
  label: 'Frame Only'
}
export const bikeBudget = {
  type: 'text',
  label: 'Budget'
}
export const bikeBrandsModels = {
  type: 'text-area',
  rows: 4,
  label: 'Brands or Models Under Consideration',
  placeholder: 'List any particular brands or models you are interested in.'
}

export const factorsBrandModel = {
  type: 'checkbox',
  label: 'Brand/Model'
}
export const factorsComponents = {
  type: 'checkbox',
  label: 'Components'
}
export const factorsValue = { type: 'checkbox', label: 'Value' }
export const factorsAvailability = { type: 'checkbox', label: 'Availability' }
export const factorsReviews = { type: 'checkbox', label: 'Reviews' }
export const factorsReputation = { type: 'checkbox', label: 'Reputation' }
export const factorsCost = { type: 'checkbox', label: 'Cost' }
export const factorsFit = { type: 'checkbox', label: 'Fit' }
export const factorsAppearance = { type: 'checkbox', label: 'Appearance' }
export const factorsOther = { type: 'checkbox', label: 'Other' }
export const factorsOtherText = {
  type: 'text-area',
  placeholder: 'Describe other factors.',
  fksmode: 'other-text'
}
