import { useState, useEffect } from 'react'
import store from '../../../redux/store'
import { loadResources } from '../../../redux/actions/resources'

const useResources = () => {
  const [resourcesLoaded, setResourcesLoaded] = useState(false)
  const [resources, setResources] = useState()

  useEffect(() => {
    if (!resourcesLoaded) {
      const fetchResources = async () => {
        const resources = await store.dispatch(loadResources())

        setResources(resources)
        setResourcesLoaded(true)
      }
      fetchResources()
    }
  }, [resourcesLoaded])

  return { resources, resourcesLoaded, setResourcesLoaded }
}

export default useResources
