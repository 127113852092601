import React from 'react'

import { connect } from 'react-redux'
import { deleteInterview } from '../../../redux/actions/prefitInterviews'

import { utcToutc, usNormal } from '../../../utils/dateFormats'

import Tip from '../../layout/overlays/Tip'

import DeleteRecordButton from '../../hook-components/DeleteRecordButton'

import AddNewCustomer from '../customers/AddNewCustomer'

import {
  CYCLIST_ICON,
  CALENDAR_ICON,
  PLUS_ICON
} from '../../../utils/fontAwesomeDefaults'
import AddInterviewToExistingCustomer from './AddInterviewToExistingCustomer'

const NewInterviewRow = ({ interview, deleteInterview, handleDelete }) => {
  const { firstName, lastName } = interview

  const deleteFunction = (interviewId) => {
    deleteInterview(interviewId)
    handleDelete()
  }

  return (
    <>
      <tr>
        <td>
          <Tip
            message={
              <>
                {interview.firstName + ' ' + interview.lastName}
                <br />
                Birthdate: {utcToutc(interview.birthDate)} <br />
                Gender: {interview.gender}
                <br />
                {interview.email}
              </>
            }
          >
            <span>
              <i className={CYCLIST_ICON} />{' '}
              {interview.firstName + ' ' + interview.lastName}
            </span>
          </Tip>
        </td>

        <td>
          <Tip
            message={<>Interview completed {usNormal(interview.created)} </>}
          >
            <span>
              <i className={CALENDAR_ICON} /> {usNormal(interview.created)}
            </span>
          </Tip>
        </td>
        <td>
          <AddNewCustomer
            link={
              <>
                <i className={PLUS_ICON} /> New
              </>
            }
            tip='Create new Customer from Interview.'
            interview={interview}
          />
        </td>
        <td>
          <AddInterviewToExistingCustomer
            link={
              <>
                <i className={CYCLIST_ICON} /> Existing
              </>
            }
            tip='Add Interview to existing customer.'
            interview={interview}
          />
        </td>
        <td>
          <DeleteRecordButton
            title='Delete Interview?'
            confirmationPrompt='To confirm deletion'
            confirmationText='delete interview'
            confirmationButtonText='Delete Interview'
            tip='Delete this Interview.'
            deleteFunction={deleteFunction}
            recordId={interview._id}
          >
            <p>
              Do you want to delete the interview for{' '}
              <strong>{firstName + ' ' + lastName}</strong>?
            </p>
            <p> This action cannot be undone.</p>
          </DeleteRecordButton>
        </td>
      </tr>
    </>
  )
}

export default connect(null, {
  deleteInterview
})(NewInterviewRow)
