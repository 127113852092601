import { useState } from 'react'
import { Card, Tab, Row, Col, Nav } from 'react-bootstrap'

import UserList from './lists/UserList'
import CustomerList from './lists/CustomerList'
import ShopList from './lists/ShopList'
import SearchData from './search/SearchData'
import StackReachData from './stack-reach-data/StackReachData'
import AdminContentContainer from '../layout/cards-containers/AdminContentContainer'

const DataToolsTabs = (props) => {
  const [searchData, setSearchData] = useState(null)
  const [collection, setCollection] = useState(null)
  const [stackReachData, setStackReachData] = useState(null)
  const [errors, setErrors] = useState(null)

  return (
    <AdminContentContainer>
      <Tab.Container
        id='left-tabs-example'
        onSelect={(k) => {
          if (k !== 'search') {
            setSearchData(null)
            setCollection(null)
            setErrors(null)
          }
          if (k !== 'stack-reach') {
            setStackReachData(null)
          }
        }}
        defaultActiveKey='users'
      >
        <Row>
          <Col sm='auto'>
            <Card>
              <Nav variant='pills' className='flex-column'>
                <Nav.Item>
                  <Nav.Link eventKey='users'>User List</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='customers'>Customer List</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='shops'>Shop List</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='search'>Search Database</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='stack-reach'>Stack Reach Data</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card>
          </Col>
          <Col>
            <Tab.Content>
              <Tab.Pane eventKey='users'>
                <UserList {...props} />
              </Tab.Pane>
              <Tab.Pane eventKey='customers'>
                <CustomerList {...props} />
              </Tab.Pane>
              <Tab.Pane eventKey='shops'>
                <ShopList {...props} />
              </Tab.Pane>
              <Tab.Pane eventKey='search'>
                <SearchData
                  {...props}
                  setSearchData={setSearchData}
                  setCollection={setCollection}
                  searchedCollection={collection}
                  setErrors={setErrors}
                  searchData={searchData}
                  errors={errors}
                />
              </Tab.Pane>
              <Tab.Pane eventKey='stack-reach'>
                <StackReachData
                  {...props}
                  setErrors={setErrors}
                  stackReachData={stackReachData}
                  setStackReachData={setStackReachData}
                  errors={errors}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </AdminContentContainer>
  )
}

export default DataToolsTabs
