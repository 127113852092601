import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import QuickFitTabContent from '../QuickFitTabContent'

import useBikeEquipmentForm from '../../hooks/form-hooks/bikes/useBikeEquipmentForm'

import { updateBike } from '../../../redux/actions/bikes'

import { BIKE_ICON } from '../../../utils/fontAwesomeDefaults'

import { validateForm, changesMade } from '../../../utils/formValidation'

import { getFitHistory } from '../../../utils/generalFunctions'

const BikeEquipment = ({ bike, updateBike, user, fieldOverrides }) => {
  const bikeEquipmentForm = useBikeEquipmentForm(bike, fieldOverrides)

  const [currentBike, setCurrentBike] = useState(bike)

  useEffect(() => {
    if (bike !== currentBike) {
      bikeEquipmentForm.reset()
      setCurrentBike(bike)
    }
  }, [bike, currentBike, bikeEquipmentForm])

  const { type } = bike
  let editForm

  switch (type) {
    case 'Mountain Bike':
    case 'Fat Bike':
      editForm = bikeEquipmentForm.mountainBikeForm
      break
    case 'TT/Tri Bike':
      editForm = bikeEquipmentForm.ttBikeForm
      break
    default:
      editForm = bikeEquipmentForm.roadBikeForm
  }

  const saveFunction = () => {
    const bikeObject = {
      ...bikeEquipmentForm.values,
      fitHistory: getFitHistory(bike)
    }
    updateBike(bike._id, bikeObject, 'Bike Equipment Updated')
  }

  const oldValues = {}
  const newValues = {}

  Object.entries(editForm).map(([key, value]) => {
    oldValues[key] = bike[key]
    newValues[key] = bikeEquipmentForm.values[key]
    return null
  })

  return (
    <>
      <QuickFitTabContent
        title='Equipment'
        dataForm={editForm}
        dataObject={bike}
        editButtonText='Edit Equipment'
        modalTitle='Edit Equipment'
        modalForm={{ form: editForm, reset: bikeEquipmentForm.reset }}
        modalTitleIcon={BIKE_ICON}
        confirmationButtonIcon={BIKE_ICON}
        saveFunction={saveFunction}
        user={user}
        formGroup={'quickfit'}
        cols={['1', '5', '5', '1']}
        disabled={
          !validateForm({ make: editForm.make, model: editForm.model }) ||
          !changesMade(oldValues, newValues)
        }
      />
    </>
  )
}

export default connect(null, { updateBike })(BikeEquipment)
