import React from 'react'
import { useLocation, Link } from 'react-router-dom'

import store from '../../redux/store'

import { createFeedback } from '../../redux/actions/feedback'

import GenerateForm from '../forms/form-components/GenerateForm'

import useFeedbackForm from '../hooks/form-hooks/users-auth/useFeedbackForm'
import useFormModal from '../hooks/modal-hooks/useFormModal'
import FormModal from '../layout/modals/FormModal'

import { COMMENT_ICON } from '../../utils/fontAwesomeDefaults'

const FeedbackForm = ({ showFeedbackModal, setShowFeedbackModal, user }) => {
  const location = useLocation()

  const feedbackForm = useFeedbackForm({
    feedbackMessage: ''
  })

  const { validateForm, form } = feedbackForm

  const saveFunction = () => {
    const feedback = {
      feedbackMessage: feedbackForm.values.feedbackMessage,
      userName: user.name,
      userEmail: user.email,
      postedBy: user._id,
      originUrl: location.pathname
    }

    store.dispatch(
      createFeedback(feedback, 'Your feedback has been recorded. Thank you.')
    )
    feedbackForm.reset()
    setShowFeedbackModal(false)
  }

  const formModal = useFormModal({
    icon: COMMENT_ICON,
    title: `Feedback`,
    confirmationButtonIcon: COMMENT_ICON,
    confirmationButtonText: 'Send Feedback',

    cancelAction: () => {
      feedbackForm.reset()
      setShowFeedbackModal(false)
    },

    confirmationAction: () => {
      saveFunction()
    }
  })

  return (
    <>
      <FormModal
        {...formModal}
        showModal={showFeedbackModal}
        disabled={
          !validateForm({
            feedbackMessage: form.feedbackMessage
          })
        }
      >
        Please use this form to make suggestions, report bugs or provide any
        other feedback. For assistance with account or service related
        questions, please go to{' '}
        <Link onClick={() => setShowFeedbackModal(false)} to='/contact'>
          Contact Us
        </Link>
        . We are unable to respond to support requests submitted through this
        form.
        <GenerateForm {...feedbackForm} />
      </FormModal>
    </>
  )
}

export default FeedbackForm
